import {
  ActionIcon,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Group,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import Store from "../../utils/store";
import { GetM14Ent } from "../../utils/slices/TaxClassSlice";
import { useSelector } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import GlobalClass from "../../utils/GlobalClass";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLDateBox from "../../PlusComponent/PLDateBox";
import gensetting from "../../utils/gensetting";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import {
  GetAccountList,
  GetTMFormat,
} from "../../utils/slices/AccountListSlice";
import useWindowDimensions from "../../utils/UseWindowDimensions";

const TaxClassForm = (props) => {
  console.log("TaxClassForm props=>",props);
  const { obj, index } = props;
  let p0 = obj?.p0;
  const formRef = useRef(null);
  const dateRef = useRef(null);
  const taxRef = useRef(null);

  // console.log(obj, "TaxClassForm");
  const [columns, setColumns] = useState([]);
  const [taxClassObj, setTaxClassObj] = useState({
    M51F01: "",
    APPDATE: "",
    APPRATE: null,
    EGKEYID: new Date().getTime(),
  });
  const [editData, setEditData] = useState(null);
  const [group, setGroup] = useState({
    FIELD01: "",
    FIELD02: "",
    FIELD03: "",
    FIELD04: "",
    FIELD05: 0,
    FLDUNQ: 0,
    FLDAED: "A",
    FLDBRC: "",
  });
  const [m14Data, setM14Data] = useState([]);
  const [editDataoM14, setEditDataoM14] = useState({});
  const [editM14DATA, setEditM14DATA] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const { height } = useWindowDimensions();

  // let TmData , isLoading
  const {
    TaxClassFormData: formEntData,
    isLoadingForm: isLoading,
    hasError,
    ErrorMsg,
  } = useSelector((state) => state?.TaxClassData);
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const M14DATA = formEntData?.M14DATA;
  const oM14 = formEntData?.oM14;
  const columnHelper = createColumnHelper();
  const {
    AccountListData: accList,
    TMFormatData: tmList,
    isLoading: AccNameisLoading,
  } = useSelector((state) => state.AccountList);

  // console.log(ErrorMsg, "ErrorMsg-");
  function hendleSaveData() {
    // if (taxClassObj?.M51F01 == null) {
    //   GlobalClass.Notify(
    //     "warning",
    //     "Validation error",
    //     "Please fill out all the required fields"
    //   );
    //   return;
    // }
    let date = DateToString(new Date());
    let newObj = {
      ...taxClassObj,
      APPDATE: date?.startDate,
      EGKEYID: new Date().getTime(),
    };
    // console.log(newObj, "newObj-", date);

    setM14Data([...m14Data, newObj]);
    setTaxClassObj({
      M51F01: "",
      APPDATE: "",
      APPRATE: null,
      EGKEYID: new Date().getTime(),
    });
    table.setCreatingRow(null);
    setRowSelection({});
    setEditMode(false);
  }

  function handleEditData(e) {
    // setEditMode(true);
    let EditM14Data = [...m14Data];
    console.log(taxClassObj, "exitEditingMode", editData);
    EditM14Data[editData?.index] = taxClassObj;
    setM14Data(EditM14Data);
    setTaxClassObj({
      M51F01: "",
      APPDATE: "",
      APPRATE: null,
      EGKEYID: new Date().getTime(),
    });
    setEditMode(false);
    setEditData(null);
    table.setEditingRow(null);
    setRowSelection({});
    // exitEditingMode();
  }
  function handleNullObj() {
    setTaxClassObj({
      M51F01: "",
      APPDATE: "",
      APPRATE: null,
      EGKEYID: new Date().getTime(),
    });
    setEditData(null);
    setEditMode(false);
  }
  useEffect(() => {
    Promise.resolve(Store.dispatch(GetM14Ent(obj)))
      .then((data) => {
        if (p0 == "E") {
          if (data?.meta?.requestStatus == "fulfilled") {
            setEditDataoM14(data?.payload?.oM14);
            setEditM14DATA(data?.payload?.M14DATA);
          }
        }
      })
      .then(() => {
        Promise.resolve(
          Store.dispatch(
            GetAccountList({
              id: "P_M51",
              name: "",
              type: "A",
              p0: "P_M51",
              p1: "P_M51",
              p2: "",
              p3: "",
            })
          )
        ).then(() =>
          Store.dispatch(
            GetTMFormat({
              id: "P_M51",
              name: "",
              type: "A",
              p0: "P_M51",
              p1: "P_M51",
              p2: "",
              p3: "",
            })
          )
        );
      });

    // else if (p0 == "E") {
    //   Store.dispatch(GetM14Ent(obj)).then((data) => {
    //     // console.log(data, "GetM14EntEdit");

    //   });
    // }
  }, []);

  useEffect(() => {
    if (oM14) {
      setGroup(oM14);
    }
    if (formEntData && M14DATA?.length > 0) {
      console.log(M14DATA, "formEntData--");
      setM14Data(M14DATA?.filter((t) => t?.M51F01 != null));
      setTaxClassObj({ ...M14DATA?.[M14DATA?.length - 1] });
    }
  }, [formEntData, M14DATA, oM14]);
  let compareTwoArrayOfObjects = (editT02, t02Data) => {
    // console.log(editT02, "editT02", t02Data);
    return (
      editT02?.length === t02Data?.length &&
      editT02?.every((v) =>
        t02Data?.some((c) => Object.keys(v).every((key) => v[key] === c[key]))
      )
    );
  };
  const table = useMantineReactTable({
    data: m14Data ?? [],
    columns: columns,
    positionToolbarAlertBanner: "bottom",
    // enableRowSelection: true,
    enableStickyHeader: true,
    enableColumnActions: false,
    enableHiding: false,
    enableSorting: false,
    enableFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    initialState: {},
    mantineTableContainerProps: {
      sx: { height: height * 0.55, maxHeight: "600px" },
    },
    mantineTableProps:{withColumnBorders:true},
    positionToolbarAlertBanner: false,
    // onRowSelectionChange: setRowSelection,
    state: {
      density: "0px",
      rowSelection,
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
      tabIndex: 0,
      onKeyUp: (e) => handleRowKeyDown(e, row, table),
      onClick: (e) => {
        setRowSelection((prev) => {
          if (!prev[row.id]) {
            return { [row.id]: true };
          }
          return prev;
        });
      },
    }),

    editDisplayMode: "row",
    createDisplayMode: "row",
    enableEditing: true,
    onCreatingRowSave: hendleSaveData,
    onEditingRowSave: handleEditData,
    onCreatingRowCancel: handleNullObj,
    onEditingRowCancel: handleNullObj,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              // console.log(taxClassObj, "taxClassObj-",editMode);
              if (!editMode) {
                table.setEditingRow(row);
                setEditData(row);
                // setEditMode(true);
                setTaxClassObj({
                  ...row?.original,
                  EGKEYID: new Date().getTime(),
                });
              }
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        onClick={() => {
          // setTaxClassObj(null)
          // console.log(editData, "taxClassObj-");
          if (editData == null) Promise.resolve(table.setCreatingRow(true));
          setEditMode(true);
        }}
        onFocus={() => {
          // console.log(editData, "taxClassObj");
          if (editData == null)
            Promise.resolve(table.setCreatingRow(true)).then(() => {
              setTimeout(() => {
                taxRef?.current?.handlePopoverToggle();
                setEditMode(true);
              }, 500);
            });
        }}
        size="xs"
      >
        Add New
      </Button>
    ),
  });
  const handleRowKeyDown = (e, row, tab) => {
    // const table = tableRef.current;
    // const rows = table.querySelectorAll("tr");
    // const currentRowIndex = Array.from(rows).indexOf(e.target);
    if (e?.key == "Enter") {
      console.log("TABKEY", e.key, tab);
      // if(!batchTrue)
      {
        Promise.resolve(tab?.setCreatingRow(null)).then(() => {
          tab?.setEditingRow(row);
          setEditData(row);
          setEditMode(false);
          setTaxClassObj({
            ...row?.original,
            EGKEYID: new Date().getTime(),
          });
        });
      }
    }
  };
  function SaveTaxData() {
    // console.log(group?.FIELD02, "m14Data?.length", group?.FIELD02?.length);
    if (group?.FIELD02 == "" || group?.FIELD02?.length < 1) {
      GlobalClass.Notify(
        "warning",
        "Validation error",
        "Please fill out Description field"
      );
      return;
    } else {
      const sortedEditoM14 = JSON.stringify(editDataoM14);
      const sortedGroup = JSON.stringify(group);
      // console.log(
      //   sortedEditoM14 === sortedGroup,
      //   "sortedEditoM14",
      //   sortedGroup,
      //   compareTwoArrayOfObjects(editM14DATA, m14Data)
      // );
      if (
        sortedEditoM14 === sortedGroup &&
        compareTwoArrayOfObjects(editM14DATA, m14Data)
      ) {
        Store.dispatch(ModalDelete(index));
      } else {
        let dataToSend = { oM14: group, M14DATA: m14Data };
        console.log("taxClassObj", dataToSend);
        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: p0 == "E" ? p0 : "A",
          cCode: p0 == "E" ? obj?.id : "",
          cSData: JSON.stringify(dataToSend),
        };
        // console.log(dataToSend, "data Num");
        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: p0 == "E" ? p0 : "A",
          cCode: p0 == "E" ? obj?.id : "",
        });

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };

        fetch(
          GlobalClass.ApiUrl + GlobalClass.PostM14Ent + "?pa=" + param,
          requestOptions
        )
          .then((response) => {
            // console.log(!response.ok, "response.ok");
            if (!response?.ok) {
              throw new Error(`HTTP error! Status: ${response?.status}`);
            } //This block give more detail info about error other then 2xx status codes.
            return response?.json();
          })
          .then((data) => {
            if (data?.status == "SUCCESS") {
              Promise.resolve(Store.dispatch(DataSaveLoading(false)))
                .then(() => {
                  Store.dispatch(ModalDelete(index));
                })
                .then(() => {
                  GlobalClass.Notify(
                    "success",
                    p0 == "A" ? "Added" : "Edited",
                    p0 == "A" ? "Added successfully" : "Edited successfully"
                  );
                }).then(()=>{
                  let PaginationObj = Store?.getState()?.DataSaveLoadingSlice?.PaginationObj;
                  // Store.dispatch(
                  //   GetAccountList({
                  //     id: "15000001",
                  //     name: "Tax Class ",
                  //     p0: "MT",
                  //     p1: "T_M14",
                  //     p2: "TFORM0000001",
                  //     p3: "",
                  //     type: "A",
                  //     pagination: PaginationObj["15000001"],
                  //   })
                  // )
                  Store?.dispatch(GetAccountList({ ...obj?.BtnPnlObj, pagination: PaginationObj[obj?.cSTID] }))
                })
              
            } else if (data?.status == "FAIL") {
              Promise.resolve(Store.dispatch(DataSaveLoading(false))).then(() =>
                GlobalClass.Notify("error", data?.status, data?.message)
              );
            }
          })
          .catch((e) => {
            // console.log("inside catch", e);
            GlobalClass.Notify("error", "Error", `${e.message}`);
            Store.dispatch(DataSaveLoading(false));
          });
      }
    }
  }
  const seperate = (e) => {
    var paraArr = e?.split("~C~");
    // console.log("paraArr=>", paraArr);
    return paraArr;
  };
  function DateToString(e) {
    // console.log(e, "Datetostring");
    let year = e.toLocaleString("en-IN", { year: "numeric" });
    let month = e.toLocaleString("en-IN", {
      month: "2-digit",
    });
    let day = e.toLocaleString("en-IN", { day: "2-digit" });

    let formatedStartDate = day + "/" + month + "/" + year;
    let startDate = year + month + day;
    return { formatedStartDate, startDate };
  }
  function renderValue(c) {
    let setInput;
    // console.log(taxClassObj?.[c?.S53F04], "renderValue");
    switch (c?.S53F14) {
      case "NUMBOX":
        return (setInput = (
          <PLNumberBox
            value={taxClassObj?.[c?.S53F04]}
            setEdit={(e) => {
              if (e != 0) setTaxClassObj({ ...taxClassObj, [c?.S53F04]: e });
              else setTaxClassObj({ ...taxClassObj, [c?.S53F04]: 0 });
            }}
          />
        ));

        break;
      case "TEXTBOX":
        if (c?.S53F04 == "APPDATE") {
          let { startDate } = DateToString(new Date());
          return (setInput = (
            <PLDateBox
              ref={dateRef}
              setEdit={(e) => {
                let { startDate } = DateToString(e);

                if (startDate?.length == 8) {
                  setTaxClassObj({ ...taxClassObj, [c?.S53F04]: startDate });
                }
              }}
              onKeyDown={(e) => {
                console.log("Esc key Press", e);
                if (e.key == "Enter" || e.key == "Tab") {
                  // console.log("Enter key Press",e)
                  if (editData == null) {
                    // if (InvTypeDropDown == null) {
                    Promise.resolve(hendleSaveData())
                      .then(() => {
                        // if (!batchTrue){
                        //   console.log("Here In Amount",!batchTrue);

                        handleNullObj();
                      })
                      .then(() => {
                        //  Promise.resolve().then(()=>{
                        // if (!batchTrue) {

                        setEditMode(true);
                        table.setCreatingRow(true);
                        // } else{
                        //   setCreateFlag(false)
                        // }
                      });
                    // });
                    // }
                  } else {
                    console.log("Edit Product Call", editData);

                    Promise.resolve()
                      .then(() => handleEditData())
                      .then(() => {
                       
                        setTimeout(() => {
                          setTaxClassObj({
                            M51F01: "",
                            APPDATE: "",
                            APPRATE: null,
                            EGKEYID: new Date().getTime(),
                          });
                          setEditMode(true);
                          table.setCreatingRow(true);
                        }, 500);
                      });
                  }
                  // }
                }
                // else if (e.key == "Escape") {
                //   console.log("Esc key Press", e);
                //   rowCancelHandler();
                //   table.setCreatingRow(false);
                //   setCreateFlag(false);
                // }
              }}
              defaultval={
                typeof taxClassObj?.[c?.S53F04] == "string" &&
                taxClassObj?.[c?.S53F04] != "Invalid Date"
                  ? taxClassObj?.[c?.S53F04] != ""
                    ? taxClassObj?.[c?.S53F04]?.trim()
                    : startDate
                  : startDate
              }
              showClearButton={false}
              showDropDownButton={false}
              useMaskBehavior={true}
              dispformat="DD/MM/YYYY"
            />
          ));
        } else
          return (setInput = (
            <PLTextBox
              value={taxClassObj?.[c?.S53F04]}
              setEdit={(e) => {
                setTaxClassObj({
                  ...taxClassObj,
                  [c?.S53F04]: e?.target?.value,
                });
              }}
            />
          ));

        break;
      case "PLPOPUP":
        return (setInput = (
          <PLDataGrid
            // autoFocus={true}
            ref={taxRef}
            width={"500px"}
            value={taxClassObj?.[c?.S53F04]}
            TmData={tmList["P_M51"]?.cSTID}
            data={accList["P_M51"]}
            // p1={seperate(c?.S53F15)[1]}
            setEdit={(e) => {
              Promise.resolve(
                setTaxClassObj({ ...taxClassObj, [c?.S53F04]: e?.FIELD01 })
              ).then(() => {
                dateRef?.current?.focus();
              });
              // setProdCode(e?.FIELD01)
            }}
          />
        ));
        break;
      case "COMBOBOX":
        return (setInput = (
          <PLComboBox
            value={taxClassObj?.[c?.S53F04]}
            copno={seperate(c?.S53F15)[1]}
            dispexpr={"DisplayMember"}
            valexpr={"ValueMember"}
            setEdit={(e) => {
              setTaxClassObj({ ...taxClassObj, [c?.S53F04]: e.value });
            }}
            dropdownPosition="Bottom"
            defaultval={taxClassObj?.[c?.S53F04]}
          />
        ));

        break;
      case "DATEPICKER":
        let { startDate } = DateToString(new Date());
        // console.log(startDate, "startDate--");
        return (setInput = (
          <PLDateBox
            setEdit={(e) => {
              let { startDate } = DateToString(e);
              if (startDate?.length == 8) {
                setTaxClassObj({ ...taxClassObj, [c?.S53F04]: startDate });
              }
            }}
            defaultval={
              typeof taxClassObj?.[c?.S53F04] == "string" &&
              taxClassObj?.[c?.S53F04] != "Invalid Date"
                ? taxClassObj?.[c?.S53F04] != ""
                  ? taxClassObj?.[c?.S53F04]?.trim()
                  : startDate
                : startDate
            }
            showClearButton={false}
            showDropDownButton={false}
            useMaskBehavior={true}
            dispformat="DD/MM/YYYY"
          />
        ));
        break;
      default:
        return (setInput = null);
        break;
    }
  }
  function formatDate(input) {
    // console.log(input, "input--");
    if (input != null) {
      let year = input?.substring(0, 4);
      let month = input?.substring(4, 6);
      let day = input?.substring(6, 8);
      return day + "/" + month + "/" + year;
    } else {
      return "";
    }
  }
  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);
  useEffect(() => {
    // console.log("TmData--");
    if (formEntData?.M14ENT && formEntData?.M14ENT?.length > 0) {
      var cols = [];
      formEntData?.M14ENT?.map((v, i) => {
        if (v?.S53F00 === "C") {
          cols.push(
            columnHelper.accessor(v?.S53F04, {
              header: v?.S53F03,
              enableHiding: true,
              id: v?.S53F03,
              Cell: ({ cell }) => {
                let val = cell?.getValue();
                if (v?.S53UID == 274) {
                  if (val !== " " || val !== null) {
                    let date = formatDate(val);
                    return date;
                  }
                } else if (v?.S53UID == 273 && val !== null) {
                  if (accList["P_M51"]) {
                    let value = accList["P_M51"]?.filter(
                      (v) => v?.FIELD01 == val
                    );
                    if (value?.length > 0) {
                      return value[0]?.FIELD02;
                    } else {
                      return "";
                    }
                  }
                }
                return val;
              },
              Edit: (props) => {
                if (v?.S53F03 == "Tax Applied Date") {
                  // console.log(v, "val--", props);
                }
                return renderValue(v);
              },
            })
          );
        }
      });

      setColumns(cols);
    }
  }, [formEntData?.M14ENT, taxClassObj, M14DATA, accList, tmList]);
  console.log(formEntData, "formEntData9");
  return (
    <>
      {" "}
      {hasError ? <>
          <Text>{ErrorMsg?.message}</Text>
          <Button onClick={() => {
            if (props?.obj) {
              Promise.resolve(Store.dispatch(GetM14Ent(obj)))
              .then((data) => {
                if (p0 == "E") {
                  if (data?.meta?.requestStatus == "fulfilled") {
                    setEditDataoM14(data?.payload?.oM14);
                    setEditM14DATA(data?.payload?.M14DATA);
                  }
                }
              })
              .then(() => {
                Promise.resolve(
                  Store.dispatch(
                    GetAccountList({
                      id: "P_M51",
                      name: "",
                      type: "A",
                      p0: "P_M51",
                      p1: "P_M51",
                      p2: "",
                      p3: "",
                    })
                  )
                ).then(() =>
                  Store.dispatch(
                    GetTMFormat({
                      id: "P_M51",
                      name: "",
                      type: "A",
                      p0: "P_M51",
                      p1: "P_M51",
                      p2: "",
                      p3: "",
                    })
                  )
                );
              });
            }
          }}>Retry</Button>
        </>  :SaveLoading == true || isLoading || AccNameisLoading["P_M51"] ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <div ref={formRef}>
          <Grid gutter={5}>
            <Grid.Col span={12}>
              <Grid.Col
                span={4}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Grid.Col span={5}>
                  <Text style={{ fontSize: 12 }}>Description</Text>
                </Grid.Col>
                <Grid.Col span={8} style={{ overflow: "hidden" }}>
                  <PLTextBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    value={group?.FIELD02}
                    setEdit={(e) => {
                      setGroup({ ...group, FIELD02: e?.target?.value });
                    }}
                    // disabled={true}
                  />
                </Grid.Col>
              </Grid.Col>
              <Grid.Col>
                <MantineReactTable table={table} />
              </Grid.Col>
              <Grid.Col span={12}>
                <Group spacing={"xs"} position="center" display={"flex"}>
                  <Button
                    size="xs"
                    variant="filled"
                    disabled={editMode}
                    // compact
                    onClick={() => SaveTaxData()}
                  >
                    OK
                  </Button>
                </Group>
              </Grid.Col>
            </Grid.Col>
          </Grid>
        </div>
      )}
    </>
  );
};

export default TaxClassForm;
