import { Button, Grid, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import gensetting from '../../utils/gensetting'
import GlobalClass from '../../utils/GlobalClass'
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice'
import { useDispatch } from 'react-redux'
import Store from '../../utils/store'
import { ModalDelete } from '../../utils/slices/ModalSlice'
import { GetAccountList } from '../../utils/slices/AccountListSlice'
import { BatchLogAddRemoveFun } from '../../utils/TransactionSlices/Transaction'
import PLDataGrid from '../../PlusComponent/PLDataGrid'

const BatchReceipt = (props) => {
    const { T02DataSend, setT02DataSend, oYearSend, OBJSend, setDrawerObjSend, ProductionDataSend, DrawerObjSend, T41ObjSend, propsSend } = props
    console.log('props', props)

    const [T02Data, setT02Data] = useState([])

    const dispatch = useDispatch();

    useEffect(() => {
        if (T02DataSend) {
            setT02Data(T02DataSend)
        }
    }, [T02DataSend])

    useEffect(() => {
        console.log('T02Data', T02Data)
        console.log('T02DataSend', T02DataSend)
        console.log('oYearSend', oYearSend)
    }, [T02DataSend, T02Data, oYearSend])

    const handleFieldChange = (e, i) => {
        const { value } = e.target;

        setT02Data(prev => {
            const newState = [...prev];
            newState[i].FIELD41 = value;
            return newState;
        });
    };

    const handleLocationChange = (e, i) => {
        console.log('e location', e)
        const locationVal = e?.FIELD01;
        const LocationNM = e?.FIELD02;
        setT02Data(prev => {
            const newState = [...prev];
            newState[i].FIELD20 = locationVal;
            newState[i].F20NM = LocationNM;
            return newState;
        });
        console.log('locationVal_', locationVal, 'LocationNM_', LocationNM)
    }

    // const handleSaveData = () => {
    //     Promise.resolve(setT02DataSend(T02Data)).then(() => {
    //         setDrawerObjSend({ ...DrawerObjSend, open: false })
    //     })
    // }

    const isButtonDisabled = T02Data?.some((v, i) => {
        if (oYearSend?.OSC?._BATCHSTOC == "Y" && oYearSend?.OSC?._LOCATIONW == "Y") {
            return v?.FIELD41 == '', v?.FIELD20 == ''
        } else if (oYearSend?.OSC?._BATCHSTOC == "Y" && oYearSend?.OSC?._LOCATIONW == "N") {
            return v?.FIELD41 == ''
        } else if (oYearSend?.OSC?._BATCHSTOC == "N" && oYearSend?.OSC?._LOCATIONW == "Y") {
            return v?.FIELD20 == ''
        }
    })

    const handleSubmit = () => {
        dispatch(DataSaveLoading(true));

        GlobalClass.Notify(
            "info",
            "Adding",
            "Please wait while we process your data"
        );
        var JData = { T41: { ...T41ObjSend, UCRE2: 0 }, T02: T02Data };

        console.log("JData==>", JData);
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "A",
            cCode: "ININ",
            cSData: JSON.stringify(JData),
        };
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "A",
            cCode: "ININ",
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                // console.log('api data', data);
                // console.log('api data status', data?.status);
                if (data.status == "SUCCESS") {
                    let PaginationObj =
                        Store.getState().DataSaveLoadingSlice.PaginationObj;
                    // Promise.resolve(dispatch(DataSaveLoading(false)))
                    Promise.resolve(
                        T02Data?.map((v, i) => {
                            return dispatch(BatchLogAddRemoveFun({ PageCode: "ININ", T02Data: T02Data, Row: v, T41Obj: T41ObjSend, OBJ: { ...OBJSend, p0: "A" } }));
                        })
                    )
                        .then(() => dispatch(DataSaveLoading(false)))
                        .then(() => setTimeout(() => { setDrawerObjSend({ ...DrawerObjSend, open: false }) }, 500))
                        .then(() => setTimeout(() => { dispatch(ModalDelete(propsSend.index)) }, 500))
                        .then(() => {
                            dispatch(GetAccountList({
                                id: "TXNVOULISTIN",
                                name: "Production",
                                p0: "MT",
                                p1: "TXNVOULISTIN",
                                p2: "",
                                p3: "",
                                type: "A",
                                pagination: PaginationObj["TXNVOULISTIN"],
                            }))
                        }).then(() =>
                            GlobalClass.Notify(
                                "success",
                                "Added",
                                "Added successfully"
                            )
                        );
                } else {
                    Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
                        GlobalClass.Notify("error", data?.status, data?.message)
                    );
                }
            })
            .catch((e) => {
                Promise.resolve(
                    GlobalClass.Notify("error", "Error", `${e.message}`)
                ).then(() => dispatch(DataSaveLoading(false)));

            });
    }

    // const seperate = (e) => {
    //     var paraArr = e?.split("~C~");
    //     return paraArr;
    // };

    const LocationFun = () => {
        ProductionDataSend?.T02Ent.map((v, i) => {
            if (v.S53F02 === "E00FIELD20") {
                return (v?.S53F15)[1]
            }
        })
    }

    return (
        <>
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: oYearSend?.OSC?._BATCHSTOC == "Y" && oYearSend?.OSC?._LOCATIONW == "Y" ? 'flex-end' : 'center', alignItems: 'center' }}>
                    {oYearSend?.OSC?._BATCHSTOC == "Y" ?
                        <Grid.Col span={6}>
                            <Text fw={700} style={{ marginLeft: oYearSend?.OSC?._LOCATIONW == "Y" && oYearSend?.OSC?._BATCHSTOC == "Y" ? '210px' : '40px' }}>Batch Receipt</Text>
                        </Grid.Col> : ''
                    }
                    {oYearSend?.OSC?._LOCATIONW == "Y" ?
                        <Grid.Col span={6}>
                            <Text fw={700} style={{ marginLeft: oYearSend?.OSC?._LOCATIONW == "Y" && oYearSend?.OSC?._BATCHSTOC == "Y" ? '120px' : '60px' }}>Location</Text>
                        </Grid.Col> : ''
                    }
                </Grid.Col>
            </Grid>
            {
                T02DataSend?.length > 0 &&
                T02DataSend?.map((v, i) => {
                    // if (i >= 1) {
                    return <>
                        <div key={i}>
                            <Grid gutter={4}>

                                <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid.Col span={3}>
                                        <Text size={12}>{T02Data[i]?.F03NM}</Text>
                                    </Grid.Col>

                                    {
                                        oYearSend?.OSC?._BATCHSTOC == "Y" ?
                                            <Grid.Col span={4}>
                                                <Grid.Col span={12}>
                                                    {/* <Grid.Col span={4}>
                                                    </Grid.Col> */}
                                                    <Grid.Col span={8}>
                                                        <PLTextBox
                                                            value={T02Data[i]?.FIELD41}
                                                            setEdit={(e) => {
                                                                handleFieldChange(e, i)
                                                                // setT02Data(prev => {
                                                                //     const newState = [...prev]
                                                                //     newState[i].FIELD41 = e.target.value
                                                                //     return newState;
                                                                // })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col> : ''
                                    }
                                    {
                                        oYearSend?.OSC?._LOCATIONW == "Y" ?
                                            <Grid.Col span={5}>
                                                <Grid.Col span={12}>
                                                    <Grid.Col span={8}>
                                                        <PLDataGrid
                                                            value={{ value: T02Data[i]?.FIELD20, label: T02Data[i]?.F20NM }}
                                                            p1={"T_M32L"}
                                                            setEdit={(e) => {
                                                                handleLocationChange(e, i)
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col> : ''
                                    }

                                </Grid.Col>
                            </Grid>
                        </div>
                    </>
                    // }
                })
            }
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        style={{ height: "30px", width: "100px" }}
                        disabled={isButtonDisabled}
                        onClick={handleSubmit}
                        onKeyDown={(e) => {
                            if (e.key == "Enter") {
                                handleSubmit()
                            }
                        }}
                    >Save</Button>
                </Grid.Col>
            </Grid>
        </>
    )
}

export default BatchReceipt