import {
  Container,
  Group,
  ActionIcon,
  rem,
  Image,
  useMantineColorScheme,
  createStyles,
  Text,
  useMantineTheme,
  Dialog,
  Divider,
  Grid,
} from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandInstagram,
  IconVersions,
  IconBrandWhatsapp,
  IconSun,
  IconMoonStars,
  IconAB,
  IconTypography,
  IconPalette,
  IconBellRinging2,
  IconBellRinging,
} from "@tabler/icons-react";
import App from "../App.json";
import WhatsAppPopover from "../MyComponent/WhatsappPopup";
import { useEffect, useState } from "react";
import SessionSetting from "./SessionSetting";
import PLDateBox from "../PlusComponent/PLDateBox";
import { useDispatch, useSelector } from "react-redux";
import { getFooterDate } from "../utils/slices/LanguageSlice";
import { createColumnHelper } from "@tanstack/react-table";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import useWindowDimensions from "../utils/UseWindowDimensions";
import { ModalFunction } from "../utils/slices/ModalSlice";
import PLTextBox from "../PlusComponent/PLTextBox";
var iconSize = "2rem";
export function FooterBar(styles) {
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseBtn: "",
  };
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const dark = colorScheme === "dark";
  const date1 = new Date();
  const columnHelper = createColumnHelper()
  const { height, width } = useWindowDimensions();

  const GetGeneral = useSelector((state) => state?.Language?.General);
  const isLoading = useSelector((state) => state.Language?.isLoading);
  const { ReminderData } = useSelector(store => store?.Reminder);

  const [DrawerObj, setDrawerObj] = useState(dra);
  const [fDate, setFDate] = useState();
  const [GRDDT, setGRDDT] = useState();
  const [REMGRD, setREMGRD] = useState();
  const [columns, setColumns] = useState([])
  const [search, setSearch] = useState();
  const [grouping, setGrouping] = useState([]);




  const week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  const year = date1.getFullYear();
  const month = (date1.getMonth() + 1).toString().padStart(2, '0');
  const day = date1.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}/${month}/${day}`;

  useEffect(() => {
    dispatch(getFooterDate(new Date(GetGeneral?.dYSDate).getTime() <= date1.getTime() &&
      new Date(GetGeneral?.dYEDate).getTime() >= date1.getTime()
      ? date1 : new Date(GetGeneral?.dYEDate)))
  }, [GetGeneral])

  useEffect(() => {
    if (ReminderData) {
      setGRDDT(ReminderData?.GRDDT);
      setREMGRD(ReminderData?.REMGRD);
    }
  }, [ReminderData])

  useEffect(() => {
    if (REMGRD && REMGRD?.length > 0) {
      var cols = [];
      REMGRD.filter(ele => ele.S53F00 !== "H").map((item, index) => {
        cols.push(
          columnHelper.accessor(item?.S53F04, {
            header: item?.S53F03,
            enableGrouping: true,
            id: item?.S53UID,
            size: item?.S53F03 == "Particular" ? 160 : 120,
            Cell: ({ cell, row }) => {
              let val = cell.getValue()
              if (item.S53F02 == "RID0075" && val?.trim()) {
                return val.slice(6, 8) + "/" + val.slice(4, 6) + "/" + val.slice(0, 4);
              }
              return val
            }
          })
        );
      });
      setColumns(cols);
      setGrouping(["FIELD05"]);
    }
  }, [REMGRD])

  const table = useMantineReactTable({
    data: GRDDT ?? [],
    columns,
    mantineTableProps: { withColumnBorders: true },
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: true,
    enablePagination: true,
    enableColumnActions: false,
    enableSorting: false,
    enableColumnFilters: false,
    enableColumnDragging: false,
    enableGrouping: true,
    enableHiding: false,
    enableRowNumbers: false,
    enableColumnOrdering: false,
    enableGlobalFilter: false,
    positionToolbarAlertBanner: false,



    onGroupingChange: setGrouping,

    initialState: {
      grouping,
      density: "0px"
    },
    state: {
      grouping,
      globalFilter: search,
      density: '0px'
    },
    mantineTableBodyRowProps: ({ row }) => ({
      tabIndex: 0,
      // onKeyUp: (e) => handleRowKeyDown(e, row),
      // onClick: () => {
      //   setTableRowSelection((prev) => {
      //     if (!prev[row.id]) {
      //       return { [row.id]: true };
      //     }
      //     return prev;
      //   });
      // },
      // selected: tableRowSelection[row.id],
    }),
    mantineTableContainerProps: {
      sx: { height: height * 0.4 },
    },

  });


  return (
    <>
      <Dialog
        opened={DrawerObj.open}
        withCloseButton
        onClose={() => setDrawerObj(dra)}
        size="lg"
        radius="md"
        p={30}
        shadow="md"
      >
        {DrawerObj.body}
      </Dialog>

      {isLoading ? "" : <Container fluid className={"footerf"}>
        {/* <div className={'footerf'} style={{paddingBottom:5}} >   */}
        <Grid columns={11}>
          <Grid.Col span={2}>
            <Text variant="text" color={"gray"}>
              {App.version}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <WhatsAppPopover />
          </Grid.Col>
          <Grid.Col span={1}>
            <ActionIcon
              onClick={() => toggleColorScheme()}
              variant="transparent"
              color={dark ? "yellow" : "blue"}
              title="Toggle color scheme"
              mr={5}
            >
              {dark ? (
                <IconSun size={iconSize} />
              ) : (
                <IconMoonStars size={iconSize} />
              )}
            </ActionIcon>
          </Grid.Col>
          <Grid.Col span={1}>
            <ActionIcon
              onClick={() =>
                setDrawerObj({
                  ...dra,
                  title: <Text fw={700}>Font Setting</Text>,
                  body: (
                    <>
                      <SessionSetting setting={"font"} />
                    </>
                  ),
                  open: true,
                  size: "md",
                  position: "right",
                })
              }
              variant="transparent"
              // color={dark ? 'yellow' : 'blue'}
              title="Font Settings"
              mr={5}
            >
              <IconTypography size={iconSize} />
            </ActionIcon>
          </Grid.Col>
          <Grid.Col span={1}>
            <ActionIcon
              onClick={() =>
                setDrawerObj({
                  ...dra,
                  title: <Text fw={700}>Color Setting</Text>,
                  body: (
                    <>
                      <SessionSetting setting={"color"} />
                    </>
                  ),
                  open: true,
                  size: "md",
                  position: "right",
                })
              }
              variant="transparent"
              // color={dark ? 'yellow' : 'blue'}
              title="color Settings"
              mr={5}
            >
              <IconPalette size={iconSize} />
            </ActionIcon>
          </Grid.Col>
          <Grid.Col span={4} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

            <PLDateBox
              value={
                fDate ?? (new Date(GetGeneral?.dYSDate).getTime() <= date1.getTime() &&
                  new Date(GetGeneral?.dYEDate).getTime() >= date1.getTime()
                  ? date1 : new Date(GetGeneral?.dYEDate))
              }
              setEdit={(e) => {
                if (e || !isNaN(e?.getTime())) {
                  setFDate(e);
                  dispatch(getFooterDate(e))
                }

              }}
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <ActionIcon
              onClick={() =>
              // setDrawerObj({
              //   ...dra,
              //   title: <Text fw={700}>Color Setting</Text>,
              //   body: (
              //     <>
              //       <SessionSetting setting={"color"} />
              //     </>
              //   ),
              //   open: true,
              //   size: "md",
              //   position: "right",
              // })

              // setDrawerObj({
              //   ...dra,
              //   title: <Text fw={700}>Reminder for Date : {formattedDate} </Text>,
              //   body: (
              //     <>
              //       <MantineReactTable table={table} />
              //     </>
              //   ),
              //   open: true,
              //   size: "100%",
              //   position: "right",
              // })

              {
                if (GRDDT && GRDDT?.length > 0) {
                  dispatch(ModalFunction({
                    MTitle: <Text size="lg" weight={600} align="center" style={{ color: '#333' }}> Reminder for Date : {formattedDate} </Text>,
                    MAction: true,
                    MSize: "auto",
                    // position:'right',
                    fullScreen: false,
                    MBody: (i) => (
                      <Grid>
                        <Grid.Col span={12} m={0} p={0}>
                          <Grid.Col span={6} pl={1}>
                            <PLTextBox value={search}
                              setEdit={(e) => {
                                setSearch(e.target.value)
                              }}
                            />
                          </ Grid.Col>
                        </Grid.Col>
                        <Grid.Col span={12} m={0} p={0}>
                          <MantineReactTable table={table} />
                        </Grid.Col>
                      </Grid>
                    ),
                    MClose: true,
                    // tab:OBJ.page.startsWith("P_")?false:true,
                    onclose: () => { },
                    onClickCloseButton: () => {
                      // Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(() => Store.dispatch(GetPendingQuotationDataNull([]))).then(() => Store.dispatch(GetSingleSelectedOrderRowNull([]))).then(() => Store.dispatch(GetPendingOrderDataNull([]))).then(() => Store.dispatch(GetSingleOrderRowNull([]))).then(() => Store.dispatch(GetExpenseDataNull([]))).then(() => Store.dispatch(GetNullTransactionData()))
                      // GetPendingOrderDataNull, GetSingleOrderRowNull
                    }
                  }))
                }
              }

              }
              variant="transparent"
              // color={dark ? 'yellow' : 'blue'}
              title="Reminder"
              mr={5}
            >
              <IconBellRinging size={iconSize} />
            </ActionIcon>
          </Grid.Col>
        </Grid>

        {/* <Divider orientation="vertical" /> */}

        {/* </Group> */}
      </Container>}


      {/* </div> */}
    </>
  );
}
