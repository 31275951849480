import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
import Store from "../store";
import { ClearTabs } from "../slices/TabList";

export const VerifyUser = createAsyncThunk(
  "UserAuth/VerifyUser",
  async (obj) => {
    // console.log(obj, " =>Obj");
    // const user = sessionStorage.getItem("authUser");
    const user = localStorage.getItem("authUser")
    if (user) {
      return JSON.parse(user);
    }
    // GlobalClass.Notify(
    //     "info",
    //     "Verifying",
    //     "Please wait while we Verify Credentials...",
    //     true,
    //   );
    try {
        let param = "p1=" + obj.p1 + "&p2=" + obj.p2 + "&p3=" + obj.p3;
        const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetCorpDet + "?" + param);
        // console.log("GetUserList response=>>", response.data.data);
        if (response.data.status === "SUCCESS") {
            GlobalClass.Notify("success", "Login Success", "",false,1000);
            // sessionStorage.setItem("authUser",JSON.stringify(response.data.data));
            localStorage.setItem("authUser",JSON.stringify(response.data.data))

            // console.log("obj==>",obj);

            localStorage.setItem("corpid",obj.p1); //rathod kishan
            localStorage.setItem("cuser",obj.p2); 
            localStorage.setItem("cpass",obj.p3); 

            // gensetting.setcorpid(obj.p1);
            // gensetting.setcuser(obj.p2);
            // gensetting.getcpass(obj.p3);
            return response.data.data;
        }
        else {
            return { error: response.data.message }
        }
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);
export const checkUser = createAsyncThunk(
    "UserAuth/checkUser",
    async (obj) => {
      // console.log(obj, " =>Obj");
      // const user = sessionStorage.getItem("authUser");
      const user = localStorage.getItem("authUser")
      if (user) {
        return JSON.parse(user);
      }
      else{
        return null
      }
      
    }
  );
export const LogoutUser = createAsyncThunk(
  "UserAuth/LogoutUser",
  async (obj) => {
    
    try {
      // sessionStorage.removeItem("authUser");
      Store.dispatch(ClearTabs(""))
      sessionStorage.clear()
      localStorage.clear()
      GlobalClass.Notify("success", "Logout Success", "",true,1000);
      return true;
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);
      return { e };
    }
  }
);
const LoginSlices = createSlice({
  name: "UserAuth",
  initialState: {
    Data: null,
    isLoading: true,
    hasError: false,
    ErrorMsg: {},
    successMsg:""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkUser.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.Data = null;
      })
      .addCase(checkUser.fulfilled, (state, action) => {
        state.Data = action.payload;
        // console.log("action.payload", action.payload);
        // console.log("state.AreaListData", state.AreaListData);
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(checkUser.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(VerifyUser.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false; 
        state.Data = null;
      })
      .addCase(VerifyUser.fulfilled, (state, action) => {
        if (!action.payload?.error) {
          state.Data = action.payload;
          state.isLoading = false;
          state.hasError = false;
          state.ErrorMsg = {};
      }
      else {
          state.Data = null;
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action?.payload?.error
      }
        // state.Data = action.payload;
        // // console.log("action.payload", action.payload);
        // // console.log("state.AreaListData", state.AreaListData);
        // state.isLoading = false;
        // state.hasError = false;
      })
      .addCase(VerifyUser.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(LogoutUser.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(LogoutUser.fulfilled, (state, action) => {
        state.successMsg = action.payload.status;
        state.Data = null;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(LogoutUser.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});
export default LoginSlices.reducer;
