import React, { useState } from 'react'
import TransactionLeadEntryForm from './TransactionLeadEntryForm'
import { Button, Grid, Text } from '@mantine/core'
import Store from '../../utils/store'
import { ModalDelete, ModalFunction } from '../../utils/slices/ModalSlice'
import { TransactionDeleteApi } from '../../utils/TransactionSlices/Transaction'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice'
import gensetting from '../../utils/gensetting'
import GlobalClass from '../../utils/GlobalClass'
import { GetAccountList } from '../../utils/slices/AccountListSlice'
import LeadEntryOrderForm from './LeadEntryOrderForm'
import { GetInvTypeDropDown, GetPreFillTransactionFormData } from '../../utils/TransactionSlices/TSalesSlices'
import FollowUpEntry from './FollowUpEntry'

let PreFillFormData = {
  CashDebit: "",
  PartyAc: "",
  PartyAcName:"",
  DeliveryAt: "",
  DeliveryAtName:"",
  InvoiceType: "",
  BillDate: ""
}

export function LEADAED(data) {
  const OBJ = JSON.parse(data)
  console.log("LEADAED_OBJ", OBJ)

  function PostLeadEntry(obj, code, index) {
    console.log("POSTOBJ", obj)
    console.log("POSTCODE", code)
    
    Store.dispatch(DataSaveLoading(true))
    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0 == "F" ? "U" : obj?.p0,
      cCode: obj?.id,
      cOPara: code,
    };
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0,
      cCode: obj?.id,
      cOPara: code,
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostLeadData + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log('api data', data);
        // console.log('api data status', data?.status);
        if (data.status == "SUCCESS") {
          Promise.resolve(
            GlobalClass.Notify(
              "success",
              "successfull"
            ))
            .then(() => {
              let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
              Promise.resolve(Store.dispatch(DataSaveLoading(false)))
              .then(() => Store.dispatch(ModalDelete(index)))
                .then(() => {
                  // Store.dispatch(
                  //   GetAccountList({
                  //     id: "20600002",
                  //     name: "Lead Entry",
                  //     p0: "MT",
                  //     p1: "TXNVOULISTLD",
                  //     p2: "TFORM0000001",
                  //     p3: "",
                  //     type: "A",
                  //     pagination: PaginationObj["20600002"],
                  //   })
                  // );

                  Store.dispatch(GetAccountList({ ...OBJ?.BtnPnlObj, pagination: PaginationObj[OBJ?.cSTID] }))
                })
            })
        } else {
          Promise.resolve(Store.dispatch(DataSaveLoading(false))).then(() =>
            console.log('test'),
            GlobalClass.Notify("error", data?.status, data?.message)
          );
        }
      })
      .catch((e) => {
        Promise.resolve(
          GlobalClass.Notify("error", "Error", `${e.message}`)
        ).then(() => Store.dispatch(DataSaveLoading(false)));
      });
  }

  if (OBJ.p0 === 'A') {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "70%",
        position: "right",
        // tab:OBJ.page.startsWith("P_")?false:true,
        MBody: (i) => (
          <TransactionLeadEntryForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 === "E" && OBJ?.id) {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "70%",
        position: "right",
        // tab:OBJ.page.startsWith("P_")?false:true,
        MBody: (i) => (
          <TransactionLeadEntryForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 === "D" && OBJ?.id) {
    if (window.confirm('Confirm to Delete ?')) {

      Store.dispatch(TransactionDeleteApi(OBJ))
    }
  } else if (OBJ?.p0 == "S" && OBJ?.id) {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {

        },
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "50%",
        position: "right",
        MBody: (i) => (
          <>
            <Grid gutter={4}>
              <Grid.Col span={12}>
                <PLDataGrid
                  TmData="P_M15"
                  setEdit={(e) => {
                    console.log("status Value",e)
                    // setStatusCode(e?.FIELD01) 
                    PostLeadEntry(OBJ, e.FIELD01, i) 
                  }}
                />
              </Grid.Col>
            </Grid>
          </>
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ?.p0 == "F" && OBJ?.id) {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {

        },
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "50%",
        position: "right",
        MBody: (i) => (
          <>
            <Grid gutter={4}>
              <Grid.Col span={12}>
                <PLDataGrid
                  TmData="P_M81"
                  setEdit={(e) => {
                    console.log("forward value",e)
                    // setForwardCode(e?.FIELD01)  
                    PostLeadEntry(OBJ, e.FIELD01, i)
                  }}
                />
              </Grid.Col>
            </Grid>
          </>
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ?.p0 == "O" && OBJ?.id) {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "98%",
        position: "right",
        // tab:OBJ.page.startsWith("P_")?false:true,
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(()=>Store.dispatch(GetPreFillTransactionFormData(PreFillFormData)))
          // GetPendingOrderDataNull, GetSingleOrderRowNull
      },
        MBody: (i) => (
          <LeadEntryOrderForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ?.p0 == "Q" && OBJ?.id) {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "98%",
        position: "right",
        // tab:OBJ.page.startsWith("P_")?false:true,
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(()=>Store.dispatch(GetPreFillTransactionFormData(PreFillFormData)))
          // GetPendingOrderDataNull, GetSingleOrderRowNull
      },
        MBody: (i) => (
          <LeadEntryOrderForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 == "FU" && OBJ?.id) {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "70%",
        position: "right",
        // tab:true,
        onClickCloseButton: () => {
      },
        MBody: (i) => (
          <FollowUpEntry obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  }
}
