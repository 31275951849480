import React from 'react'
import Store from '../../utils/store'
import { SetAccountListData } from '../../utils/slices/AccountListSlice'
import { GetButtonPannel, GetButtonPannelList } from '../../utils/slices/DataSaveLoadingSlice'

const ReportClearedFilter = () => {
    return (
        <div>ReportClearedFilter</div>
    )
}

export default ReportClearedFilter

export const CHLNOP = (itemOBJ) => {
    const OBJ = JSON.parse(itemOBJ);
    console.log('CHLNOP fun ===>', OBJ);

    //Cleared => CAPID: "TMP_61"

    const BtnStatus = Store.getState().DataSaveLoadingSlice.ButtonPannelSave;
    const Data = Store.getState().AccountList.AccountListData;
    const SaveAccData = Store.getState().AccountList.SaveAccountListArr;

    console.log("BtnStatus CHLNOP=>", [{
        "BtnStatus": BtnStatus,
        "Data": Data,
        "OBJ": OBJ,
        "SaveAccData": SaveAccData
    }]);

    if (BtnStatus[OBJ?.cSTID] == "A") {
        Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: SaveAccData[OBJ?.cSTID] }))
        Store.dispatch(GetButtonPannel({ ...BtnStatus, [OBJ?.cSTID]: "C" }))
        document.getElementById("TMP_61").innerHTML = "Cleared"
       

    } else if (BtnStatus[OBJ?.cSTID] == "C") {

        let filt = SaveAccData[OBJ?.cSTID]?.filter((d) => (d?.FIELD51 == "C" || d?.FIELD51 == "D"))

        Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: filt }))
        Store.dispatch(GetButtonPannel({ ...BtnStatus, [OBJ?.cSTID]: "P" }))
        document.getElementById("TMP_61").innerHTML = "Pending"
        

    } else if (BtnStatus[OBJ?.cSTID] == "P") {

        let filt = SaveAccData[OBJ?.cSTID]?.filter((d) => (d?.FIELD51 == "P" || d?.FIELD51 == ""))

        Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: filt }))
        Store.dispatch(GetButtonPannel({ ...BtnStatus, [OBJ?.cSTID]: "A" }))
        document.getElementById("TMP_61").innerHTML = "All"
        
    }
}

export const CHLNOS = (itemOBJ) => {
    const OBJ = JSON.parse(itemOBJ);
    console.log('CHLNOP fun ===>', OBJ);

    const BtnStatus = Store.getState().DataSaveLoadingSlice.ButtonPannelSave;
    const Data = Store.getState().AccountList.AccountListData;
    const SaveAccData = Store.getState().AccountList.SaveAccountListArr;

    console.log("BtnStatus CHLNOS =>", [{
        "BtnStatus": BtnStatus,
        "Data": Data,
        "OBJ": OBJ,
        "SaveAccData": SaveAccData
    }]);

    if (BtnStatus[OBJ?.cSTID] == "A") {
        Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: SaveAccData[OBJ?.cSTID] }))
        Store.dispatch(GetButtonPannel({ ...BtnStatus, [OBJ?.cSTID]: "C" }))
        document.getElementById("TMP_61").innerHTML = "Cleared"
    } else if (BtnStatus[OBJ?.cSTID] == "C") {

        let filt = SaveAccData[OBJ?.cSTID]?.filter((d) => (d?.FIELD51 == "C" || d?.FIELD51 == "D"))

        Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: filt }))
        Store.dispatch(GetButtonPannel({ ...BtnStatus, [OBJ?.cSTID]: "P" }))
        document.getElementById("TMP_61").innerHTML = "Pending"

    } else if (BtnStatus[OBJ?.cSTID] == "P") {

        let filt = SaveAccData[OBJ?.cSTID]?.filter((d) => (d?.FIELD51 == "P" || d?.FIELD51 == ""))

        Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: filt }))
        Store.dispatch(GetButtonPannel({ ...BtnStatus, [OBJ?.cSTID]: "A" }))
        document.getElementById("TMP_61").innerHTML = "All"
    }
}

export const BTOBOSTDR = (itemOBJ) => {
    const OBJ = JSON.parse(itemOBJ);
    console.log('BTOBOSTDR fun ===>', OBJ);

    const BtnStatusList = Store.getState().DataSaveLoadingSlice.ButtonPannelList;
    const Data = Store.getState().AccountList.AccountListData;
    const SaveAccData = Store.getState().AccountList.SaveAccountListArr;

    console.log("BtnStatusList BTOBOSTDR =>", [{
        "BtnStatusList": BtnStatusList,
        "Data": Data,
        "OBJ": OBJ,
        "SaveAccData": SaveAccData
    }]);
    if (OBJ?.p0 == "AD") { //AdjustmentWise
        if (BtnStatusList[OBJ?.cSTID]["AdjustmentWise"] == "T") {
            Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: SaveAccData[OBJ?.cSTID] }))
            Store.dispatch(GetButtonPannelList({ ...BtnStatusList, [OBJ?.cSTID]: { ...BtnStatusList[OBJ?.cSTID], "AdjustmentWise": "F" } }))
        } else if (BtnStatusList[OBJ?.cSTID]["AdjustmentWise"] == "F") {

            let filt = SaveAccData[OBJ?.cSTID]?.filter((d) => d?.FIELD04 == "N" || d?.FIELD04 == "X" || d?.FIELD04 == "P")

            Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: filt }))
            Store.dispatch(GetButtonPannelList({ ...BtnStatusList, [OBJ?.cSTID]: { ...BtnStatusList[OBJ?.cSTID], "AdjustmentWise": "T" } }))
        }
    } else if (OBJ?.p0 == "DU") { //PendingDue
        if (BtnStatusList[OBJ?.cSTID]["PendingDue"] == "C") {

            Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: SaveAccData[OBJ?.cSTID] }))
            Store.dispatch(GetButtonPannelList({ ...BtnStatusList, [OBJ?.cSTID]: { ...BtnStatusList[OBJ?.cSTID], "PendingDue": "A" } }))
        } else if (BtnStatusList[OBJ?.cSTID]["PendingDue"] == "A") {

            let filt = SaveAccData[OBJ?.cSTID]?.filter((d) => d?.MREFPAMT !== 0)

            Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: filt }))
            Store.dispatch(GetButtonPannelList({ ...BtnStatusList, [OBJ?.cSTID]: { ...BtnStatusList[OBJ?.cSTID], "PendingDue": "P" } }))
        } else if (BtnStatusList[OBJ?.cSTID]["PendingDue"] == "P") {

            let filt = SaveAccData[OBJ?.cSTID]?.filter((d) => ((d?.MREFPAMT !== 0) && ((d?.DUEDAYS - d?.FIELD12 + 1) > 0)))

            Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: filt }))
            Store.dispatch(GetButtonPannelList({ ...BtnStatusList, [OBJ?.cSTID]: { ...BtnStatusList[OBJ?.cSTID], "PendingDue": "C" } }))

        }
    }else if(OBJ?.p0 == "P"){
        if (BtnStatusList[OBJ?.cSTID]["AllParty"] == "N"){
            Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: SaveAccData[OBJ?.cSTID] }))
            Store.dispatch(GetButtonPannelList({ ...BtnStatusList, [OBJ?.cSTID]: { ...BtnStatusList[OBJ?.cSTID], "AllParty": "A" } }))
        }else if (BtnStatusList[OBJ?.cSTID]["AllParty"] == "A"){

            let filt = SaveAccData[OBJ?.cSTID]?.filter((d) => d?.MREFPAMT !== 0) 

            Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: filt }))
            Store.dispatch(GetButtonPannelList({ ...BtnStatusList, [OBJ?.cSTID]: { ...BtnStatusList[OBJ?.cSTID], "AllParty": "N" } }))
        }
    } else if(OBJ?.p0 == "D"){
        if (BtnStatusList[OBJ?.cSTID]["DueDays"] == "T"){

            Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: SaveAccData[OBJ?.cSTID] }))
            Store.dispatch(GetButtonPannelList({ ...BtnStatusList, [OBJ?.cSTID]: { ...BtnStatusList[OBJ?.cSTID], "DueDays": "F" } }))
        }else if (BtnStatusList[OBJ?.cSTID]["DueDays"] == "F") {

            let filt = SaveAccData[OBJ?.cSTID]?.filter((d) => d?.DUEDAYS > d?.FIELD12)
    
            Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: filt }))
            Store.dispatch(GetButtonPannelList({ ...BtnStatusList, [OBJ?.cSTID]: { ...BtnStatusList[OBJ?.cSTID], "DueDays": "T" } }))
        }
    }
}