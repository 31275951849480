import * as AccountLedgerProductLedgerTab from "../FunctionsCall/AccountLedger/SLedger";
import * as AccountLedgerProductLedgerForm from "../ReportComponent/Account Books/LedGer/AccLedger";

export const TM_ZINOUT = (itemOBJ) => {
    const OBJ = itemOBJ;
    console.log('executeFunction TM_ZINOUT ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "LEDMAIN": //Report > Account Book > Ledger > Account Ledger
        case "PLED_MAIN": //Report > Stock report > Product Ledger > Product Ledger Report
        case "TXNVOULIST": //Report > Account Book > Voucher List
            AccountLedgerProductLedgerTab.TM_ZINOUT(JSON.stringify(OBJ))
            break;
        case "ASLEDGER":
        case "PRODLEDGER":
            AccountLedgerProductLedgerForm.TM_ZINOUT(JSON.stringify(OBJ))
            break;
        default:
            // AccountLedgerProductLedgerTab.TM_ZINOUT(JSON.stringify(OBJ))
            AccountLedgerProductLedgerForm.TM_ZINOUT(JSON.stringify(OBJ))

            break;
    }
}