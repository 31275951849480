import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import GlobalClass from '../GlobalClass';
import gensetting from '../gensetting';
import Store from '../store';
import { GetAccountList } from './AccountListSlice';

export const GetM32List = createAsyncThunk(
    'M32LocationForm/GetM32List',
    async (data) => {
        // console.log('data', data)
        try {

            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": data ? data.action : 'A',
                    "cCode": data ? data.code : '',
                    "cOPara": "",
                    "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM32LC + "?pa=" + param)
            return response.data?.data;
        } catch (error) {
            GlobalClass.Notify("error", "Error", "Fail", error.message)
            console.error(error)
            return { error }
        }
    }
)

export const DeleteLocData = createAsyncThunk(
    'M32LocationForm/DeleteLocData',
    async (obj) => {
        // console.log('M32LocationForm/DeleteLocData', obj);
        GlobalClass.Notify(
            "info",
            "Deleting",
            "Please wait while we process your data"
        );
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": obj.action ? obj.action : 'A',
                    "cCode": obj.code ? obj.code : '',
                    "cOPara": '',
                    "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM32LC + "?pa=" + param);
            if (response.data.status === "SUCCESS") {
                let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
                Promise.resolve(GlobalClass.Notify('success', 'Success:' + 'Delete Data', 'Delete Succesfully')).then(() => Store.dispatch(GetAccountList({
                    id: "T_M32L",
                    name: "Location List",
                    p0: "MT",
                    p1: "T_M32L",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["T_M32L"]
                })))
            } else {
                GlobalClass.Notify("success", "Deleted", "Deleted successfully");
            }
            return response.data
        } catch (e) {
            GlobalClass.Notify("error", "Error", `${e?.message}`);
            return { e };
        }
    }

)

const M32LocationFormSlice = createSlice({
    name: 'M32LocationForm',
    initialState: {
        M32LocationFormData: [],
        isLoading: false,
        hasError: false,
        ErrorMsg: {},
        successMsg: ''
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetM32List.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetM32List.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    state.M32LocationFormData = action.payload;
                    state.isLoading = false;
                    state.hasError = false;
                } else {
                    state.isLoading = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error;
                }
            })
            .addCase(GetM32List.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })
            .addCase(DeleteLocData.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(DeleteLocData.fulfilled, (state, action) => {
                state.successMsg = action.payload.status
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(DeleteLocData.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })
    }
});

export default M32LocationFormSlice.reducer