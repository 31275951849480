import * as ProductMaster from "../FunctionsCall/ProductList/ProductList"

export const M21AED=(itemOBJ)=>{
    const OBJ = itemOBJ;
    console.log('executeFunction M21AED ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "T_M21": // Master > Product List (Product Master)
        case "P_M21":
            ProductMaster.M21AED(JSON.stringify(OBJ))
            break;
    
        default:
            break;
    }
}

export const PLED_MAIN=(itemOBJ)=>{
    const OBJ = itemOBJ;
    console.log('executeFunction PLED_MAIN ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "PLED_MAIN":
    console.log('executeFunction PLED_MAIN 1===>', OBJ);

            ProductMaster.M21AED(JSON.stringify(OBJ))
            break;
    
        default:
            break;
    }
}