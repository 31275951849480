import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetColumnSelection } from '../utils/TransactionSlices/ColumnSelectionSlice';
import { createColumnHelper } from '@tanstack/react-table';
import useWindowDimensions from '../utils/UseWindowDimensions';
import PLNumberBox from './PLNumberBox';

const PLColumnSelection = (props) => {

    const dispatch = useDispatch();
    const columnHelper = createColumnHelper();
    const { height } = useWindowDimensions();


    const columnSelectionData = useSelector((state)=>state?.ColumnSelection?.columnSelectionData);
    const [columns, setColumns] = useState([]);
    const [columnData, setColumnData] = useState([]);
    

    useEffect(()=>{
        dispatch(GetColumnSelection(props.cCode));

    },[])

    useEffect(()=>{
        setColumnData(columnSelectionData?.DT);
    },[columnSelectionData])


    useEffect(()=>{
      let col = [];
      columnSelectionData?.MIDGRD?.filter((item) => item.S53F00 == "C")?.map((a,i)=>{
        col.push(columnHelper?.accessor(a?.S53F04,{
          header: a.S53F07 == 0 ? "" : a.S53F03,
          size: a?.S53F07,
            // minSize: 0,
            // maxSize: a?.ColWidth,
            id: a?.S53UID,
            Edit: ({ cell, column, table, row }) => {
              let val = cell.getValue();
              return renderValue(a, row, i, column, cell?.id, a?.S53UID, val)
            },
            Cell: ({ col, cell, row }) => {
              let val = cell.getValue();
              return val;
            },
        }))
      })
      setColumns(col);
    },[columnSelectionData?.MIDGRD]);

    const renderValue = (c, row, index, column, cellid, uID, val) =>{
          if(c?.S53F02=="RID0085"){
            return val;
          }
          // switch (c?.S53F14) {
          //   case "NUMBOX":
          //     return(
          //       <PLNumberBox />
          //     )
          //     break;
          
          //   default:
          //     break;
          // }

    }


    const table = useMantineReactTable({
      columns: columns ? columns : [],
      data: columnData ? columnData : [],
      enableBottomToolbar:true,
      enableTopToolbar:false,
      enableColumnActions:false,
      enableSorting:false,
      enableEditing:true,
      editDisplayMode:"row",
      enableStickyFooter: true,
      enableStickyHeader: true,
      enablePagination: true,
      mantineTableContainerProps: {
        sx: { height: height * 0.6 },
      },
      state: {
        density: "1.5px",
      },
    })


    console.log("columnSelectionData",columnSelectionData)

  return (
    <>
        <MantineReactTable table={table}/>
    </>
  )
}

export default PLColumnSelection