import * as ProcessMaster from "../FunctionsCall/ProcessMaster/M71"

export const M71AED=(itemOBJ)=>{
    const OBJ = itemOBJ;
    console.log('executeFunction M71AED ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "T_M71": //Master > Process Master
            ProcessMaster.M71AED(JSON.stringify(OBJ))
            break;
    
        default:
            break;
    }
}