import { Button, Center, Container, Drawer, Flex, Grid, Group, Loader, Modal, MultiSelect, Skeleton, Space, Tabs, Text } from '@mantine/core';
import React, { useEffect, useState, useRef } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLComboBox from '../../PlusComponent/PLComboBox';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import { useDispatch, useSelector } from 'react-redux';
import { ProductListAPi } from '../../utils/slices/ProductList';
import GlobalClass from '../../utils/GlobalClass';
import gensetting from '../../utils/gensetting';
import { GetAccountList } from '../../utils/slices/AccountListSlice';
import ItemWiseList from './ItemWiseList';
import { ModalDelete } from '../../utils/slices/ModalSlice';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import { ModalFunction } from '../../utils/slices/ModalSlice';
import Store from '../../utils/store';
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice';
import ProductListUserField from './ProductListUserField';
import TCSUserField from './TCSUserField';
import RateBtnForm from './RateBtnForm';
import { TabRemove } from '../../utils/slices/TabList';


export function ProductListForm(props) {
    // console.log("ProductListForm props=>", props);

    const { OBJ } = props

    const dispatch = useDispatch();
    const ProductListData = useSelector(store => store?.ProductList?.ProductListData);
    const General = useSelector(store => store?.Language?.General.oYear.OSC);
    const isLoading = useSelector(store => store?.ProductList?.isLoading);
    const hasError = useSelector((state) => state?.ProductList?.hasError);
    const ErrorMsg = useSelector((state) => state?.ProductList?.ErrorMsg);

    const SaveLoading = useSelector(store => store?.DataSaveLoadingSlice?.SaveLoading);

    const [state, setState] = useState({});
    const [dataObj, setDataObj] = useState();
    const [tabEnabled, setTabEnabled] = useState();
    const [flag, setFlag] = useState(false);
    const [autoProduction, setAutoProduction] = useState('N')
    const [prefixSuffix, setPrefixSuffix] = useState({
        isPrefixDisable: true,
        isSuffixDisable: true,
    });
    const [isUserNoDisable, setIsUserNoDisable] = useState(true)

    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
    };
    const [DrawerObj, setDrawerObj] = useState(dra);

    const [activeTab, setActiveTab] = useState("Main Details");

    const formRef = useRef(null);
    const userFieldRef = useRef(null);
    const datagridRef = useRef(null);
    const taxClass = useRef(null);
    const HSNCode = useRef(null);
    const groupName = useRef(null);
    const categoryName = useRef(null);
    const productType = useRef(null);
    const TCSDetail = useRef(null);
    const purchaseAcc = useRef(null);
    const salesAcc = useRef(null);
    const nameInputRef = useRef(null);


    useEffect(() => {
        dispatch(ProductListAPi({ ...OBJ, p0: OBJ?.p0 == "R" ? "E" : OBJ?.p0 }))
    }, [])

    useEffect(() => {
        if (hasError && !isLoading) {
            GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
        }
    }, [hasError]);

    useEffect(() => {
        // console.log("ProductListData", ProductListData);
        setState(ProductListData);
        setDataObj(ProductListData?.M21);
    }, [ProductListData])

    const nameInput = document.getElementById("nameInput")

    useEffect(() => {

        if (state?.M21?.FIELD51 == "Y" && General?._SRNOSTOCK == "Y") {
            setTabEnabled("Yes")
        }

        if (state?.M21?.FIELD56) {
            if (state?.M21?.FIELD56 === "N") {
                setPrefixSuffix({ isPrefixDisable: true, isSuffixDisable: true })
            }
            else if (state?.M21?.FIELD56 === "B") {
                setPrefixSuffix({ isPrefixDisable: false, isSuffixDisable: false })
            }
            else if (state?.M21?.FIELD56 === "P") {
                setPrefixSuffix({ isPrefixDisable: false, isSuffixDisable: true })
            }
            else if (state?.M21?.FIELD56 === "S") {
                setPrefixSuffix({ isPrefixDisable: true, isSuffixDisable: false })
            }
        }

        // if (state?.M21?.FIELD50) {
        setAutoProduction(state?.M21?.FIELD50 ? "Y" : "N")
        // }

    }, [state])

    const submit = (e) => {

        const ary = (OBJ?.p0 == "R" ? { M21: e?.M21, F02DT: e?.F02DT } : { M21: state?.M21, F02DT: state?.F02DT })

        if (state?.M21?.FIELD02) {


            dispatch(DataSaveLoading(true))
            GlobalClass.Notify("info", OBJ.p0 == "A" ? "Adding" : "Editing", "Please wait while we process your data");

            if (OBJ.p0 == "E" && (JSON.stringify(state?.M21) === JSON.stringify(dataObj))) {
                Promise.resolve(dispatch(DataSaveLoading(false))).then(() => {
                    // dispatch(ModalDelete(props.index))
                    if (String(props?.index).startsWith("modalForm")) {
                        dispatch(TabRemove(props?.index))
                    } else {
                        dispatch(ModalDelete(props.index))
                    }
                })
                GlobalClass.Notify("success", "Edited", "Edited successfully");

                let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
                // dispatch(GetAccountList({
                //     id: "00930001",
                //     name: "Product List",
                //     p0: "MT",
                //     p1: "T_M21",
                //     p2: "",
                //     p3: "",
                //     type: "A",
                //     pagination: PaginationObj["00930001"]
                // }))
                dispatch(GetAccountList({ ...OBJ?.BtnPnlObj, pagination: PaginationObj[OBJ?.cSTID] }))

            } else {

                var data = {
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": OBJ.p0 == "R" ? "E" : OBJ.p0,
                    "cCode": OBJ?.p0 == "A" ? "" : OBJ.id,
                    "cOPara": "",
                    "cSData": JSON.stringify(ary)
                };

                let param =
                    JSON.stringify({
                        "CorpID": gensetting.getcorpid(),
                        "cUser": gensetting.getcuser(),
                        "cPass": gensetting.getcpass(),
                        "CmpNo": gensetting.getcmpno(),
                        "cAction": OBJ.p0 == "R" ? "E" : OBJ.p0,
                        "cCode": OBJ?.p0 == "A" ? "" : OBJ.id,
                        "cOPara": "",
                    });

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                };

                fetch(GlobalClass.ApiUrl + GlobalClass.PostM21 + "?pa=" + param, requestOptions)
                    .then(response => response.json())
                    .then((data) => {
                        console.log("props.index", props.index);

                        if (data.status == 'SUCCESS') {
                            Promise.resolve(dispatch(DataSaveLoading(false)))
                            // .then(() => dispatch(ModalDelete(props.index)))
                            GlobalClass.Notify("success", OBJ.p0 == "A" ? "Added" : "Edited", OBJ.p0 == "A" ? "Added successfully" : "Edited successfully");

                            if (OBJ.p0 == "A" && String(props?.index).startsWith("modalForm")) {
                                dispatch(ProductListAPi(OBJ))
                            }
                            else {
                                // dispatch(ModalDelete(props.index))
                                // console.log("props?.index", props?.index);

                                if (String(props?.index)?.startsWith("modalForm")) {
                                    dispatch(TabRemove(props?.index))
                                } else {
                                    dispatch(ModalDelete(props?.index))
                                }

                                let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
                                // dispatch(GetAccountList({
                                //     id: "00930001",
                                //     name: "Product List",
                                //     p0: "MT",
                                //     p1: "T_M21",
                                //     p2: "",
                                //     p3: "",
                                //     type: "A",
                                //     pagination: PaginationObj["00930001"]
                                // }))
                                dispatch(GetAccountList({ ...OBJ?.BtnPnlObj, pagination: PaginationObj[OBJ?.cSTID] }))

                            }
                        } else if (data.status == 'FAIL') {
                            dispatch(DataSaveLoading(false))
                            GlobalClass.Notify("error", data?.status, data?.message);
                        }
                    })
                    .catch((e) => {
                        dispatch(DataSaveLoading(false));
                        GlobalClass.Notify("error", "Error", `${e?.message}`);
                    });
            }
        }
        else {
            GlobalClass.Notify("warning", "Validation error", "Blank product name is not allowed")
        }
    }

    const functionTwo = () => {
        // if (General?._ITEMSPACC === "N" && (General?._GSTSETUP === "N" || General?._VATSETUP === "N")) {

        // this code is now discarded. so let it be.(Madam Replied this for Above Condition Ticket No 326)

        setDrawerObj({
            ...dra,
            title: <Text fw={700}>Multi Trading Accounts</Text>,
            body: (
                <>
                    <ProductListUserField
                        state={state} setState={setState} OBJ={OBJ}
                        DrawerOBJ={DrawerObj}
                        setDrawerOBJ={(e) => {
                            setDrawerObj(e);
                        }}
                        setFocus={() => {
                            // console.log("setFocus", state?.M21?.FIELD46);
                            if (General?._TCSREQD == "Y") {
                                const TCSDetail = document.getElementById("TCSDetail");
                                // console.log("productType else ", TCSDetail);
                                TCSDetail && TCSDetail.focus()
                            }
                            // else {
                            //     const btnElement = document.getElementById('#stockRequired');
                            //     // console.log("btnElement", btnElement);
                            //     btnElement && btnElement.focus()

                            // }
                        }}

                    />
                </>
            ),
            open: true,
            size: "sm",
            position: "right",
        });


        // dispatch(ModalFunction({
        //         onclose: () => {
        //             dispatch(ModalFunction({
        //                 MTitle: '',
        //                 MAction: false,
        //                 MSize: 'md',
        //                 MBody: () => (''),
        //                 MClose: false
        //             }))
        //         },
        //         MTitle: <Text fw={700}>Multi Trading Accounts</Text>,
        //         MAction: true,
        //         MSize: 'lg',
        //         MBody: () => (
        //             <>
        //                 {
        //                     popupUserField()
        //                 }

        //             </>
        //         ),
        //         MClose: true,
        //         MCentered: false
        //     }))

        // }

    }

    useEffect(() => {

        const formElement = formRef.current;
        if (formElement) {
            formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
        }
        return () => {
            if (formElement) {
                formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
            }
        };
    }, [formRef]);

    useEffect(() => {

        const formElement = userFieldRef.current;
        if (formElement) {
            formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, userFieldRef));
        }
        return () => {
            if (formElement) {
                formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, userFieldRef));
            }
        };
    }, [userFieldRef]);

    const btnFocus = () => {
        // console.log("btnFocus");
        const btnElement = document.getElementById('btn');
        btnElement && btnElement.focus()

    }

    const setFlagFunction = () => {
        setFlag(false)
    }


    return (<div ref={formRef} >
        <Modal
            opened={DrawerObj?.open}
            // onClose={close}
            withCloseButton={() => { }}
            fullScreen={false} //
            // overlayProps={{ opacity: 0.5, blur: 4 }}
            closeButtonProps={{
                onClick: () => {
                    setDrawerObj(dra);
                },
            }}
            title={DrawerObj.title}
            size={DrawerObj.size}
            // centered={.Centered}
            closeOnClickOutside={true}
            // overlayProps={m.Overlay}
            position={DrawerObj?.position ?? "bottom"}
            onClose={
                typeof DrawerObj?.onclose == "function"
                    ? DrawerObj?.onclose
                    : () => {
                        setDrawerObj(dra);
                    }
            }
        // scrollAreaComponent={ScrollArea.Autosize}
        >
            {DrawerObj.body}
        </Modal>
        {
            hasError ?
                <>
                    <Text>{ErrorMsg?.message}</Text>
                    <Button
                        onClick={() => {
                            if (OBJ) {
                                dispatch(ProductListAPi({ ...OBJ, p0: OBJ?.p0 == "R" ? "E" : OBJ?.p0 }))
                            }
                        }}
                    >
                        Retry
                    </Button>
                </> :
                isLoading || SaveLoading == true ? GlobalClass.RenderLoader("dots") :
                    (ProductListData != null || ProductListData != undefined) && <>
                        {OBJ?.p0 == "R" ? <>
                            <RateBtnForm
                                formData={state}
                                onChange={(e) => {
                                    submit(e)
                                }}
                            />
                        </> :
                            <div >
                                <Tabs
                                    //defaultValue='Main Details' 
                                    value={activeTab}
                                    onTabChange={setActiveTab}
                                >
                                    <Tabs.List>
                                        <Tabs.Tab value='Main Details'>Main Details</Tabs.Tab>
                                        <Tabs.Tab value='Other Details'>Other Details</Tabs.Tab>
                                        <Tabs.Tab value='Serial No Details' disabled={tabEnabled == "Yes" ? false : true} >Serial No Details</Tabs.Tab>
                                        <Tabs.Tab value='Itemwise Expense'>Itemwise Expense</Tabs.Tab>
                                    </Tabs.List>

                                    <Tabs.Panel value='Main Details'>
                                        <Grid gutter={4}>
                                            <Grid.Col span={6}>
                                                <Text weight={500} style={{ padding: '5px 0px' }}>
                                                    Main Details
                                                </Text>
                                                {/* <h4 style={{ fontSize: 16}}>Main Details</h4> */}
                                                <Grid gutter={4}>
                                                    <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                        <Grid.Col span={4}>
                                                            <Text style={{ fontSize: 12 }}>Name</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={7}>
                                                            <PLTextBox
                                                                autoFocus={true}
                                                                id="nameInput"
                                                                ref={nameInputRef}
                                                                value={state?.M21?.FIELD02}
                                                                setEdit={(e) => {
                                                                    setState(prevState => ({
                                                                        ...prevState,
                                                                        M21: {
                                                                            ...prevState?.M21,
                                                                            FIELD02: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                // setText={(e) => {
                                                                //     if (e.target.value.trim()?.length <= 0) {
                                                                //         GlobalClass.Notify("warning", "Warning", "Blank name is not allowed.", false, 3000)
                                                                //     }
                                                                // }}
                                                                onKeyDown={(e) => {
                                                                    // console.log("onKeyDown", e);
                                                                    if (e.key == "Enter" || e.key == "Tab") {
                                                                        if (state?.M21?.FIELD02?.trim()?.length <= 0) {
                                                                            GlobalClass.Notify("warning", "Warning", "Blank name is not allowed.", false, 3000)
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </Grid.Col>
                                                    </Grid.Col>
                                                    <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                        {
                                                            General?._ITMALIASN != "N" ?
                                                                <>
                                                                    <Grid.Col span={4}>
                                                                        <Text style={{ fontSize: 12 }}>Alias</Text>
                                                                    </Grid.Col>
                                                                    <Grid.Col span={3}>
                                                                        <PLTextBox value={state?.M21?.FIELD40}
                                                                            setEdit={(e) => {
                                                                                setState(prevState => ({
                                                                                    ...prevState,
                                                                                    M21: {
                                                                                        ...prevState?.M21,
                                                                                        FIELD40: e.target.value
                                                                                    }
                                                                                }))
                                                                            }}
                                                                        />
                                                                    </Grid.Col>
                                                                </>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            General?._CRATEINFO == "Y" || General?._REQPROD == "Y" ?
                                                                <>
                                                                    <Grid.Col span={1} style={{ display: 'flex' }}>
                                                                        <Text mx={0} style={{ fontSize: 12 }}>Type</Text>
                                                                    </Grid.Col>
                                                                    <Grid.Col span={3}>
                                                                        <PLComboBox defaultValue="P" value={state?.M21?.FIELD42}
                                                                            copno={17}
                                                                            dispexpr='DisplayMember'
                                                                            valexpr='ValueMember'
                                                                            setEdit={(e) => {
                                                                                Promise.resolve(setState(prevState => ({
                                                                                    ...prevState,
                                                                                    M21: {
                                                                                        ...prevState?.M21,
                                                                                        FIELD42: e.value
                                                                                    }
                                                                                }))).then(() => taxClass?.current?.handlePopoverToggle())
                                                                            }}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key == "Enter") {
                                                                                    taxClass?.current?.handlePopoverToggle();
                                                                                }
                                                                                GlobalClass.formNextInput(e, formRef)
                                                                            }}
                                                                        />
                                                                    </Grid.Col>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </Grid.Col>

                                                    {
                                                        (General?._VATSETUP == "Y" || General?._GSTSETUP == "Y") ?
                                                            <>
                                                                <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                                    <Grid.Col span={4}>
                                                                        <Text style={{ fontSize: 12 }}>Tax Class</Text>
                                                                    </Grid.Col>
                                                                    <Grid.Col span={7}>
                                                                        <PLDataGrid ref={taxClass} width={"500px"}
                                                                            value={{ value: state?.M21?.FIELD03, label: state?.M21?.FIELD03NM }}
                                                                            TmData={state?.P_M14}
                                                                            // data={state?.P_M14DT?.jData}
                                                                            setEdit={(e) => {
                                                                                Promise.resolve(setState((prevState) => ({
                                                                                    ...prevState,
                                                                                    M21: {
                                                                                        ...prevState?.M21,
                                                                                        FIELD03: e.FIELD01,
                                                                                        FIELD03NM: e.FIELD02
                                                                                    }
                                                                                })))
                                                                                    .then(() => {
                                                                                        HSNCode?.current?.handlePopoverToggle();
                                                                                    })
                                                                            }}
                                                                        />
                                                                    </Grid.Col>
                                                                </Grid.Col>

                                                                <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>

                                                                    <Grid.Col span={4}>
                                                                        <Text style={{ fontSize: 12 }}>HSN Code</Text>
                                                                    </Grid.Col>
                                                                    <Grid.Col span={7}>
                                                                        <PLDataGrid ref={HSNCode} position="target"
                                                                            isButton={true}
                                                                            width={"500px"}
                                                                            value={{ value: state?.M21?.FIELD04, label: state?.M21?.FIELD04 }}
                                                                            TmData={state?.P_HSN}
                                                                            // data={state?.P_HSNDT?.jData}
                                                                            valexpr="FIELD01"
                                                                            dispexpr="FIELD01" //As instructed by madam (error list 3)
                                                                            setEdit={(e) => {
                                                                                Promise.resolve(setState((prevState) => ({
                                                                                    ...prevState,
                                                                                    M21: {
                                                                                        ...prevState?.M21,
                                                                                        FIELD04: e.FIELD01,
                                                                                    }
                                                                                }))).then(() => {
                                                                                    groupName?.current?.handlePopoverToggle();
                                                                                })
                                                                            }}
                                                                        />
                                                                    </Grid.Col>

                                                                </Grid.Col>

                                                            </>

                                                            :
                                                            null
                                                    }

                                                    {
                                                        General?._PRGROUP == "Y" ?
                                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                                <Grid.Col span={4}>
                                                                    <Text style={{ fontSize: 12 }}>Group Name</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={7}>
                                                                    <PLDataGrid ref={groupName} position="target" width={"500px"}
                                                                        value={{ value: state?.M21?.FIELD11, label: state?.M21?.FIELD11NM }}
                                                                        TmData={state?.P_M32G}
                                                                        // data={state?.P_M32GDT?.jData}
                                                                        setEdit={(e) => {
                                                                            Promise.resolve(setState((prevState) => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState?.M21,
                                                                                    FIELD11: e.FIELD01,
                                                                                    FIELD11NM: e.FIELD02
                                                                                }
                                                                            }))).then(() => {
                                                                                categoryName?.current?.handlePopoverToggle();
                                                                            })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </Grid.Col>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        General?._PRCATEGOR == "Y" ?
                                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                                <Grid.Col span={4}>
                                                                    <Text style={{ fontSize: 12 }}>Category Name</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={7}>
                                                                    <PLDataGrid ref={categoryName} position="target" width={"500px"}
                                                                        value={{ value: state?.M21?.FIELD12, label: state?.M21?.FIELD12NM }}
                                                                        TmData={state?.P_M32C}
                                                                        // data={state?.P_M32CDT?.jData}
                                                                        setEdit={(e) => {
                                                                            Promise.resolve(setState((prevState) => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState?.M21,
                                                                                    FIELD12: e.FIELD01,
                                                                                    FIELD12NM: e.FIELD02
                                                                                }
                                                                            }))).then(() => {
                                                                                if (General?._GSTSETUP == "Y") {
                                                                                    const productType = document.getElementById("productType");
                                                                                    // console.log("productType if ", productType);
                                                                                    productType && productType.focus()
                                                                                }
                                                                                else {
                                                                                    const TCSDetail = document.getElementById("TCSDetail");
                                                                                    // console.log("productType else ", TCSDetail);
                                                                                    TCSDetail && TCSDetail.focus()
                                                                                }
                                                                            })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </Grid.Col>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        General?._GSTSETUP == "Y" ?
                                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                                <Grid.Col span={4}>
                                                                    <Text style={{ fontSize: 12 }}>Product Type</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={4}>
                                                                    <PLComboBox
                                                                        cmbid={"productType"}
                                                                        data={state?.COP10}
                                                                        value={state?.M21?.FIELD23}
                                                                        dispexpr="DisplayMember"
                                                                        valexpr="ValueMember"
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState?.M21,
                                                                                    FIELD23: e
                                                                                }
                                                                            }))
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            if (state?.M21?.FIELD23 === "S" || state?.M21?.FIELD23 === "G") {
                                                                                if (e.key == "Tab" || e.key == "Enter") {
                                                                                    functionTwo()
                                                                                }
                                                                            }
                                                                            else {
                                                                                // GlobalClass.formNextInput(e, formRef);
                                                                                if (e.key == "Tab" || e.key == "Enter") {
                                                                                    purchaseAcc.current.handlePopoverToggle()

                                                                                }

                                                                            }

                                                                        }
                                                                        }
                                                                    />
                                                                </Grid.Col>
                                                                <Grid.Col span={4}></Grid.Col>
                                                            </Grid.Col>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (General?._GSTSETUP == "Y" && state?.M21?.FIELD23 == "E") ?
                                                            <>
                                                                <Grid.Col span={4}>
                                                                    <Text style={{ fontSize: 12 }}>Purchase A/c</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={8}>
                                                                    <PLDataGrid ref={purchaseAcc} position="target" width={"500px"}
                                                                        value={{ value: state?.M21?.FIELD24, label: state?.M21?.FIELD24NM }}
                                                                        TmData={state?.P_M01P}
                                                                        // data={state?.P_M01PDT?.jData}
                                                                        setEdit={(e) => {
                                                                            Promise.resolve(setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState?.M21,
                                                                                    FIELD24: e.FIELD01,
                                                                                    FIELD24NM: e.FIELD02
                                                                                }
                                                                            }))).then(() => {
                                                                                salesAcc.current.handlePopoverToggle()
                                                                            })
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                        }}
                                                                        handleClose={() => { }}
                                                                    />
                                                                </Grid.Col>
                                                                <Grid.Col span={4}>
                                                                    <Text style={{ fontSize: 12 }}>Sales A/c</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={8}>
                                                                    <PLDataGrid ref={salesAcc} position="target" width={"500px"}
                                                                        value={{ value: state?.M21?.FIELD25, label: state?.M21?.FIELD25NM }}
                                                                        TmData={state?.P_M01S}
                                                                        // data={state?.P_M01SDT.jData}
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState?.M21,
                                                                                    FIELD25: e.FIELD01,
                                                                                    FIELD25NM: e.FIELD02
                                                                                }
                                                                            }))
                                                                            functionTwo()
                                                                        }}
                                                                        handleClose={() => { }}
                                                                    />
                                                                </Grid.Col>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        General?._TCSREQD == "Y" ?
                                                            <>
                                                                <Grid.Col span={4}>
                                                                    <Text style={{ fontSize: 12 }}>TCS Details</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={4}>
                                                                    <PLComboBox
                                                                        cmbid={"TCSDetail"}
                                                                        copno={1}
                                                                        dispexpr="DisplayMember"
                                                                        valexpr="ValueMember"
                                                                        value={state?.M21?.FIELD46}
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState?.M21,
                                                                                    FIELD46: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        onKeyDown={(e) => {

                                                                            // if (e.key == "Tab" || e.key == "Enter") {
                                                                            //     state?.UFM21 && state?.UFM21.length > 0 &&
                                                                            //         setDrawerObj({
                                                                            //             ...dra,
                                                                            //             title: <Text fw={700}>User Field</Text>,
                                                                            //             body: (
                                                                            //                 <>
                                                                            //                     <TCSUserField
                                                                            //                         UFM21={state?.UFM21} state={state} setState={setState} OBJ={OBJ}
                                                                            //                         DrawerOBJ={DrawerObj}
                                                                            //                         setDrawerOBJ={(e) => {
                                                                            //                             setDrawerObj(e);
                                                                            //                         }}
                                                                            //                         setFocus={() => {
                                                                            //                             // console.log("setFocus", state?.M21?.FIELD46);
                                                                            //                             if (state?.M21?.FIELD46 == "Y") {
                                                                            //                                 datagridRef.current.handlePopoverToggle()

                                                                            //                             }
                                                                            //                             else {
                                                                            //                                 const btnElement = document.getElementById('#stockRequired');
                                                                            //                                 // console.log("btnElement", btnElement);
                                                                            //                                 btnElement && btnElement.focus()

                                                                            //                             }
                                                                            //                         }

                                                                            //                         }
                                                                            //                     />
                                                                            //                 </>
                                                                            //             ),
                                                                            //             open: true,
                                                                            //             size: "sm",
                                                                            //             position: "right",
                                                                            //         });
                                                                            // }
                                                                        }
                                                                        }
                                                                    />
                                                                </Grid.Col>
                                                                <Grid.Col span={4}></Grid.Col>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        state?.M21?.FIELD46 === "Y" ?
                                                            <>
                                                                <Grid.Col span={4}>
                                                                    <Text style={{ fontSize: 12 }}>Nature Of Goods</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={8}>
                                                                    <PLDataGrid ref={datagridRef} position="target" width={"500px"}
                                                                        value={{ value: state?.M21?.FIELD47, label: state?.M21?.FIELD47NM }}
                                                                        TmData={state?.P_PLTCSDT}
                                                                        // data={state?.P_PLTCSDTDT.jData}
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState?.M21,
                                                                                    FIELD47: e.FIELD01,
                                                                                    FIELD47NM: e.FIELD02
                                                                                }
                                                                            }))
                                                                        }}
                                                                        handleClose={() => { }}
                                                                    />
                                                                </Grid.Col>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </Grid >
                                            </Grid.Col>
                                            <Grid.Col span={5}>
                                                <Text weight={500} style={{ padding: '5px 0px' }}>Rate</Text>
                                                {/* <h4 style={{ fontSize: 16 }}>Rate</h4> */}
                                                <Grid gutter={4}>
                                                    <Grid.Col span={5}>
                                                        <Text style={{ fontSize: 12 }}>Sales Rate</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={5}>
                                                        <PLNumberBox value={state?.M21?.FIELD22}
                                                            decimalSeparator={true}
                                                            precision={2}
                                                            setEdit={(e) => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    M21: {
                                                                        ...prevState?.M21,
                                                                        FIELD22: e
                                                                    }
                                                                }))
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                    <Grid.Col span={5}>
                                                        <Text style={{ fontSize: 12 }}>Purchase Rate</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={5}>
                                                        <PLNumberBox
                                                            value={state?.M21?.FIELD21}
                                                            decimalSeparator={true}
                                                            precision={2}
                                                            setEdit={(e) => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    M21: {
                                                                        ...prevState?.M21,
                                                                        FIELD21: e
                                                                    }
                                                                }))
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                    {
                                                        General?._TPRATE == "Y" || General?._TPPPRATE == "Y" ?
                                                            <>
                                                                <Grid.Col span={5}>
                                                                    <Text style={{ fontSize: 12 }}>Tax Paid Rate</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={4}>
                                                                    <PLComboBox value={state?.M21?.FIELD45}
                                                                        copno={11}
                                                                        dispexpr="DisplayMember"
                                                                        valexpr="ValueMember"
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState?.M21,
                                                                                    FIELD45: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            GlobalClass.formNextInput(e, formRef)
                                                                        }}

                                                                    />
                                                                </Grid.Col>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </Grid>
                                                <Text weight={500} style={{ padding: '5px 0px' }}>Unit Name</Text>
                                                {/* <h4 style={{ fontSize: 16 }}>Unit Name</h4> */}
                                                <Grid gutter={4}>
                                                    <Grid.Col span={5}>
                                                        <Text style={{ fontSize: 12 }}>Sale</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={5}>
                                                        <PLDataGrid position="target"
                                                            value={{ value: state?.M21?.FIELD06, label: state?.M21?.FIELD06 }}
                                                            width={"250px"}
                                                            TmData={state?.P_UNTS}
                                                            // data={state?.P_UNTSDT?.jData}
                                                            valexpr="FIELD01"
                                                            dispexpr="FIELD01" //As instructed by madam (error list 3)
                                                            setEdit={(e) => {
                                                                // console.log("e", e);
                                                                setState((prevState) => ({
                                                                    ...prevState,
                                                                    M21: {
                                                                        ...prevState?.M21,
                                                                        FIELD06: e.FIELD01,
                                                                    }
                                                                }))
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                    <Grid.Col span={5}>
                                                        <Text style={{ fontSize: 12 }}>Purchase</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={5}>
                                                        <PLDataGrid position="target"
                                                            value={{ value: state?.M21?.FIELD05, label: state?.M21?.FIELD05 }}
                                                            width={"250px"}
                                                            TmData={state?.P_UNTP}
                                                            // data={state?.P_UNTPDT?.jData}
                                                            valexpr="FIELD01"
                                                            dispexpr="FIELD01"
                                                            setEdit={(e) => {
                                                                // console.log("Purchase e", e);
                                                                setState((prevState) => ({
                                                                    ...prevState,
                                                                    M21: {
                                                                        ...prevState?.M21,
                                                                        FIELD05: e.FIELD01,
                                                                    }
                                                                }))
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid>
                                                {
                                                    (state?.M21?.FIELD52 == "Y" && state?.M21?.FIELD59 == "Y" && General?._DUALSTOCK == "Y") ?
                                                        <>
                                                            <Text weight={500} style={{ padding: '5px 0px' }}>Second Unit Information</Text>

                                                            {/* <h4 style={{ fontSize: 16 }}>Second Unit Information</h4> */}
                                                            <Grid gutter={4}>
                                                                <Grid.Col span={5}>
                                                                    <Text style={{ fontSize: 12 }}>Description</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={6}>
                                                                    <PLTextBox value={state?.M21?.FIELD27}
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState?.M21,
                                                                                    FIELD27: e.target.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                                <Grid.Col span={5}>
                                                                    <Text style={{ fontSize: 12 }}>Conv.Factor</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={5}>
                                                                    <PLNumberBox value={state?.M21?.FIELD28}
                                                                        decimalSeparator={true}
                                                                        precision={3}
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState?.M21,
                                                                                    FIELD28: e
                                                                                }
                                                                            }))
                                                                        }} />
                                                                </Grid.Col>
                                                                <Grid.Col span={5}>
                                                                    <Text style={{ fontSize: 12 }}>Rate On</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={5}>
                                                                    <PLComboBox
                                                                        value={state?.M21?.FIELD13}
                                                                        copno={'RON'}
                                                                        dispexpr="DisplayMember"
                                                                        valexpr="ValueMember"
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState?.M21,
                                                                                    FIELD13: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            if (e?.key == "Tab" || e?.key == "Enter") {
                                                                                setActiveTab("Other Details")
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                                {
                                                                    General?._CHLDPROD == "Y" ?
                                                                        <>
                                                                            <Grid.Col span={5}>
                                                                                <Text style={{ fontSize: 12 }}>Main Product</Text>
                                                                            </Grid.Col>
                                                                            <Grid.Col span={5}>
                                                                                <PLComboBox
                                                                                    copno={1}
                                                                                    dispexpr="DisplayMember"
                                                                                    valexpr="ValueMember"
                                                                                    value={state?.M21}
                                                                                    setEdit={(e) => {
                                                                                        setState(prevState => ({
                                                                                            ...prevState,
                                                                                            M21: {
                                                                                                ...prevState.M21,
                                                                                                M21CPF01: e.value
                                                                                            }
                                                                                        }))
                                                                                    }}
                                                                                    onKeyDown={(e) => {
                                                                                        GlobalClass.formNextInput(e, formRef)
                                                                                    }}
                                                                                />
                                                                            </Grid.Col>
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </Grid>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </Grid.Col>
                                            <Grid.Col span={7}>
                                                <Text weight={500} style={{ padding: '5px 0px' }}>Stock</Text>
                                                {/* <h4 style={{ fontSize: 16 }}>Stock</h4> */}
                                                <Grid gutter={4}>
                                                    <Grid.Col span={3}>
                                                        <Text style={{ fontSize: 12 }}>Stock Required</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={3}>
                                                        <PLComboBox cmbid="#stockRequired"
                                                            value={state?.M21?.FIELD52}
                                                            copno={1}
                                                            dispexpr="DisplayMember"
                                                            valexpr="ValueMember"
                                                            setEdit={(e) => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    M21: {
                                                                        ...prevState.M21,
                                                                        FIELD52: e.value
                                                                    }
                                                                }))
                                                            }}
                                                            onKeyDown={(e) => {
                                                                GlobalClass.formNextInput(e, formRef)
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                    <Grid.Col span={6}></Grid.Col>
                                                    {
                                                        General?._PRTYRATE == "Y" ?
                                                            <>
                                                                <Grid.Col span={3}>
                                                                    <Text style={{ fontSize: 12 }}>Price List</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLComboBox
                                                                        copno={1}
                                                                        dispexpr="DisplayMember"
                                                                        valexpr="ValueMember"
                                                                        value={state?.M21?.FIELD53}
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState.M21,
                                                                                    FIELD53: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            if (state?.M21?.FIELD52 === "Y") {
                                                                                GlobalClass.formNextInput(e, formRef)
                                                                            }
                                                                            else if (e?.key == "Tab" || e?.key == "Enter") {
                                                                                setActiveTab("Other Details")
                                                                            }

                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (General?._ITMQTYEFF == "Y" && state?.M21?.FIELD52 === "Y") ?
                                                            <>
                                                                <Grid.Col span={3}>
                                                                    <Text style={{ fontSize: 12 }}>Quantity Effect</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLComboBox
                                                                        copno={1}
                                                                        dispexpr="DisplayMember"
                                                                        valexpr="ValueMember"
                                                                        value={state?.M21?.FIELD58}
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState.M21,
                                                                                    FIELD58: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            GlobalClass.formNextInput(e, formRef)
                                                                        }}

                                                                    />
                                                                </Grid.Col>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        (OBJ.p0 === "A" && state?.M21?.FIELD52 === "Y" && General?._BATCHSTOC == "Y") ?
                                                            <>
                                                                <Grid.Col span={3}>
                                                                    <Text style={{ fontSize: 12 }}>Batchwise Stock</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLComboBox
                                                                        copno={1}
                                                                        dispexpr="DisplayMember"
                                                                        valexpr="ValueMember"
                                                                        value={state?.M21?.FIELD51}
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState.M21,
                                                                                    FIELD51: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            GlobalClass.formNextInput(e, formRef)
                                                                        }}

                                                                    />
                                                                </Grid.Col>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        (OBJ.p0 === "E" && state?.M21?.LENABLE === true) ?
                                                            <>
                                                                <Grid.Col span={3}>
                                                                    <Text style={{ fontSize: 12 }}>Batchwise Stock</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLComboBox
                                                                        copno={1}
                                                                        dispexpr="DisplayMember"
                                                                        valexpr="ValueMember"
                                                                        value={state?.M21?.FIELD51}
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState.M21,
                                                                                    FIELD51: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            GlobalClass.formNextInput(e, formRef)
                                                                        }}


                                                                    />
                                                                </Grid.Col>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        (OBJ.p0 === "A" && state?.M21?.FIELD52 === "Y" && General?._LOCATIONW == "Y") ?
                                                            <>
                                                                <Grid.Col span={3}>
                                                                    <Text style={{ fontSize: 12 }}>Locationwise Stock</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLComboBox
                                                                        copno={1}
                                                                        dispexpr="DisplayMember"
                                                                        valexpr="ValueMember"
                                                                        value={state?.M21?.FIELD57}
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState.M21,
                                                                                    FIELD57: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            GlobalClass.formNextInput(e, formRef)
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        (OBJ.p0 === "E" && state?.M21?.LENABLE === true) ?
                                                            <>
                                                                <Grid.Col span={3}>
                                                                    <Text style={{ fontSize: 12 }}>Locationwise Stock</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLComboBox
                                                                        copno={1}
                                                                        dispexpr="DisplayMember"
                                                                        valexpr="ValueMember"
                                                                        value={state?.M21?.FIELD57}
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState.M21,
                                                                                    FIELD57: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            GlobalClass.formNextInput(e, formRef)
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </>
                                                            :
                                                            null

                                                    }

                                                    {
                                                        (state?.M21?.FIELD52 === "Y" && General?._DUALSTOCK == "Y") ?
                                                            <>
                                                                <Grid.Col span={3}>
                                                                    <Text style={{ fontSize: 12 }}>Dual Stock</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLComboBox
                                                                        copno={1}
                                                                        dispexpr="DisplayMember"
                                                                        valexpr="ValueMember"
                                                                        value={state?.M21?.FIELD59}
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState.M21,
                                                                                    FIELD59: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            if (state?.M21?.FIELD51 === "Y" && General?._SRNOSTOCK == "Y") {
                                                                                GlobalClass.formNextInput(e, formRef)
                                                                            }
                                                                            else if (e?.key == "Tab" || e?.key == "Enter") {
                                                                                setActiveTab("Other Details")
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        (state?.M21?.FIELD51 === "Y" && General?._SRNOSTOCK == "Y") ?
                                                            <>
                                                                <Grid.Col span={3}>
                                                                    <Text style={{ fontSize: 12 }}>Serial No.Stock</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLComboBox
                                                                        copno={1}
                                                                        dispexpr="DisplayMember"
                                                                        valexpr="ValueMember"
                                                                        value={state?.M21?.FIELD34}
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState.M21,
                                                                                    FIELD34: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            if (OBJ.p0 === "A" && state?.M21?.FIELD51 === "Y" && General?.__MRPSTOCK == "Y") {
                                                                                GlobalClass.formNextInput(e, formRef)

                                                                            }
                                                                            else if (OBJ.p0 === "E" && state?.M21?.LENABLE === true) {
                                                                                GlobalClass.formNextInput(e, formRef)
                                                                            }
                                                                            else if (e?.key == "Tab" || e?.key == "Enter") {
                                                                                setActiveTab("Other Details")
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (OBJ.p0 === "A" && state?.M21?.FIELD51 === "Y" && General?.__MRPSTOCK == "Y") ?
                                                            <>
                                                                <Grid.Col span={3}>
                                                                    <Text style={{ fontSize: 12 }}>MRP</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLComboBox
                                                                        value={state?.M21?.FIELD69}
                                                                        copno={1}
                                                                        dispexpr="DisplayMember"
                                                                        valexpr="ValueMember"
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState.M21,
                                                                                    FIELD69: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            GlobalClass.formNextInput(e, formRef)
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                                <Grid.Col span={6}></Grid.Col>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (OBJ.p0 === "E" && state?.M21?.LENABLE === true) ?
                                                            <>
                                                                <Grid.Col span={3}>
                                                                    <Text style={{ fontSize: 12 }}>MRP</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLComboBox
                                                                        value={state?.M21?.FIELD69}
                                                                        copno={1}
                                                                        dispexpr="DisplayMember"
                                                                        valexpr="ValueMember"
                                                                        setEdit={(e) => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                M21: {
                                                                                    ...prevState.M21,
                                                                                    FIELD69: e.value
                                                                                }
                                                                            }))
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            if (e?.key == "Tab" || e?.key == "Enter") {
                                                                                setActiveTab("Other Details")
                                                                            }
                                                                        }}

                                                                    />
                                                                </Grid.Col>
                                                                <Grid.Col span={6}></Grid.Col>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </Grid>
                                            </Grid.Col>
                                        </Grid >
                                    </Tabs.Panel>{/*Main Details*/}

                                    <Tabs.Panel value='Other Details'>
                                        <div style={{ padding: '10px 0px' }}>
                                            <Grid gutter={4}>
                                                <Grid.Col span={12} style={{ display: 'flex' }}>
                                                    <Grid.Col span={6}>
                                                        <Grid.Col span={12} style={{ display: 'flex' }}>
                                                            <Grid.Col span={5} style={{ padding: 0 }}>
                                                                <Text style={{ fontSize: 12 }}>Minimum Stock</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={5} style={{ padding: 0 }}>
                                                                <PLNumberBox value={state?.M21?.FIELD61}
                                                                    autoFocus={true}
                                                                    decimalSeparator={true}
                                                                    precision={2}
                                                                    setEdit={(e) => {
                                                                        setState(prevState => ({
                                                                            ...prevState,
                                                                            M21: {
                                                                                ...prevState.M21,
                                                                                FIELD61: e
                                                                            }
                                                                        }))
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex' }}>
                                                            <Grid.Col span={5} style={{ padding: 0 }}>
                                                                <Text style={{ fontSize: 12 }}>Recorder Level</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={5} style={{ padding: 0 }}>
                                                                <PLNumberBox value={state?.M21?.FIELD63}
                                                                    decimalSeparator={true}
                                                                    precision={3}
                                                                    setEdit={(e) => {
                                                                        setState(prevState => ({
                                                                            ...prevState,
                                                                            M21: {
                                                                                ...prevState.M21,
                                                                                FIELD63: e
                                                                            }
                                                                        }))
                                                                    }} />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', fontWeight: 'bold', padding: 0 }}>
                                                            {/* <Grid.Col span={12}>Rate Conversion</Grid.Col> */}
                                                            <Text weight={500} style={{ padding: '5px 0px' }}>Rate Conversion</Text>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex' }}>
                                                            <Grid.Col span={5} style={{ padding: 0 }}>
                                                                <Text style={{ fontSize: 12 }}>Sales Conversion Factor</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={5} style={{ padding: 0 }}>
                                                                <PLNumberBox value={state?.M21?.FIELD10}
                                                                    decimalSeparator={true}
                                                                    precision={2}
                                                                    setEdit={(e) => {
                                                                        setState(prevState => ({
                                                                            ...prevState,
                                                                            M21: {
                                                                                ...prevState.M21,
                                                                                FIELD10: e
                                                                            }
                                                                        }))
                                                                    }} />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', color: 'red', padding: 0 }}>
                                                            <Grid.Col span={1}></Grid.Col>
                                                            <Grid.Col span={11}>Sales Rate = Rate/Conversion Factor</Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex' }}>
                                                            <Grid.Col span={5} style={{ padding: 0 }}>
                                                                <Text style={{ fontSize: 12 }}>Purchase Conversion Factor</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={5} style={{ padding: 0 }}>
                                                                <PLNumberBox value={state?.M21?.FIELD18}
                                                                    decimalSeparator={true}
                                                                    precision={2}
                                                                    setEdit={(e) => {
                                                                        setState(prevState => ({
                                                                            ...prevState,
                                                                            M21: {
                                                                                ...prevState.M21,
                                                                                FIELD18: e
                                                                            }
                                                                        }))
                                                                    }} />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', fontWeight: 'bold', padding: 0 }}>
                                                            {/* <Grid.Col span={12}>Sale/Purchase Unit Conversion</Grid.Col> */}
                                                            <Text weight={500} style={{ padding: '5px 0px' }}>Sale/Purchase Unit Conversion</Text>

                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex' }}>
                                                            <Grid.Col span={5} style={{ padding: 0 }}>
                                                                <Text style={{ fontSize: 12 }}>Stock Conversion Factor</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={5} style={{ padding: 0 }}>
                                                                <PLNumberBox value={state?.M21?.FIELD07}
                                                                    decimalSeparator={true}
                                                                    precision={2}
                                                                    setEdit={(e) => {
                                                                        setState(prevState => ({
                                                                            ...prevState,
                                                                            M21: {
                                                                                ...prevState.M21,
                                                                                FIELD07: e
                                                                            }
                                                                        }))
                                                                    }} />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', color: 'red', padding: 0 }}>
                                                            <Grid.Col span={1}></Grid.Col>
                                                            <Grid.Col span={11}>Stock = Conversion Factor*Purchase</Grid.Col>
                                                        </Grid.Col>

                                                    </Grid.Col>
                                                    <Grid.Col span={6}>

                                                        <Grid.Col span={12} style={{ display: 'flex', fontWeight: 'bold', padding: 0 }}>
                                                            {/* <Grid.Col span={12}>Auto Production</Grid.Col> */}
                                                            <Text weight={500} style={{ padding: '5px 0px' }}>Auto Production</Text>

                                                        </Grid.Col>

                                                        {
                                                            General?._AUTOPROD == "Y" ?
                                                                <>
                                                                    <Grid.Col span={12} style={{ display: 'flex' }}>
                                                                        <Grid.Col span={4} style={{ padding: 0 }}>
                                                                            <Text style={{ fontSize: 12 }}>Auto Production</Text>
                                                                        </Grid.Col>
                                                                        <Grid.Col span={3} style={{ padding: 0 }}>
                                                                            <PLComboBox
                                                                                value={autoProduction}
                                                                                copno={1}
                                                                                dispexpr='DisplayMember'
                                                                                valexpr='ValueMember'
                                                                                setEdit={(e) => {
                                                                                    setAutoProduction(e.value)
                                                                                }}
                                                                                onKeyDown={(e) => {
                                                                                    GlobalClass.formNextInput(e, formRef)
                                                                                }}
                                                                            />
                                                                        </Grid.Col>
                                                                    </Grid.Col>

                                                                    <Grid.Col span={12} style={{ display: 'flex' }}>
                                                                        <Grid.Col span={4} style={{ padding: 0 }}>
                                                                            <Text style={{ fontSize: 12 }}>Process Name</Text>
                                                                        </Grid.Col>
                                                                        <Grid.Col span={7} style={{ padding: 0 }}>
                                                                            {
                                                                                autoProduction === "Y" ?
                                                                                    <PLDataGrid position="target" width={"200px"}
                                                                                        value={{ value: state?.M21?.FIELD50, label: state?.M21?.FIELD50NM }}
                                                                                        TmData={state?.P_M71}
                                                                                        // data={state?.P_M71DT?.jData}
                                                                                        setEdit={(e) => {
                                                                                            setState(prevState => ({
                                                                                                ...prevState,
                                                                                                M21: {
                                                                                                    ...prevState.M21,
                                                                                                    FIELD50: e.FIELD01,
                                                                                                    FIELD50NM: e.FIELD02
                                                                                                }
                                                                                            }))
                                                                                        }}
                                                                                        // onKeyDown={(e) => {
                                                                                        //     GlobalClass.formNextInput(e,formRef)
                                                                                        // }}
                                                                                        handleClose={() => { }}
                                                                                    /> :
                                                                                    <PLTextBox disabled />
                                                                            }

                                                                        </Grid.Col>
                                                                    </Grid.Col>
                                                                </>
                                                                : null

                                                        }
                                                        {
                                                            (OBJ.p0 === "A" || (OBJ.p0 === "E" && General?._BCODEREQ == "Y")) ?
                                                                <Grid.Col span={12} style={{ display: 'flex' }}>
                                                                    <Grid.Col span={4} style={{ padding: 0 }}>
                                                                        <Text style={{ fontSize: 12 }}>Barcode Type</Text>
                                                                    </Grid.Col>
                                                                    <Grid.Col span={7} style={{ padding: 0 }}>
                                                                        <PLDataGrid position="target" width={"200px"}
                                                                            value={{ value: state?.M21?.FIELD30, label: state?.M21?.FIELD30NM }}
                                                                            TmData={state?.P_MBRCD}
                                                                            // data={state?.MBRCD}
                                                                            dispexpr="BRCD01"
                                                                            valexpr="BRCD02"
                                                                            setEdit={(e) => {
                                                                                setState(prevState => ({
                                                                                    ...prevState,
                                                                                    M21: {
                                                                                        ...prevState.M21,
                                                                                        FIELD30: e.BRCD02,
                                                                                        FIELD30NM: e.BRCD01
                                                                                    }
                                                                                }))
                                                                            }}
                                                                            onKeyDown={(e) => {
                                                                                if (e?.key == "Enter") {
                                                                                    const btnElement = document.getElementById('btn');
                                                                                    btnElement && btnElement?.current?.focus()

                                                                                    // if (tabEnabled == "Yes")
                                                                                    //     setActiveTab("Serial No Details")
                                                                                    // else
                                                                                    //     setActiveTab("Itemwise Expense")
                                                                                }
                                                                                // GlobalClass.formNextInput(e,formRef)
                                                                            }}
                                                                            handleClose={() => { }}
                                                                        />
                                                                    </Grid.Col>
                                                                </Grid.Col>
                                                                : null
                                                        }
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid>
                                        </div>
                                    </Tabs.Panel>{/*Other Detail*/}

                                    <Tabs.Panel value='Serial No Details'>

                                        <Grid mx={30}>
                                            <Grid.Col span={12} style={{ display: 'flex', fontWeight: 'bold', padding: 0 }}>
                                                {/* <Grid.Col span={12} >Warranty</Grid.Col> */}
                                                <Text weight={500} style={{ padding: '5px 0px' }}>Warranty</Text>
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>

                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={4} style={{ padding: 0 }}>
                                                        <Text style={{ fontSize: 12 }}>Warranty Type</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6} style={{ padding: 0 }}>
                                                        <PLComboBox
                                                            value={state?.M21?.FIELD33}
                                                            copno={12}
                                                            dispexpr='DisplayMember'
                                                            valexpr='ValueMember'
                                                            setEdit={(e) => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    M21: {
                                                                        ...prevState.M21,
                                                                        FIELD33: e.value
                                                                    }
                                                                }))
                                                            }}
                                                        // onKeyDown ={(e) => {
                                                        //     if(e.key === "Tab")
                                                        //     {

                                                        //     }
                                                        // }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>

                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={4} style={{ padding: 0 }}>
                                                        <Text style={{ fontSize: 12 }}>Warranty Months</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6} style={{ padding: 0 }}>
                                                        {
                                                            (state?.M21?.FIELD33 === "I" || state?.M21?.FIELD33 === "R") ?
                                                                <PLNumberBox value={state?.M21?.FIELD31}
                                                                    decimalSeparator={true}
                                                                    precision={2}
                                                                    setEdit={(e) => {
                                                                        // console.log("e",e);
                                                                        setState(prevState => ({
                                                                            ...prevState,
                                                                            M21: {
                                                                                ...prevState.M21,
                                                                                FIELD31: e
                                                                            }
                                                                        }))
                                                                    }}
                                                                />
                                                                :
                                                                <PLNumberBox value={state?.M21?.FIELD31} disabled />
                                                        }
                                                    </Grid.Col>
                                                </Grid.Col>

                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', fontWeight: 'bold', padding: 0 }}>
                                                {/* <Grid.Col span={12}>Prefix\Suffix</Grid.Col> */}
                                                <Text weight={500} style={{ padding: '5px 0px' }}>Prefix\Suffix</Text>
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: '0' }}>
                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={4} style={{ padding: 0 }}>
                                                        <Text style={{ fontSize: 12 }}>Prefix/Suffix</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6} style={{ padding: 0 }}>
                                                        <PLComboBox value={state?.M21?.FIELD56}
                                                            copno={13}
                                                            dispexpr='DisplayMember'
                                                            valexpr='ValueMember'
                                                            setEdit={(e) => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    M21: {
                                                                        ...prevState.M21,
                                                                        FIELD56: e.value
                                                                    }
                                                                }))
                                                                if (e.value === "N") {
                                                                    setPrefixSuffix({ isPrefixDisable: true, isSuffixDisable: true })
                                                                }
                                                                else if (e.value === "B") {
                                                                    setPrefixSuffix({ isPrefixDisable: false, isSuffixDisable: false })
                                                                }
                                                                else if (e.value === "P") {
                                                                    setPrefixSuffix({ isPrefixDisable: false, isSuffixDisable: true })
                                                                }
                                                                else if (e.value === "S") {
                                                                    setPrefixSuffix({ isPrefixDisable: true, isSuffixDisable: false })
                                                                }
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6}></Grid.Col>
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: '0' }}>
                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={4} style={{ padding: 0 }}>
                                                        <Text style={{ fontSize: 12 }}>Prefix</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6} style={{ padding: 0 }}>
                                                        <PLTextBox disabled={prefixSuffix?.isPrefixDisable} value={state?.M21?.T92F14}
                                                            setEdit={(e) => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    M21: {
                                                                        ...prevState.M21,
                                                                        T92F14: e.target.value
                                                                    }
                                                                }))
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={4} style={{ padding: 0 }}>
                                                        <Text style={{ fontSize: 12 }}>Suffix</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6} style={{ padding: 0 }}>
                                                        <PLTextBox disabled={prefixSuffix?.isSuffixDisable} value={state?.M21?.T92F15}
                                                            setEdit={(e) => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    M21: {
                                                                        ...prevState.M21,
                                                                        T92F15: e.target.value
                                                                    }
                                                                }))
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', fontWeight: 'bold', padding: 0 }}>
                                                {/* <Grid.Col span={12}>Auto Number</Grid.Col> */}
                                                <Text weight={500} style={{ padding: '5px 0px' }}>Auto Number</Text>
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: '0' }}>
                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={4} style={{ padding: 0 }}>
                                                        <Text style={{ fontSize: 12 }}>Auto Number Type</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6} style={{ padding: 0 }}>
                                                        <PLComboBox value={state?.M21?.FIELD54}
                                                            copno={14}
                                                            dispexpr='DisplayMember'
                                                            valexpr='ValueMember'
                                                            setEdit={(e) => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    M21: {
                                                                        ...prevState.M21,
                                                                        FIELD54: e.value
                                                                    }
                                                                }))
                                                                if (e.value === "A") {
                                                                    setIsUserNoDisable(false)
                                                                }
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={4} style={{ padding: 0 }}>
                                                        <Text style={{ fontSize: 12 }}>Allow Duplicate No</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6} style={{ padding: 0 }}>
                                                        <PLComboBox value={state?.M21?.FIELD55}
                                                            copno={1}
                                                            dispexpr='DisplayMember'
                                                            valexpr='ValueMember'
                                                            setEdit={(e) => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    M21: {
                                                                        ...prevState.M21,
                                                                        FIELD55: e.value
                                                                    }
                                                                }))
                                                            }}
                                                            onKeyDown={(e) => {
                                                                GlobalClass.formNextInput(e, formRef)
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: '0' }}>
                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={4} style={{ padding: 0 }}>
                                                        <Text style={{ fontSize: 12 }}>User No</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6} style={{ padding: 0 }}>
                                                        <PLNumberBox disabled={isUserNoDisable} value={state?.M21?.T92F16}
                                                            setEdit={(e) => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    M21: {
                                                                        ...prevState.M21,
                                                                        T92F16: e
                                                                    }
                                                                }))
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6}></Grid.Col>
                                            </Grid.Col>

                                        </Grid>
                                    </Tabs.Panel>{/*Serial No Detail*/}

                                    <Tabs.Panel value='Itemwise Expense'>
                                        <ItemWiseList OBJ={OBJ} M21={state?.M21} index={props?.index} btnFocus={btnFocus} flag={flag} setFlagFunction={setFlagFunction} activeTab={activeTab} setActiveTab={setActiveTab} nameInput={nameInput} nameInputRef={nameInputRef} />
                                    </Tabs.Panel>{/*Itemwise Expense*/}

                                </Tabs >
                                <Center my={10}>
                                    <Button id='btn' onClick={submit}
                                        onKeyDown={(e) => {
                                            if (e.key == "Tab" && activeTab === "Itemwise Expense") {
                                                setFlag(true)
                                            }
                                        }}
                                    >
                                        OK
                                    </Button>
                                </Center>
                            </div >}
                    </>
        }
    </div>)
}
