import React from "react";
import { useEffect, useState } from "react";
import { json } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GetTDSMasterGroup } from "../../utils/slices/TDSMasterSlice";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { createColumnHelper } from "@tanstack/react-table";
import GlobalClass from "../../utils/GlobalClass";
import { Paper } from "@mantine/core";
import Store from "../../utils/store";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import useWindowDimensions from "../../utils/UseWindowDimensions";

const SimpleTable = (props) => {
  const { frmtId } = props;
  // console.log("fmtid", frmtId);
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();

  const [updatedColumn, setUpdatedColumn] = useState([]);
  const [data, setData] = useState();
  const [rowSelection, setRowSelection] = useState({});
  const TDSMasterData = useSelector(
    (state) => state.TDSMasterGroup.TDSMasterGroupData
  );
//   const TDSMaster = useSelector((state)=>state?.AccountList?.AccountListData?.P_M53NO);
// console.log("TDSMaster",TDSMaster);
  // console.log("TDSMasterData",TDSMasterData?.data?.data?.jData)
  const isLoading = useSelector((state) => state?.TDSMasterGroup?.isLoading);
  const isLoading1 = useSelector((state)=>state?.AccountList?.isLoading);
  const TDSMaster = useSelector((state)=>state?.AccountList?.AccountListData?.P_M53NO);
// console.log("TDSMaster",TDSMaster);

const { height, width } = useWindowDimensions();
  useEffect(() => {
    if (frmtId) {
      Promise.resolve(
      dispatch(GetTDSMasterGroup({ frmtId }))).then(()=>{
        Store.dispatch(
          GetAccountList({
            id: "P_M53NO",
            name: "",
            p0: "TFORM0000001",
            p1: "P_M53NO",
            p2: "",
            p3: "",
            type: "A",
          })
        );
      })
    }
  }, [frmtId]);

  // console.log("frmtId",frmtId)

  // console.log("data",data)


  useEffect(() => {
    let column = [];
    column.push(
      columnHelper.accessor("FIELD02", {
        header: "Deductee Name",
        enableColumnFilter: false,
        enableGrouping:true,
        enableSorting: false,
        size: "300",
      })
    );
    column.push(
      columnHelper.accessor("FIELD11", {
        header: "Applied Date",
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) =>
          cell.getValue().slice(6, 8) +
          "/" +
          cell.getValue().slice(4, 6) +
          "/" +
          cell.getValue().slice(0, 4),
        size: "300",
      })
    );
    column.push(
      columnHelper.accessor("FIELD15", {
        header: "TDS Limit",
        enableColumnFilter: false,
        enableSorting: false,
        size: "200",
      })
    );
    column.push(
      columnHelper.accessor("FIELD16", {
        header: "TDS%",
        enableColumnFilter: false,
        enableSorting: false,
        size: "200",
      })
    );
    
    setUpdatedColumn(column);
  }, [data]);
  useEffect(() => {
    try {
      var final = [];
      var mainData = TDSMaster?.filter(
        (e) => e.FIELD00 == "R" && frmtId==e?.FIELD13
      );

      
      mainData.map((e) => {
        TDSMasterData?.data?.data?.jData.map((d) => {
          if (d.FIELD01 == e.FIELD12) {
            final.push({ ...d});
          }
        });
      });
      setData(final);
    } catch (error) {
      console.error(error);
    }
  }, [frmtId, TDSMasterData?.data?.data?.jData,TDSMaster]);

  // console.log("data to show", data);
  const table = useMantineReactTable({
    columns: updatedColumn ? updatedColumn : [],
    data: data ? data : [],
    // size: "full-screen",
    enableFilters: false,
    enableColumnActions: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enablePagination: true,
    enableGrouping: true,
    enableRowSelection:true,
    initialState: {
      expanded: false,
      grouping: ["FIELD02"],
      density: "0px",
    },
    mantineTableProps:{withColumnBorders:true},
    mantineTableContainerProps: {
      sx: { height: height * 0.6 },
    },
    state: {
      density: "1.5px",
      rowSelection,
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
      onClick: () => {
        setRowSelection((prev) => {
          if (!prev[row.id]) {
            return { [row.id]: true };
          }
          return prev;
        });
      },
      selected: rowSelection[row?.id],
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      {isLoading || isLoading1
        ? GlobalClass.RenderLoader("skeleton")
        : updatedColumn &&
          updatedColumn.length > 0 &&
          data &&
          data.length > 0 && <MantineReactTable table={table} striped={true} />}
    </>
  );
};

export default SimpleTable;
