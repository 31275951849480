import { Button, Center, Grid, Text } from "@mantine/core";
import PLPrint from "../FunctionsCall/Print/PLPrint";
import Store from "../utils/store";
import { ModalDelete, ModalFunction } from "../utils/slices/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import PLTextBox from "./PLTextBox";
import { useEffect, useRef, useState } from "react";
import PLComboBox from "./PLComboBox";
import GlobalClass from "../utils/GlobalClass";
import { GetTMFormat, getSortingField } from "../utils/slices/AccountListSlice";



const PLOrder = (props) =>{
  const {
    AccountListData: accList,
    TMFormatData: tmList,
    isLoading,
    hasError,
    ErrorMsg,
  } = useSelector((state) => state.AccountList);
  // console.log("accList=>",accList[props?.data?.page],"tmList=>",tmList[props?.data?.page],"AccNameisLoading=>",AccNameisLoading[props?.data?.page]);
  // console.log("props",props);
  console.log("isLoading",isLoading);
  // console.log("props?.data?.page",props?.data?.page);
  const orderData = [
    { dispexpr: "Ascending", valexpr: "A" },
    {
      dispexpr: "Descending",
      valexpr: "D",
    },
  ];
const [arrangeOn,setArrangeOn]=useState();
const [arrangeData,setArrangeData]=useState([]);
const [orderType,setOrderType]=useState("A");
const formRef = useRef(null);
const dispatch = useDispatch();

useEffect(() => {
  if (hasError && !isLoading[props?.data?.cSTID]) {
    GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
  }
}, [hasError]);

useEffect(() => {
  const formElement = formRef.current;
  if (formElement) {
    formElement.addEventListener("keypress", (e) =>
      GlobalClass.formNextInput(e, formRef)
    );
  }
  return () => {
    if (formElement) {
      formElement.removeEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
  };
}, [formRef]);

useEffect(()=>{
  let colDef=[];
  tmList[props?.data?.cSTID]?.oDCFrmt?.aDCol?.map((a)=>{
    // console.log("a?.ColCap",a?.ColCap);
    // console.log("a?.cColID",a?.cColID);
    if(a?.ColCap!=="" && a?.lColOrder==true){
    colDef?.push(
      {
        dispexpr: a?.ColCap, valexpr: a?.cColID        
      }
    )}
  })
  // console.log("colDef",colDef);
  setArrangeData(colDef);
  setArrangeOn(colDef?.at(0)?.valexpr)
},[tmList[props?.data?.cSTID]?.oDCFrmt?.aDCol]);


// console.log("arrangeData",arrangeData);

const handleOkButton = () =>{
  Promise.resolve(
  dispatch(getSortingField({"arrange":arrangeOn,"order":orderType,"id":props?.data?.cSTID}))).then(()=>{
    dispatch(ModalDelete(props.index));
  })
}

return  hasError ? (
    <>
      <Text>{ErrorMsg?.message}</Text>
      <Button
        onClick={() => {
          if (props?.obj) {
              dispatch(
                GetTMFormat({
                  ...props?.obj,
                   })
              );
          }
        }}
      >
        Retry
      </Button>
    </>
  ) : isLoading[props?.data?.cSTID] ? (
    GlobalClass?.RenderLoader("dots")
  ) : (
  <div ref={formRef}>
  {arrangeData==[] ? 
   <Center>
    <Text>Ordering is not available</Text>
   </Center>   : <Grid gutter={4}>
   <Grid.Col span={12}>
   <Grid.Col
          span={12}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        >
          <Grid.Col span={2}>
            <Text style={{ fontSize: 12 }}>Arrange On</Text>
          </Grid.Col>
          <Grid.Col span={10}>
              <PLComboBox 
              autoOpen={true}
              data={arrangeData}
              dispexpr={"dispexpr"}
              valexpr={"valexpr"}
              value={arrangeOn}
              setEdit={(e)=>{
                // console.log("e of arrange",e);
                setArrangeOn(e);
              }}
              onKeyDown={(e) => {
                GlobalClass.formNextInput(e, formRef);
              }}
              />
            
          </Grid.Col>
        </Grid.Col>
    </Grid.Col> 
    <Grid.Col span={12}>
   <Grid.Col
          span={12}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        >
          <Grid.Col span={2}>
            <Text style={{ fontSize: 12 }}>Order type</Text>
          </Grid.Col>
          <Grid.Col span={10}>
              <PLComboBox 
              data={orderData}
              dispexpr={"dispexpr"}
              valexpr={"valexpr"}
              value={orderType}
              setEdit={(e)=>{
                // console.log("e of order",e);
                setOrderType(e);
              }}
              onKeyDown={(e) => {
                GlobalClass.formNextInput(e, formRef);
              }}
              />
            
          </Grid.Col>
        </Grid.Col>
    </Grid.Col> 
    <Grid.Col span={12}>
          <Center>
            <Button
              style={{ width: "130px" }}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  e.preventDefault();
                  handleOkButton();
                }
              }}
              onClick={handleOkButton}
            >
              Ok
            </Button>
          </Center>
        </Grid.Col>
</Grid>
}

</div>
)
}
export function TMSHOWORDER(data) {
    const OBJ = JSON.parse(data);
    console.log("data", data, "OBJ=>", OBJ);
    Store.dispatch(
      ModalFunction({
        onclose: (e) => { },
        MTitle: <Text fw={700}>Report Order</Text>,
        MAction: true,
        MSize: "xl",
        position:"right",
        MBody: (i) => <PLOrder data={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  }