import { Button, Drawer, Grid, Group, Modal, Text } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { ModalDelete, ModalFunction } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import { createColumnHelper } from "@tanstack/react-table";
import { ColumnDirective, ColumnsDirective, QueryBuilderComponent } from '@syncfusion/ej2-react-querybuilder';
import { ComboBoxComponent, DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GetAccountList, GetTMFormat, SaveAccountListData, SetAccountListData } from "../../utils/slices/AccountListSlice";
import { useDispatch, useSelector } from "react-redux";
import { GetAccountListForPrint } from "../../utils/PrintSlice/PrintSlice";
import GlobalClass from "../../utils/GlobalClass";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import DataGrid from "../../components/DataGrid";
import PLModal from "./PLModal";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { GetFilterData, GetM47AED, getDataStateNull, getFilterStatus, getFiltersName } from "../../utils/FilterSlice/FilterSlice";
import { GetReportDesignerData } from "../../utils/PrintSlice/ReportDesignerSlice";
import PLComboBox from "../../PlusComponent/PLComboBox";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import PLDateBox from "../../PlusComponent/PLDateBox";
import gensetting from "../../utils/gensetting";
import { modals } from "@mantine/modals";

let globalid = "";
let P1Value = "";
let pageid = "";
let separator = ".";
export const PLFilter = (props) => {
    // console.log("PLFilter", props);
    const { ary, OBJ } = props;
    const qryBldrObj = useRef(null);
    console.log("qryBldrObj", qryBldrObj)
    // console.log("OBJ", OBJ)
    // console.log("props ary", ary)

    const dispatch = useDispatch();
    // const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)
    const SaveLoading = useSelector(
        (state) => state?.DataSaveLoadingSlice?.SaveLoading
    );
    const { isLoading, hasError, ErrorMsg, Data } = useSelector(
        (state) => state?.FilterSlice
    );
    //   console.log("reportData",Data)
    const filterData = useSelector((state) => state.FilterSlice?.Data)
    console.log("filterData", filterData)
    const CBOF = useSelector((state) => state.FilterSlice?.Data?.CBOF)
    const CBOFFV = useSelector((state) => state.FilterSlice?.Data?.CBOFFV)
    const CBOND = useSelector((state) => state.FilterSlice?.Data?.CBOND)
    const CBOOT = useSelector((state) => state.FilterSlice?.Data?.CBOOT)
    const CBOP = useSelector((state) => state.FilterSlice?.Data?.CBOP)
    const M47DATA = useSelector((state) => state.FilterSlice?.Data?.M47DATA)
    const M47list = useSelector((state) => state.FilterSlice?.Data?.M47list)
    const S96 = useSelector((state) => state.FilterSlice?.Data?.S96)
    const { TMFormatData: tmList } = useSelector((state) => state.AccountList);
    const AccountListData = useSelector(store => store?.PrintList?.PrintData);
    // const isLoading = useSelector(store => store?.PrintList?.isLoading);
    const FiltersList = useSelector((state) => state.FilterSlice?.FiltersData)
    console.log("FiltersList", FiltersList)

    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
    };
    const [DrawerObj, setDrawerObj] = useState(dra);
    const [groupedData, setGroupedData] = useState([]);
    const [ruleID, setruleID] = useState();
    const [queryState, setQueryState] = useState({});
    // console.log("queryState", queryState)
    const [selectedObj, setSelectedObj] = useState(null)
    const [customDropDown, setCustomDropDown] = useState([])
    const [opeartorVal, setOperatorVal] = useState(null)
    const [customValue, setCustomValue] = useState("")
    const [M47Data, setM47Data] = useState([])
    const [M47Obj, setM47Obj] = useState({})
    const [counter, setCounter] = useState(0)
    const [rules, setRules] = useState({
        'condition': 'and',
        'rules': []
      });
    const [showButtons, setShowButtons] = useState({ cloneGroup: true, cloneRule: true });
    // const filter = S96?.filter((v) => v.field06 == "C" && (v.field07 == "2" || v.field07 == "3"))
    // const filter = S96?.filter((v) => v.field07 == "P")
    // console.log("filter", filter)

    useEffect(() => {
        console.log("M47Obj", M47Obj)
        console.log("M47Data", M47Data)
        console.log("flterData", filterData)
    }, [M47Obj, M47Data, filterData])

    useEffect(() => {
        if (props?.value) {
            dispatch(GetM47AED({
                cSTID: props?.value?.FIELD01,
                cCode: props?.value?.FIELD02,
                cAction: "E"
            }))
            // setRule(
            //     {
            //         condition: "and",
            //         rules: [
            //             {
            //                 label: "Audited Voucher Only",
            //                 field: "TL100002",
            //                 type: "string",
            //                 operator: "=",
            //                 value: "F",
            //             },
            //             {
            //                 label: "Audited Voucher Only",
            //                 field: "TL100002",
            //                 type: "string",
            //                 operator: "=",
            //                 value: "F",
            //             },
            //             {
            //                 condition: "or",
            //                 rules: [
            //                     {
            //                         label: "Audited Voucher Only",
            //                         field: "TL100002",
            //                         type: "string",
            //                         operator: "=",
            //                         value: "F",
            //                     },
            //                     {
            //                         label: "Audited Voucher Only",
            //                         field: "TL100002",
            //                         type: "string",
            //                         operator: "=",
            //                         value: "F",
            //                     },
            //                 ]
            //             }
            //         ],
            //     },
            // )
        }
    }, [props?.value])

    useEffect(() => {
        if (FiltersList?.M47DATA && FiltersList?.M47DATA?.length > 0 && props?.value) {
            setM47Data(FiltersList?.M47DATA)
            let filterData = FiltersList?.M47DATA?.filter((f) => f.FIELD01 != null)
            let newRule = {
                condition: "and",
                rules: filterData?.map((v, i) => {
                    return {
                        label: v.FIELD10NM,
                        field: v.FIELD10,
                        type: "string",
                        operator: v.FIELD05,
                        value: v.FIELD99,
                    }
                })
            }
            setRules(newRule)
        }
    }, [FiltersList?.M47DATA, props?.value])

    // useEffect(() => {
    //     if (FiltersList?.M47DATA && FiltersList?.M47DATA?.length > 0) {
    //         let updatedData = FiltersList?.M47DATA?.filter((f) => f.FIELD01 !== null)?.map((v, i) => {
    //             return {
    //                 condition: "and",
    //                 rules: [
    //                     {
    //                         label: "", // You can set this dynamically if needed
    //                         field: v.FIELD10, // setting field to v.FIELD10
    //                         type: "string", // type remains "string" as per your request
    //                         operator: v.FIELD05, // setting operator to v.FIELD05
    //                         value: v.FIELD99 // setting value to v.FIELD99
    //                     }
    //                 ]
    //             }
    //         })
    //         setRule(updatedData)
    //     }
    // }, [FiltersList?.M47DATA])

    useEffect(() => {
        if (hasError && !isLoading) {
            GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
        }
    }, [hasError]);

    useEffect(() => {
        if (OBJ) {
            dispatch(GetFilterData(OBJ))
        }
    }, [OBJ])

    useEffect(() => {
        if (M47DATA) {
            // setM47Data(M47DATA)
            setM47Obj({ ...M47DATA[M47DATA?.length - 1], FIELD07: "and" })
        }
    }, [M47DATA])

    useEffect(() => {
        if (CBOFFV) {
            let item = CBOFFV?.map((v, i) => {
                return {
                    value: v.ValueMember,
                    label: v.DisplayMember
                }
            })
            setCustomDropDown(item)
        }
    }, [CBOFFV])

    function selectedOperators(selected) {
        // console.log("run function", selected)
        let findVal = groupedData.find((v) => v.field02 == selected)
        console.log("findVal", findVal)
        // setM47Obj({ ...M47Obj, FIELD01: findVal?.field01, FIELD04: findVal?.field05, FIELD06: findVal?.field06, FIELD10: findVal?.field02, FIELD90: findVal?.field02, FIELD15: 0 })
        let val = [];
        if (findVal) {
            if (findVal?.field06 === "N" || findVal?.field06 === "D") {
                CBOND?.map(v => val.push({ label: v.DisplayMember, value: v.ValueMember }));
            } else if (findVal?.field06 === "C" && ["2", "3"].includes(findVal?.field07)) {
                CBOF?.map(v => val.push({ label: v.DisplayMember, value: v.ValueMember }));
            } else if (findVal?.field07 === "P") {
                CBOP?.map(v => val.push({ label: v.DisplayMember, value: v.ValueMember }));
            } else {
                CBOOT?.map(v => val.push({ label: v.DisplayMember, value: v.ValueMember }));
            }
        } else {
            CBOOT?.map(v => val.push({ key: v.DisplayMember, value: v.ValueMember }));
        }
        // console.log("operator_val", val)
        return val
    }

    const dropdownTemplate = (selectdVal) => {
        let selectedObj = groupedData.find((v) => v.field02 == selectdVal)
        console.log("selectedObj", selectedObj)

        if (selectedObj) {
            setM47Obj((prev) => ({ ...prev, FIELD01: selectedObj?.field01, FIELD04: selectedObj?.field05, FIELD06: selectedObj?.field06, FIELD10: selectedObj?.field02, FIELD90: selectedObj?.field02, FIELD15: 0, FIELD11: counter }))
        }

        const split = (value) => {
            let val = value.split("~")
            return val
        }
        // console.log("dropdownTemplate")
        return (
            <>
                {
                    selectedObj?.field07 == "P" && selectedObj?.field06 == "C" ?
                        <PLDataGrid
                            // position="target"
                            // pid={P1Value}
                            // value={M47Obj?.FIELD23}
                            disabled={M47Obj?.FIELD99 == "V" ? true : false}
                            pid={split(selectedObj?.FIELD08)[0]}
                            value={{ value: M47Obj?.FIELD23, label: M47Obj?.FIELD17 }}
                            TmData={selectedObj?.FIELD08}
                            isMulti={true}
                            width="500px"
                            setEdit={(e) => {
                                console.log("datagrid", e)
                                let selectedRow = "";
                                let selectedName = ""
                                if (e?.length > 0) {
                                    selectedRow = e?.map((item) => item?.original?.FIELD01).join(",");
                                    selectedName = e?.map((item) => item?.original?.FIELD02).join(",");
                                }
                                setCustomValue(selectedRow)
                                setM47Obj((prev) => ({ ...prev, FIELD23: selectedRow, FIELD17: selectedName }))
                            }}
                        /> :
                        selectedObj?.field06 == "C" && ["2", "3"].includes(selectedObj?.field07) ?
                            <PLComboBox
                                disabled={M47Obj?.FIELD99 == "V" ? true : false}
                                copno={selectedObj?.FIELD08 ?? "A"}
                                // value={M47Obj?.FIELD17}
                                dispexpr="DisplayMember"
                                valexpr="ValueMember"
                                setEdit={(e) => {
                                    console.log("comboval", e)
                                    setM47Obj((prev) => ({ ...prev, FIELD17: e.value }))
                                    setCustomValue(e)
                                }}
                            /> :
                            selectedObj?.field06 == "C" && selectedObj?.field07 !== "P" ?
                                <PLTextBox
                                    disabled={M47Obj?.FIELD99 == "V" ? true : false}
                                    setEdit={(e) => {
                                        console.log("textBox val", e.target.value)
                                        setM47Obj((prev) => ({ ...prev, FIELD17: e.target.value }))
                                        setCustomValue(e.target.value)
                                    }}
                                /> :
                                selectedObj?.field06 == "N" ?
                                    <PLNumberBox
                                        disabled={M47Obj?.FIELD99 == "V" ? true : false}
                                        setEdit={(e) => {
                                            setM47Obj((prev) => ({ ...prev, FIELD17: e }))
                                            setCustomValue(e)
                                        }}
                                    /> :
                                    selectedObj?.field06 == "D" ?
                                        <PLDateBox
                                            disabled={M47Obj?.FIELD99 == "V" ? true : false}
                                            setEdit={(e) => {
                                                let year = e.toLocaleString("en-IN", {
                                                    year: "numeric",
                                                });
                                                let month = e.toLocaleString("en-IN", {
                                                    month: "2-digit",
                                                });
                                                let day = e.toLocaleString("en-IN", {
                                                    day: "2-digit",
                                                });

                                                let StringDate = year + month + day;
                                                if (StringDate?.length == 8) {
                                                    setM47Obj((prev) => ({ ...prev, FIELD17: StringDate }))
                                                    setCustomValue(StringDate)
                                                }
                                            }}
                                        /> : ""
                }
            </>
        )
    }

    const groupAndTransformData = (data) => {

        console.log("selectedObj", selectedObj)
        // let operators = selectedOperators(selectedObj);
        // console.log("operator", operators)
        let groupedData = data?.reduce((acc, item) => {
            const groupName = item.field12;
            const groupId = item.field02;

            let group = acc.find(g => g.field === groupName);

            if (!group) {
                group = {
                    field: groupName,
                    label: groupName,
                    columns: [],
                };
                acc.push(group);
            }

            let type = "string";

            if (item.field06 === 'C' && item.field07 !== 'P') {
                type = "string";
            } else if (item.field06 === 'N') {
                type = "number";
            } else if (item.field06 === 'D') {
                type = "date";
            }

            group.columns.push({
                ...item,
                field: groupId,
                label: item.field03,
                type: type
                // operators: operators,
                // template: dropdownTemplate,
            });

            return acc;
        }, []);
        // console.log("groupedData", groupedData);
        return groupedData
    };

    const renderColumns = (data) => {
        let columns = []
        let type = "string";

        data?.map((v, i) => {
            if (v.field06 === 'C' && v.field07 !== 'P') {
                type = "string";
            } else if (v.field06 === 'N') {
                type = "number";
            } else if (v.field06 === 'D') {
                type = "date";
            }
            columns.push({
                ...v,
                field: v.field02,
                label: v.field03,
                type: type
            })
        })
        return columns
    }

    useEffect(() => {
        // if(M47Data?.length > 0){
            const grouped = renderColumns(S96);
            console.log("grouped", grouped);
            setGroupedData(grouped);
        // }
    }, [selectedObj, S96]);

    const handleRuleChange = (args) => {
        console.log("handleRuleChange", args);

        const { type, groupID, field, value, operator, condition, ruleID, childGroupID } = args;

        const selectedObject = S96?.find(item => item?.field02 == value);

        if (selectedObject) {
            setSelectedObj(selectedObject);
        }


        switch (type) {
            case "insertRule":
                // Adding Condition
                // setQueryState(prevState => ({
                //     ...prevState,
                //     [ruleID]: {
                //         field: "",
                //         operator: "",
                //         value: "",
                //     },
                // }));
                break;
            case "insertGroup":
                // Adding Group

                setQueryState(prevState => ({
                    ...prevState,
                    [ruleID]: {
                        field: "",
                        operator: "",
                        value: "",
                    },
                }));
                break;

            case "deleteRule":
                // Removing Condition
                setQueryState(prevState => {
                    const newState = { ...prevState };
                    if (newState[ruleID]) {
                        delete newState[ruleID];
                    }
                    return newState;
                });
            //     setPLDataGridSelectedValues(prevState => {
            //         const newState = { ...prevState };
            //         if (newState[ruleID]) {
            //             delete newState[ruleID]
            //         }
            //         return newState;
            //     })

            //     break;
            case "deleteGroup":
                // Removing Group
                setQueryState(prevState => {
                    const newState = { ...prevState };
                    if (newState[ruleID]) {
                        delete newState[ruleID];
                    }
                    return newState;
                });
                break;
            case "field":
                setQueryState(prevState => ({
                    ...prevState,
                    [ruleID]: {
                        ...prevState?.[ruleID],
                        field: value
                    }
                }));
                break;
            case "value":
                setQueryState(prevState => ({
                    ...prevState,
                    [ruleID]: {
                        ...prevState?.[ruleID],
                        value: value
                    }
                }));
                break;
            case "operator":
                setOperatorVal(value)
                setQueryState(prevState => ({
                    ...prevState,
                    [ruleID]: {
                        ...prevState?.[ruleID],
                        operator: value
                    }
                }));
                break;
            case "condition":
                setQueryState(prevState => ({
                    ...prevState,
                    [`${groupID}_condition`]: value
                }));
                break;
            default:
                break;
        }

    };

    const RunFilter = () => {

        let updatedData = [...M47Data, M47Obj]
        const filterData = updatedData?.filter((v) => v.FIELD01 !== null && v.FIELD01 !== "")
        console.log("filterData", filterData)
        if (filterData && filterData?.length > 0) {
            dispatch(getFilterStatus(true))
            var JData = { M47DATA: [...filterData] }
            console.log("JData", JData)
            var data = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "",
                cCode: "",
                cOPara: OBJ?.cSTID,
                cSData: JSON.stringify(JData),
            };
            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "",
                cCode: "",
                cOPara: OBJ?.cSTID,
            });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            fetch(
                GlobalClass.ApiUrl + GlobalClass.ApplyFilter + "?pa=" + param,
                requestOptions
            )
                .then((response) => response.json())
                .then((data) => {
                    console.log("data", data)
                    if (data.status == "SUCCESS") {
                        Promise.resolve(dispatch(DataSaveLoading(false)))
                            .then(() => {
                                dispatch(ModalDelete(props.index))
                                modals.closeAll()
                            })
                            .then(() => dispatch(ModalDelete(props.index)))
                            .then(() => {
                                dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: data?.data?.jData }))
                            })
                    } else {
                        dispatch(DataSaveLoading(false))
                        GlobalClass.Notify("error", data?.status, data?.message)

                    }
                })
                .catch((e) => {
                    dispatch(DataSaveLoading(false));
                    GlobalClass.Notify("error", "Error", `${e.message}`)
                });
        } else {
            return;
        }
    }

    const SubmitFilter = (obj) => {
        console.log("SubmitFilter obj", obj)
        // console.log("M47Obj in function",M47Obj)
        dispatch(DataSaveLoading(true));
        const filterData = obj?.FormData?.filter((v) => v.FIELD01 !== null && v.FIELD01 !== "")
        console.log("filterData", filterData)
        var JData = { M47DATA: [...filterData] }
        console.log("JData", JData)

        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "",
            cCode: "",
            cOPara: obj?.FormName,
            cInvCode: OBJ?.cSTID,
            cSData: JSON.stringify(JData),
        };
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "",
            cCode: "",
            cOPara: obj?.FormName,
            cInvCode: OBJ?.cSTID,
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.SaveM47 + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.status == "SUCCESS") {
                    Promise.resolve(dispatch(DataSaveLoading(false)))
                        .then(() => {
                            dispatch(ModalDelete(props.index))
                        })
                        .then(() =>
                            GlobalClass.Notify(
                                "success",
                                "Added",
                                "Added successfully"
                            )
                        )
                        .then(() => {
                            dispatch(GetFilterData(OBJ))
                        })
                } else {
                    dispatch(DataSaveLoading(false))
                    GlobalClass.Notify("error", data?.status, data?.message)

                }
            })
            .catch((e) => {
                dispatch(DataSaveLoading(false));
                GlobalClass.Notify("error", "Error", `${e.message}`)
            });
    }

    // const saveBtn = () => {
    //     let newObj = {};
    //    let updatedData = setM47Data((prev) => ([
    //         ...prev,
    //         M47Obj
    //     ]))

    //     setDrawerObj({
    //         ...dra,
    //         title: <Text fw={700}>Save Filter</Text>,
    //         body: (
    //             <>
    //                 <Grid>
    //                     <Grid.Col span={12} style={{ display: 'flex' }}>
    //                         <Grid.Col span={4}>
    //                             <Text size={12}>Filter Name</Text>
    //                         </Grid.Col>
    //                         <Grid.Col span={8}>
    //                             <PLTextBox
    //                                 setEdit={(e) => {
    //                                     // setFilterName(e.target.value)
    //                                     newObj = { "FormName": e.target.value, "FormData": updatedData }
    //                                 }}
    //                             />
    //                         </Grid.Col>
    //                     </Grid.Col>
    //                     <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
    //                         <Button onClick={() => SubmitFilter(newObj)} >Ok</Button>
    //                     </Grid.Col>
    //                 </Grid>
    //             </>
    //         ),
    //         open: true,
    //         size: "md",
    //         position: "right",
    //     });
    // }

    const saveBtn = () => {
        let filterData = M47Data?.filter((f) => f.FIELD01 != null)
        console.log("filterData_saveBtn", filterData)
        if ((filterData && filterData?.length > 0) || M47Obj?.FIELD01 != null) {

            let newObj = {};

            // setM47Data((prev) => {
            const updatedData = [...M47Data, M47Obj];

            setDrawerObj({
                ...dra,
                title: <Text fw={700}>Save Filter</Text>,
                body: (
                    <>
                        <Grid>
                            <Grid.Col span={12} style={{ display: 'flex' }}>
                                <Grid.Col span={4}>
                                    <Text size={12}>Filter Name</Text>
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <PLTextBox
                                        setEdit={(e) => {
                                            newObj = { "FormName": e.target.value, "FormData": updatedData };
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => SubmitFilter(newObj)} >Ok</Button>
                            </Grid.Col>
                        </Grid>
                    </>
                ),
                open: true,
                size: "md",
                position: "right",
            });
            // return updatedData;
            // });
        }
        else {
            return;
        }
    }

    const transformDataForQueryBuilder = (data) => {
        // const queryData = {
        //     condition: data.group0_condition,
        //     rules: Object.keys(data)
        //         .filter(key => key.includes('_rule'))
        //         .map(key => {
        //             const ruleId = key.split('_')[1];
        //             const ruleData = data[key];
        //             return {
        //                 label: ruleData.field,
        //                 field: ruleData.field,
        //                 type: 'string', // You may need to adjust this based on your data types
        //                 operator: ruleData.operator,
        //                 value: ruleData.value
        //             };
        //         })
        // };
        // return queryData;


        // const queryData = {
        //     condition: "and", // Default condition
        //     rules: []
        // };

        // let currentGroup = null;

        // Object.keys(data).forEach(key => {
        //     if (key.includes('_rule')) {
        //         const ruleId = key.split('_')[1];
        //         const ruleData = data[key];
        //         const ruleObj = {
        //             label: ruleData.field,
        //             field: ruleData.field,
        //             type: 'string', // You may need to adjust this based on your data types
        //             operator: ruleData.operator,
        //             value: ruleData.value
        //         };

        //         if (currentGroup) {
        //             // If a rule belongs to a group, add it to the group's rules
        //             currentGroup.rules.push(ruleObj);
        //         } else {
        //             // If a rule doesn't belong to any group, add it directly to queryData
        //             queryData.rules.push(ruleObj);
        //         }
        //     } else if (key.endsWith('_condition')) {
        //         // Set the condition for the current group
        //         const groupId = key.split('_')[0];
        //         if (queryData.rules.length > 0 && groupId in queryData.rules[queryData.rules.length - 1]) {
        //             // If there are rules in the queryData and the current group matches the last rule
        //             queryData.rules[queryData.rules.length - 1].condition = data[key];
        //         } else {
        //             // If the condition belongs to a new group
        //             currentGroup = {
        //                 condition: data[key],
        //                 rules: []
        //             };
        //             queryData.rules.push(currentGroup);
        //         }
        //     }
        // });

        // return queryData;

        // const queryData = {
        //     condition: "and", // Default condition
        //     rules: []
        // };

        // let currentGroup = null;

        // Object.keys(data).forEach(key => {
        //     if (key.includes('_rule')) {


        //         console.log("queryData IF",queryData);

        //         const ruleId = key.split('_')[1];
        //         const ruleData = data[key];
        //         const ruleObj = {
        //             label: ruleData.field,
        //             field: ruleData.field,
        //             type: 'string', // You may need to adjust this based on your data types
        //             operator: ruleData.operator,
        //             value: ruleData.value
        //         };

        //         if (currentGroup) {
        //             // If a rule belongs to a group, add it to the group's rules
        //             currentGroup.rules.push(ruleObj);
        //         } else {
        //             // If a rule doesn't belong to any group, add it directly to queryData
        //             queryData.rules.push(ruleObj);
        //         }
        //     } else if (key.endsWith('_condition')) {
        //         // Set the condition for the current group
        //         console.log("queryData ELSE",queryData);
        //         const groupId = key.split('_')[0];
        //         if (queryData.rules.length > 0 && groupId in queryData.rules[queryData.rules.length - 1]) {
        //             // If there are rules in the queryData and the current group matches the last rule
        //             queryData.rules[queryData.rules.length - 1].condition = data[key];
        //         } else {
        //             // If the condition belongs to a new group
        //             currentGroup = {
        //                 condition: data[key],
        //                 rules: []
        //             };
        //             queryData.rules.push(currentGroup);
        //         }
        //     }
        // });

        // return queryData;


        const queryData = {
            condition: "and", // Default condition
            rules: []
        };

        let currentGroup = null;

        Object.keys(data).forEach(key => {
            if (key.includes('_rule')) {
                const ruleId = key.split('_')[1];
                const ruleData = data[key];
                const ruleObj = {
                    label: ruleData.field,
                    field: ruleData.field,
                    type: 'string', // You may need to adjust this based on your data types
                    operator: ruleData.operator,
                    value: ruleData.value
                };

                if (currentGroup) {
                    // If a rule belongs to a group, add it to the group's rules
                    currentGroup.rules.push(ruleObj);
                } else {
                    // If a rule doesn't belong to any group, add it directly to queryData
                    queryData.rules.push(ruleObj);
                }
            } else if (key.endsWith('_condition')) {
                // Set the condition for the current group
                const groupId = key.split('_')[0];
                if (currentGroup) {
                    // If there's already a current group, join it with the previous group based on the condition
                    const joinCondition = currentGroup.condition === 'or' ? 'or' : 'and';
                    queryData.rules.push({ condition: joinCondition, rules: currentGroup.rules });
                }
                currentGroup = {
                    condition: data[key],
                    rules: []
                };
            }
        });

        // If there's a current group, add it to the queryData
        if (currentGroup) {
            const joinCondition = currentGroup.condition === 'or' ? 'or' : 'and';
            queryData.rules.push({ condition: joinCondition, rules: currentGroup.rules });
        }

        return queryData;

    };
    const queryData = ary && transformDataForQueryBuilder(ary);
    // console.log(queryData , "queryData");

    const handleCreated = ({ type="", groupID, field, value, operator, condition, ruleID, childGroupID }) => {
        // console.log("handleCreated", args)
        console.log("handleCreated M47Data", [{
            type:type,
            M47Data:M47Data
        }])

        // const { type, groupID, field, value, operator, condition, ruleID, childGroupID } = args;

        switch (type) {
            case "insertRule":
                // Adding Condition
                // setM47Data(prevState => ({
                //     ...prevState,
                //     [ruleID]: M47Obj
                // }));
                let arr = [];
                arr[ruleID] = {...M47Obj}
                let newArr = [...M47Data,...arr]
                console.log("arr[ruleID]",newArr,ruleID)
                setM47Data(newArr);
                setCounter(counter + 1)
                
                setM47Obj({ ...M47DATA[M47DATA?.length - 1], FIELD07: "and" })
                break;
            case "insertGroup":
                // Adding Group
                setCounter(counter + 1)
                // setM47Data(prevState => ({
                //     ...prevState,
                //     [ruleID]: M47Obj
                // }));
                setM47Obj({ ...M47DATA[M47DATA?.length - 1], FIELD07: "and" })
                break;

            case "deleteRule":
                // Removing Condition
                // setM47Data(prevState => {
                //     const newState = { ...prevState };
                //     if (newState[ruleID]) {
                //         delete newState[ruleID];
                //     }
                //     return newState;
                // });
                if(M47Data?.length > 0){
                    let FilterArr = M47Data?.filter((d,i)=> i != ruleID)
                    setM47Data(FilterArr)
                }
                // if(M47Data && Object.keys(M47Data).length > 0) {
                //     if(M47Data.hasOwnProperty(ruleID)) {
                //       const updatedData = { ...M47Data };
                //       delete updatedData[ruleID]; 
                //       setM47Data(updatedData);
                //     }
                //   }
                break;
            case "deleteGroup":
                // Removing Group

                break;
            case "field":

            case "value":

                break;
            case "operator":

                break;
            case "condition":
                setM47Obj((prev) => ({
                    ...prev,
                    FIELD07: value
                }))
                break;
            default:
                break;
        }
    }

    const ruleTemplate = (e) => {
        console.log("ruleTemplate_e", e)
        let fields = { text: "label", value: "value" };
        let value = e.rule.field
        let operatorData = selectedOperators(value)
        // setM47Obj({...M47Obj, FIELD10:value, FIELD90:value})
        setM47Obj((prev) => ({
            ...prev,
            FIELD10: value,
            FIELD10NM: e.rule.label,
            FIELD90: value
        }))

        setShowButtons({ cloneGroup: true, cloneRule: true });
        const fieldChange = (args) => {
            console.log("fieldChange", args);
            qryBldrObj.current.notifyChange(args.value, args.element, "field");
            operatorData = selectedOperators(args.value)
            console.log("operatorData", operatorData)
            // setM47Obj({...M47Obj, FIELD10:args.value, FIELD90:args.value})
            setM47Obj((prev) => ({
                ...prev,
                FIELD10: value,
                FIELD10NM: args?.itemData?.label,
                FIELD90: value
            }))
        };

        const operatorClick = (args) => {
            console.log("operatorClick", args);
            setOperatorVal(args.value)
            qryBldrObj.current.notifyChange(args.value, args.element, "operator");
            setM47Obj((prev) => ({
                ...prev,
                FIELD05: args.value,
            }))
        };

        const typeChange = (args) => {
            qryBldrObj.current.notifyChange(args.value, args.element, "value");
            setM47Obj((prev) => ({
                ...prev,
                FIELD99: args.value
            }))
        }

        const deleteRule = (args) => {
            console.log("deleteRule", args)

        }

        return (
            <div className="e-rule e-rule-template">
                <div className="e-rule-filter">
                    <DropDownListComponent
                        change={fieldChange}
                        fields={e?.fields}
                        dataSource={e?.columns}
                        value={e?.rule?.field}
                    />
                </div>

                <div className="e-rule-operator e-operator">
                    {
                        <DropDownListComponent
                            dataSource={operatorData}
                            fields={fields}
                            change={operatorClick}
                            value={e.rule.operator}
                        />}
                </div>

                <div className="e-rule-value e-value e-custom-value">
                    <DropDownListComponent
                        dataSource={customDropDown}
                        fields={fields}
                        change={typeChange}
                        value={e.rule.value}
                    />
                </div>

                <div className="e-rule-value e-value e-custom-value">
                    {
                        dropdownTemplate(value)
                    }
                </div>

                <div className="e-rule-value-delete">
                    <button
                        className="e-removerule e-custom-delete e-rule-delete e-css e-btn e-small e-round"
                        title="Delete Rule"
                        onClick={deleteRule}
                    >
                        <span className="e-btn-icon e-icons e-delete-icon" />
                    </button>
                </div>
            </div>
        );
    };

    const handleBegin = (args) => {
        console.log("handleBegin",args)
    }

    const handleBeforeInsertRule = (args) => {
        args.cancel = true; // prevent insertRule
      };

    return (<>
        {
            filterData && filterData?.M47list && filterData?.M47list?.length > 0 && !props?.form ?
                <PLModal OBJ={OBJ} index={props.index} /> :
                <>
                    {
                        hasError ? <>
                            <Text>{ErrorMsg?.message}</Text>
                            <Button onClick={() => {
                                if (props?.value) {
                                    dispatch(GetM47AED({
                                        cSTID: props?.value?.FIELD01,
                                        cCode: props?.value?.FIELD02,
                                        cAction: "E"
                                    }))
                                    dispatch(GetFilterData(OBJ))
                                } else {
                                    dispatch(GetFilterData(OBJ))
                                }
                            }}>Retry</Button>
                        </> :
                            <>
                                {
                                    isLoading ? GlobalClass.RenderLoader("dots") :
                                        groupedData && groupedData?.length > 0 &&
                                        <>
                                            <Modal
                                                opened={DrawerObj?.open}
                                                // onClose={close}
                                                withCloseButton={() => { }}
                                                fullScreen={false} //
                                                // overlayProps={{ opacity: 0.5, blur: 4 }}
                                                closeButtonProps={{
                                                    onClick: () => {
                                                        setDrawerObj(dra);
                                                    },
                                                }}
                                                title={DrawerObj.title}
                                                size={DrawerObj.size}
                                                // centered={.Centered}
                                                closeOnClickOutside={false}
                                                // overlayProps={m.Overlay}
                                                position={DrawerObj?.position ?? "bottom"}
                                                onClose={
                                                    typeof DrawerObj?.onclose == "function"
                                                        ? DrawerObj?.onclose
                                                        : () => {
                                                            setDrawerObj(dra);
                                                        }
                                                }
                                            // scrollAreaComponent={ScrollArea.Autosize}
                                            >
                                                {DrawerObj.body}
                                            </Modal>

                                            <Grid gutter={4}>
                                                <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    {
                                                        <QueryBuilderComponent
                                                            width="100%"
                                                            ref={qryBldrObj}
                                                            id="querybuilder"
                                                            // rule={importRules}
                                                            dataSource={S96}
                                                            // dataSource={[]}

                                                            // columns={groupedData ?? []}
                                                            // change={handleRuleChange}
                                                            // change={(e)=>console.log("handleRuleChange =>",e)}
                                                            rule={rules}
                                                            // separator="."
                                                            showButtons={showButtons}
                                                            change={(e) => handleCreated(e)}
                                                            // actionBegin={handleBegin}
                                                            fieldMode="DropdownTree"
                                                            // beforeInsertRule={handleBeforeInsertRule}
                                                        >
                                                            <ColumnsDirective>
                                                                {groupedData && groupedData?.map((v, i) => {
                                                                    // console.log("return column", v)
                                                                    return (
                                                                        <ColumnDirective
                                                                            field={v.field}
                                                                            label={v.label}
                                                                            // columns={v.columns}
                                                                            type={v.type}
                                                                            ruleTemplate={ruleTemplate}
                                                                        />
                                                                    )
                                                                })
                                                                }
                                                            </ColumnsDirective>
                                                        </QueryBuilderComponent>
                                                    }
                                                </Grid.Col>
                                                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                                    <Group>
                                                        <Button compact onClick={saveBtn}>Save</Button>
                                                        <Button compact onClick={RunFilter}>Run</Button>
                                                        <Button compact>Delete</Button>
                                                        <Button compact>Copy</Button>
                                                    </Group>
                                                </Grid.Col>

                                            </Grid>
                                        </>
                                }
                            </>
                    }
                </>
        }

    </>
    )

}


export function TM_EXPORT(data) {
    const OBJ = JSON.parse(data);
    console.log("TM_EXPORTOBJ", OBJ);
    // const dispatch = useDispatch();
    // const flterData = useSelector((state) => state.FilterSlice?.Data)
    const flterData = Store?.getState().FilterSlice.Data;

    console.log("flterData", flterData)
    const state = Store.getState();
    console.log("state_TM_Export", state)
    const FiltersName = state?.FilterSlice;
    console.log("FiltersName", FiltersName)
    const isFilterApplied = state?.FilterSlice?.isFilterApplied;
    console.log("isFilterApplied", isFilterApplied)
    const SaveAccData = Store.getState().AccountList.SaveAccountListArr;
    console.log("SaveAccData", SaveAccData)
    const Data = Store.getState().AccountList.AccountListData;
    console.log("AccountListData", Data)

    let isAnySavedFilter = FiltersName && Object.keys(FiltersName).length
    //  console.log("isAnySavedFilter",isAnySavedFilter);

    if (isFilterApplied) {
        // Store.dispatch((GetAccountListForPrint({...OBJ,pagination:{pageIndex: 0,pageSize: 25}})))
        // Store.dispatch(GetAccountList({ ...OBJ?.BtnPnlObj, pagination: { pageIndex: 0, pageSize: 25 } }))
        Store.dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: SaveAccData[OBJ?.cSTID] }))
        Store.dispatch((getFilterStatus(false)))
    }
    else {
        Store.dispatch(
            ModalFunction({
                onclose: (e) => { },
                onClickCloseButton: () => { Store.dispatch(getDataStateNull()) },
                MTitle: <Text fw={700}>{OBJ?.text}</Text>,
                MAction: true,
                MSize: "85%",
                position: 'bottom',
                MBody: (i) => <PLFilter OBJ={OBJ} index={i} />,
                MClose: true,
                Overlay: {
                    backgroundOpacity: 0.8,
                    blur: 5,
                },
            })
        );
    }
    // else {
    //     console.log("else condition")
    //     Store.dispatch(
    //         ModalFunction({
    //             onclose: () => { },
    //             MTitle: <Text fw={700}>Select Filter to Apply</Text>,
    //             MAction: true,
    //             MSize: "xl",
    //             // MHeight :100,
    //             MBody: (i) => <PLModal OBJ={OBJ} index={i} />,
    //             MClose: true,
    //             Overlay: {
    //                 backgroundOpacity: 0.8,
    //                 blur: 5,
    //             }
    //         })
    //     );
    // }
    // }



}
