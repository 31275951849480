import * as VatTdsGst from "../FunctionsCall/GSTMaster/GSTMaster"
export const M51AED=(itemOBJ)=>{
    const OBJ = itemOBJ;
    console.log('M51AED ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "T_M51V": //Master > VAT/TDS > Tax Master
        case "T_M51G": //Master > GST > Gst Master
            VatTdsGst.M51AED(JSON.stringify(OBJ))
            break;
            
        default:
            break;
    }
}