import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import axios from "axios";
import GlobalClass from "../GlobalClass";

export const GetReminder = createAsyncThunk(
    "Reminder/GetReminder",
    async (obj) => {
        console.log("obj in GetReminder", obj);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": obj.type ?? "A",
                    "cCode": obj?.rowId ?? "",
                    "cOPara": "",
                    "cWhere": obj?.cWhere,
                    "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetReminder + "?pa=" + param);
            console.log("GetAccountList response=>>", response);
            return [response.data?.data, obj.type];

        } catch (error) {
            return { error }
        }
    }

)

// export const GetAddressBookDeleteAPI = createAsyncThunk(
//     "AddressBook/GetAddressBookDeleteAPI",
//     async (obj) => {
//         console.log("obj in GetAddressBookDeleteAPI", obj);
//         try {

//             let param =
//                 JSON.stringify({
//                     "CorpID": gensetting.getcorpid(),
//                     "cUser": gensetting.getcuser(),
//                     "cPass": gensetting.getcpass(),
//                     "CmpNo": gensetting.getcmpno(),
//                     "cAction": obj.cAction ?? "A",
//                     "cCode": obj?.cCode ?? "",
//                     "cOPara": "",
//                 });

//             const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetAddressBook + "?pa=" + param);
//             console.log("GetAccountList response=>>", response);
//             return response.data;

//         } catch (error) {
//             return { error }
//         }
//     }

// )

const Reminder = createSlice({
    name: "Reminder",
    initialState: {
        ReminderData: [],
        isLoading: false,
        hasError: false,
        ErrorMsg: {},
        oREMData: {},
        isDeleted: false,
    },
    reducers: {
        getData: (state, action) => {
            console.log("oREMData", action.payload);
            state.oREMData = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetReminder.pending, (state, action) => {
                state.hasError = false;
                state.isLoading = true;
            })
            .addCase(GetReminder.fulfilled, (state, action) => {
                state.isDeleted = false
                console.log("GetReminder fulfilled", action?.payload);
                if (!action.payload?.error) {
                    if (action.payload[1] === "A" || action.payload[1] === "E") {
                        state.ReminderData = action.payload[0];
                    }
                    else if (action.payload[1] === "D") {
                        state.isDeleted = true
                    }
                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = false;
                }
                else {
                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error
                }
            })
            .addCase(GetReminder.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })
        // .addCase(GetAddressBookDeleteAPI.pending, (state, action) => {
        //     state.hasError = false;
        //     state.isLoading = true;
        // })
        // .addCase(GetAddressBookDeleteAPI.fulfilled, (state, action) => {
        //     if (!action.payload?.error) {
        //         state.isDeleted = true;
        //         state.isLoading = false;
        //         state.isRefetching = false;
        //         state.hasError = false;
        //     }
        //     else {
        //         state.isLoading = false;
        //         state.isRefetching = false;
        //         state.hasError = true;
        //         state.ErrorMsg = action.payload?.error;
        //     }
        // })
        // .addCase(GetAddressBookDeleteAPI.rejected, (state, action) => {
        //     state.hasError = true;
        //     state.isLoading = false;
        // })
    }
})

export default Reminder.reducer;
export const { getData } = Reminder.actions;