import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetLeadEntryOrder } from '../../utils/TransactionSlices/TransactionLeadEntrySlice'
import GlobalClass from '../../utils/GlobalClass'
import { ActionIcon, Button, Container, Divider, Flex, Grid, Group, Modal, Spoiler, Text, Tooltip } from '@mantine/core'
import PLComboBox from '../../PlusComponent/PLComboBox'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import { GetExpenseData, GetInvTypeDropDown, GetPreFillTransactionFormData } from '../../utils/TransactionSlices/TSalesSlices'
import PLTextBox from '../../PlusComponent/PLTextBox'
import ExpenseEntryForm from '../SaleEntry/ExpenseEntryForm'
import { GetAccountList } from '../../utils/slices/AccountListSlice'
import { BatchLogAddRemoveFun, TransactionApi } from '../../utils/TransactionSlices/Transaction'
import PLDateBox from '../../PlusComponent/PLDateBox'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import Store from '../../utils/store'
import PLTextarea from '../../PlusComponent/PLTextarea'
import PLNarration from '../../PlusComponent/PLNarration'
import { EquationFunction, ReverseCalCulation } from '../SaleEntry/EquationsFunctions'
import { IconEdit, IconReceiptTax, IconTrash } from '@tabler/icons-react'
import gensetting from '../../utils/gensetting'
import { AssignItemDefault, CalCFIPromise, MidCalc, RecalcFun, RetTaxAcc } from '../SaleEntry/AssignItemDefaultFun'
import { Qty_CheckStk } from '../SaleEntry/QtyCheckStock'
import SITableUserField from '../SaleEntry/SITableUserField'
import { createColumnHelper } from '@tanstack/react-table'
import { MRT_TablePagination, MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import useWindowDimensions from '../../utils/UseWindowDimensions'
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice'
import { TabRemove } from '../../utils/slices/TabList'
import { ModalDelete } from '../../utils/slices/ModalSlice'

const LeadEntryOrderForm = (props) => {
    const { obj } = props
    console.log("orderFormProps", props)
    const { height, width } = useWindowDimensions();
    const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)
    const { isLoading, hasError, ErrorMsg } = useSelector(
        (state) => state.transactionLeadEntry
    );
    const GetYearAndDate = useSelector((state) => state?.UserList?.YearAndDateSend)
    const leadOrderData = useSelector((state) => state.transactionLeadEntry?.OrderData)
    console.log("leadOrderData", leadOrderData)
    const ExpenseData = useSelector((state) => state?.TSalesSlices?.ExpenseData)
    const PreFillFormData = useSelector((state) => state?.TSalesSlices?.PreFillTransactionFormData)
    const COP1 = useSelector((state) => state.transactionLeadEntry?.OrderData?.COP1)
    const COP8 = useSelector((state) => state.transactionLeadEntry?.OrderData?.COP8)
    const COP75 = useSelector((state) => state.transactionLeadEntry?.OrderData?.COP75)
    const M01 = useSelector((state) => state.transactionLeadEntry?.OrderData?.M01)
    const M01D = useSelector((state) => state.transactionLeadEntry?.OrderData?.M01D)
    const M01P = useSelector((state) => state.transactionLeadEntry?.OrderData?.M01P)
    const M01PRT = useSelector((state) => state.transactionLeadEntry?.OrderData?.M01PRT)
    const M01SP = useSelector((state) => state.transactionLeadEntry?.OrderData?.M01SP)
    const M45I = useSelector((state) => state.transactionLeadEntry?.OrderData?.M45I)
    const M45IDT = useSelector((state) => state.transactionLeadEntry?.OrderData?.M45IDT)
    const OS11 = useSelector((state) => state.transactionLeadEntry?.OrderData?.OS11)
    const OSV = useSelector((state) => state.transactionLeadEntry?.OrderData?.OSV)
    const PLDATA = useSelector((state) => state.transactionLeadEntry?.OrderData?.PLDATA)
    const PORT = useSelector((state) => state.transactionLeadEntry?.OrderData?.PORT)
    const T02 = useSelector((state) => state.transactionLeadEntry?.OrderData?.T02)
    const T02ENT = useSelector((state) => state.transactionLeadEntry?.OrderData?.T02ENT)
    const T02UF = useSelector((state) => state.transactionLeadEntry?.OrderData?.T02UF)
    const T41 = useSelector((state) => state.transactionLeadEntry?.OrderData?.T41)
    const T92 = useSelector((state) => state.transactionLeadEntry?.OrderData?.T92)
    const UFT41H = useSelector((state) => state.transactionLeadEntry?.OrderData?.UFT41H)
    const dtM14 = useSelector((state) => state.transactionLeadEntry?.OrderData?.dtM14)
    const dtM46 = useSelector((state) => state.transactionLeadEntry?.OrderData?.dtM46)
    const dtM51 = useSelector((state) => state.transactionLeadEntry?.OrderData?.dtM51)
    const dtM52 = useSelector((state) => state.transactionLeadEntry?.OrderData?.dtM52)
    const { oYear } = useSelector((state) => state?.Language?.General);
    const footerDate = useSelector((state) => state?.Language?.footerDate);
    const { isLoadingExpenseData, hasErrorExpenseData, ErrorMsgExpenseData } = useSelector((state) => state?.TSalesSlices)
    const dtM14DT = Store.getState().Transaction?.TransactionData?.dtM14;
    const dtM51DT = Store.getState().Transaction?.TransactionData?.dtM51;
    const dtM52DT = Store.getState().Transaction?.TransactionData?.dtM52;
    const dtM46DT = Store.getState().Transaction?.TransactionData?.dtM46;
    const InvTypeDropDown = useSelector(
        (state) => state?.TSalesSlices?.InvTypeDropDown
    );
    const SaveLoading = useSelector(
        (state) => state?.DataSaveLoadingSlice?.SaveLoading
    );
    const dispatch = useDispatch()
    const formRef = useRef(null);
    const TableRef = useRef();
    const TableContainer = useRef(null);
    const spoilerControlRef = useRef(null);
    const CategoryRef = useRef(null)
    const PartyAcRef = useRef(null);
    const PartyAc2Ref = useRef(null);
    const DeliveryAtRef = useRef(null);
    const InvTypeRef = useRef(null);
    const BillNoRef = useRef(null);
    const LocationRef = useRef(null)
    const PurcSaleRef = useRef(null)
    const ProductNameRef = useRef(null)
    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
        onClickCloseBtn: null
    }
    const [HeaderExpenseFlag, setHeaderExpenseFlag] = useState(false);
    const [ExpenseHideShowFlag, setExpenseHideShowFlag] = useState(null)

    const [GroupCatogaryProduct, setGroupCatogaryProduct] = useState({
        "Group": "",
        "Catogary": "",
        "Product": ""
    })
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 25,
    });
    const [ExpenseArrayT02, setExpenseArrayT02] = useState([])
    const [tableEditingRow, setTableEditingRow] = useState(null)
    const [cRorI, setcRorI] = useState(null)
    const [DefaultInvFlag, setDefaultInvFlag] = useState(false)
    const [FooterExpenseFlag, setFooterExpenseFlag] = useState(false);
    const [selectedProductObj, setSelectedProductObj] = useState({});
    const [userFieldHideShow, setUserFieldHideShow] = useState("")
    const [rowSelection, setRowSelection] = useState({})
    const [BillNoStatus, setBillNoStatus] = useState(false)
    const [f02, setF02] = useState(false)
    const [M01PRow, setM01PRow] = useState(null);
    const [DrawerObj, setDrawerObj] = useState(dra);
    const [columns, setColumns] = useState([])
    const [T41Obj, setT41Obj] = useState({})
    const [T02Obj, setT02Obj] = useState({})
    const [T02Data, setT02Data] = useState([])
    const [ExpenseArrayT41, setExpenseArrayT41] = useState([])
    const [selectedDay, setSelectedDay] = useState("");
    const [InvAPIStatus, setInvAPIStatus] = useState(false)
    const [GSTNoStatus, setGSTNoStatus] = useState({
        "status": false,
        "object": {}
    })
    const [priceListParemeter, setPriceListParemeter] = useState({
        priceListCode: "",
        productCode: "",
        accountCode: "",
    });
    const [FIELD01Value, setFIELD01value] = useState("");
    const [NarrationData, setNarrationData] = useState([]);
    const [SelectedNarrationData, setSelectedNarrationData] = useState([]);
    const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
        BillNoVal: "",
        TextInput: false,
        DataGridInput: true
    })
    const [BatchIssueObj, setBatchIssueObj] = useState(null)
    const [receiptIssueObj, setReceiptIssueObj] = useState(null)
    const [LocationObj, setLocationObj] = useState(null)
    const [ExpenseArray, setExpenseArray] = useState({
        dtExp: null,
        dtExpDef: null,
        dtExp_I: null,
        dtExpDef_I: null,
        M01SP: null,
        M01SPDT: null
    })

    let ExpenseObj = {
        OS11,
        OSV,
        oYear,
        M01SP: ExpenseArray?.M01SP,
        M01SPDT: ExpenseArray?.M01SPDT,
        dtM14DT,
        dtM51DT,
        dtM52DT,
        dtM46DT,
        dtExp: ExpenseArray?.dtExp,
        dtExpDef: ExpenseArray?.dtExpDef,
        dtExp_I: ExpenseArray?.dtExp_I,
        dtExpDef_I: ExpenseArray?.dtExpDef_I,
        InvTypeDropDown,
        ItemCode: selectedProductObj?.FIELD03,
        selectedProductObj,
        T02Obj: { ...T02Obj, VorC: T41Obj?.FIELD45 },
        T41Obj,
        T02Data
    }

    useEffect(() => {

        console.log("ExpenseData =>", ExpenseData);

        setExpenseArray({
            ...ExpenseArray,
            dtExp: ExpenseData?.dtExp,
            dtExpDef: ExpenseData?.dtExpDef,
            dtExp_I: ExpenseData?.dtExp_I,
            dtExpDef_I: ExpenseData?.dtExpDef_I,
            M01SP: ExpenseData?.M01SP,
            M01SPDT: ExpenseData?.M01SPDT?.jData
        })
    }, [ExpenseData])

    useEffect(() => {
        if (T02Obj?.FIELD03) {
            new Promise((resolve) => {
                let rta = RetTaxAcc("TS", false, "FIELD16", 0, "", T02Obj, false, ExpenseObj)

                resolve(rta)
            }).then((res) => {
                console.log('T02ObjFIELD16RetTaxAcc =>', res);
                setT02Obj({
                    ...T02Obj,
                    FIELD16: res
                })
            })
        }
    }, [T02Obj?.FIELD03])

    useEffect(() => {
    if (T02Data) {
      if (ExpenseArray?.dtExp && ExpenseArray?.dtExp_I) {
        console.log("HeaderExpenseFlag", HeaderExpenseFlag);
        new Promise((resolve) => {
          let fun = CalCFIPromise({ ...ExpenseObj, Flag: HeaderExpenseFlag })
          resolve(fun)
        }).then((res) => {
          console.log("CalCFIPromiseResponse =>", res);
          if (HeaderExpenseFlag) {
            setT02Data(res?.T02CalCFIData)
            new Promise((resolve) => {
              let dt = MidCalc({ ...ExpenseObj, T41Obj: res?.T02CalAarray })
              // console.log("ExpenseObj T41Object=>", ExpenseObj);
              resolve(dt)
            }).then((res2) => {
              setT41Obj(res2?.T41Object)
            })
          } else {
            setT41Obj(res)
          }

          setHeaderExpenseFlag(false)
        })
      } else {
        new Promise((resolve) => {
          let dt = MidCalc(ExpenseObj)
          // console.log("ExpenseObj T41Object=>", ExpenseObj);
          resolve(dt)
        }).then((res2) => {
          setT41Obj(res2?.T41Object)
        })
      }

    }
  }, [T02Data, InvTypeDropDown, ExpenseArray, FooterExpenseFlag, HeaderExpenseFlag])

    useEffect(() => {
        // if (T02Obj?.FIELD16 && !tableEditingRow) {
        if (T02Obj?.FIELD16) {
            console.log("T02Object =>", T02Obj);
            new Promise((resolve, reject) => {
                let dt = AssignItemDefault(ExpenseObj);
                resolve(dt);
            })
                .then((expdt) => {
                    console.log("AssignItemDefault event =>", expdt);
                    setExpenseArrayT02(expdt?.dtExpArr);
                    setT02Obj(expdt?.T02Object)
                })
                .then(() => {
                    return RecalcFun(T41Obj, "", false, "", ExpenseObj)
                }).then((res) => {
                    console.log("T02Obj?.FIELD16 =>", res);
                    // Promise.resolve(SetFieldObj(res?.T02CalAarray)).then(() => setDtExpTableField(res?.ExpenseCalArray))
                    Promise.resolve(setExpenseArrayT41(res?.ExpenseCalArray)).then(() => setT41Obj(res?.T02CalAarray))
                })
        }
    }, [T02Obj?.FIELD16])

    useEffect(() => {
        // if(LocationObj != null){
        Promise.resolve().then(() => {
            setT02Obj({
                ...T02Obj,
                FIELD20: LocationObj?.FIELD01 ?? "",
                F20NM: LocationObj?.FIELD02 ?? ""
            })
        })
        // }
        console.log("LocationObj =>", LocationObj);
        // if(LocationObj != null){
        //     setT02Obj({
        //       ...T02Obj,
        //       FIELD20: LocationObj?.FIELD01 ?? ""
        //     })
        // }

    }, [LocationObj])

    useEffect(() => {

        if (T02Obj?.FIELD05 == "D") {
            console.log("BatchIssueObj 1=>", BatchIssueObj);
            setT02Obj({
                ...T02Obj,
                FIELD11: BatchIssueObj?.FIELD01 ?? "",
                FIELD41: BatchIssueObj?.FIELD02 ?? "",
            })

        } else {
            console.log("BatchIssueObj 2=>", BatchIssueObj);
            setT02Obj({
                ...T02Obj,
                FIELD11: BatchIssueObj?.FIELD01 ?? "",
                FIELD41: BatchIssueObj?.FIELD02 ?? "",
            })

        }
    }, [T02Obj?.FIELD05, BatchIssueObj])

    const NagativeQtyPopUpFun = (e) => {
        GlobalClass.Notify("warning", "Warning", e)
    }

    useEffect(() => {

        let T02F05F07 = {};

        if (receiptIssueObj) {
            console.log("ConditionFIELD07 two");
            Promise.resolve().then(() => {
                T02F05F07 = {
                    ...T02Obj,
                    FIELD05: receiptIssueObj?.value ?? "",
                    FIELD07: ["C", "D"].includes(receiptIssueObj?.value) ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22
                }
                setT02Obj(T02F05F07)
            })
        }

    }, [receiptIssueObj])

    useEffect(() => {
        if (T41) {
            setT41Obj(T41)
        }
    }, [T41])

    useEffect(() => {
        if (OS11?.S11F22) {
            let ReceiptOrIssue = ["R", "C"].includes(OS11?.S11F22) ? "C" : "D";
            setcRorI(ReceiptOrIssue)
        }
    }, [OS11, obj])

    useEffect(() => {
        if (T02) {
            setT02Data(T02);
            if (obj.p0 == "O" || obj.p0 == "Q") {
                setT02Obj(T02?.[T02?.length - 1]);
            } else {
                setT02Obj(T02?.[0])
            }
        }
    }, [T02])

    useEffect(() => {
        Promise.resolve(dispatch(GetLeadEntryOrder({ ...obj, modalIndex: props.index }))).then(() => {
            dispatch(GetAccountList({
                "id": "P_M21",
                "name": "",
                "type": "A",
                "p0": "P_M21",
                "p1": "P_M21",
                "p2": "",
                "p3": ""
            }))
        })
    }, [])

    useEffect(() => {
        if (hasError && !isLoading) {
            GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
        }
    }, [hasError]);

    useEffect(() => {
        if (hasErrorExpenseData && !isLoadingExpenseData) {
            // console.log("ErrorMsgExpenseData", ErrorMsgExpenseData);
            GlobalClass.Notify("error", "Error: " + ErrorMsgExpenseData.code, ErrorMsgExpenseData.message)
        }
    }, [hasErrorExpenseData])

    const NextIndexFun = (index) => {
        const nextInd = index + 1;
        console.log("nextInd=>", nextInd)
        const nextInput = document.getElementById(`T02Ent_${nextInd}`)
        console.log("nextInput=>", nextInput)

        if (nextInput) {
            nextInput.focus()
        }
    }

    const Summary = (obj, i, tag) => {
        // console.log("Summary==============>",obj);
        // let stype = "sum";
        var value = "";

        switch (obj?.S53F03) {
            // case 128:
            //     value = T02Data?.reduce((a, b) => a = a + b?.FIELD25, 0)
            //     break;
            case "Qty":
                value = parseFloat(T02Data?.reduce((a, b) => (a = a + b?.FIELD33), 0).toFixed(2));
                break;
            // case 131:
            //     value = T02Data?.reduce((a, b) => a = a + b?.FIELD81, 0)
            //     break;
            case "Amount":
                value = parseFloat(T02Data?.reduce((a, b) => (a = a + b?.FIELD08), 0).toFixed(2));
                break;
            default:
                value = null;
                break;
        }

        return value;
    };

    let receiptIssueVal = null;
    const renderInputBox = (uID, v, c, cellid, index) => {
        if (oYear?.OSC?._CDINITEM == "N") {
            // console.log("receiptIssueVal condition true");
            receiptIssueVal = cRorI
        }

        if (c?.S53F07 == 0) {
            // <IconReceiptTax size={"20px"} /> //  OSV?._SPACCEACH == "N"
            if (c?.S53F02 == "E00000000018" && OSV?._SPACCEACH == "N") {

                return <div style={{ display: "flex" }}>
                    <Button
                        id={`T02Ent_${index}`}
                        variant="default"
                        onFocus={() => {
                            document.activeElement.blur()
                            // console.log("Test=>", `T02Ent_${index}`)
                            HiddenButtonField(uID, v, c, cellid, index)
                        }}
                        // onClick={() => HiddenButtonField(uID, v, c, cellid, index)}
                        style={{ padding: "0px", margin: "0px" }}
                    />
                    <ActionIcon
                        color="blue"
                        // id={`T02Ent_${index}`}
                        // onFocus={(e) => {
                        //   // document.activeElement.blur()
                        //   console.log("Test=>", e)
                        //   // HiddenButtonField(uID, v, c, cellid, index)
                        // }}
                        onClick={() => {
                            Promise.resolve(HiddenButtonField(uID, v, c, cellid, index, "Y")).then(() => {
                                // setExpenseHideShowFlag("Y")
                            })
                        }
                        }
                    >
                        <IconReceiptTax size={"20px"} />
                    </ActionIcon>
                </div>

                // return <IconReceiptTax
                //   //  color=""
                //   size={"25px"}
                //   id={`T02Ent_${index}`}
                //   onFocus={() => {
                //     document.activeElement.blur()
                //     // console.log("Test=>", `T02Ent_${index}`)
                //     HiddenButtonField(uID, v, c, cellid, index)
                //   }}
                //   onClick={() => Promise.resolve(HiddenButtonField(uID, v, c, cellid, index)).then(() => setExpenseHideShowFlag("Y"))}
                // />
            } else {
                return <Button
                    id={`T02Ent_${index}`}
                    variant="default"
                    onFocus={() => {
                        document.activeElement.blur()
                        // console.log("Test=>", `T02Ent_${index}`)
                        HiddenButtonField(uID, v, c, cellid, index)
                    }}
                    onClick={() => HiddenButtonField(uID, v, c, cellid, index)}
                    style={{ padding: "0px", margin: "0px" }}
                />
            }
            {/* {c.S53F03}</Button> */ }
        } else {
            let setInput;
            switch (c?.S53F14) {
                case "PLPOPUP":
                    if (c.S53F02 == "E00000000002") { //Product Name
                        // console.log("PLDataGrid Product Value 1==>", [{
                        //   "c": c,
                        //   "T02Obj": T02Obj
                        // }]);
                        setInput = <>
                            {/* {GroupCatogaryProduct?.Group != "" && GroupCatogaryProduct?.Catogary != "" ? */}
                            <PLDataGrid
                                ref={ProductNameRef}
                                // TmData={tmList["P_M21"]?.cSTID}
                                // data={accList["P_M21"]}
                                id={`T02Ent_${index}`}
                                width={'500px'}
                                // autoFocus={true}

                                // cWhere={`M21.FIELD11 LIKE '%${T02Obj?.GRPCD ?? ""}%' AND M21.FIELD12 LIKE '%${T02Obj?.CATCD ?? ""}%'`}

                                cWhere={`M21.FIELD12 LIKE '%${T02Obj?.CATCD ?? ""}%'`}
                                // value={T02Obj?.[c?.S53F04]}
                                value={{ value: T02Obj?.[c?.S53F04], label: T02Obj?.F03NM }}
                                p1={seperate(c.S53F15)[1]}
                                setEdit={(e) => {
                                    console.log("PLDataGrid Product Value 2==>", e);

                                    new Promise((resolve) => {
                                        let T02F05F07 = { ...T02Obj };
                                        if (oYear?.OSC?._CDINITEM == "N") {
                                            console.log("ConditionFIELD07 one");
                                            T02F05F07 = {
                                                ...T02F05F07,
                                                [c?.S53F04]: e?.FIELD01,
                                                F03NM: e?.FIELD02,
                                                VorC: T41Obj?.FIELD45,
                                                FIELD22: e?.FIELD28 == 0 ? 1 : e?.FIELD28,
                                                FIELD05: cRorI,
                                                FIELD07: ["C", "D"].includes(cRorI) ? e?.FIELD21 : e?.FIELD22
                                            }
                                        }
                                        resolve(setT02Obj(T02F05F07))
                                    }).then(() => setPriceListParemeter({ ...priceListParemeter, productCode: e.FIELD01 })).then(() => setSelectedProductObj(e)).then(() => setFIELD01value(e.FIELD01)).then(() => {
                                        setTimeout(() => {
                                            NextIndexFun(index)
                                        }, 500)
                                    })





                                    //FIELD28 = product conversion
                                    // Promise.resolve(setT02Obj({
                                    //   ...T02Obj,
                                    //   [c?.S53F04]: e?.FIELD01,
                                    //   F03NM: e?.FIELD02,
                                    //   VorC: T41Obj?.FIELD45,
                                    //   FIELD22: e?.FIELD28 == 0 ? 1 : e?.FIELD28
                                    // })).then(() => setPriceListParemeter({ ...priceListParemeter, productCode: e.FIELD01 })).then(() => setSelectedProductObj(e)).then(() => setFIELD01value(e.FIELD01)).then(() => {
                                    //   setTimeout(() => {
                                    //     NextIndexFun(index)
                                    //   }, 500)
                                    // })
                                }}
                            />
                            {/* : <PLTextBox disabled={true} placeholder={"Select"} />
                } */}
                        </>
                    } else {
                        setInput = <PLDataGrid
                            setFocus={() => {
                                if (c.S53F02 == "E00000000012") {
                                    LocationRef?.current?.handlePopoverToggle()
                                }
                            }}
                            ref={c.S53F02 == "E00000000012" ? LocationRef : null}
                            id={`T02Ent_${index}`}
                            width={'500px'}
                            // value={T02Obj?.[c?.S53F04]}
                            disabled={(c.S53F02 == "E00000000012" && oYear?.OSC?._LOCATIONW == "Y") ? selectedProductObj != null ? (selectedProductObj?.FIELD57 == "N" ? true : false) : false : false}
                            // disabled={c.S53F02 == "E00000000012" ? (oYear?.OSC?._LOCATIONW == "Y" ? (selectedProductObj?.FIELD57 == "Y" ? false : true ) : true)  : false}
                            // disabled={(c.S53F02 == "E00000000012" && oYear?.OSC?._LOCATIONW == "N" && selectedProductObj?.FIELD57 == "N") ? true : false}
                            value={c.S53F02 == "E00000000012" ? { value: T02Obj?.FIELD20, label: T02Obj?.F20NM } : T02Obj?.[c?.S53F04]}
                            p1={seperate(c.S53F15)[1]}
                            setEdit={(e) => {
                                // console.log("PLDataGrid==>", e);
                                // setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 })
                                if (c.S53F02 == "E00000000012") { //Location
                                    // console.log("Location==>", e);
                                    Promise.resolve(setLocationObj(e)).then(() => setTimeout(() => { NextIndexFun(index) }, 500))
                                } else {
                                    Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 })).then(() => NextIndexFun(index))
                                }
                            }}
                        />
                    }
                    break;
                case "COMBOBOX":
                    if (c.S53F02 == "E00000000003") { //Receipt/Issue

                        setInput = <PLComboBox
                            onKeyDown={(e) => {
                                GlobalClass.formNextInput(e, formRef);
                            }}
                            cmbid={`T02Ent_${index}`}
                            value={T02Obj?.[c?.S53F04]}
                            copno={seperate(c.S53F15)[1]}
                            dispexpr={"DisplayMember"}
                            valexpr={"ValueMember"}
                            setEdit={(e) => {
                                console.log("COMBOBOX==>", e);
                                // {value: 'C', label: 'Receipt'}
                                // {value: 'D', label: 'Issue'}

                                // Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e.value, FIELD07: e.value == "C" ? selectedProductObj?.FIELD21 : e.value == "D" ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22 })).then(() => NextIndexFun(index + 1))


                                Promise.resolve(setReceiptIssueObj(e)).then(() => {
                                    receiptIssueVal = e.value;
                                }).then(() => NextIndexFun(index + 1))

                                // FIELD07: T02Obj.FIELD05 == "C" ? M21.FIELD21 : T02Obj.FIELD05 == "R" ? M21.FIELD21 : M21.FIELD22
                            }}
                        />
                    } else {
                        setInput = <PLComboBox
                            onKeyDown={(e) => {
                                GlobalClass.formNextInput(e, formRef);
                            }}
                            cmbid={`T02Ent_${index}`}
                            value={T02Obj?.[c?.S53F04]}
                            copno={seperate(c.S53F15)[1]}
                            dispexpr={"DisplayMember"}
                            valexpr={"ValueMember"}
                            setEdit={(e) => {
                                console.log("COMBOBOX==>", e);
                                setT02Obj({ ...T02Obj, [c?.S53F04]: e.value })
                                // Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e.value }))

                            }}
                        />
                    }

                    break;
                case "TEXTBOX":
                    if (c.S53F02 == "E00000000006") { //User FIeld
                        // setInput = (
                        //   <Button
                        //     id={`T02Ent_${index}`}
                        //     style={{ height: 30, marginTop: 5, width: "0px", padding: "0px" }}
                        //     onFocus={() => {
                        //       document.activeElement.blur();
                        //       if (T02UF?.length > 0) {
                        //         TableUserFieldFun(index)
                        //       }
                        //     }}
                        //     variant="default"
                        //     onClick={() => T02UF?.length > 0 ? TableUserFieldFun(index) : null}
                        //   >
                        //   </Button>
                        // );
                    } else if (c.S53F02 == "E00000000015") { //Pricelist
                        setInput = (
                            <>
                                <PLComboBox
                                    onKeyDown={(e) => {
                                        GlobalClass.formNextInput(e, formRef);
                                    }}
                                    cmbid={`T02Ent_${index}`}
                                    data={typeof PLDATA == "string" ? [{}] : PLDATA}
                                    value={T02Obj?.[c?.S53F04]}
                                    dispexpr={"FIELD02"}
                                    valexpr={"FIELD01"}
                                    setEdit={(e) => {
                                        // console.log("Pricelist OBJ=>", e);
                                        Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(() => {
                                            if (T41Obj.FIELD16 == "C") {
                                                setPriceListParemeter({
                                                    ...priceListParemeter,
                                                    accountCode: T41Obj.FIELD31,
                                                    priceListCode: e,
                                                });
                                            } else {
                                                setPriceListParemeter({
                                                    ...priceListParemeter,
                                                    accountCode: T41Obj.FIELD04,
                                                    priceListCode: e,
                                                });
                                            }
                                        })
                                    }}
                                />
                            </>
                        );
                    } else if (c.S53F02 == "E00000000005") { //Batch Issue
                        setInput = (
                            <>

                                {
                                    T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 != "N" ?
                                        <PLDataGrid
                                            // autoFocus={true}
                                            // setBlur={()=>T02UF?.length > 0 ? TableUserFieldFun() : null}
                                            id={`T02Ent_${index}`}
                                            cWhere={`T11.FIELD08 LIKE '%${FIELD01Value ?? ""}%'`}
                                            width={"500px"}
                                            value={T02Obj?.[c?.S53F04]}
                                            p1={seperate(c.S53F15)[1]}
                                            // p1={'P_M21'}
                                            setEdit={(e) => {
                                                console.log("Batch Issue Object=>", e);
                                                // setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 })
                                                setBatchIssueObj(e)
                                                // Promise.resolve(setBatchIssueObj(e))
                                            }}
                                        /> : <Button
                                            fullWidth
                                            disabled
                                            style={{
                                                padding: "0px 5px",
                                                display: "flex",
                                                alignItems: "flex-start",
                                                fontWeight: "normal",
                                                fontSize: "12px",
                                                overflow: "hidden",
                                            }}
                                            variant='default'
                                        // onClick={() => GlobalClass.Notify("info", "Info", "Select Any Product")}
                                        >
                                            Select
                                        </Button>
                                }
                            </>
                        );
                    } else {
                        console.log("Batch Receipt receiptIssueVal =>", [{
                            "receiptIssueVal": receiptIssueVal
                        }]);
                        setInput = (
                            <PLTextBox
                                disabled={c?.S53F02 == "E00000000011" && selectedProductObj?.FIELD51 == "N" ? true :
                                    c?.S53F02 == "E00000000013" && selectedProductObj?.FIELD51 == "N" ? true :
                                        c?.S53F02 == "E00000000011" && ((oYear?.OSC?._CDINITEM == "N" ? cRorI : T02Obj?.FIELD05) != "C") ? true :
                                            false} // Batch Receipt && Batch User FIeld
                                id={`T02Ent_${index}`}
                                value={T02Obj?.[c?.S53F04]}
                                setEdit={(e) => {
                                    setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value })
                                }}
                            />
                        );
                    }
                    break;
                case "NUMBOX":

                    if (c.S53F02 == "E00000000018") { //ItemWise Expense

                        setInput = (
                            <Button
                                style={{ height: 30, marginTop: 5, width: "0px", padding: "0px" }}
                                onFocus={() => {
                                    document.activeElement.blur();
                                    if (T02Obj.FIELD08 > 0 && InvTypeDropDown != null) {
                                        TableExpenseEntry()
                                    }
                                }}
                                id={`T02Ent_${index}`}
                                variant="default"
                                onClick={() => {
                                    //console.log("T02Obj.FIELD08=>", T02Obj.FIELD08);
                                    if (T02Obj.FIELD08 > 0 && InvTypeDropDown != null) {
                                        TableExpenseEntry();
                                    }
                                }}
                            >
                            </Button>
                        );
                    } else if (c.S53F02 == "E00000000017") { //Amount
                        setInput = (
                            <PLNumberBox
                                decimalSeparator={c.S53F02 == "E00000000017" ? true : false}
                                precision={c.S53F02 == "E00000000017" ? 2 : 0}
                                onKeyDown={(e) => {
                                    // e.stopPropagation();

                                    // console.log("Aamount Number", parseFloat(e?.target?.value));


                                    if (e.key == "Enter" || e.key == "Tab") {
                                        e.preventDefault();
                                        let value = parseFloat(e?.target?.value)
                                        //   if (gensetting.getcorpid() == "PLSTI") {
                                        //     new Promise((resolve) => {
                                        //       let Equ = EquationFunction(c, T02Obj, T41Obj, T02UF, obj);
                                        //       resolve(Equ)
                                        //     }).then((res) => {
                                        //       // setTimeout(() => {
                                        //       //   console.log("Equtation Response Amount =>", res)
                                        //         setT02Obj(res)
                                        //       // }, 500)
                                        //       setTimeout(() => {
                                        //         if ((ExpenseArrayT02?.length > 0 || ExpenseData?.dtExp_I?.length > 0) && value > 0 && InvTypeDropDown != null) {
                                        //           setTimeout(() => {
                                        //             // TableExpenseEntry()
                                        //             NextIndexFun(index)
                                        //           }, 500)
                                        //         } else {
                                        //           console.log("Enter key Press", e)
                                        //           if (tableEditingRow == null) {
                                        //             console.log("Add Product Call")
                                        //             if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                                        //               GlobalClass.Notify("warning", "Warning", "Product Name Require to Enter")
                                        //             } else {
                                        //               handleCreateUser()
                                        //               // Promise.resolve(handleCreateUser()).then(() => setTimeout(() => {
                                        //               //   table.setCreatingRow(true)
                                        //               // }, 500))
                                        //             }
                                        //           } else {
                                        //             console.log("Edit Product Call")
                                        //             if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                                        //               GlobalClass.Notify("warning", "Warning", "Product Name Require to Enter")
                                        //             } else {
                                        //               handleSaveUser()
                                        //               // Promise.resolve(handleSaveUser()).then(() => setTimeout(() => {
                                        //               //   table.setCreatingRow(true)
                                        //               // }, 500))
                                        //             }
                                        //           }
                                        //         }
                                        //       },500)
                                        //     })
                                        //   }
                                        //   else{
                                        if ((ExpenseArrayT02?.length > 0 || ExpenseData?.dtExp_I?.length > 0) && value > 0 && InvTypeDropDown != null) {
                                            setTimeout(() => {
                                                // TableExpenseEntry()
                                                NextIndexFun(index)
                                            }, 500)
                                        } else {
                                            console.log("Enter key Press", e)
                                            if (tableEditingRow == null) {
                                                console.log("Add Product Call")
                                                if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                                                    GlobalClass.Notify("warning", "Warning", "Product Name Require to Enter")
                                                } else {
                                                    handleCreateUser()
                                                    // Promise.resolve(handleCreateUser()).then(() => setTimeout(() => {
                                                    //   table.setCreatingRow(true)
                                                    // }, 500))
                                                }
                                            } else {
                                                console.log("Edit Product Call")
                                                if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                                                    GlobalClass.Notify("warning", "Warning", "Product Name Require to Enter")
                                                } else {
                                                    handleSaveUser()
                                                    // Promise.resolve(handleSaveUser()).then(() => setTimeout(() => {
                                                    //   table.setCreatingRow(true)
                                                    // }, 500))
                                                }
                                            }
                                        }
                                    }

                                    // }
                                }}
                                //   onFocus={() => {

                                //     if (gensetting.getcorpid() == "PLSTI") {
                                //       new Promise((resolve) => {
                                //         let Equ = EquationFunction(c, T02Obj, T41Obj, T02UF, obj);
                                //         resolve(Equ)
                                //       }).then((res) => {
                                //         setTimeout(() => {
                                //           console.log("Equtation Response Amount =>", res)
                                //           setT02Obj(res)
                                //         }, 500)
                                //       })
                                //     }
                                //   }}
                                id={`T02Ent_${index}`}
                                value={T02Obj?.[c?.S53F04]}
                                setEdit={(e) => {
                                    setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                                }}
                            />
                        );

                    } else {
                        setInput = (
                            <PLNumberBox
                                setNumber={(e) => {
                                    // if (oYear?.OSC?._REVSECOND == "Y" && ["E00000000007", "E00000000008", "E00000000010"].includes(c?.S53F02)) {
                                    // if (["E00000000016"].includes(c?.S53F02) && gensetting.getcorpid() == "PLSTI") {
                                    //   new Promise((resolve) => {
                                    //     let Equ = EquationFunction(c, T02Obj, T41Obj, T02UF, obj)
                                    //     resolve(Equ)
                                    //   }).then((res) => {
                                    //     if (res) {
                                    //       console.log("Equtation Response Rate =>", res)
                                    //       setT02Obj(res)
                                    //     } else {
                                    //       e?.preventDefault()
                                    //       // document.getElementById(`T02Ent_${index}`).focus()
                                    //       NextIndexFun(index - 1)
                                    //     }
                                    //   })
                                    // }

                                    if (["E00000000007", "E00000000008", "E00000000010"].includes(c?.S53F02)) {

                                        setTimeout(() => {
                                            new Promise((resolve) => {
                                                let rev = ReverseCalCulation(c, e?.target?.value, T02Obj, oYear?.OSC?._REVSECOND)
                                                resolve(rev)
                                            }).then((res) => {
                                                console.log("ReverseCalCulation res", res);
                                                setT02Obj(res)
                                            })
                                        }, 500)
                                    }

                                    if (c.S53F02 == "E00000000010" && ["D", "I"].includes(T02Obj?.FIELD05)) {
                                        console.log("E00000000010 T02 QTY-1 =>", [{
                                            "QTY-1": e?.target?.value,
                                            "T02OBJ": T02Obj
                                        }]);
                                        new Promise((resolve) => {
                                            let qt = Qty_CheckStk({ ...ExpenseObj, BatchIssue: BatchIssueObj, ProductAllData: accList["P_M21"] })
                                            resolve(qt)
                                        }).then((res) => {
                                            console.log("E00000000010 T02 QTY-1 Response =>", [{
                                                "res": res
                                            }]);
                                            if (res != "OK" && T02Obj?.FIELD33 != 0) {
                                                Promise.resolve(NagativeQtyPopUpFun(res)).then(() => NextIndexFun(index - 1))
                                                // NagativeQtyPopUpFun(res)
                                            }
                                        })
                                    }
                                }}
                                decimalSeparator={true}
                                precision={2}
                                onKeyDown={(e) => {
                                    if (e.key == "Tab") {
                                        console.log("onKeyDown=>", e);
                                        if (gensetting.getcorpid() == "PLSTI") {

                                            if (c?.S53F02 == "E00000000016") { // Rate
                                                e.preventDefault();
                                                new Promise((resolve) => {
                                                    let Equ = EquationFunction(c, T02Obj, T41Obj, T02UF, obj)
                                                    resolve(Equ)
                                                }).then((res) => {
                                                    // setTimeout(() => {
                                                    //   console.log("Equtation Response Rate =>", res)
                                                    setT02Obj(res)
                                                    // }, 500)
                                                    setTimeout(() => {
                                                        NextIndexFun(index)
                                                    }, 500)
                                                })
                                            }
                                            else {
                                                NextIndexFun(index - 1)
                                            }
                                        }
                                        else {
                                            NextIndexFun(index - 1)
                                        }

                                    }
                                }}
                                disabled={(c?.S53F02 == "E00000000007" && oYear?.OSC?._DUALSTOCK == "Y") ? selectedProductObj != null ? (selectedProductObj?.FIELD59 == "N" ? true : false) : false : (c?.S53F02 == "E00000000008" && oYear?.OSC?._DUALSTOCK == "Y") ? selectedProductObj != null ? (selectedProductObj?.FIELD59 == "N" ? true : false) : false : false} //Qty-2 //Conversion
                                id={`T02Ent_${index}`}
                                value={T02Obj?.[c?.S53F04]}
                                onFocus={() => {
                                    // runEQ(c)
                                    if (gensetting.getcorpid() == "PLSTI") {
                                        if (c?.S53F02 == "E00000000016") { // Rate
                                            new Promise((resolve) => {
                                                let Equ = EquationFunction(c, T02Obj, T41Obj, T02UF, obj)
                                                resolve(Equ)
                                            }).then((res) => {
                                                setTimeout(() => {
                                                    console.log("Equtation Response Rate =>", res)
                                                    setT02Obj(res)
                                                }, 500)
                                            })
                                        }
                                    }
                                }}
                                setEdit={(e) => {
                                    setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                                }}
                            />
                        );
                    }
                    break;
                default:
                    setInput = null;
                    break;
            }
            return setInput;
        }
    };

    const HiddenButtonField = (uID, v, c, cellid, index, Expense) => {
        switch (c.S53F02) {
            case "E00000000004": //Dummy Order 

                NextIndexFun(index)
                // NextIndexFun(index)
                break;
            case "E00000000005": //Batch Issue
                // if (T02Obj?.FIELD05 == "D") {
                console.log("Batch Issue if condition =>", [{
                    "receiptIssueVal": receiptIssueVal,
                    "T02Obj?.FIELD03": T02Obj?.FIELD03,
                    "selectedProductObj": selectedProductObj
                }]);
                if (((oYear?.OSC?._CDINITEM == "N" ? cRorI : receiptIssueVal) == "D") && T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 != "N") {

                    console.log("Selected Product BatchIssue", [{
                        "T02Obj?.FIELD03": T02Obj?.FIELD03,
                        "selectedProductObj?.FIELD51": selectedProductObj?.FIELD51,
                        "FIELD01Value": FIELD01Value
                    }])

                    setDrawerObj({
                        ...dra,
                        title: <Text fw={700}>{c.S53F03}</Text>,
                        onClickCloseButton: () => {
                            // setDrawerObj(dra);
                            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                        },
                        onClose: () => {
                            // setDrawerObj(dra);
                            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                        },
                        body: <>
                            <Grid>
                                <Grid.Col span={12}>
                                    <PLDataGrid
                                        size={"0.3"}
                                        autoFocus={true}
                                        // setBlur={()=>T02UF?.length > 0 ? TableUserFieldFun() : null}
                                        id={`T02Ent_${index}`}
                                        cWhere={`T11.FIELD08 LIKE '%${FIELD01Value ?? ""}%'`}
                                        // width={"500px"}
                                        value={T02Obj?.[c?.S53F04]}
                                        p1={seperate(c.S53F15)[1]}
                                        setEdit={(e) => {
                                            console.log("Batch Issue Object=>", e);
                                            Promise.resolve(setBatchIssueObj(e)).then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => setTimeout(() => { NextIndexFun(index) }, 300))

                                            // setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 })
                                            // Promise.resolve(setBatchIssueObj(e)).then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                                        }}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Button
                                        variant="filled"
                                        size="sm"
                                        onClick={() => {
                                            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key == "Enter") {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                                            }
                                        }}
                                    >Ok</Button>
                                </Grid.Col>
                            </Grid>
                        </>,
                        open: true,
                        size: "xl",
                        position: "right"
                    })
                } else {
                    NextIndexFun(index)
                }
                break;
            case "E00000000006": //User FIeld
                setTimeout(() => {
                    if (T02UF && T02UF?.length > 0) {

                        console.log("T02Obj userField", T02Obj);

                        setDrawerObj({
                            ...dra, title: <Text fw={700}>User Field</Text>,
                            onClickCloseButton: () => {
                                // setDrawerObj(dra);
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                            },
                            onClose: () => {
                                // setDrawerObj(dra);
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                            },
                            body: <>
                                <SITableUserField
                                    PageObj={obj}
                                    T41Obj={T41Obj}
                                    T02UF={T02UF}
                                    T02ObjSend={T02Obj}
                                    // T02ObjSend={{...T02Obj,FIELD03:selectedProductObj?.FIELD01,F03NM:selectedProductObj?.FIELD02}}
                                    setT02Objsend={(e) => {
                                        // setT02Obj(e)
                                        console.log("SetT02E => ", e);
                                        if (obj?.page == "02530249") {
                                            // Promise.resolve(setT02Obj({...e,FIELD41:fieldDisable.Doc_No == "Y" ? (e?.UBA + T41Obj?.FIELD10) : (e?.UBA + T41Obj?.FIELD12)})).then(() => {
                                            //   setTimeout(() => {
                                            //     NextIndexFun(index)
                                            //   }, [500])
                                            // })

                                            Promise.resolve(setT02Obj({ ...e, FIELD41: (e?.UBA ?? "") + T41Obj?.FIELD10 })).then(() => {
                                                if (oYear?.OSC?._DUALSTOCK == "Y") {
                                                    if (selectedProductObj?.FIELD59 == "N") {
                                                        setTimeout(() => {
                                                            NextIndexFun(index + 2)
                                                        }, 500)
                                                    } else {
                                                        setTimeout(() => {
                                                            NextIndexFun(index)
                                                        }, 500)
                                                    }
                                                } else {
                                                    setTimeout(() => {
                                                        NextIndexFun(index)
                                                    }, 500)
                                                }
                                            })
                                        } else {
                                            Promise.resolve(setT02Obj(e)).then(() => {
                                                if (oYear?.OSC?._DUALSTOCK == "Y") {
                                                    if (selectedProductObj?.FIELD59 == "N") {
                                                        setTimeout(() => {
                                                            NextIndexFun(index + 2)
                                                        }, 500)
                                                    } else {
                                                        setTimeout(() => {
                                                            NextIndexFun(index)
                                                        }, 500)
                                                    }
                                                } else {
                                                    setTimeout(() => {
                                                        NextIndexFun(index)
                                                    }, 500)
                                                }
                                            })
                                        }
                                    }}
                                    DrawerOBJ={DrawerObj}
                                    setDrawerOBJ={(e) => {
                                        console.log("setDrawerOBJ=>", e);
                                        setDrawerObj(e)
                                    }}
                                />
                            </>, open: true, size: "sm", position: "right"
                        })
                    } else {
                        NextIndexFun(index)
                    }
                }, 500)
                break;
            case "E00000000015": //Pricelist => FIELD =PRICELIST,FIELD27
                if (selectedProductObj?.FIELD53 == "N" || "02530249,02590249,02670249,02760249".includes(obj?.page) || "02700249".includes(obj?.id)) {
                    NextIndexFun(index)
                } else {
                    if (PLDATA && PLDATA?.length > 0) {
                        setDrawerObj({
                            ...dra,
                            title: <Text fw={700}>{c.S53F03}</Text>,
                            onClickCloseButton: () => {
                                // setDrawerObj(dra);
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                            },
                            onClose: () => {
                                // setDrawerObj(dra);
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                            },
                            body: <>
                                <Grid>
                                    <Grid.Col span={12}>
                                        <PLComboBox
                                            onKeyDown={(e) => {
                                                GlobalClass.formNextInput(e, formRef);
                                            }}
                                            initiallyOpened={true}
                                            cmbid={`T02Ent_${index}`}
                                            data={typeof PLDATA == "string" ? [{}] : PLDATA}
                                            // value={T02Obj?.[c?.S53F04]}
                                            value={T02Obj?.FIELD27}
                                            dispexpr={"FIELD02"}
                                            valexpr={"FIELD01"}
                                            setEdit={(e) => {
                                                console.log("Pricelist OBJ=>", e);
                                                Promise.resolve().then(() => {
                                                    if (T41Obj.FIELD16 == "C") {
                                                        setPriceListParemeter({
                                                            ...priceListParemeter,
                                                            accountCode: T41Obj.FIELD31,
                                                            priceListCode: e,
                                                        });
                                                    } else {
                                                        setPriceListParemeter({
                                                            ...priceListParemeter,
                                                            accountCode: T41Obj.FIELD04,
                                                            priceListCode: e,
                                                        });
                                                    }
                                                }).then(() => setTimeout(() => { setDrawerObj({ ...DrawerObj, open: false }) }, 250)).then(() => setTimeout(() => { NextIndexFun(index) }, 250))



                                                // Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(() => {
                                                //   if (T41Obj.FIELD16 == "C") {
                                                //     setPriceListParemeter({
                                                //       ...priceListParemeter,
                                                //       accountCode: T41Obj.FIELD31,
                                                //       priceListCode: e,
                                                //     });
                                                //   } else {
                                                //     setPriceListParemeter({
                                                //       ...priceListParemeter,
                                                //       accountCode: T41Obj.FIELD04,
                                                //       priceListCode: e,
                                                //     });
                                                //   }
                                                // })
                                                // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                                            }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Button
                                            variant="filled"
                                            size="sm"
                                            onClick={() => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                    Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                                                }
                                            }}
                                        >Ok</Button>
                                    </Grid.Col>
                                </Grid>

                            </>,
                            open: true,
                            size: "sm",
                            position: "right"
                        })
                    } else {
                        NextIndexFun(index)
                    }
                }
                break;
            case "E00000000018": //ItemWise Expense
                if (T02Obj?.FIELD08 > 0 && InvTypeDropDown != null && ExpenseData?.dtExp_I) {
                    setTimeout(() => {
                        setDrawerObj({
                            ...dra, title: <Text fw={700}>Expense Entry</Text>,
                            onClickCloseButton: () => {
                                // setDrawerObj(dra);
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index - 2))
                                // NextIndexFun(index-2)
                            },
                            onClose: () => {
                                // setDrawerObj(dra);
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index - 2))
                                // NextIndexFun(index-2)//
                            },
                            body: <>
                                {
                                    console.log("ExpenseHideShowFlagForm 1", ExpenseHideShowFlag)
                                }
                                <ExpenseEntryForm
                                    ExpenseHideShowFlag={Expense == "Y" ? "Y" : ExpenseHideShowFlag}
                                    ExpenseObj={ExpenseObj}
                                    ProductObj={selectedProductObj}
                                    isExpense={true}
                                    expenseArr={ExpenseArrayT02 && ExpenseArrayT02?.length > 0 ? ExpenseArrayT02 : ExpenseData?.dtExp_I}
                                    // expenseArr={ExpenseData?.dtExp_I}
                                    expenseArrDef={ExpenseData?.dtExpDef_I}
                                    M01PData={ExpenseData?.M01SPDT?.jData}
                                    M01Format={ExpenseData?.M01SP}
                                    valueSaveObj={T02Obj}
                                    T41ObjSend={T41Obj}
                                    //   TableExpenseArr={TableExpense}
                                    onChange={(e) => {
                                        console.log("TableExpenseEntry Object=>", e);
                                        // setSelectedTaxAcc(e.FIELD16)

                                        if (tableEditingRow == null) {
                                            console.log("Add Product Call")
                                            if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                                                GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                                            } else {

                                                handleCreateUser(e)
                                                // Promise.resolve(handleCreateUser(e)).then(() => setTimeout(() => {
                                                //   table.setCreatingRow(true)
                                                // }, 500))
                                            }
                                        } else {
                                            console.log("Edit Product Call")
                                            if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                                                GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                                            } else {
                                                Promise.resolve(handleSaveUser(e)).then(() => setExpenseHideShowFlag(null))

                                                // Promise.resolve(handleSaveUser(e)).then(() => setTimeout(() => {
                                                //   table.setCreatingRow(true)
                                                // }, 500))
                                            }
                                        }
                                    }}
                                    DrawerOBJ={DrawerObj}
                                    setDrawerOBJ={(e) => setDrawerObj(e)}
                                />
                            </>, open: true, size: "50%", position: "right"
                        })
                    }, 500)
                } else {
                    if (tableEditingRow == null) {
                        console.log("Add Product Call")
                        if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                            GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                        } else {

                            handleCreateUser()
                            // Promise.resolve(handleCreateUser(e)).then(() => setTimeout(() => {
                            //   table.setCreatingRow(true)
                            // }, 500))
                        }
                    } else {
                        console.log("Edit Product Call")
                        if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                            GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                        } else {
                            Promise.resolve(handleSaveUser()).then(() => setExpenseHideShowFlag(null))

                            // Promise.resolve(handleSaveUser(e)).then(() => setTimeout(() => {
                            //   table.setCreatingRow(true)
                            // }, 500))
                        }
                    }
                }
                break;
            default:
                break;
        }
    }

    const TableExpenseEntry = () => {
        setDrawerObj({
            ...dra, title: <Text fw={700}>Expense Entry</Text>, body: <>
                <ExpenseEntryForm
                    // saveBtnPara={(e) => {
                    //   console.log("setExpenseSaveBtn=>", e);
                    //   setExpenseSaveBtn(e)
                    // }}
                    ProductObj={selectedProductObj}
                    isExpense={true}
                    expenseArr={ExpenseData?.dtExp_I}
                    expenseArrDef={ExpenseData?.dtExpDef_I}
                    M01PData={ExpenseData?.M01SPDT?.jData}
                    M01Format={ExpenseData?.M01SP}
                    valueSaveObj={T02Obj}
                    //   TableExpenseArr={TableExpense}
                    onChange={(e) => {
                        console.log("TableExpenseEntry Object=>", e);
                        // setSelectedTaxAcc(e.FIELD16)

                        if (tableEditingRow == null) {
                            console.log("Add Product Call")
                            if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                                GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                            } else {
                                // handleCreateUser(e)

                                Promise.resolve(handleCreateUser(e)).then(() => setTimeout(() => {
                                    table.setCreatingRow(true)
                                }, 500))
                            }
                        } else {
                            console.log("Edit Product Call")
                            if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                                GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                            } else {
                                // handleSaveUser(e)
                                Promise.resolve(handleSaveUser(e)).then(() => setTimeout(() => {
                                    table.setCreatingRow(true)
                                }, 500))
                            }
                        }

                        setT41Obj(prevFieldObj => {
                            // Use functional update to avoid overwriting previous state
                            return ExpenseData?.dtExp?.reduce((updatedFieldObj, d) => {
                                // Only update if the corresponding IA key exists in FieldObj
                                if (d?.FIELD54) {
                                    return { ...updatedFieldObj, ['EA' + d.FIELD54]: e?.FIELD16 };
                                } else {
                                    return updatedFieldObj;
                                }
                            }, { ...prevFieldObj }); // Spread previous state to avoid modifying it directly
                        })

                    }}
                    DrawerOBJ={DrawerObj}
                    setDrawerOBJ={(e) => setDrawerObj(e)}
                />
            </>, open: true, size: "50%", position: "right"
        })
    };

    const RowDetailHandle = (e) => {
        console.log("RowDetailHandle =>", e);

        let productObjFind = {};
        let cStr = "";
        accList["P_M21"]?.forEach((p, i) => {
            if (p?.FIELD01 == e?.FIELD03) {
                productObjFind = { ...p }
            }
        })

        let FUnit = (e?.FIELD05 == 'I' || e?.FIELD05 == 'D') ? (productObjFind?.FIELD06 == "" ? oYear?.OSC?._UNIT1 : productObjFind?.FIELD06) : (productObjFind?.FIELD05 == "" ? oYear?.OSC?._UNIT1 : productObjFind?.FIELD05);

        // let cStr = cStr + FUnit + "  " + productObjFind.FIELD98 + "   ";

        cStr = cStr + FUnit + "  " + productObjFind?.FIELD98 + "   ";

        if (oYear?.OSC?._DUALSTOCK == "Y" && productObjFind?.FIELD59 == 'Y') {
            let SUnit = productObjFind?.FIELD27 == "" ? oYear?.OSC?._UNIT2 : productObjFind?.FIELD27
            return cStr + SUnit + "  " + productObjFind?.FIELD93
        } else {
            return cStr
        }

    }

    const columnHelper = createColumnHelper();

    useEffect(() => {
        if (T02ENT) {
            let cols = [];
            {
                oYear?.OSC?._PRGROUP == "Y" &&
                cols.push(
                    columnHelper.accessor('GRPCD', {
                        header: "Product Group",
                        id: "Product_Group",
                        size: 150,
                        Edit: ({ cell, row }) => {
                            let val = cell.getValue();
                            return <PLDataGrid
                                autoFocus={true}
                                width={"500px"}
                                TmData={"P_M32G"}
                                // data={accList["T_M32G"]}
                                value={{ value: T02Obj?.GRPCD, label: T02Obj?.GRPNM }}
                                setEdit={(e) => {
                                    console.log("Product Group Obj=>", e);
                                    setGroupCatogaryProduct({ ...GroupCatogaryProduct, Group: e?.FIELD01 })
                                    setT02Obj({ ...T02Obj, GRPCD: e?.FIELD01, GRPNM: e?.FIELD02 })
                                    CategoryRef.current?.handlePopoverToggle()
                                }}
                            />
                        },
                        Cell: ({ cell, row }) => {
                            let val = cell.getValue();
                            return T02Data[row.id]?.GRPNM
                        },
                    })
                )
            }
            {
                oYear?.OSC?._PRCATEGOR == "Y" &&
                    cols.push(
                        columnHelper.accessor("CATCD", {
                            header: "Product Category",
                            id: "Product_Category",
                            size: 30,
                            Edit: ({ cell, row }) => {
                                let val = cell.getValue();
                                return <PLDataGrid
                                    // autoFocus={true}
                                    ref={CategoryRef}
                                    width={"500px"}
                                    TmData={"P_M32C"}
                                    // data={accList["T_M32C"]}
                                    value={{ value: T02Obj?.CATCD, label: T02Obj?.CATNM }}
                                    setEdit={(e) => {
                                        console.log("Product Category Obj=>", e);
                                        setGroupCatogaryProduct({ ...GroupCatogaryProduct, Catogary: e?.FIELD01 })
                                        Promise.resolve(setT02Obj({ ...T02Obj, CATCD: e?.FIELD01, CATNM: e?.FIELD02 }))
                                            .then(() => {
                                                ProductNameRef?.current?.handlePopoverToggle()
                                            });
                                    }}
                                />
                            },
                            Cell: ({ cell, row }) => {
                                let val = cell.getValue();
                                return T02Data[row.id]?.CATNM
                            },
                        })
                    )
            }
            T02ENT?.filter((v, i) => v?.S53F00 === "C").map((v, i) => {
                cols.push(
                    columnHelper.accessor(v.S53F04, {
                        header: v.S53F07 == 0 ? "" : v.S53F03,
                        id: v.S53UID,
                        enableGlobalFilter: v.S53F07 == 0 ? false : true,
                        size: v.S53F07 == 0 ? 0 : v.S53F07,
                        Edit: ({ cell, row }) => {
                            let val = cell.getValue();
                            return renderInputBox(v.S53UID, val, v, cell?.id, i);
                        },
                        Cell: ({ cell, row }) => {
                            // console.log("cell.getValue()", cell.getValue())
                            let val = cell.getValue();
                            // console.log("T02Data[i]=>",T02Data[row.id]);
                            if (v.S53F03 == "Product Name") {
                                return T02Data[row.id]?.F03NM ?? T02Data[row.id]?.PrdNM
                                // return "Test"
                            } else if (v.S53F02 == "E00000000012") {
                                return T02Data[row.id]?.F20NM
                            } else if (v.S53F02 == "E00000000003") {
                                return T02Data[row.id]?.FIELD05 == "D" ? "I" : "R"
                            }
                            return v.S53F07 == 0 ? "" : val
                        },
                        Footer: () => <div>{v.S53F02 == "E00000000002" ? `Total Entry :${table?.getSelectedRowModel()?.flatRows.length > 0 ? (parseInt(table?.getSelectedRowModel()?.flatRows[0]?.index) + 1) + "/" : ""}${T02Data?.length}` : Summary(v, i, "c")}</div>,
                    })
                );
            });
            return setColumns(cols);
        }
    }, [T02ENT, T02Obj, T02, accList, tmList, selectedProductObj, GroupCatogaryProduct, T02Data]);

    let stringOfNames;
    useEffect(() => {
        if (SelectedNarrationData.length >= 0) {
            const stringifyArray = SelectedNarrationData.map((a) => a.FIELD01);
            stringOfNames = stringifyArray.join(" ");
            console.log("stringOfNames", stringOfNames);
        }
        setT41Obj({ ...T41Obj, FIELD15: stringOfNames });
        // setT41Obj({...T41Obj,FIELD15:})
    }, [stringOfNames, SelectedNarrationData]);

    useEffect(() => {
        const formElement = formRef.current;
        console.log("formRef", formRef);

        if (formElement) {
            formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
        }

        return () => {
            if (formElement) {
                formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
            }
        };
    }, [formRef])

    useEffect(() => {

        if (obj.p0 == "Q" || obj.p0 == "O") {
            setM01PRow(M01PRT)
        }

        if (T41Obj) {
            // PortFieldHideShow(T41Obj?.FIELD45);

            M45IDT?.map((d) => {
                if (d.FIELD01 == T41Obj?.FIELD13) {
                    setT41Obj({ ...T41Obj, FIELD45: d.FIELD20 });
                    // PortFieldHideShow(d.FIELD20);
                }
            });
        }
    }, [leadOrderData, M01PRT])

    useEffect(() => {
        if (oYear) {
            setUserFieldHideShow(oYear?.OSC?._USERFIELD)
        }
    }, [oYear])

    const HandleHistoryBtn = () => {
        //T41.T41PVT = oS11.S11F01
        setDrawerObj({
            ...dra, title: <Text fw={700}>History</Text>, body: <>
                <PLDataGrid
                    // ref={PurcSaleRef}
                    width={"500px"}
                    // value={T41Obj?.FIELD05}
                    // value={{ value: T41Obj?.FIELD05, label: T41Obj?.FIELD05NM }}
                    TmData={"P_T41"}
                    // data={ExpenseData?.M01SPDT?.jData}
                    cWhere={`T41.T41PVT = '${OS11?.S11F01}' AND (T41.FIELD04 LIKE '%${T41Obj?.FIELD04 ?? ""}%' OR T41.FIELD31 LIKE '%${T41Obj?.FIELD04 ?? ""}%')`}
                    setEdit={(e) => {
                        console.log("HandleHistoryBtn=>", e)

                        Promise.resolve(dispatch(GetLeadEntryOrder({
                            id: e?.FIELD01,
                            p0: obj?.p0,
                            page: obj?.page,
                            text: obj?.text
                        }))).then(() => setInvAPIStatus(true)).then(() => {
                            dispatch(GetAccountList({
                                "id": "P_M21",
                                "name": "",
                                "type": "A",
                                "p0": "P_M21",
                                "p1": "P_M21",
                                "p2": "",
                                "p3": ""
                            }))
                        })
                    }}
                />

            </>, open: true, size: "50%", position: "right"
        })
    }

    useEffect(() => {
        let date = f02 || props?.obj?.p0 == "E" ? T41Obj?.FIELD02 : dateFormater(footerDate);

        if (date) {
            const year = parseInt(date.substr(0, 4));
            const month = parseInt(date.substr(4, 2)) - 1; // Month is zero-based in JavaScript Date
            const day = parseInt(date.substr(6, 2));
            const dateObject = new Date(year, month, day);
            // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
            const dayOfWeek = dateObject.toLocaleString("en-US", { weekday: "long" });
            setSelectedDay(dayOfWeek?.slice(0, 3));
        }
    }, [T41Obj?.FIELD02, footerDate]);

    useEffect(() => {
        if (InvAPIStatus == true && leadOrderData?.T41?.FIELD13) {
            GetExpenseFunction()
        }
    }, [leadOrderData?.T41?.FIELD13, obj, InvAPIStatus])

    const GetExpenseFunction = () => {
        // if (obj.p0 == "E") {
        // console.log("pagecCode=>", pagecCode);
        // console.log("TransactionData?.T41=>", T41Obj);
        dispatch(GetInvTypeDropDown(leadOrderData?.T41?.FIELD13))
        dispatch(GetExpenseData({ action: "A", code: obj?.p0 == "O" ? "OSOS" : "QTQT", invCode: leadOrderData?.T41?.FIELD13 }))
        setInvAPIStatus(false)
        // }
    }

    useEffect(() => {
        if (DefaultInvFlag && T41Obj?.FIELD13) {
            dispatch(GetPreFillTransactionFormData({ ...PreFillFormData, InvoiceType: T41Obj?.FIELD13 }))
            dispatch(GetInvTypeDropDown(T41Obj?.FIELD13))
            dispatch(GetExpenseData({ action: "A", code: obj?.p0 == "O" ? "OSOS" : "QTQT", invCode: T41Obj?.FIELD13 }))
            setDefaultInvFlag(false)
        }
    }, [DefaultInvFlag, T41Obj?.FIELD13])

    useEffect(() => {
        // if (T02Obj?.FIELD33 !== null && T02Obj?.FIELD07 !== null) {
        setT02Obj({ ...T02Obj, FIELD08: T02Obj?.FIELD33 * T02Obj?.FIELD07 });
        // }
    }, [T02Obj?.FIELD33, T02Obj?.FIELD07]);

    function dateFormater(date) {
        let year = date.toLocaleString("en-IN", { year: "numeric" });
        let month = date.toLocaleString("en-IN", { month: "2-digit" });
        let day = date.toLocaleString("en-IN", { day: "2-digit" });
        let date1 = year + month + day;
        return date1;
    }

    const seperate = (e) => {
        var paraArr = e.split("~C~");
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr;
    };

    const UFT41HRenderField = (e) => {
        //S13F02 Save UserField
        if (e == "Header") {
            return (
                UFT41H &&
                UFT41H?.length > 0 &&
                UFT41H?.map((h) => {
                    // console.log("User Field=>", h);
                    if (h?.S13F87 == "HEADER") {
                        //b?.S13F14
                        // //console.log("UFT41HRenderHeader==>",h);
                        let inputBox;
                        switch (h?.S13F14) {
                            case "TEXTBOX":
                                inputBox = <>{
                                    <Grid.Col
                                        span={4}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLTextBox
                                                nmaxlength={h?.S13F04}
                                                value={T41Obj?.[h?.S13F02]}
                                                setEdit={(e) => {
                                                    // //console.log(e)
                                                    setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                }</>
                                break;
                            case "NUMBOX":
                                inputBox = <>{
                                    <Grid.Col
                                        span={4}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLNumberBox
                                                nmaxlength={h?.S13F04}
                                                value={T41Obj?.[h?.S13F02]}
                                                setEdit={(e) => {
                                                    //console.log(e)
                                                    setT41Obj({ ...T41Obj, [h?.S13F02]: e });
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                }</>
                                break;
                            case "PLPOPUP":

                                if (h.S13F07 == "Sales Executive") {
                                    inputBox = <>{
                                        <Grid.Col
                                            span={4}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 0,
                                            }}
                                        >
                                            <Grid.Col span={3}>
                                                <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                                            </Grid.Col>
                                            <Grid.Col span={9}>
                                                <PLDataGrid
                                                    // setFocus={(e) => {
                                                    //   // console.log("page",e);
                                                    //   if (spoilerControlRef?.current?.innerText == "Show more") {
                                                    //     spoilerControlRef.current.click()
                                                    //   }
                                                    // }}

                                                    width={"500px"}
                                                    name={h.S13F02}
                                                    value={{
                                                        value: T41Obj?.[h?.S13F02],
                                                        label: accList?.[seperate(h.S13F09)[1]]?.filter((a) => a?.FIELD01 == T41Obj?.[h?.S13F02])?.[0]?.[h?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(h.S13F09)[1]]?.filter((a) => a?.FIELD01 === T41Obj?.[h?.S13F02])?.[0] || {}) ? "FIELD02" : tmList[seperate(h?.S13F09)[1]]?.oS60?.cAssignFld)],
                                                    }}
                                                    p0={seperate(h.S13F09)[0]}
                                                    p1={seperate(h.S13F09)[1]}
                                                    // dispexpr={"USALES_E"}
                                                    setEdit={(e) => {
                                                        console.log("@P", e);
                                                        setT41Obj({ ...T41Obj, [h?.S13F02]: e?.FIELD01 })
                                                        // M01handlechange({ id: item.S13F02, value: e })
                                                        // setUserFieldSpoiler(true)
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    }</>
                                } else {
                                    inputBox = <>{
                                        <Grid.Col
                                            span={4}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 0,
                                            }}
                                        >
                                            <Grid.Col span={3}>
                                                <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                                            </Grid.Col>
                                            <Grid.Col span={9}>
                                                <PLDataGrid
                                                    width={"500px"}
                                                    name={h.S13F02}
                                                    value={{
                                                        value: T41Obj?.[h?.S13F02],
                                                        label: accList?.[seperate(h.S13F09)[1]]?.filter((a) => a?.FIELD01 == T41Obj?.[h?.S13F02])?.[0]?.[h?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(h.S13F09)[1]]?.filter((a) => a?.FIELD01 === T41Obj?.[h?.S13F02])?.[0] || {}) ? "FIELD02" : tmList[seperate(h?.S13F09)[1]]?.oS60?.cAssignFld)],
                                                    }}
                                                    p0={seperate(h.S13F09)[0]}
                                                    p1={seperate(h.S13F09)[1]}
                                                    setEdit={(e) => {
                                                        //console.log(e);
                                                        console.log("@P", e);
                                                        setT41Obj({ ...T41Obj, [h?.S13F02]: e?.FIELD01 });
                                                        // M01handlechange({ id: item.S13F02, value: e })
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    }</>
                                }

                                break;
                            case "COMBOBOX":
                                inputBox = <>{
                                    <Grid.Col
                                        span={4}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLComboBox
                                                onKeyDown={(e) => {
                                                    GlobalClass.formNextInput(e, formRef);
                                                }}
                                                value={T41Obj?.[h?.S13F02]}
                                                copno={seperate(h.S13F09)[1]}
                                                // data={}
                                                name={h?.S13F07}
                                                dispexpr={"DisplayMember"}
                                                valexpr={"ValueMember"}
                                                setEdit={(e) => {
                                                    //console.log(e)
                                                    setT41Obj({ ...T41Obj, [h?.S13F02]: e.value });
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                }</>
                                break;
                            case "DATEPICKER":
                                inputBox = <>{
                                    <Grid.Col
                                        span={4}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLDateBox
                                                // value={T41Obj?.[h?.S13F02].trim() == "" ? new Date() : T41Obj?.[h?.S13F02]}
                                                // defaultval={T41Obj?.[h?.S13F02]}
                                                defaultval={T41Obj?.[h?.S13F02]?.trim() != "" ? T41Obj?.[h?.S13F02] : GetYearAndDate?.strtDate}
                                                dispformat="DD/MM/YYYY"
                                                setEdit={(e) => {
                                                    console.log("DATEPICKER=>", e)
                                                    let year = e.toLocaleString("en-IN", {
                                                        year: "numeric",
                                                    });
                                                    let month = e.toLocaleString("en-IN", {
                                                        month: "2-digit",
                                                    });
                                                    let day = e.toLocaleString("en-IN", {
                                                        day: "2-digit",
                                                    });

                                                    let StringDate = year + month + day;
                                                    if (StringDate?.length == 8) {
                                                        setT41Obj({ ...T41Obj, [h?.S13F02]: StringDate });
                                                    }
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                }</>
                                break;
                            case "TIMEPICKER":
                                inputBox = <>{
                                    <Grid.Col
                                        span={4}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLDateBox
                                                // value={T41Obj?.[h?.S13F02].trim() == "" ? new Date() : T41Obj?.[h?.S13F02]}
                                                // defaultval={T41Obj?.[h?.S13F02]}
                                                setEdit={(e) => {
                                                    console.log(e)
                                                    setT41Obj({ ...T41Obj, [h?.S13F02]: e });
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                }</>
                                break;
                            case "MEMOBOX":
                                if (h.S13F21 === "N") {
                                    inputBox = <>{
                                        <Grid.Col
                                            span={4}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 0,
                                            }}
                                        >
                                            <Grid.Col span={3}>
                                                <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                                            </Grid.Col>
                                            <Grid.Col span={9}>
                                                <PLTextBox
                                                    value={T41Obj?.[h?.S13F02]}
                                                    setEdit={(e) => {
                                                        //console.log(e)
                                                        setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    }</>
                                } else if (h.S13F21 === "H") {
                                    // h.S13F21 === "H"
                                    inputBox = <>{
                                        <Grid.Col
                                            span={4}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 0,
                                            }}
                                        >
                                            <Grid.Col span={3}>
                                                <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                                            </Grid.Col>
                                            <Grid.Col span={9}>
                                                <PLTextBox
                                                    value={T41Obj?.[h?.S13F02]}
                                                    setEdit={(e) => {
                                                        //console.log(e)
                                                        setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    }</>
                                }
                                break;
                            case "FILEUPLOAD":
                                if (h.S13F21 === "I") {
                                    inputBox = <>{
                                        <Grid.Col
                                            span={4}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 0,
                                            }}
                                        >
                                            <Grid.Col span={3}>
                                                <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                                            </Grid.Col>
                                            <Grid.Col span={9}>
                                                <PLTextBox
                                                    value={T41Obj?.[h?.S13F02]}
                                                    setEdit={(e) => {
                                                        //console.log(e)
                                                        setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    }</>
                                } else if (h.S13F21 === "A") {
                                    // h.S13F21 === "A"
                                    inputBox = <>{
                                        <Grid.Col
                                            span={4}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 0,
                                            }}
                                        >
                                            <Grid.Col span={3}>
                                                <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                                            </Grid.Col>
                                            <Grid.Col span={9}>
                                                <PLTextBox
                                                    value={T41Obj?.[h?.S13F02]}
                                                    setEdit={(e) => {
                                                        //console.log(e)
                                                        setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    }</>
                                }
                                break;

                            default:
                                break;
                        }
                        return inputBox
                    }
                })
            );
        } else if (e == "Footer") {
            return (
                UFT41H &&
                UFT41H?.length > 0 &&
                UFT41H?.map((f) => {
                    if (f?.S13F87 == "FOOTER") {
                        //b?.S13F14
                        let inputBox;
                        switch (f?.S13F14) {
                            case "TEXTBOX":
                                inputBox = <>{
                                    <Grid.Col
                                        span={12}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <Grid.Col span={4}>
                                            <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                                        </Grid.Col>
                                        <Grid.Col span={4}>
                                            <PLTextBox
                                                nmaxlength={f?.S13F04}
                                                value={T41Obj?.[f?.S13F02]}
                                                setEdit={(e) => {
                                                    // //console.log(e)
                                                    setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                }</>
                                break;
                            case "NUMBOX":
                                inputBox = <>{
                                    <Grid.Col
                                        span={12}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <Grid.Col span={4}>
                                            <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                                        </Grid.Col>
                                        <Grid.Col span={4}>
                                            <PLNumberBox
                                                nmaxlength={f?.S13F04}
                                                value={T41Obj?.[f?.S13F02]}
                                                setEdit={(e) => {
                                                    //console.log(e)
                                                    setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                }</>
                                break;
                            case "PLPOPUP":
                                inputBox = <>{
                                    <Grid.Col
                                        span={12}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <Grid.Col span={4}>
                                            <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                                        </Grid.Col>
                                        <Grid.Col span={4}>
                                            <PLDataGrid
                                                width={"500px"}
                                                name={f.S13F02}
                                                value={{
                                                    value: T41Obj?.[f?.S13F02],
                                                    label: accList?.[seperate(f.S13F09)[1]]?.filter((a) => a?.FIELD01 == T41Obj?.[f?.S13F02])?.[0]?.[f?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(f.S13F09)[1]]?.filter((a) => a?.FIELD01 === T41Obj?.[f?.S13F02])?.[0] || {}) ? "FIELD02" : tmList[seperate(f?.S13F09)[1]]?.oS60?.cAssignFld)],
                                                }}
                                                p0={seperate(f.S13F09)[0]}
                                                p1={seperate(f.S13F09)[1]}
                                                setEdit={(e) => {
                                                    //console.log(e);
                                                    setT41Obj({ ...T41Obj, [f?.S13F02]: e?.FIELD01 });
                                                    // M01handlechange({ id: item.S13F02, value: e })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                }</>
                                break;
                            case "COMBOBOX":
                                inputBox = <>{
                                    <Grid.Col
                                        span={12}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <Grid.Col span={4}>
                                            <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                                        </Grid.Col>
                                        <Grid.Col span={4}>
                                            <PLComboBox
                                                onKeyDown={(e) => {
                                                    GlobalClass.formNextInput(e, formRef);
                                                }}
                                                value={T41Obj?.[f?.S13F02]}
                                                copno={seperate(f.S13F09)[1]}
                                                dispexpr={"DisplayMember"}
                                                valexpr={"ValueMember"}
                                                setEdit={(e) => {
                                                    //console.log(e)
                                                    setT41Obj({ ...T41Obj, [f?.S13F02]: e.value });
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                }</>
                                break;
                            case "DATEPICKER":
                                inputBox = <>{
                                    <Grid.Col
                                        span={12}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <Grid.Col span={4}>
                                            <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                                        </Grid.Col>
                                        <Grid.Col span={4}>
                                            <PLDateBox
                                                // value={T41Obj?.[f?.S13F02].trim() == "" ? new Date() : T41Obj?.[f?.S13F02]}
                                                // defaultval={T41Obj?.[f?.S13F02]}
                                                defaultval={T41Obj?.[f?.S13F02]?.trim() != "" ? T41Obj?.[f?.S13F02] : GetYearAndDate?.strtDate}
                                                dispformat="DD/MM/YYYY"
                                                setEdit={(e) => {
                                                    console.log(e)

                                                    let year = e.toLocaleString("en-IN", {
                                                        year: "numeric",
                                                    });
                                                    let month = e.toLocaleString("en-IN", {
                                                        month: "2-digit",
                                                    });
                                                    let day = e.toLocaleString("en-IN", {
                                                        day: "2-digit",
                                                    });

                                                    let StringDate = year + month + day;
                                                    if (StringDate?.length == 8) {
                                                        setT41Obj({ ...T41Obj, [f?.S13F02]: StringDate });
                                                    }
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                }</>
                                break;
                            case "TIMEPICKER":
                                inputBox = <>{
                                    <Grid.Col
                                        span={12}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 0,
                                        }}
                                    >
                                        <Grid.Col span={4}>
                                            <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                                        </Grid.Col>
                                        <Grid.Col span={4}>
                                            <PLDateBox
                                                // value={T41Obj?.[f?.S13F02].trim() == "" ? new Date() : T41Obj?.[f?.S13F02]}
                                                // defaultval={T41Obj?.[f?.S13F02]}
                                                setEdit={(e) => {
                                                    console.log(e)
                                                    setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                }</>
                                break;
                            case "MEMOBOX":
                                if (f.S13F21 === "N") {
                                    inputBox = <>{
                                        <Grid.Col
                                            span={12}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 0,
                                            }}
                                        >
                                            <Grid.Col span={4}>
                                                <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                                            </Grid.Col>
                                            <Grid.Col span={4}>
                                                <PLTextBox
                                                    value={T41Obj?.[f?.S13F02]}
                                                    setEdit={(e) => {
                                                        //console.log(e)
                                                        setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    }</>
                                } else {
                                    // h.S13F21 === "H"
                                    inputBox = <>{
                                        <Grid.Col
                                            span={12}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 0,
                                            }}
                                        >
                                            <Grid.Col span={4}>
                                                <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                                            </Grid.Col>
                                            <Grid.Col span={4}>
                                                <PLTextBox
                                                    value={T41Obj?.[f?.S13F02]}
                                                    setEdit={(e) => {
                                                        //console.log(e)
                                                        setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    }</>
                                }
                                break;
                            case "FILEUPLOAD":
                                if (f.S13F21 === "I") {
                                    inputBox = <>{
                                        <Grid.Col
                                            span={12}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 0,
                                            }}
                                        >
                                            <Grid.Col span={4}>
                                                <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                                            </Grid.Col>
                                            <Grid.Col span={4}>
                                                <PLTextBox
                                                    value={T41Obj?.[f?.S13F02]}
                                                    setEdit={(e) => {
                                                        //console.log(e)
                                                        setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    }</>
                                } else {
                                    // h.S13F21 === "A"
                                    inputBox = <>{
                                        <Grid.Col
                                            span={12}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 0,
                                            }}
                                        >
                                            <Grid.Col span={4}>
                                                <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                                            </Grid.Col>
                                            <Grid.Col span={4}>
                                                <PLTextBox
                                                    value={T41Obj?.[f?.S13F02]}
                                                    setEdit={(e) => {
                                                        //console.log(e)
                                                        setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    }</>
                                }
                                break;

                            default:
                                break;
                        }
                        return inputBox
                    }
                })
            );
        }
    };

    const dispatchFunction = () => {
        let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
        Promise.resolve(dispatch(DataSaveLoading(false)))
            // .then(() => dispatch(ModalDelete(props.index)))
            .then(() => {
                dispatch(ModalDelete(props.index));
            }).then(() => {

                dispatch(GetAccountList({...obj?.BtnPnlObj,pagination: PaginationObj[obj?.cSTID]}))

                // dispatch(
                //     GetAccountList({
                //         id: "20600002",
                //         name: "Lead Entry",
                //         p0: "MT",
                //         p1: "TXNVOULISTLD",
                //         p2: "TFORM0000001",
                //         p3: "",
                //         type: "A",
                //         pagination: PaginationObj["20600002"],
                //     })
                // );
            })

    };

    // final submit
    const handleSubmit = () => {
        dispatch(DataSaveLoading(true));
        GlobalClass.Notify(
            "info",
            "Editing",
            "Please wait while we process your data"
        )
        let FilterT02Data = T02Data?.filter((t) => t?.FIELD03 != "")
        var JData = { T41: { ...T41Obj }, T02: [...FilterT02Data] };
        console.log('jdata', JData)
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "A",
            cCode:
                obj.p0 == "O"
                    ? "OSOS"
                    : "QTQT",
            cSData: JSON.stringify(JData),
        };
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "A",
            cCode:
                obj.p0 == "O"
                    ? "OSOS"
                    : "QTQT",
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.status == "SUCCESS") {
                    Promise.resolve(dispatch(DataSaveLoading(false)))
                        .then(() =>
                            GlobalClass.Notify(
                                "success",
                                "Edited",
                                "Edited successfully"
                            )
                        ).then(() => {
                            dispatchFunction()
                        }).then(() => Store.dispatch(GetInvTypeDropDown(null)))
                } else {
                    Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
                        GlobalClass.Notify("error", data?.status, data?.message)
                    );
                }
            })
            .catch((e) => {
                Promise.resolve(
                    GlobalClass.Notify("error", "Error", `${e.message}`)
                ).then(() => dispatch(DataSaveLoading(false)));
            });

    };

    //Add New Row
    const handleCreateUser = (e, multi, status) => {
        // console.log("handleCreateUser para=>", e);
        // console.log("handleCreateUser T02Obj==>", [{
        //   T02Obj
        // }], "#handleCreateUser Event==>", e);

        // setT02Data([...T02Data, {...T02Obj,FIELD08: T02Obj?.FIELD33 * T02Obj?.FIELD07}])
        // BatchLogAddRemove(T02Obj)
        setGroupCatogaryProduct(null)
        let objChange = (e ? { ...e, FIELD09: parseInt(T02Data?.length) + 1, FIELD06: parseInt(e?.FIELD33) + parseInt(e?.FIELD81) } : { ...T02Obj, FIELD09: parseInt(T02Data?.length) + 1, FIELD06: parseInt(T02Obj?.FIELD33) + parseInt(T02Obj?.FIELD81) })

        console.log("handleCreateUser objChange ==>", [{
            objChange: objChange,
            T02DataLength: T02Data?.length,
            multi: multi
        }]);
        dispatch(BatchLogAddRemoveFun({ PageCode: obj?.p0 == "O" ? "OSOS" : "QTQT", T02Data: T02Data, Row: objChange, OBJ: obj, T41Obj: T41Obj }));

        let nullFilter = T02Data?.filter((d) => d?.FIELD03 != "")

        setT02Data([...nullFilter, { ...objChange }, ({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 })]);

        if (obj.p0 == "O" || obj.p0 == "Q") {
            setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
        } else {
            setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 })
        }

        setHeaderExpenseFlag(true)
        setTimeout(() => {
            table.setCreatingRow(null)
            setReceiptIssueObj(null)
            setBatchIssueObj(null)
            setLocationObj(null)
            setSelectedProductObj(null)
            //   setT02ObjSaveFlag(true)
            setRowSelection({})
        }, 100)
        // table.setEditingRow(null);
    };

    //Update Row
    const handleSaveUser = (e) => {
        // { values, table, row }
        // console.log("handleSaveUser==>", T02Obj);
        // BatchLogAddRemove(T02Obj)
        // console.log("handleSaveUser T02Obj==>", T02Obj ,"#handleSaveUser Event==>",e );
        console.log("tableEditingRow.index=>", tableEditingRow);

        // console.log("handleSaveUser=>", e);

        let objChange = (e ? { ...e, FIELD06: parseInt(e?.FIELD33) + parseInt(e?.FIELD81) } : { ...T02Obj, FIELD06: parseInt(T02Obj?.FIELD33) + parseInt(T02Obj?.FIELD81) })

        console.log("handleSaveUser==>", objChange);

        dispatch(BatchLogAddRemoveFun({
            PageCode: obj?.p0 == "O" ? "OSOS" : "QTQT",
            T41Obj: T41Obj,
            T02Data: T02Data,
            Row: objChange,
            OBJ: obj
        }));


        let EditT02Data = [...T02Data];
        EditT02Data[tableEditingRow.index] = objChange;
        // console.log("objChange handleSaveUser=>",objChange);
        let nullFilter = EditT02Data?.filter((d) => d?.FIELD03 != "")
        setT02Data([...nullFilter, ((obj.p0 == "O" || obj.p0 == "Q") ? { ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 } : { ...T02?.[0], FIELD05: OS11?.S11F21 })])
        setHeaderExpenseFlag(true)

        if (obj.p0 == "O" || obj.p0 == "Q") {
            setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
        } else {
            setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 })
        }


        table.setEditingRow(null); //exit editing mode
        setGroupCatogaryProduct(null)
        setTableEditingRow(null)
        setReceiptIssueObj(null)
        setBatchIssueObj(null)
        setLocationObj(null)
        setSelectedProductObj(null)
        // setT02ObjSaveFlag(true)
        setRowSelection({ [T02Data?.length]: true })

    }

    const handleNullT02Obj = () => {

        if (obj.p0 == "O" || obj.p0 == "Q") {
            setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
        } else {
            setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 })
        }
        setGroupCatogaryProduct(null)
        setTimeout(() => {
            table.setCreatingRow(null)
            setReceiptIssueObj(null)
            setBatchIssueObj(null)
            setLocationObj(null)
            setSelectedProductObj(null)
            //   setT02ObjSaveFlag(true)
            setRowSelection({})
            setTableEditingRow(null)
        }, 100)
    };

    const handleRowKeyDown = (e, row) => {
        console.log("handleRowKeyDown parameter =>", [{
            "e": e,
            "row": row,
            "tableEditingRow": tableEditingRow,
            "TableRef.current": TableRef.current
        }])

        // if (e?.key == "Enter") {
        //   console.log("handleRowKeyDown parameter enter =>", [{
        //     "e": e,
        //     "row": row
        //   }])

        //   if (row?.id == 'mrt-row-create') {
        //     return
        //   } else {
        //     table.setEditingRow(row)
        //     setTableEditingRow(row)
        //     setT02Obj(row?.original)
        //   }
        // }else if(e?.key == "ArrowDown"){
        //     setRowSelection({[(row?.index) + 1]:true})
        // }else if(e?.key == "ArrowUp"){
        //     setRowSelection({[(row?.index) - 1]:true})
        // }



        const tabled = TableRef.current;
        const rows = tabled.querySelectorAll('tr');
        const currentRowIndex = Array.from(rows).indexOf(e.target);
        console.log("TABKEY", e.key);
        switch (e?.key) {
            case 'ArrowDown':
                // e.preventDefault();
                if (currentRowIndex < rows.length - 1) {
                    e.preventDefault();
                    rows[currentRowIndex + 1].focus();
                    // rows[currentRowIndex + 1].scrollIntoView({ behavior: "smooth",block: "start"})

                } else if (
                    table?.getPageCount() && table?.getPageCount() > 1 &&
                    pagination?.pageIndex < table?.getPageCount() - 1
                ) {
                    // Navigate to the next page
                    table.nextPage()
                    // setPagination((prev) => ({
                    //   ...prev,
                    //   pageIndex: prev.pageIndex + 1,
                    // }));
                    // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
                    // rows[0].focus();
                }

                break;
            case 'ArrowUp':

                if (currentRowIndex > 0) {
                    e.preventDefault();
                    rows[currentRowIndex - 1].focus();
                    // rows[currentRowIndex - 1].scrollIntoView({ behavior: "smooth",block: "end"})
                } else if (pagination.pageIndex > 0) {
                    // Navigate to the previous page
                    table.previousPage()
                    // setPagination((prev) => ({
                    //   ...prev,
                    //   pageIndex: prev.pageIndex - 1,
                    // }));
                    // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
                    // rows[0].focus();
                }

                break;
            case "Enter":
                if (row?.id == 'mrt-row-create') {
                    return
                } else if (tableEditingRow) {
                    return
                } else {
                    if (row?.original?.LVALID == "N") {
                        GlobalClass?.Notify("warning", "Warning", "Bill prepared for this entry, cannot edit entry")
                    } else {
                        table?.setEditingRow(row)
                        setTableEditingRow(row)
                        setT02Obj(row?.original)
                        if (OSV?._SPACCEACH == "N") {
                            setExpenseHideShowFlag("N")
                        }
                    }
                }
                break;
            default:
                break;
        }

    }

    const openDeleteConfirmModal = (row) => {
        console.log("openDeleteConfirmModal", row);
        if (window.confirm('Confirm to Delete ?')) {
            let newArray = T02Data?.filter((d, i) => i != row?.index)
            setT02Data(newArray)
        }
    }

    const table = useMantineReactTable({
        data: T02Data,
        columns,
        mantineTableProps: { withColumnBorders: true },
        enableTopToolbar: false,
        mantineTableContainerProps: {
            sx: { height: height * 0.2 }
            // sx: { height: "200px" }

            // ref: TableContainer
        },
        enableHiding: false,
        positionActionsColumn: "last",
        positionToolbarAlertBanner: "none",
        enableRowActions: true,
        paginationDisplayMode: "default",
        enablePagination: true,
        enableStickyHeader: true,
        enableStickyFooter: true,
        // getRowId: (row) => row.FIELD01,
        initialState: {
            density: "0px",
            pagination
        },
        state: {
            rowSelection,
            density: "0px",
            pagination
        },
        enableColumnActions: false,

        enableSorting: true,
        enableEditing: true,
        enableFilters: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        editDisplayMode: "row",
        createDisplayMode: "row",
        enableSelectAll: false,
        enableMultiRowSelection: false,
        onPaginationChange: setPagination,
        onCreatingRowSave: () => {
            if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
            } else {
                handleCreateUser()
            }
        },
        onEditingRowSave: () => {
            if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
            } else {
                handleSaveUser()
            }
        },
        onCreatingRowCancel: handleNullT02Obj,
        onEditingRowCancel: handleNullT02Obj,
        // mantineTableProps: () => ({
        //   // ref:TableRef
        //   // tabIndex: 0,
        //   // onKeyUp: (e) =>handleRowKeyDown(e)
        // }),
        mantineTableBodyProps: {
            tabIndex: 0,
            ref: TableRef,
        },
        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
            ref: TableContainer,
            tabIndex: 0,
            onKeyUp: (e) => handleRowKeyDown(e, row),
            // onKeyDown:(e)=>{handleRowKeyDown(e, row)},
            // onBlur: (e) => console.log(),
            onClick: () => {
                // console.log("Selected Row==>", row);
                // setSelectedRow(row.original);
                setRowSelection((prev) => {
                    // [row.id]: !prev[row.id],
                    if (!prev[row.id]) {
                        return { [row.id]: true }
                    }
                    return prev
                })
            },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
        }),
        renderRowActions: ({ row, table }) => (
            <Flex gap="md">
                <Tooltip label="Edit">
                    <ActionIcon
                        onClick={() => {
                            console.log("row.original =>", row?.original);
                            dispatch(GetAccountList({
                                "id": "P_M21",
                                "name": "",
                                "type": "A",
                                "p0": "P_M21",
                                "p1": "P_M21",
                                "p2": "",
                                "p3": "",
                                "cWhere": `M21.FIELD12 LIKE '%${row?.original?.CATCD ?? ""}%'`
                                // "cWhere": ""
                            }))
                            // cWhere={`M21.FIELD12 LIKE '%${T02Obj?.CATCD ?? ""}%'`}

                            if (OSV?._SPACCEACH == "N") {
                                setExpenseHideShowFlag("N")
                            }

                            if (row?.original?.LVALID == "N") {
                                GlobalClass?.Notify("warning", "Warning", "Bill prepared for this entry, cannot edit entry")
                            } else {
                                table.setEditingRow(row)
                                setTableEditingRow(row)
                                setT02Obj(row.original)
                            }
                        }}
                    >
                        <IconEdit size={"20px"} />
                    </ActionIcon>
                </Tooltip>
                {
                    row?.original?.FIELD03 != "" &&
                    <Tooltip label="Delete">
                        <ActionIcon color="red" onClick={() => {
                            console.log("openDeleteConfirmModal =>", row);
                            // let newRow = {...row?.original,LVALID:"N"}
                            if (row?.original?.LVALID == "N") {
                                GlobalClass?.Notify("warning", "Warning", "Bill prepared for this entry, cannot edit entry")
                            } else {
                                openDeleteConfirmModal(row)
                            }
                            setHeaderExpenseFlag(true)
                        }}>
                            <IconTrash size={'20px'} />
                        </ActionIcon>
                    </Tooltip>
                }
            </Flex>
        ),
        renderBottomToolbar: ({ table }) => (
            <Grid style={{ display: "flex", alignItems: "center" }} p={0}>
                <Grid.Col span={4} p={0}>
                    <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                        {
                            table?.getSelectedRowModel()?.flatRows.length > 0 && table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD03 != "" ? RowDetailHandle(table?.getSelectedRowModel()?.flatRows[0]?.original) : null
                        }
                    </Text>
                </Grid.Col>
                <Grid.Col span={4} p={0}>
                    <Group spacing={"xs"} position="center">
                        <Button size="xs" variant="subtle" compact onClick={() => {
                            if (table?.getSelectedRowModel()?.flatRows.length > 0 && table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD03 != "") {
                                let newT02Data = T02Data?.filter((d) => d?.FIELD03 != "")
                                setT02Data([...newT02Data, table?.getSelectedRowModel()?.flatRows[0]?.original, ((obj.p0 == "O" || obj.p0 == "Q") ? { ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 } : { ...T02?.[0], FIELD05: OS11?.S11F21 })])
                                setHeaderExpenseFlag(true)
                            }
                        }}>
                            Copy
                        </Button>
                    </Group>
                </Grid.Col>

                <Grid.Col span={4} p={0}>
                    <Group
                        spacing={"xs"}
                        position="right"
                        display={"flex"}
                    >
                        <MRT_TablePagination position="bottom" table={table} />
                    </Group>
                </Grid.Col>

            </Grid>
        )
    });

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key == "Escape") {
                // console.log('useEffect run')
                setTableEditingRow(null)
                Promise.resolve().then(() => handleNullT02Obj())
                    .then(() => {
                        setTimeout(() => {
                            if (tableEditingRow == null) {
                                table.setCreatingRow(false)
                            } else {
                                table.setEditingRow(null)
                            }
                        }, 500)
                    })
            }
        })
    }, [tableEditingRow])

    return (
        <>
            {
                hasError ? <>
                    <Text>{ErrorMsg?.message}</Text>
                    <Button onClick={() => {
                        if (obj) {
                            Promise.resolve(dispatch(GetLeadEntryOrder({ ...obj, modalIndex: props.index }))).then(() => setInvAPIStatus(true)).then(() => {
                                dispatch(GetAccountList({
                                    "id": "P_M21",
                                    "name": "",
                                    "type": "A",
                                    "p0": "P_M21",
                                    "p1": "P_M21",
                                    "p2": "",
                                    "p3": ""
                                }))
                            })
                        }
                    }}>Retry</Button>
                </> :
                    SaveLoading ? GlobalClass.RenderLoader("dots") :
                        <>
                            {
                                isLoading ? GlobalClass.RenderLoader("dots") :
                                    leadOrderData &&
                                    <Container fluid ref={formRef}>
                                        <Modal
                                            opened={DrawerObj?.open}
                                            // onClose={close}
                                            withCloseButton={true}

                                            fullScreen={false}//
                                            // overlayProps={{ opacity: 0.5, blur: 4 }}
                                            closeButtonProps={{
                                                onClick: (e) => {
                                                    if (typeof DrawerObj?.onClickCloseButton == "function") {
                                                        DrawerObj?.onClickCloseButton();
                                                    }
                                                    else {
                                                        setDrawerObj(dra);
                                                    }
                                                },
                                            }}
                                            title={DrawerObj?.title}
                                            size={DrawerObj?.size}
                                            // centered={.Centered}
                                            closeOnClickOutside={true}
                                            // overlayProps={m.Overlay}
                                            position={DrawerObj?.position ?? 'bottom'}
                                            onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
                                        // scrollAreaComponent={ScrollArea.Autosize}
                                        >
                                            {DrawerObj.body}
                                        </Modal>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col span={4} style={{ alignItems: 'center', padding: 0 }} >
                                                    <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                                        <Grid.Col span={3}>
                                                            <Text style={{ fontSize: 12 }}>Cash/Debit:</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={6}>
                                                            <PLComboBox
                                                                initiallyOpened
                                                                autoFocus
                                                                onKeyDown={(e) => {
                                                                    GlobalClass.formNextInput(e, formRef);
                                                                }}
                                                                value={T41Obj?.FIELD16}
                                                                data={COP8 || []}
                                                                dispexpr={"DisplayMember"}
                                                                valexpr={"ValueMember"}
                                                                setEdit={(e) => {
                                                                    console.log("Case/Debit=>", e)
                                                                    Promise.resolve(setT41Obj({ ...T41Obj, FIELD16: e }))
                                                                        .then(() => {
                                                                            setTimeout(() => {
                                                                                PartyAcRef?.current?.handlePopoverToggle()
                                                                            }, 500)
                                                                        }).then(() => dispatch(GetPreFillTransactionFormData({ ...PreFillFormData, CashDebit: e })))
                                                                }}
                                                            />
                                                        </Grid.Col>
                                                        <Grid.Col span={3}>
                                                            <Text style={{ fontSize: 12, color: "red" }}>{
                                                                M01PRow?.FIELD37 == "R" ? "Registered" :
                                                                    M01PRow?.FIELD37 == "U" ? "Unregistered" :
                                                                        M01PRow?.FIELD37 == "C" ? "Consumer" :
                                                                            M01PRow?.FIELD37 == "M" ? "Composition" :
                                                                                null
                                                            }</Text> {/*R-Registered/U-Unregitered/C-Consumer/M-Composition*/}
                                                        </Grid.Col>
                                                    </Grid.Col>

                                                    <Grid.Col
                                                        span={12}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Grid.Col span={3}>
                                                            <Text style={{ fontSize: 12 }}>Party A/c:</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={9}>
                                                            <PLDataGrid
                                                                width={"500px"}
                                                                value={{value:T41Obj?.FIELD04, label:T41Obj?.FIELD04NM}}
                                                                TmData={M01P}
                                                                ref={PartyAcRef}
                                                                p1={"P_M01"}
                                                                setEdit={(e) => {
                                                                    Promise.resolve(setT41Obj({ ...T41Obj, FIELD04: e?.FIELD01, FIELD04NM: e.FIELD02, FIELD39: e?.FIELD01, FIELD39NM: e?.FIELD02 }))
                                                                        .then(() => {
                                                                            setM01PRow(e)
                                                                        })
                                                                        .then(() => DeliveryAtRef?.current?.handlePopoverToggle())
                                                                        .then(() => {
                                                                            if (obj.p0 == "Q" || obj.p0 == "O") {
                                                                                //Default Inv 
                                                                                Promise.resolve(dispatch(GetAccountList({
                                                                                    "id": obj?.BtnPnlObj?.p1,
                                                                                    "name": "",
                                                                                    "type": "A",
                                                                                    "p0": obj?.BtnPnlObj?.p0,
                                                                                    "p1": obj?.BtnPnlObj?.p1,
                                                                                    "p2": "",
                                                                                    "p3": "",
                                                                                    "cWhere": `T41.T41PVT = '${OS11?.S11F01 ?? ""}' AND T41.FIELD04 = '${e?.FIELD01 ?? ""}'`
                                                                                }))).then(() => {
                                                                                    // setTimeout(()=>{
                                                                                    setDefaultInvFlag(true)
                                                                                    // },1000)
                                                                                }).then(() => dispatch(GetPreFillTransactionFormData({
                                                                                    ...PreFillFormData,
                                                                                    PartyAc: e?.FIELD01,
                                                                                    PartyAcName: e?.FIELD02,
                                                                                    DeliveryAt: e?.FIELD01,
                                                                                    DeliveryAtName: e?.FIELD02
                                                                                })))
                                                                            }
                                                                        })
                                                                }}
                                                            />
                                                        </Grid.Col>
                                                    </Grid.Col>

                                                    <Grid.Col
                                                        span={12}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Grid.Col span={4}>
                                                            <Button size="xs" variant="light" onClick={() => HandleHistoryBtn()}>
                                                                History
                                                            </Button>
                                                        </Grid.Col>
                                                        <Grid.Col span={4}>
                                                            <Text style={{ fontSize: 12 }}>
                                                                {M01PRow != null ? M01PRow?.FIELD17 : null}
                                                            </Text>{" "}
                                                            {/*City Name*/}
                                                        </Grid.Col>
                                                        <Grid.Col span={4}>
                                                            <Text style={{ fontSize: 12 }}>
                                                                Bal :{M01PRow != null ? M01PRow?.FIELD99 : null} DB
                                                            </Text>
                                                        </Grid.Col>
                                                    </Grid.Col>

                                                    {

                                                        <Grid.Col
                                                            span={12}
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Grid.Col span={3}>
                                                                <Text style={{ fontSize: 12 }}>Delivery At:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={9}>
                                                                {oYear?.OSC?._ECOMDLADD == "Y" ? (
                                                                    <PLDataGrid
                                                                        ref={DeliveryAtRef}
                                                                        width={"500px"}
                                                                        // value={T41Obj?.FIELD39}
                                                                        value={{ value: T41Obj?.FIELD39, label: T41Obj?.FIELD39NM }}
                                                                        TmData={M01D}
                                                                        // form={false}
                                                                        // data={M01DDT?.jData}
                                                                        setEdit={(e) => {
                                                                            // //console.log(e)

                                                                            Promise.resolve(setT41Obj({ ...T41Obj, FIELD39: e?.FIELD01, FIELD39NM: e?.FIELD02 })).then(() => {
                                                                                InvTypeRef?.current?.handlePopoverToggle()
                                                                            }).then(() => dispatch(GetPreFillTransactionFormData({ ...PreFillFormData, DeliveryAt: e?.FIELD01, DeliveryAtName: e?.FIELD02 })))
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <PLTextBox disabled={true} placeholder={"Select"} />
                                                                )}
                                                            </Grid.Col>
                                                        </Grid.Col>
                                                    }


                                                </Grid.Col>

                                                <Grid.Col
                                                    span={4}
                                                    style={{ alignItems: "center", padding: 0 }}
                                                >
                                                    <Grid.Col
                                                        span={12}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Grid.Col span={3}>
                                                            <Text style={{ fontSize: 12 }}>Inv Type:</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={9}>
                                                            {/* M45I = Invoice array */}
                                                            {
                                                                !M45I ? < PLTextBox disabled={true} placeholder={'Select'} /> :
                                                                    <PLDataGrid
                                                                        ref={InvTypeRef}
                                                                        // width={'500px'}
                                                                        value={T41Obj?.FIELD13}
                                                                        TmData={M45I}
                                                                        data={M45IDT}
                                                                        setEdit={(e) => {
                                                                            console.log("Inv Type======>", e)
                                                                            Promise.resolve(setT41Obj({
                                                                                ...T41Obj,
                                                                                FIELD45: e.FIELD20,
                                                                                FIELD13: e?.FIELD01
                                                                            })).then(() => dispatch(GetInvTypeDropDown(e))).then(() => dispatch(GetExpenseData({
                                                                                action: "A",
                                                                                code: obj.p0 == "O" ? "OSOS" : "QTQT",
                                                                                invCode: e.FIELD01
                                                                            }))).then(() => {
                                                                                PurcSaleRef?.current?.handlePopoverToggle()
                                                                            }).then(() => dispatch(GetPreFillTransactionFormData({ ...PreFillFormData, InvoiceType: e?.FIELD01 })))
                                                                        }}
                                                                    />
                                                            }
                                                        </Grid.Col>
                                                    </Grid.Col>

                                                    <Grid.Col
                                                        span={12}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Grid.Col span={3}>
                                                            <Text style={{ fontSize: 12 }}>Sales A/C:</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={9}>
                                                            <PLDataGrid
                                                                ref={PurcSaleRef}
                                                                disabled={T41Obj?.lMTaxEnt == true ? true : false}
                                                                setFocus={() => {
                                                                    PurcSaleRef?.current?.handlePopoverToggle()
                                                                }}
                                                                width={"500px"}
                                                                // value={T41Obj?.FIELD05}
                                                                value={{ value: T41Obj?.FIELD05, label: T41Obj?.FIELD05NM }}
                                                                TmData={ExpenseData?.M01SP}
                                                                // data={ExpenseData?.M01SPDT?.jData}
                                                                setEdit={(e) => {
                                                                    //console.log(e)
                                                                    setT41Obj({ ...T41Obj, FIELD05: e.FIELD01, FIELD05NM: e?.FIELD02 });
                                                                }}
                                                            />
                                                        </Grid.Col>
                                                    </Grid.Col>

                                                    <Grid.Col
                                                        span={12}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Grid.Col span={3}>
                                                            <Text style={{ fontSize: 12 }}>
                                                                Tax/Bill of Supply:
                                                            </Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={5}>
                                                            <PLComboBox
                                                                // open={true}
                                                                // ref={BillOfSupplyRef}
                                                                cmbid={"TaxBillId"}
                                                                onKeyDown={(e) => {
                                                                    GlobalClass.formNextInput(e, formRef);
                                                                }}
                                                                value={T41Obj?.FIELD21}
                                                                data={COP75}
                                                                dispexpr={"DisplayMember"}
                                                                valexpr={"ValueMember"}
                                                                setEdit={(e) => {
                                                                    Promise.resolve(setT41Obj({ ...T41Obj, FIELD21: e })).then(() => {
                                                                        document.getElementById("FIELD02").focus()
                                                                    })
                                                                }}
                                                            />
                                                        </Grid.Col>
                                                        <Grid.Col span={4}>
                                                            <Text style={{ fontSize: 12, color: "red" }}>
                                                                {
                                                                    GSTNoStatus?.status == true && T41Obj?.FIELD21 == "T" && GSTNoStatus?.object?.FIELD35 == "" ? "GSTIN No. Not Present" :
                                                                        GSTNoStatus?.status == true && T41Obj?.FIELD21 == "R" && GSTNoStatus?.object?.FIELD35 != "" ? "GSTIN No. is Present" : ""
                                                                }
                                                            </Text>
                                                        </Grid.Col>
                                                    </Grid.Col>

                                                    {
                                                        oYear?.OSC?._BROKER?.includes(OS11?.S11F01) &&
                                                        <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                                            <Grid.Col span={3}>
                                                                <Text style={{ fontSize: 12 }}>Broker</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={9}>
                                                                <PLDataGrid
                                                                    position={"unset"}
                                                                    width={"500px"}
                                                                    value={T41Obj?.FIELD35}
                                                                    // dispexpr={"T41BNO"}
                                                                    TmData={"P_M01"}
                                                                    cWhere={`M01.FIELD05 LIKE 'G00002DT' OR M01.FIELD06 LIKE 'G00002DT'`}
                                                                    setEdit={(e) => {
                                                                        console.log("Broker obj=>", e)
                                                                        setT41Obj({ ...T41Obj, FIELD35: e?.FIELD01 })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>
                                                    }

                                                </Grid.Col>

                                                <Grid.Col
                                                    span={4}
                                                    style={{ alignItems: "center", padding: 0 }}
                                                >
                                                    <Grid.Col
                                                        span={12}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Grid.Col span={3}>
                                                            <Text style={{ fontSize: 12 }}>{obj.p0 == "O" ? "Order Date:" : "Bill Date:"}</Text>
                                                            {/* "02340213" == bill from challan */}
                                                        </Grid.Col>
                                                        <Grid.Col span={9} style={{ display: "flex", alignItems: "center" }}>
                                                            <Grid.Col span={10}>
                                                                <PLDateBox
                                                                    // onBlur={(e) => {
                                                                    //     if (obj?.id === "02700249" || obj?.id === "02340213") {
                                                                    //         if (PendingChallanData?.dtchln?.length > 0) {
                                                                    //             setPendingChallanStatus(true)
                                                                    //         } else {
                                                                    //             setPendingChallanStatus(false)
                                                                    //         }
                                                                    //     }
                                                                    // }}
                                                                    // value={new Date(T41Obj?.FIELD02).getTime()}
                                                                    name={"FIELD02"}
                                                                    dispformat="DD/MM/YYYY"
                                                                    // defaultval={DefaultBillDate}
                                                                    defaultval={f02 || props?.obj?.p0 == "O" ? T41Obj?.FIELD02 : dateFormater(footerDate)}
                                                                    setEdit={(e) => {

                                                                        // console.log("Date to String==>",`"${new Date().getMonth() <= 9 ? (`0` + new Date().getMonth()) :new Date().getMonth()}"`);
                                                                        // ${new Date().getFullYear()} ${new Date().getDay()} ${new Date().getMonth()}
                                                                        // //console.log("Bill Date==>", e)
                                                                        let year = e.toLocaleString("en-IN", {
                                                                            year: "numeric",
                                                                        });
                                                                        let month = e.toLocaleString("en-IN", {
                                                                            month: "2-digit",
                                                                        });
                                                                        let day = e.toLocaleString("en-IN", {
                                                                            day: "2-digit",
                                                                        });

                                                                        let BillDate = year + month + day;
                                                                        // //console.log("startDate--", BillDate);
                                                                        if (BillDate.length == 8) {
                                                                            setF02(true);
                                                                            setT41Obj({ ...T41Obj, FIELD02: BillDate, FIELD11: BillDate })
                                                                            dispatch(GetPreFillTransactionFormData({ ...PreFillFormData, BillDate: BillDate }))
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid.Col>

                                                            <Grid.Col span={2}>
                                                                <Text size={12} style={{ color: "red" }}>
                                                                    {selectedDay}
                                                                </Text>
                                                            </Grid.Col>
                                                        </Grid.Col>
                                                    </Grid.Col>
                                                    <Grid.Col
                                                        span={12}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Grid.Col span={3}>
                                                            <Text style={{ fontSize: 12 }}>{obj.p0 == "O" ? "Order No." : "Quatation No."}</Text>

                                                        </Grid.Col>
                                                        <Grid.Col span={9}>
                                                            {/* EntryPages */}
                                                            {
                                                                T41Obj?.FIELD12 !== "" ?
                                                                    <Grid.Col span={12}>
                                                                        <PLTextBox
                                                                            onKeyDown={(e) => {
                                                                                GlobalClass.formNextInput(e, formRef);
                                                                            }}
                                                                            // setText={(e) => {
                                                                            //   if (e?.target?.value?.length > 0) {
                                                                            //     console.log("accList[obj?.page]",accList[obj?.page])
                                                                            //     if (accList[obj?.page] && accList[obj?.page].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                                            //       console.log("Duplicate Value log call");
                                                                            //       GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                                            //       setBillNoStatus(true)
                                                                            //     } else {
                                                                            //       setBillNoStatus(false)
                                                                            //     }
                                                                            //   } else {
                                                                            //     setBillNoStatus(false)
                                                                            //   }
                                                                            // }}  
                                                                            value={T41Obj?.FIELD12}
                                                                            setEdit={(e) => {
                                                                                console.log("setEdit=>", e);
                                                                                setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                                                            }}
                                                                        />
                                                                    </Grid.Col> :
                                                                    <Grid.Col span={12}>
                                                                        {
                                                                            T92 ? <>
                                                                                {
                                                                                    BillSeriesHideShow?.DataGridInput == true && <>
                                                                                        {
                                                                                            !T41Obj?.T92F00 ?
                                                                                                <PLDataGrid
                                                                                                    // ref={vouNoRef}
                                                                                                    onKeyDown={(e) => {
                                                                                                        GlobalClass.formNextInput(e, formRef);
                                                                                                    }}
                                                                                                    width={"400px"}
                                                                                                    position={"unset"}
                                                                                                    valexpr={"FIELD00"}
                                                                                                    // width={350} 
                                                                                                    value={BillSeriesHideShow?.BillNoVal}
                                                                                                    TmData={T92}
                                                                                                    // data={T92DT}
                                                                                                    setEdit={(e) => {
                                                                                                        console.log("Bill No.=>", e)
                                                                                                        // Promise.resolve(setT41Obj({ ...T41Obj, T92F00: e.FIELD01 })).then(()=>{
                                                                                                        //   setBillSeriesHideShow({...BillSeriesHideShow,DataGridInput:false,TextInput:true,BillNoVal:e.FIELD01})
                                                                                                        // })
                                                                                                        //"FIELD00"
                                                                                                        Promise.resolve(setT41Obj({ ...T41Obj, T92F00: e.FIELD00, FIELD12: "" })).then(() => {
                                                                                                            if (e) {
                                                                                                                var letr = e.FIELD02
                                                                                                                console.log("num=>", num);
                                                                                                                var num = parseInt(e.FIELD03) + 1
                                                                                                                setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: false, TextInput: true, BillNoVal: letr + num })
                                                                                                            }
                                                                                                        })

                                                                                                    }}
                                                                                                /> : <PLTextBox
                                                                                                    setText={(e) => {
                                                                                                        // console.log("bill number setText 1", [{
                                                                                                        //   "e": e,
                                                                                                        //   "value": e?.target?.value
                                                                                                        // }]);
                                                                                                        if (e?.target?.value?.length > 0) {
                                                                                                            if (accList[obj?.page] && accList[obj?.page].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                                                                                console.log("Duplicate Value log call");
                                                                                                                GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                                                                                setBillNoStatus(true)
                                                                                                            } else {
                                                                                                                setBillNoStatus(false)
                                                                                                            }
                                                                                                        } else {
                                                                                                            setBillNoStatus(false)
                                                                                                        }
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        GlobalClass.formNextInput(e, formRef);
                                                                                                    }}
                                                                                                    value={T41Obj?.FIELD12}
                                                                                                    // value={T41Obj?.T92F00}
                                                                                                    setEdit={(e) => {
                                                                                                        console.log("setEdit=>", e);
                                                                                                        // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                                                                                        setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                                                                                    }}
                                                                                                />
                                                                                        }
                                                                                    </>
                                                                                }
                                                                                {BillSeriesHideShow?.TextInput == true && <PLTextBox
                                                                                    setText={(e) => {
                                                                                        // console.log("bill number setText 2", [{
                                                                                        //   "e": e,
                                                                                        //   "value": e?.target?.value
                                                                                        // }]);
                                                                                        if (e?.target?.value?.length > 0) {
                                                                                            if (accList[obj?.page] && accList[obj?.page].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                                                                console.log("Duplicate Value log call");
                                                                                                GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                                                                setBillNoStatus(true)
                                                                                            } else {
                                                                                                setBillNoStatus(false)
                                                                                            }
                                                                                        } else {
                                                                                            setBillNoStatus(false)
                                                                                        }
                                                                                        if (BillSeriesHideShow.BillNoVal.length <= 0) {
                                                                                            setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
                                                                                        }
                                                                                    }}
                                                                                    onKeyDown={(e) => {
                                                                                        GlobalClass.formNextInput(e, formRef);
                                                                                    }}
                                                                                    value={BillSeriesHideShow?.BillNoVal}
                                                                                    // value={T41Obj?.T92F00}
                                                                                    setEdit={(e) => {
                                                                                        console.log("setEdit=>", e);
                                                                                        setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                                                                        setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                                                                    }}
                                                                                />
                                                                                }
                                                                            </> :
                                                                                < PLTextBox
                                                                                    id="vouNo"
                                                                                    setText={(e) => {
                                                                                        // console.log("bill number setText 3", [{
                                                                                        //   "e": e,
                                                                                        //   "value": e?.target?.value
                                                                                        // }]);
                                                                                        if (e?.target?.value?.length > 0) {
                                                                                            if (accList[obj?.page] && accList[obj?.page].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                                                                console.log("Duplicate Value log call");
                                                                                                GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                                                                setBillNoStatus(true)
                                                                                            } else {
                                                                                                setBillNoStatus(false)
                                                                                            }
                                                                                        } else {
                                                                                            setBillNoStatus(false)
                                                                                        }
                                                                                    }}
                                                                                    value={BillSeriesHideShow?.BillNoVal}
                                                                                    setEdit={(e) => {
                                                                                        console.log("setEdit=>", e);
                                                                                        setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                                                                        // setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e?.target?.value })
                                                                                        // if(EntryPages?.PurchaseEntry?.includes(obj?.page) || EntryPages?.PurchaseEntry?.includes(obj?.id)){
                                                                                        //   setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value, FIELD10: e.target.value })
                                                                                        // }else{
                                                                                        setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                                                                        // }
                                                                                    }}
                                                                                    onKeyDown={(e) => {
                                                                                        GlobalClass.formNextInput(e, formRef);
                                                                                    }}
                                                                                />
                                                                        }
                                                                    </Grid.Col>
                                                            }
                                                        </Grid.Col>

                                                    </Grid.Col>
                                                    <Grid.Col
                                                        span={12}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Grid.Col span={3}>
                                                            <Text style={{ fontSize: 12 }}>Doc. No.</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={9}>

                                                            <PLTextBox
                                                                disabled={
                                                                    OSV?._DOCNODATE === "Y" ? false : true
                                                                }
                                                                id={"FIELD10"}
                                                                // width={"500px"}
                                                                value={T41Obj?.FIELD10}
                                                                setEdit={(e) => {
                                                                    // //console.log(e)
                                                                    setT41Obj({ ...T41Obj, FIELD10: e?.target?.value });
                                                                }}
                                                            />

                                                        </Grid.Col>
                                                    </Grid.Col>
                                                    <Grid.Col
                                                        span={12}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Grid.Col span={3}>
                                                            <Text style={{ fontSize: 12 }}>Doc Date</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={9}>
                                                            {/* {
                                          fieldDisable.Doc_No == "Y" ?  */}
                                                            <PLDateBox
                                                                name={"FIELD11"}
                                                                // disabled={fieldDisable?.Doc_No == "Y" ? false : true}
                                                                defaultval={T41Obj?.FIELD11?.trim() != "" ? T41Obj?.FIELD11 : GetYearAndDate?.strtDate}
                                                                // defaultval={T41Obj?.FIELD11}
                                                                dispformat="DD/MM/YYYY"
                                                                setEdit={(e) => {
                                                                    // //console.log(e)

                                                                    let year = e.toLocaleString("en-IN", {
                                                                        year: "numeric",
                                                                    });
                                                                    let month = e.toLocaleString("en-IN", {
                                                                        month: "2-digit",
                                                                    });
                                                                    let day = e.toLocaleString("en-IN", {
                                                                        day: "2-digit",
                                                                    });

                                                                    let BillDate = year + month + day;
                                                                    if (BillDate?.length == 8) {
                                                                        setT41Obj({ ...T41Obj, FIELD11: BillDate });
                                                                    }
                                                                }}
                                                            />
                                                        </Grid.Col>
                                                    </Grid.Col>

                                                    {oYear?.OSC?._GR1RTTAX == "Y" ? (
                                                        <Grid.Col
                                                            span={12}
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Grid.Col span={3}>
                                                                <Text style={{ fontSize: 12 }}>Applicable % ?:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={9}>
                                                                <PLComboBox
                                                                    onKeyDown={(e) => {
                                                                        GlobalClass.formNextInput(e, formRef);
                                                                    }}
                                                                    value={T41Obj?.FIELD53}
                                                                    copno={1}
                                                                    dispexpr={"DisplayMember"}
                                                                    valexpr={"ValueMember"}
                                                                    setEdit={(e) => {
                                                                        // //console.log("Applicable % ?=========>", e.value)
                                                                        setT41Obj({ ...T41Obj, FIELD53: e.value });
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>
                                                    ) : null}
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>
                                        {
                                            userFieldHideShow == "Y" &&
                                            <>
                                                {
                                                    UFT41H && UFT41H?.length > 0 ?
                                                        <Divider
                                                            my="xs"
                                                            label={<Button
                                                                variant="subtle"
                                                                color="dark"
                                                                size="xs"
                                                                compact
                                                                onFocus={() => {
                                                                    if (UFT41H && UFT41H?.filter((af) => af?.S13HMF == "H")?.length > 3) {
                                                                        spoilerControlRef.current.click()
                                                                    }
                                                                }}
                                                            >User Fields</Button>}
                                                            labelPosition="center"
                                                            labelProps={{ component: "strong" }}
                                                        /> : null
                                                }
                                                {/* Header User Field  */}
                                                <Spoiler
                                                    // initialState={userFieldSpoiler}
                                                    // initialState={true}
                                                    onKeyDown={(e) => {
                                                        GlobalClass.formNextInput(e, formRef);
                                                    }}
                                                    maxHeight={38}
                                                    controlRef={spoilerControlRef}
                                                >
                                                    <Grid
                                                        gutter={2}
                                                        onKeyDown={(e) => {
                                                            GlobalClass.formNextInput(e, formRef);
                                                        }}>
                                                        {
                                                            UFT41HRenderField("Header")
                                                        }
                                                    </Grid>
                                                </Spoiler>
                                            </>
                                        }

                                        <Divider
                                            // my="xs"
                                            mb={"xs"}
                                            label={<Button
                                                variant="subtle"
                                                color="dark"
                                                size="xs"
                                                compact
                                                onFocus={() => {
                                                    if (UFT41H && UFT41H?.filter((af) => af?.S13HMF == "H")?.length > 3) {
                                                        spoilerControlRef.current.click()
                                                    }

                                                    // document?.activeElement?.blur()
                                                    console.log("TableRef =>", TableRef);
                                                    TableRef?.current?.focus()

                                                    setTimeout(() => {
                                                        TableContainer?.current?.focus()
                                                        setRowSelection({ 0: true })
                                                    }, 500)
                                                }}
                                            >Products</Button>}
                                            labelPosition="center"
                                            labelProps={{ component: "strong" }}
                                        />
                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ padding: 0 }}>
                                                {columns && <MantineReactTable table={table} />}
                                            </Grid.Col>
                                        </Grid>
                                        {/* <Divider my="xs" /> */}

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: "flex", padding: "0px" }}>
                                                <Grid.Col span={4}></Grid.Col>
                                                <Grid.Col span={4}>
                                                    <Group spacing={"xs"} position="left">


                                                    </Group>

                                                </Grid.Col>
                                                <Grid.Col span={4}></Grid.Col>
                                            </Grid.Col>
                                            <Grid.Col span={12} style={{ display: "flex" }}>
                                                <Grid.Col
                                                    span={6}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: 0,
                                                    }}
                                                >
                                                    {/* <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                              NOS-NUMBERS 000000.0000 Qty-2
                            </Text> */}
                                                </Grid.Col>
                                                <Grid.Col
                                                    span={6}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: 0,
                                                    }}
                                                >
                                                    <Grid.Col
                                                        span={6}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                            justifyContent: "flex-end",
                                                        }}
                                                    >
                                                        <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                                                            Item Amount :
                                                        </Text>
                                                    </Grid.Col>
                                                    <Grid.Col
                                                        span={6}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                            justifyContent: "flex-end",
                                                        }}
                                                    >
                                                        <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                                                            {console.log("t41obj", T41Obj)}
                                                            {T41Obj?.FIELD07} {OS11 ? (OS11["S11F21"] == "D" ? "CR" : "DB") : ""}
                                                        </Text>
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>

                                            {/* Footer User Field */}
                                            <Grid.Col span={6} onKeyDown={(e) => {
                                                GlobalClass.formNextInput(e, formRef);
                                            }}>
                                                {
                                                    userFieldHideShow == "Y" &&
                                                    UFT41HRenderField("Footer")

                                                }
                                            </Grid.Col>

                                            <Grid.Col span={6}>

                                                <Grid.Col span={12} style={{ border: "1px solid gray", height: "150px", overflow: "hidden", overflowY: "scroll" }}>

                                                    {
                                                        (isLoadingExpenseData) ? GlobalClass.RenderLoader('skeleton', 6, true) : <>
                                                            {/* Footer Expense */}
                                                            {ExpenseData?.dtExp && <>
                                                                {/* {
                                    console.log("FooterExpenseT41Obj=>", T41Obj)
                                  } */}
                                                                <ExpenseEntryForm
                                                                    ExpenseObj={ExpenseObj}
                                                                    ProductObj={selectedProductObj}
                                                                    isExpense={false}
                                                                    // expenseArr={ExpenseData?.dtExp}
                                                                    expenseArr={ExpenseArrayT41 && ExpenseArrayT41?.length > 0 ? ExpenseArrayT41 : ExpenseData?.dtExp}
                                                                    expenseArrDef={ExpenseData?.dtExpDef}
                                                                    M01PData={ExpenseData?.M01SPDT?.jData}
                                                                    M01Format={ExpenseData?.M01SP}
                                                                    valueSaveObj={T41Obj}
                                                                    // TableExpenseValue={T41F07RowSum}
                                                                    TableExpenseValue={T41Obj?.FIELD07}
                                                                    TableExpenseArr={T02Data}
                                                                    // SendselectedTaxAcc={selectedTaxAcc}
                                                                    onChange={(e) => {
                                                                        new Promise(() => {
                                                                            setT41Obj(e?.ObjT41)
                                                                            setT02Data(e?.ArrT02)
                                                                        }).then(() => setTimeout(() => {
                                                                            setFooterExpenseFlag(!FooterExpenseFlag)
                                                                        }, 200))

                                                                        console.log("FooterTableExpenseEntryT41Obj =>", e);
                                                                        // Promise.resolve(setT41Obj(e?.FieldObj)).then(()=>{
                                                                        //   setTimeout(()=>{
                                                                        //     setT02Data(e.T02UpdateArray)
                                                                        //   },200)
                                                                        // })

                                                                    }}
                                                                    DrawerOBJ={DrawerObj}
                                                                    setDrawerOBJ={(e) => setDrawerObj(e)}
                                                                // setT02Data={(e) =>{
                                                                //   console.log("FooterTableExpenseEntryT41Obj T02=>", e);
                                                                //    setT02Data(e)
                                                                //   }}
                                                                />
                                                            </>
                                                            }
                                                        </>
                                                    }


                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                                                <Grid.Col
                                                    span={4}
                                                    style={{ alignItems: "center", padding: 0 }}
                                                >
                                                    <Grid.Col>
                                                        <Text style={{ fontSize: 12 }}>Narration:</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={12} display={"flex"}>
                                                        <Grid.Col span={9} p={0}>
                                                            <PLTextarea
                                                                minRows={1}
                                                                value={T41Obj?.FIELD15}
                                                                onChange={(e) => {
                                                                    // //console.log(e)
                                                                    setT41Obj({ ...T41Obj, FIELD15: e.target.value });
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    // console.log("Narration=>", e);
                                                                    if (e.ctrlKey && e.key === "F1") {
                                                                        //console.log("Narration call");

                                                                        setDrawerObj({
                                                                            ...dra, title: <Text fw={700}>Naration</Text>, body: <>
                                                                                <PLNarration

                                                                                    NarrationArray={NarrationData}
                                                                                    Flag={SelectedNarrationData?.length > 0 ? false : true}
                                                                                    selectedNarrationArray={
                                                                                        SelectedNarrationData
                                                                                    }
                                                                                    // NarrationVal={T41Obj}
                                                                                    setNarrationArray={(e) => {
                                                                                        // setT41Obj(e)
                                                                                        console.log("setNarrationArray", e);
                                                                                        setNarrationData(e.NarrationData);
                                                                                        setSelectedNarrationData(
                                                                                            e.selectedNarration
                                                                                        );
                                                                                    }}
                                                                                    DrawerOBJ={DrawerObj}
                                                                                    setDrawerOBJ={(e) => setDrawerObj(e)}
                                                                                />
                                                                            </>, open: true, size: "50%", position: "right"
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </Grid.Col>
                                                    </Grid.Col>
                                                </Grid.Col>

                                                <Grid.Col
                                                    span={4}
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                        padding: 0,
                                                    }}
                                                >
                                                    <Group spacing={"xs"} position="center">
                                                        <Button
                                                            // disabled={T02Data.length > 2 ? false : true}
                                                            style={{ height: "30px", width: "100px" }}
                                                            size="sm"
                                                            id="okbutton"

                                                            onClick={() => BillNoStatus ? GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present") : T02Data?.length < 1 ? GlobalClass.Notify("warning", "Warning", "Minimum 1 Item or Account Entry Present") : T41Obj?.FIELD04 == "" ? GlobalClass.Notify("warning", "Warning", "Party Account is Not Selected") : handleSubmit()}
                                                        >
                                                            Ok
                                                        </Button>
                                                        <Button style={{ height: "30px", width: "100px" }} size="sm">Print</Button>
                                                    </Group>
                                                </Grid.Col>

                                                {/* <Grid.Col span={4} style={{ display: "flex", alignItems: 'center',justifyContent:"end", padding: 0 }}> */}
                                                <Grid.Col span={4} style={{ display: "flex", alignItems: 'center', justifyContent: "end", padding: 0 }}>
                                                    <Grid.Col span={6} style={{ display: "flex", flexDirection: "row" }}>
                                                        <Text style={{ fontSize: 12, fontWeight: "bold", padding: 0 }}>Bill Amount:</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                                        {/* <Text style={{ fontSize: 12, fontWeight: "bold", padding: 0 }}>{T02Data.reduce((a,b)=>a=a+b?.FIELD90,0)} DB</Text> */}
                                                        <Text style={{ fontSize: 12, fontWeight: "bold", padding: 0 }}>{T41Obj?.FIELD06 == 0 ? T41Obj?.FIELD07 : T41Obj?.FIELD06} {OS11 ? (OS11["S11F21"] == "C" ? "CR" : "DB") : ""}</Text>
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        {
                                            //   lEditVou == false && GlobalClass.Alert('info', props.index)
                                        }
                                    </Container>
                            }
                        </>
            }
        </>
    )
}

export default LeadEntryOrderForm