import Store from "../../utils/store";

import { GetTMFormat, getFormatBtnData } from "../../utils/slices/AccountListSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Button, Drawer, Grid, Group, Modal, Text } from "@mantine/core";
import GlobalClass from "../../utils/GlobalClass";
import NewBtnReportFormatForm from "./ReportFormatForm/NewBtnReportFormatForm";
import { GetFormatList } from "../../utils/FormatSlice/Format";
import { createColumnHelper } from "@tanstack/react-table";

const PLFormat = (props) => {

  console.log("PLFormat =>", props);

  const { data, index } = props;

  const dispatch = useDispatch()
  const columnHelper = createColumnHelper()

  const [formatData, setFormatData] = useState([]);
  const [columns, setColumns] = useState([]);

  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
  };
  const [DrawerObj, setDrawerObj] = useState(dra);

  const isLoading = useSelector((Store) => Store?.FormatSlice?.isLoading);
  const Data = useSelector((Store) => Store?.FormatSlice?.Data)

  useEffect(() => {
    dispatch(GetFormatList(data?.BtnPnlObj));
  }, []);

  useEffect(() => {
    if (Data?.FMDT) {
      setFormatData(Data?.FMDT);
    }

    if (Data?.FMDT && Data?.FMDT?.length > 0) {
      var cols = [];

      cols.push(
        columnHelper.accessor("Sequence", {
          header: "Seq",
          size: "0",

          Cell: ({ cell, row }) => {
            return row?.original?.ACTSEQNO;
          }
        })
      );

      cols.push(
        columnHelper.accessor("S62F04", {
          header: "Description",

          Cell: ({ cell, row }) => {
            const val = cell.getValue();
            return val
          },
          maxSize: 100,
        })

      );


      console.log("cols", cols);
      setColumns(cols);
    }
  }, [Data]);

  // var column = [
  //   {
  //     accessor: "Sequence", // Use 'accessor' instead of 'accessorKey'
  //     header: "Seq",
  //     size: 30,
  //     Cell: ({ row }) => {
  //       // console.log(row.original.PrName, "getVal");
  //       return row?.original?.ACTSEQNO;
  //     },
  //   },
  //   {
  //     accessor: "Desc.", // Convert index to string
  //     header: "Description",
  //     Cell: ({ row }) => {
  //       // console.log(row.original, "row.orginal");
  //       return row?.original?.S62F04;
  //     },

  //     maxSize: 100,
  //   },
  // ];

  // console.log("formatData", formatData);

  function newBtnCall() {
    // console.log("OnNewBtn", obj);

    // Store.dispatch(
    //   ModalFunction({
    //     MTitle: <Text fw={700}>Report Format Setup : Account Ledger</Text>,
    //     MAction: true,
    //     MSize: "xl",
    //     MBody: (i) => <NewBtnReportFormatForm index={i} cAction="A" />,
    //     MClose: true,
    //     closeOnClickOutside: true,
    //     onclose: () => {},
    //   })
    // );
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Report Format Setup : Account Ledger</Text>,
      body: (
        <NewBtnReportFormatForm cAction="A" DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => {
            setDrawerObj(e);
          }}
        />
      ),
      open: true,
      size: "lg",
      position: "right",
    });
  }

  const table = useMantineReactTable({
    data: formatData ?? [],
    columns,
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableResizing: true,
    initialState: {
      density: "xs",
      expanded: true,
    },
    mantineTableProps: { withColumnBorders: true },
    enableRowVirtualization: true,
    mantineTableContainerProps: { sx: { maxHeight: "600px" } },
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableTableFooter: false,

    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onDoubleClick: () => { },
      onClick: () => {
        // console.log(row?.original, "row?.original");
      },

      sx: { cursor: "pointer" },
      height: 50,
    }),
  });

  return (
    <>
      <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={() => { }}
        fullScreen={false} //
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: () => {
            setDrawerObj(dra);
          },
        }}
        title={DrawerObj.title}
        size={DrawerObj.size}
        closeOnClickOutside={true}
        position={DrawerObj?.position ?? "right"}
        onClose={
          typeof DrawerObj?.onclose == "function"
            ? DrawerObj?.onclose
            : () => {
              setDrawerObj(dra);
            }
        }
      >
        {DrawerObj.body}
      </Modal>
      {
        isLoading ? (GlobalClass.RenderLoader("dots")) :
          (
            <Grid gutter={4}>
              <Grid.Col span={12}>
                <MantineReactTable table={table} />
              </Grid.Col>
              <Grid.Col span={12}>
                <Group spacing={"xs"} position="center" mt={5} mb={5}>
                  <Button
                    size="xs"
                    onClick={() => newBtnCall()}
                  // disabled={activeBtn ? false : true}
                  >
                    New
                  </Button>
                  <Button
                    size="xs"
                  // onClick={() => EditVoucherNum()}
                  // disabled={activeBtn ? true : false}
                  // className={classes.button}
                  >
                    Edit
                  </Button>
                  <Button
                    size="xs"
                  // onClick={() => DeleteVoucherNum()}
                  // disabled={activeBtn ? true : false}
                  // className={classes.button}
                  >
                    Delete
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          )
      }
    </>
  );
};


// export function TM_FORMAT(data) {
//   const OBJ = JSON.parse(data);
//   // console.log("dsdk", OBJ);
//   const page = OBJ?.page;
//   const formatList = Store?.getState()?.AccountList?.TMFormatData[page]?.DTS62;
//   const currentBtn = Store?.getState()?.AccountList?.fmtbtnData;
//   // console.log(formatList, "Store.getS", currentBtn);
//   let len = formatList?.length;
//   switch (OBJ.p0) {
//     case "L":
//       // Store.dispatch(getBtnState(true));
//       // Store.dispatch(
//       //   ModalFunction({
//       //     onclose: (e) => {},
//       //     MTitle: <Text fw={700}>{OBJ?.text}</Text>,
//       //     MAction: true,
//       //     MSize: "xl",
//       //     MBody: (i) => <TestComp data={OBJ} index={i} />,
//       //     MClose: true,
//       //     Overlay: {
//       //       backgroundOpacity: 0.8,
//       //       blur: 5,
//       //     },
//       //   })
//       // );

//       break;
//     case "P":
//       // console.log(formatList, "Store.getS", currentBtn);
//       // for (let i = 0; i < len; i++) {
//       //

//       console.log(currentBtn, "currentBtn-");
//       let val = formatList?.findIndex((obj) =>
//         Object.values(obj).includes(currentBtn)
//       );
//       console.log(val, "val-");
//       //current val is present at 0 index so i have to assign newval as index+1
//       if (val == 0) {
//         // setCurrentBtn(formatList[i + 3]["S62F02"]);
//         Store.dispatch(getFormatBtnData(formatList[len - 1]["S62F02"]));

//         return;
//       }

//       else if (val == len - 1) {
//         Store.dispatch(getFormatBtnData(formatList[len - 2]["S62F02"]));

//         return;
//       } else  {
//         Store.dispatch(getFormatBtnData(formatList[val-1]["S62F02"]));
//         return;
//       }
//       // }
//       break;
//     case "N":
//       console.log(formatList, "Store.getS", currentBtn);
//       // for (let i = 0; i < len; i++) {
//       console.log(currentBtn, "currentBtn-");
//       let val2 = formatList?.findIndex((obj) =>
//         Object.values(obj).includes(currentBtn)
//       );
//       // console.log(val, "val-");
//       //current val is present at 0 index so i have to assign newval as index+1
//       if (val2 == len - 1) {
//         Store.dispatch(getFormatBtnData(formatList[0]["S62F02"]));
//         return;
//       } else if (val2 == 0) {
//         Store.dispatch(getFormatBtnData(formatList[1]["S62F02"]));
//         return;
//       } else if (val2 != -1) {
//         Store.dispatch(getFormatBtnData(formatList[val2 + 1]["S62F02"]));
//         return;
//       }

//       // }
//       break;
//     default:
//       break;
//   }

//   // if(typeof props?.setFormatBtn == "function"){
//   //   props.setFormatBtn(true)
//   // }

// }

export default PLFormat;
