import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserDetail } from "../../../utils/slices/UserDetailSlice";
import { Button, Center, Grid, Text } from "@mantine/core";
import PLTextBox from "../../../PlusComponent/PLTextBox";
import GlobalClass from "../../../utils/GlobalClass";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import { DataSaveLoading } from "../../../utils/slices/DataSaveLoadingSlice";
import gensetting from "../../../utils/gensetting";
import Store from "../../../utils/store";
import { ModalDelete, ModalFunction } from "../../../utils/slices/ModalSlice";
import { GetAccountList } from "../../../utils/slices/AccountListSlice";
import PLDataGrid from "../../../PlusComponent/PLDataGrid";
import UserSecurityNew from "../User Security/UserSecurityNew";

const UserDetailForm = (props) => {
  console.log("UserDetailForm props =>", props);
  const { OBJ } = props;
  const dispatch = useDispatch();
  const formRef = useRef();
  const uDet = useRef();
  const pswrd = useRef();
  const UserDetail = useSelector((state) => state?.UserDetail?.UserDetailData);
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const isLoading = useSelector((state) => state.UserDetail.isLoading);
  const hasError = useSelector((state)=>state?.UserDetail?.hasError);
  const ErrorMsg = useSelector((state)=>state?.UserDetail?.ErrorMsg);
  const [userDetailData, setUserDetailData] = useState();
  const [dataGrid, setDataGrid] = useState();
  const [reType, setReType] = useState();
  const [validation, setValidation] = useState(false);
  const [userCode,setUserCode]=useState();
  // console.log("AccNameisLoading[props?.obj?.id]",AccNameisLoading)
  useEffect(() => {
    if (OBJ?.p0 == "A") {
      dispatch(GetUserDetail());
    } else {
      dispatch(GetUserDetail({ OBJ: OBJ }));
    }
  }, []);

  useEffect(() => {
    setUserDetailData({...UserDetail?.User,M81F16:"Y"});
    setDataGrid(UserDetail);
    if (OBJ.p0 == "E") {
      setReType(UserDetail?.User?.FIELD04);
    }
  }, [UserDetail]);

  

  const handlePassowrdBlur = () => {
    if (userDetailData?.FIELD04 == reType) {
      setValidation(true);
    }
  };

 

  const handleOkButton = () => {
    if (OBJ?.p0 == "E") {
      handlePassowrdBlur();
    }
    if (validation == false && userDetailData?.FIELD04 != "" && OBJ?.cSTID=="P_M81") {
      GlobalClass.Notify(
        "warning",
        "Validation error",
        "Password and reType password should be the same"
      );
      return;
    }

    dispatch(DataSaveLoading(true));
    if (
      (OBJ?.cSTID == "P_M81" &&
        (userDetailData.FIELD02.trim() === "" ||
          userDetailData.FIELD03.trim() === "" ||
          userDetailData.FIELD05 === ""))
    ) {
      GlobalClass.Notify(
        "warning",
        "Validation error",
        "Please fill out all the required fields"
      );
      dispatch(DataSaveLoading(false));
    } else {
      GlobalClass.Notify(
        "info",
        OBJ?.p0 == "A" ? "Adding" : "Editing",
        "Please wait while we process your data"
      );
      var data = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ?.p0,
        cCode: userDetailData?.FIELD01,
        cSData: JSON.stringify( { ...userDetailData, FIELD06: "U" }
        ),
      };
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ?.p0,
        cCode: userDetailData?.FIELD01,
        cSData: JSON.stringify({ ...userDetailData, FIELD06: "U" }
        ),
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };

      fetch(
        GlobalClass.ApiUrl + GlobalClass.PostUserDet + "?pa=" + param,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log("api data", data);
          // console.log("api data status", data?.status);
          if (data.status === "SUCCESS") {
            // modals.closeAll();
            // props.ModalFunctionClose()
            // dispatch(ModalDelete(props.index))

            GlobalClass.Notify(
              "success",
              OBJ?.p0 == "A" ? "Added" : "Edited",
              OBJ?.p0 == "A" ? "Added successfully" : "Edited successfully"
            );
            let PaginationObj =
              Store.getState().DataSaveLoadingSlice?.PaginationObj;
            Promise.resolve(dispatch(DataSaveLoading(false)))
              // .then(() => dispatch(ModalDelete(props.index)))
              .then(() => {
                if (props?.OBJ?.p0 == "A") {
                  Store.dispatch(
                    ModalFunction({
                      onclose: () => {},
                      onClickCloseButton: () => {
                        console.log("hello")
                        Promise.resolve(dispatch(ModalDelete(props.index))).then(()=>{
                          dispatch(GetAccountList({...OBJ?.BtnPnlObj,pagination: PaginationObj[OBJ?.cSTID]}))
                        });
                      },
                      MTitle: (
                        <Text fw={700}>
                          {"User Rights"}
                        </Text>
                      ),
                      MAction: true,
                      MSize: "75%",
                      MBody: (i) => (
                        <UserSecurityNew
                          OBJ={{
                            id: "14161410",
                            name: "User Security",
                            p0: "U",
                            type: "A",
                          }}
                          index={i}
                        />
                      ),
                      MClose: true,
                      Overlay: {
                        backgroundOpacity: 0.8,
                        blur: 5,
                      },
                    })
                  );
                    setReType("");
                } else {
                  dispatch(ModalDelete(props.index));
                }
              })
              .then(() => {
                  // dispatch(
                  //   GetAccountList({
                  //     id: "14131410",
                  //     name: "User Detail",
                  //     p0: "MT",
                  //     p1: "P_M81",
                  //     p2: "",
                  //     p3: "",
                  //     type: "A",
                  //     pagination: PaginationObj["14131410"],
                  //   }) 
                  // );
                  if(props?.OBJ?.p0=="E"){
                  dispatch(GetAccountList({...OBJ?.BtnPnlObj,pagination: PaginationObj[OBJ?.cSTID]}))}
              });
          } else {
            GlobalClass.Notify("error", data?.status, data?.message);
            dispatch(DataSaveLoading(false));
          }
        })
        .catch((e) => {
          // console.log("inside catch", e);
          GlobalClass.Notify("error", "Error", `${e?.message}`);
          dispatch(DataSaveLoading(false));
        });
    }
  };

  return (
    <div ref={formRef}>
      {hasError ? (
        <>
          <Text>{ErrorMsg?.message}</Text>
          <Button
            onClick={() => {
              if (OBJ) {
                if (OBJ?.p0 == "A") {
                  dispatch(GetUserDetail());
                } else {
                  dispatch(GetUserDetail({ OBJ: OBJ }));
                }
              }
            }}
          >
            Retry
          </Button>
        </>
      ) : 
      SaveLoading || isLoading ? (
        GlobalClass.RenderLoader("dots")
      ) :  (
        <Grid gutter={4}>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0",
            }}
          >
            <Grid.Col span={3}>
              <Text style={{ fontSize: "12px" }}>User Name</Text>
            </Grid.Col>
            <Grid.Col span={9}>
              <PLTextBox
                autoFocus={true}
                nmaxlength={30}
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                // autoFocus={true}
                value={userDetailData?.FIELD02}
                setEdit={(e) => {
                  setUserDetailData({
                    ...userDetailData,
                    FIELD02: e.target.value,
                  });
                }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0",
            }}
          >
            <Grid.Col span={3}>
              <Text style={{ fontSize: "12px" }}>Login Name</Text>
            </Grid.Col>
            <Grid.Col span={9}>
              <PLTextBox
                nmaxlength={10}
                autoComplete={false}
                onKeyDown={(e) => {
                  if (e?.key == "Tab" || e?.key == "Enter") {
                    uDet?.current?.handlePopoverToggle();
                  }
                }}
                value={userDetailData?.FIELD03}
                setEdit={(e) => {
                  setUserDetailData({
                    ...userDetailData,
                    FIELD03: e.target.value,
                  });
                }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0",
            }}
          >
            <Grid.Col span={3}>
              <Text style={{ fontSize: "12px" }}>User Level</Text>
            </Grid.Col>
            <Grid.Col span={9}>
              <PLDataGrid
                ref={uDet}
                TmData={dataGrid?.P_M81G}
                // data={dataGrid?.P_M81GDT?.jData}
                value={{value:userDetailData?.FIELD05,label:userDetailData?.FIELD05NM}}
                setEdit={(e) => {
                  Promise.resolve(
                    setUserDetailData({
                      ...userDetailData,
                      FIELD05: e?.FIELD01,
                      FIELD05NM : e?.FIELD02
                    })
                    
                  ).then(() => {
                    const nextInput = document.getElementById(`pswrd`);

                    if (nextInput) {
                      nextInput.focus();
                    }
                  });
                }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0",
            }}
          >
            <Grid.Col span={3}>
              <Text style={{ fontSize: "12px" }}>Password</Text>
            </Grid.Col>
            <Grid.Col span={9}>
              <PLTextBox
                id={"pswrd"}
                autoComplete={false}
                entrytype={"PASSWORD"}
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                value={OBJ?.p0 == "A" ? userDetailData?.FIELD04 : ""}
                setEdit={(e) => {
                  setUserDetailData({
                    ...userDetailData,
                    FIELD04: e.target.value,
                  });
                }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0",
            }}
          >
            <Grid.Col span={3}>
              <Text style={{ fontSize: "12px" }}>Retype</Text>
            </Grid.Col>
            <Grid.Col span={9}>
              <PLTextBox
                autoComplete={false}
                entrytype={"PASSWORD"}
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                setText={handlePassowrdBlur}
                value={OBJ?.p0 == "A" ? reType : ""}
                setEdit={(e) => {
                  setReType(e?.target?.value);
                }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0",
            }}
          >
            <Grid.Col span={3}>
              <Text style={{ fontSize: "12px" }}>Back Entry</Text>
            </Grid.Col>
            <Grid.Col span={9}>
              <PLComboBox
                copno={1}
                dispexpr="DisplayMember"
                valexpr="ValueMember"
                value={userDetailData?.M81F16}
                setEdit={(e) => {
                  setUserDetailData({ ...userDetailData, M81F16: e?.value });
                }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col>
            <Center>
              <Button
                style={{ width: "130px" }}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    e.preventDefault();
                    handleOkButton();
                  }
                }}
                onClick={handleOkButton}
              >
                Ok
              </Button>
            </Center>
          </Grid.Col>
        </Grid>
      )}
    </div>
  );
};

export default UserDetailForm;
