import React, { useEffect, useRef, useState } from "react";
import { Center, Grid, Button, Text, Skeleton } from "@mantine/core";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import { useDispatch, useSelector } from "react-redux";
import { GetGSTMasterGroup } from "../../utils/slices/GSTMasterSlice";
import gensetting from "../../utils/gensetting";
import GlobalClass from "../../utils/GlobalClass";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import { TabRemove } from "../../utils/slices/TabList";

const GSTMasterForm = (props) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { OBJ } = props;
  console.log("GSTMasterForm props=>",props);
  const isLoading = useSelector((state) => state.GSTMasterGroup.isLoading);
  const hasError = useSelector((state) => state?.GSTMasterGroup?.hasError);
  const ErrorMsg = useSelector((state) => state?.GSTMasterGroup?.ErrorMsg);
  const { COP3, COP87, M51 } = useSelector(
    (state) => state?.GSTMasterGroup?.GSTMasterData
  );
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const [group, setGroup] = useState("");
  const [dataEdited, setDataEdited] = useState();

  useEffect(() => {
    if (OBJ.p0 == "E") {
      dispatch(GetGSTMasterGroup({ cCode: OBJ.id, cAction: OBJ.p0 }));
    } else {
      dispatch(GetGSTMasterGroup());
    }
  }, []);

  // console.log("GSTMasterData component=>", M51);

  useEffect(() => {
    if (M51) {
      setGroup(M51);
      setDataEdited(M51);
    }
  }, [M51]);

  useEffect(() => {

    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
      }
    };
  }, [formRef]);

  const handleOkButton = () => {
    // console.log(group, "Groupp");
    if (group !== dataEdited || OBJ?.p0 == "A") {
      dispatch(DataSaveLoading(true));
      // if (
      //   group.FIELD02.trim() === "" ||
      //   group.FIELD03.trim() === "" ||
      //   group.FLDAED.trim() === ""
      // ) {
      //   GlobalClass.Notify(
      //     "warning",
      //     "Validation error",
      //     "Please fill out all the required fields"
      //   );
      //   dispatch(DataSaveLoading(false));
      // } else {
      GlobalClass.Notify(
        "info",
        OBJ.p0 == "A" ? "Adding" : "Editing",
        "Please wait while we process your data"
      );
      let jDate = { ...group, FIELD08: OBJ?.cSTID == "T_M51G" ? "G" : "V" };
      var data = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: props?.OBJ?.p0,
        cCode: props?.OBJ?.p0=="A" ? "" : group?.FIELD01,
        cSData: JSON.stringify(jDate),
      };

      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: props?.OBJ?.p0,
        cCode: props?.OBJ?.p0=="A" ? "" : group?.FIELD01,
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };

      fetch(
        GlobalClass.ApiUrl + GlobalClass.PostM51 + "?pa=" + param,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log("api data", data);
          // console.log("api data status", data?.status);
          if (data.status === "SUCCESS") {
            // modals.closeAll();
            // props.ModalFunctionClose();
            // dispatch(ModalDelete(props.index))

            GlobalClass.Notify(
              "success",
              OBJ.p0 == "A" ? "Added" : "Edited",
              OBJ.p0 == "A" ? "Added successfully" : "Edited successfully"
            );
            let PaginationObj =
              Store.getState().DataSaveLoadingSlice.PaginationObj;
            Promise.resolve(dispatch(DataSaveLoading(false)))
              // .then(() => dispatch(ModalDelete(props.index)))
              .then(() => {
                if (props?.OBJ?.p0 == "A" && String(props?.index).startsWith("modalForm")) {
                  dispatch(GetGSTMasterGroup());
                } else {
                  if (String(props?.index).startsWith("modalForm")) {
                    dispatch(TabRemove(props?.index))
                  } else {
                    dispatch(ModalDelete(props.index))
                  }
                }
              })
              .then(() =>{
                // dispatch(
                //   GetAccountList({
                //     id: OBJ.page,
                //     name:
                //       OBJ?.cSTID == "T_M51G" ? "GST Master" : "Tax Master",
                //     p0: "MT",
                //     p1: OBJ?.cSTID == "T_M51G" ? "T_M51G" : "T_M51V",
                //     p2: OBJ?.cSTID == "T_M51G" ? "TFORM0000001" : "",
                //     p3: "",
                //     type: "A",
                //     pagination: PaginationObj[OBJ.page],
                //   })
                // )
                dispatch(GetAccountList({...OBJ?.BtnPnlObj,pagination: PaginationObj[OBJ?.cSTID]}))
              });
          } else {
            GlobalClass.Notify("error", data?.status, data?.message);
            dispatch(DataSaveLoading(false));
          }
        })
        .catch((e) => {
          GlobalClass.Notify("error", "Error", `${e?.message}`);
          dispatch(DataSaveLoading(false));
        });
    }
    // }
    else {
      if (String(props?.index).startsWith("modalForm")) {
        dispatch(TabRemove(props?.index))
      } else {
        dispatch(ModalDelete(props.index))
      }
      return;
    }
  };

  return (
    <div ref={formRef}>
      {hasError ? <>
        <Text>{ErrorMsg?.message}</Text>
        <Button onClick={() => {
          if (props?.OBJ) {
            if (OBJ.p0 == "E") {
              dispatch(GetGSTMasterGroup({ cCode: OBJ.id, cAction: OBJ.p0 }));
            } else {
              dispatch(GetGSTMasterGroup());
            }
          }
        }}>Retry</Button>
      </> : SaveLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : isLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <Grid gutter={4}>
          <Grid.Col
            span={12}
            style={{ display: "flex", alignItems: "center", padding: 0 }}
          >
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Tax Desc.</Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <PLTextBox
                autoFocus={true}
                value={group.FIELD02}
                setEdit={(e) => {
                  setGroup({ ...group, FIELD02: e.target.value });
                }}
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{ display: "flex", alignItems: "center", padding: 0 }}
          >
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Tax Type</Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <PLComboBox
                disabled={OBJ?.p0 == "E" ? group?.lEnabled == false ? true : false : false}
                data={COP3}
                value={group?.FIELD03}
                dispexpr="DisplayMember"
                valexpr="ValueMember"
                setEdit={(e) => {
                  setGroup({ ...group, FIELD03: e });
                }}
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
              />
            </Grid.Col>
          </Grid.Col>

          {OBJ?.cSTID == "T_M51G" && (
            <>
              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12 }}>CGST% </Text>
                </Grid.Col>
                <Grid.Col span={10}>
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={group?.FIELD07}
                    setEdit={(e) => {
                      setGroup({ ...group, FIELD07: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12 }}>SGST% </Text>
                </Grid.Col>

                <Grid.Col span={10}>
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={group?.FIELD05}
                    setEdit={(e) => {
                      setGroup({ ...group, FIELD05: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12 }}>IGST% </Text>
                </Grid.Col>
                <Grid.Col span={10}>
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    value={group?.FIELD06}
                    decimalSeparator={true}
                    precision={3}
                    setEdit={(e) => {
                      setGroup({ ...group, FIELD06: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12 }}>CESS%</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={group?.FIELD09}
                    setEdit={(e) => {
                      setGroup({ ...group, FIELD09: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12 }}>Addi. Cess </Text>
                </Grid.Col>
                <Grid.Col span={10}>
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={2}
                    value={group?.FIELD11}
                    setEdit={(e) => {
                      setGroup({ ...group, FIELD11: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12 }}>Cess Conv.</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={2}
                    value={group?.FIELD12}
                    setEdit={(e) => {
                      setGroup({ ...group, FIELD12: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12 }}>Cess ON:</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                  <PLComboBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    cmbid="87"
                    copno={87}
                    value={group?.FLDAED}
                    dispexpr="DisplayMember"
                    valexpr="ValueMember"
                    setEdit={(e) => {
                      setGroup({ ...group, FLDAED: e.value });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
            </>
          )}

          {OBJ?.cSTID == "T_M51V" && (
            <>
              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12 }}>Percentage</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={group?.FIELD05}
                    setEdit={(e) => {
                      setGroup({ ...group, FIELD05: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12 }}>Additional%</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                  <PLNumberBox
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                    decimalSeparator={true}
                    precision={3}
                    value={group?.FIELD07}
                    setEdit={(e) => {
                      setGroup({ ...group, FIELD07: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
            </>
          )}

          <Grid.Col span={12}>
            <Center>
              <Button size="md"
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    e.preventDefault();
                    handleOkButton();
                  }
                }}
                onClick={handleOkButton}>
                Ok
              </Button>
            </Center>
          </Grid.Col>
        </Grid>
      )}
    </div>
  );
};

export default GSTMasterForm;
