import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import GlobalClass from '../GlobalClass';
import gensetting from '../gensetting';
import axios from 'axios';
import { modals } from '@mantine/modals';

function slugify(str) {
  const specialCharMap = {
    '+': '_plus_',
    '%': '_percentage_',
    '&': '_and_',
    '@': '_at_',
    '#': '_hash_',
    '*': '_star_',
    '!': '_exclamation_',
    '$': '_dollar_',
    '^': '_caret_',
    '(': '_left_paren_',
    ')': '_right_paren_',
    '-': '_dash_',
    '=': '_equals_',
    '{': '_left_brace_',
    '}': '_right_brace_',
    '[': '_left_bracket_',
    ']': '_right_bracket_',
    ':': '_colon_',
    ';': '_semicolon_',
    '"': '_quote_',
    "'": '_single_quote_',
    '<': '_less_than_',
    '>': '_greater_than_',
    ',': '_comma_',
    '.': '_dot_',
    '?': '_question_',
    '/': '_slash_',
    '\\': '_backslash_',
    '|': '_pipe_',
    '~': '_tilde_',
    '`': '_backtick_'
  };
  let inputString = String(str).trim();
  let replacedString = inputString.replace(/[+%&@#*!$^\-={}[\]:;"'<>,.?/\\|~`]/g, match => specialCharMap[match]);
  let noSpaces = replacedString.replace(/\s+/g, '_');
  let result = noSpaces.replace(/[^a-zA-Z0-9_]/g, '').replace(/(_[a-z_]+_)$/, '').toLowerCase();

  return result;
}
const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    // Get the value of the key to group by
    let groupKey = currentValue[key];
    if (groupKey) {
      groupKey = slugify(groupKey)
    }
    // If the group doesn't exist yet, create it
    if (!result[groupKey]) {
      result[groupKey] = [];
    }

    // Push the current value into the group
    result[groupKey].push(currentValue);

    return result;
  }, {}); // Initial value is an empty object
};

export const GetFilterData = createAsyncThunk(
  "FilterSlice/GetFilterData",
  async (obj) => {
    console.log("obj_getfilterData", obj)
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cRepId: obj ? obj?.cSTID : "",
        cFmtID: "",
        Yrno: localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.S96data + "?pa=" + param
      );
      return response.data.data;
      // if (response.data?.data) {
      //   let main = { final: "", M47list: "", M47DATA: "", CBOND: "", CBOF: "", CBOP: "", CBOOT: "", CBOFFV: "" }
      //   if (response.data?.data?.S96) {
      //     let arr = groupBy(response.data?.data?.S96, "field12");
      //     console.log("arry1", arr);

      //     let final = [];
      //     let common = [];
      //     Object.keys(arr).map((dataset, i) => {
      //       let nameSet = new Set();
      //       let j = 0;
      //       console.log(dataset, arr[dataset]);
      //       final.push({
      //         Name: dataset,
      //         Fields: arr[dataset].map((e, i) => {
      //           let originalName = slugify(e.field03);
      //           let uniqueName = originalName;
      //           let counter = 1;
      //           while (nameSet.has(uniqueName)) {

      //             common.push({ dataset: dataset, field03: e.field03, field02: e.field02 })
      //             uniqueName = `${originalName}_${counter}`;
      //             counter++;
      //           }

      //           nameSet.add(uniqueName);
      //           return {
      //             DataField: e?.field02, Name: e.field03
      //           }
      //         })
      //       })

      //     })

      //     console.log("nameset", common);
      //     main = { ...main, final: final }
      //   }
      //   main = { ...main, M47list: response.data?.data?.M47list, M47DATA: response?.data?.data?.M47DATA, CBOND: response?.data?.data?.CBOND, CBOF: response?.data?.data?.CBOF, CBOP: response?.data?.data?.CBOP, CBOOT: response?.data?.data?.CBOOT, CBOFFV: response?.data?.data?.CBOFFV }
      //   return [
      //     main,
      //     obj?.id,
      //     response.data?.data?.S96
      //   ];
      // } else{
      //   return { error:"No Data Available Or Some Error Occured" };
      // }
    } catch (error) {
      GlobalClass.Notify("error", "Error", error.message);
      // console.log("Transaction Api", error);
      return { error };
    }
  }
)

export const DeleteFilter = createAsyncThunk(
  "FilterSlice/DeleteFilter",
  async (obj) => {
    GlobalClass.Notify(
      "info",
      "Deleting",
      "Please wait while we process your data"
    );
    try {
      let p = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.p0,
        cCode: obj?.id,
        cOPara: obj?.cSTID,
      };
      let param = JSON.stringify(p);
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.AEDM47 + "?pa=" + param
      );
      if (response.data.status === "SUCCESS") {
        modals.closeAll()
        GlobalClass.Notify("success", "Deleted", "Deleted successfully");
      }else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
      }
    } catch (error) {
      GlobalClass.Notify("error", "Error", `${error?.message}`);
      return { error };
    }
  }
)

export const GetM47AED = createAsyncThunk(
  "FilterSlice/GetM47AED",
  async (obj) => {
try {
  let param = JSON.stringify({
    CorpID: gensetting.getcorpid(),
    cUser: gensetting.getcuser(),
    cPass: gensetting.getcpass(),
    CmpNo: gensetting.getcmpno(),
    cOPara: obj ? obj?.cSTID : "",
    cCode: obj ? obj?.cCode : "",
    cAction:obj ? obj?.cAction : "A"
  });
  const response = await axios.get(
    GlobalClass.ApiUrl + GlobalClass.AEDM47 + "?pa=" + param
  );
  console.log("GetM47AED_response",response)
  return response.data.data;
} catch (error) {
  GlobalClass.Notify("error", "Error", `${error?.message}`);
  return { error };
}
  }
)

const FilterSlice = createSlice({
  name: "FilterSlice",
  initialState: {
    FiltersName: [],
    FiltersData:[],
    Data: {},
    Original: {},
    isLoading: true,
    hasError: false,
    ErrorMsg: {},
    isFilterApplied: false
  },
  reducers: {
    getFiltersName: (state, action) => {
      console.log("action", action.payload)

      if (typeof action.payload === 'object' && !Array.isArray(action.payload) && action.payload !== null) {
        const key = Object.keys(action.payload)[0];
        const value = action.payload[key];

        console.log("key", key, "value", value);
        state.FiltersName = { ...state.FiltersName, [key]: value }
      }
      else {
        delete state?.FiltersName[action.payload]
        console.log("else");
        console.log("FiltersName", state.FiltersName);

      }
    },
    getFilterStatus: (state, action) => {
      state.isFilterApplied = action.payload
    },
    getDataStateNull: (state, action) => {
      state.Data = {}
    },
    getFiltersDataNull:(state, action) => {
      state.FiltersData = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetFilterData.pending, (state, action) => {
        state.hasError = false;
        state.isLoading = true;

      })
      .addCase(GetFilterData.fulfilled, (state, action) => {
        console.log("fulfilled", action.payload);
        if (!action.payload?.error) {

          state.Data = action.payload
          // state.Data = {
          //   ...state.Data,
          //   [action.payload[1]]: action.payload[0],
          // };
          // state.Original = {
          //   ...state.Original,
          //   [action.payload[1]]: action.payload[2],
          // } 
          state.isLoading = false;
          state.hasError = false;
          state.ErrorMsg = null;
        } else {
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }

        // state.OneProductData = {};
      })
      .addCase(GetFilterData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetM47AED.pending, (state,action) => {
        state.hasError = false;
        state.isLoading = true;
      })
      .addCase(GetM47AED.fulfilled, (state, action) => {
        if (!action.payload?.error) {

          state.FiltersData = action.payload 
          state.isLoading = false;
          state.hasError = false;
          state.ErrorMsg = null;
        } else {
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(GetM47AED.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
  }

});
export const { getFiltersName, getFilterStatus, getDataStateNull, getFiltersDataNull } = FilterSlice.actions;
export default FilterSlice.reducer;
