import React from "react";
import TaxClassForm from "./TaxClassForm";
import Store from "../../utils/store";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import { Text } from "@mantine/core";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import { GetM14Ent } from "../../utils/slices/TaxClassSlice";
import GlobalClass from "../../utils/GlobalClass";

const TaxClass = () => {
  return <div>TaxClass</div>;
};

export default TaxClass;

export function M14AED(data) {
  const OBJ = JSON.parse(data);
  console.log("M14AEDfunction call", OBJ);

  if (OBJ.p0 === "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "50%",
        MBody: (i) => <TaxClassForm obj={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ.p0 === "E" && OBJ.id) {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "50%",
        MBody: (i) => <TaxClassForm obj={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ.p0 === "D" && OBJ.id) {
    // console.log("Delettt");
    if (window.confirm("Confirm to Delete ?")) {
      GlobalClass.Notify(
        "info",
        "Delete In Progress",
        "Please Wait Fetching Data"
      );
      let PaginationObj =
        Store?.getState()?.DataSaveLoadingSlice?.PaginationObj;

      Promise.resolve(Store.dispatch(GetM14Ent(OBJ))).then(() => {
        new Promise((resolve)=>{
          // Store.dispatch(
          //   GetAccountList({
          //     id: "15000001",
          //     name: "Tax Class ",
          //     p0: "MT",
          //     p1: "T_M14",
          //     p2: "TFORM0000001",
          //     p3: "",
          //     type: "A",
          //     pagination: PaginationObj["15000001"],
          //   }))

          Store?.dispatch(GetAccountList({ ...OBJ?.BtnPnlObj, pagination: PaginationObj[OBJ?.cSTID] }))

          resolve()
        }).then(() =>
            GlobalClass.Notify("success", "Deleted", "Deleted successfully")
          )
        
      });
    }
  }
}
