import React, { useEffect, useRef, useState } from 'react'
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import { Button, Grid, Group } from '@mantine/core';
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import PLComboBox from '../../PlusComponent/PLComboBox';
import Store from '../../utils/store';
import { useDispatch, useSelector } from 'react-redux';
import { UFEquationFunction } from './EquationsFunctions';
import { GetAccountList, GetTMFormat } from '../../utils/slices/AccountListSlice';
import GlobalClass from '../../utils/GlobalClass';
// import { UFEquationFunction } from './EquationsFunctions';

const SITableUserField = (props) => {

    // T41Obj={T41Obj}
    const { T02UF, UFT11, T02ObjSend, setT02Objsend, DrawerOBJ, setDrawerOBJ, PageObj, T41Obj } = props
    console.log(T02UF, "SITableUserField", T02ObjSend)
    const [UserFieldObj, SetUserFieldObj] = useState(null)
    const dispatch = useDispatch();

    const formRef = useRef(null);

    const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)

    // const TransactionSalesPLdata = useSelector(
    //     (state) => state?.TSalesSlices?.TransactionSalesPLdata
    //   );
    console.log(PageObj, "PageObj--")
    console.log(UserFieldObj, "t?.S13F02-")
    const TransactionSalesPLdata = useSelector((state) => state?.TSalesSlices?.TransactionSalesPLdata);
    const MstinfoData = useSelector((state) => state?.TSalesSlices?.MstinfoData)


    useEffect(() => {
        if (T02ObjSend) {
            //UGRO: T02ObjSend?.FIELD07,
            SetUserFieldObj({ ...T02ObjSend, UPE: 100.000000, USALE: MstinfoData?.USALES_E })
        }
    }, [T02ObjSend])

    useEffect(() => {
        const formElement = formRef.current;
        console.log("formRef", formRef);

        if (formElement) {
            formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
        }

        return () => {
            if (formElement) {
                formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
            }
        };
    }, [formRef])

    // useEffect(()=>{
    //     if(MstinfoData){
    //         // USALES_E
    //         SetUserFieldObj({ ...T02ObjSend, USALE:MstinfoData?.USALES_E})
    //         console.log("MstinfoData =>",MstinfoData);
    //     }
    // },[MstinfoData])

    const seperate = (e) => {
        var paraArr = e?.split("~C~");
        console.log("paraArr=>", paraArr);
        return paraArr;
    };

    const map = new Map();
    const seperate1 = (e) => {
        var paraArr = e.split("_");
        // console.log("paraArr=>", paraArr);
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr;
    };
    useEffect(() => {
        if (T02UF && PageObj?.p0 == "E") {
            T02UF?.forEach((item) => {
                if (item?.S13F14 == "PLPOPUP") {
                    const key = seperate(item.S13F09)[1]; // Key
                    const value = UserFieldObj?.[item.S13F02]; // Value
                    if (map.has(key)) {
                        // If it exists, add the value to the set/array of values for that key
                        map.get(key).add(value); // Use a Set to ensure uniqueness of values
                    } else {
                        map.set(key, new Set([value]));
                    }
                }
            });

            if (map?.size > 0) {
                map.forEach((value, key) => {
                    const dyValue = Array.from(value).length
                        ? "'" + Array.from(value).join("', '") + "'"
                        : "";
                    setTimeout(() => {
                        Promise.resolve(dispatch(
                            GetAccountList({
                                id: key,
                                name: "",
                                type: "A",
                                p0: key,
                                p1: key,
                                p2: "",
                                p3: "",
                                cWhere: `${seperate1(key)[1]}.FIELD01 IN (${dyValue})`,
                            })
                        )).then(() => {
                            dispatch(GetTMFormat({
                                "id": key,
                                "name": "",
                                "type": "A",
                                "p0": key,
                                "p1": key,
                                "p2": "TFORM0000001",
                                "p3": ""
                            }))
                        })
                    }, [500]);
                });
            }
        }
    }, [T02UF]);

    const UserFieldRender = () => {
        let InputField;
        return (
            T02UF?.map((t, i) => {
                // console.log("t?.S13F14", t?.S13F14);
                // console.log([t?.S13F02], "UserFieldObj?.[t?.S13F02]")

                console.log("UserFieldRender=>",i,"=",t?.S13F07,"=",t?.S13F14)

                switch (t?.S13F14) {
                    case "NUMBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLNumberBox
                                    
                                    onFocus={(e) => {
                                        // Promise.resolve().then(() => { return UFEquationFunction(t, T02ObjSend, T41Obj, T02UF, PageObj,TransactionSalesPLdata?.nRate) }).then((res) => {
                                        //     console.log("Equtation Response Rate =>", res)
                                        //     SetUserFieldObj(res)
                                        // })
                                        if (t?.S13F07 == "Gross Rate") {
                                            if (PageObj?.p1 != "S!") {
                                                SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: TransactionSalesPLdata?.nRate })
                                            }
                                        }
                                    }}
                                    decimalSeparator={true}
                                    precision={2}
                                    nmaxlength={t?.S13F04}
                                    value={PageObj?.cSTID == "TXNVOULISTJI" ? UserFieldObj?.FIELD07 : UserFieldObj?.[t?.S13F02]}
                                    disabled={t?.S13F02 == "UPE" || PageObj?.cSTID == "TXNVOULISTJI"}
                                    setEdit={(e) => {

                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e })
                                    }}

                                />
                            </Grid.Col>
                        </Grid.Col>

                        break;
                    case "TEXTBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLTextBox
                                    autoFocus={i == 0}
                                    nmaxlength={t?.S13F04}
                                    disabled={t?.S13F02 == "USALE"}
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e.target.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "PLPOPUP":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLDataGrid
                                    // value={UserFieldObj?.[t?.S13F02]}
                                    autoFocus={i == 0}
                                    value={{
                                        value: UserFieldObj?.[t?.S13F02],
                                        label: accList?.[seperate(t?.S13F09)[1]]?.filter((a) => a?.FIELD01 == UserFieldObj?.[t?.S13F02])?.[0]?.[t?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(t?.S13F09)[1]]?.filter((a) => a?.FIELD01 === UserFieldObj?.[t?.S13F02])?.[0] || {}) ? "FIELD02" : tmList[seperate(t?.S13F09)[1]]?.oS60?.cAssignFld)],
                                    }}
                                    setEdit={(e) => {
                                        console.log("UserFieldDataGrid value =>", [{
                                            "FIELD": t?.S13F02,
                                            "SelectedObj": e,
                                            "p1": t?.S13F09
                                        }]);
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e?.FIELD01 })
                                    }}
                                    p1={seperate(t?.S13F09)[1]}

                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "COMBOBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLComboBox
                                    autoFocus={i == 0}
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e?.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    default:
                        InputField = null
                        break;
                }
                return InputField
            })
        )
    }
    const BatchUserFieldRender = () => {
        let InputField;
        return (
            UFT11?.map((t) => {
                console.log("t?.S13F14", t?.S13F14);
                console.log([t?.S13F07], "UserFieldObj?.[t?.S13F02]")

                switch (t?.S13F14) {
                    case "NUMBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLNumberBox
                                    nmaxlength={t?.S13F04}
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e })
                                    }}

                                />
                            </Grid.Col>
                        </Grid.Col>

                        break;
                    case "TEXTBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLTextBox
                                    nmaxlength={t?.S13F04}
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e.target.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "PLPOPUP":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLDataGrid
                                    // value={UserFieldObj?.[t?.S13F02]}
                                    value={{
                                        value: UserFieldObj?.[t?.S13F02],
                                        label: accList?.[seperate(t?.S13F09)[1]]?.filter((a) => a?.FIELD01 == UserFieldObj?.[t?.S13F02])?.[0]?.[t?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(t?.S13F09)[1]]?.filter((a) => a?.FIELD01 === UserFieldObj?.[t?.S13F02])?.[0] || {}) ? "FIELD02" : tmList[seperate(t?.S13F09)[1]]?.oS60?.cAssignFld)],
                                    }}
                                    setEdit={(e) => {
                                        console.log(e?.FIELD01, "e?.FIELD01")
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e?.FIELD01 })
                                    }}
                                    p1={seperate(t?.S13F09)[1]}

                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "COMBOBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLComboBox
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e?.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    default:
                        InputField = null
                        break;
                }
                return InputField
            })
        )
    }

    const SaveTableUserField = () => {
        console.log("UserFieldObj", UserFieldObj);
        // console.log("T02ObjSend",T02ObjSend);
        Promise.resolve(setT02Objsend(UserFieldObj)).then(() => setDrawerOBJ({ ...DrawerOBJ, open: false }))
        // props.context.closeModal(props.id)
    }

    console.log("T02UF=>", T02UF);
    return (<>
        <Grid gutter={4} ref={formRef}>
            {
                T02UF == undefined ? BatchUserFieldRender() : UserFieldRender()
            }
            <Grid.Col span={12}>
                <Group spacing={'xs'} position='center'>
                    <Button
                        onKeyDown={(e) => {
                            if (e.key == "Enter") {
                                SaveTableUserField()
                            }
                        }}
                        onClick={() => SaveTableUserField()}>Save</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </>)
}

export default SITableUserField