import {
  ActionIcon,
  Button,
  Center,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  Spoiler,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { IconCurrencyRupee, IconEdit } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import { modals } from "@mantine/modals";
import Store from "../../../utils/store";
import GlobalClass from "../../../utils/GlobalClass";
import PLDateBox from "../../../PlusComponent/PLDateBox";
import PLTextBox from "../../../PlusComponent/PLTextBox";
import PLDataGrid from "../../../PlusComponent/PLDataGrid";
import PLNarration from "../../../PlusComponent/PLNarration";
import PLNumberBox from "../../../PlusComponent/PLNumberBox";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import SITableUserField from "../../SaleEntry/SITableUserField";
import {
  BatchLogAddRemoveFun,
  GetUserFieldObj,
  TransactionApi,
} from "../../../utils/TransactionSlices/Transaction";
import { ModalDelete } from "../../../utils/slices/ModalSlice";
import { DataSaveLoading } from "../../../utils/slices/DataSaveLoadingSlice";
import gensetting from "../../../utils/gensetting";
import {
  GetAccountList,
  GetTMFormat,
} from "../../../utils/slices/AccountListSlice";
import T02Table from "../../T02/T02Table";
import PLTextarea from "../../../PlusComponent/PLTextarea";
import PendingIssue from "../../JobWorkOut/RIOJobOutForm/PendingIssue";
import {
  GetJWListData,
  RefreshIOrderPopUp,
  RefreshIssuePopUp,
} from "../../../utils/TransactionSlices/PendingIssueSlice";
import PLUserFieldBtn from "../../../PlusComponent/PLUserFieldBtn";
import PLPrint from "../../../FunctionsCall/Print/PLPrint";
import FormatListTable from "../../SMSEmailWpBtn/FormatListTable";
import { TabRemove } from "../../../utils/slices/TabList";

export default function RIPForm(props) {
  
  console.log("RIPFormProps =>",props);

  const { index, obj } = props;

  const [group, setGroup] = useState({
    FIELD01: "JOPV73BZX483",
    FIELD02: "20240331",
    FIELD03: 0,
    FIELD04: "",
    FIELD05: "",
    FIELD06: "0",
    FIELD07: "0",
    FIELD08: 0,
    FIELD09: 0,
    FIELD10: "",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "",
    FIELD14: "N",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: 0,
    FIELD21: "",
    FIELD22: "",
    FIELD23: "",
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD70: "",
    FIELD71: "",
    FIELD75: "",
    FIELD99: "",
    FIELD72: "",
    FIELD20: 0,
    FIELD80: "",
    FIELD51: 0,
    FIELD34: "",
    FIELD35: "",
    FIELD36: "",
    FIELD37: "",
    FIELD38: "",
    FLDJIO: "",
    FIELD39: "",
    FIELD40: "",
    FIELD41: "",
    FIELD42: "",
    FIELD43: "",
    FIELD44: "",
    FIELD45: "",
    FIELD46: "",
    FIELD47: "",
    FIELD48: "",
    FIELD49: "",
    FIELD50: "",
    FIELD52: "",
    FIELD53: "",
    T41PVT: "JO",
    FIELD54: "",
    T41VNO: "",
    CMID: 0,
    ADDID: 0,
    PRESCPATH: "",
    OSTATUS: "",
    DTYPE: "",
    DELURL: "",
    DELMBNO: "",
    FIELD55: "",
    FIELD56: "",
    T41POS: "",
    FLDUNQ: 0,
    FLDAED: "A",
    FLDBRC: "",
    UU73: "",
    EA00000001: "",
    EA00000002: "",
    EA00000003: "",
    EA00000004: "",
    EA00000005: "",
    EA00000006: "",
    EA00000007: "",
    EA00000008: "",
    EA00000009: "",
    EA00000010: "",
    EA00000011: "",
    EA00000012: "",
    EA00000013: "",
    EA00000014: "",
    EA00000015: "",
    ED00000001: 0,
    ED00000002: 0,
    ED00000003: 0,
    ED00000004: 0,
    ED00000005: 0,
    ED00000006: 0,
    ED00000007: 0,
    ED00000008: 0,
    ED00000009: 0,
    ED00000010: 0,
    ED00000011: 0,
    ED00000012: 0,
    ED00000013: 0,
    ED00000014: 0,
    ED00000015: 0,
    EP00000001: 0,
    EP00000002: 0,
    EP00000003: 0,
    EP00000004: 0,
    EP00000005: 0,
    EP00000006: 0,
    EP00000007: 0,
    EP00000008: 0,
    EP00000009: 0,
    EP00000010: 0,
    EP00000011: 0,
    EP00000012: 0,
    EP00000013: 0,
    EP00000014: 0,
    EP00000015: 0,
    PA000001: "",
    PA000002: "",
    PD000001: 0,
    PD000002: 0,
    UBILTO: "",
    UDISP: "",
    UEMAIL: "",
    UGSTR: "",
    ULEN: "",
    ULRD: "",
    UNAME: "",
    UOD: "",
    UODD: "",
    UODP: "",
    UOP: "",
    UPAYT: "",
    UPR: "",
    URA: "",
    UREMA: "",
    UREST6: "",
    USAL: 0,
    USALM: "",
    UTR: "",
    UTRA: "",
    UUAASS01: "",
    UUASWER: "",
    UUFR4: "",
    UUJA: "",
    UUJBWID: "",
    UUJWTRID: "",
    UUPRDID: "",
    UUPRODC: "",
    UUPRTR: "",
    UUSALM: "",
    UUT: "",
    UUT78: "",
    UUTPR: "",
    UUUASD09: "",
    UUUASWER: "",
    UUUJBOS: "",
    UUUJOB: "",
    UUUOS: "",
    UU05: "",
    UU87: "",
    UUISSUID: "",
    UUE: "",
    EA00000016: "",
    ED00000016: 0,
    EP00000016: 0,
    EA00000017: "",
    ED00000017: 0,
    EP00000017: 0,
    EA00000018: "",
    ED00000018: 0,
    EP00000018: 0,
    EA00000019: "",
    ED00000019: 0,
    EP00000019: 0,
    EA00000020: "",
    ED00000020: 0,
    EP00000020: 0,
    EA00000021: "",
    ED00000021: 0,
    EP00000021: 0,
    EA00000022: "",
    ED00000022: 0,
    EP00000022: 0,
    UUABCD: "",
    UUGFEJH: "",
    UUTEST56: "",
    EA00000023: "",
    ED00000023: 0,
    EP00000023: 0,
    UUSALESB: "",
    EA00000024: "",
    ED00000024: 0,
    EP00000024: 0,
    DSDATE: "2023-04-01T00:00:00+03:00",
    DEDATE: "2024-03-31T00:00:00+02:00",
    CACTION: "A",
    lInvType: false,
    lMTaxEnt: false,
    T92F00: "",
    FIELD04NM: "",
    FIELD05NM: "",
    FIELD39NM: "",
    FIELD34NM: "",
    CFRMTITLE: "Add Jobwork Outward (Issue)",
    BTOBEFF: "C",
    M01BAL: "0",
    M01GRP: "",
    M01OPPGRP: "",
    M01CT: "",
    F07ACC: "",
    LBLF08AMT: "0.00   ",
    LBLF07AMT: "0.00   ",
    LBLF08SYMB: null,
    LBLF07SYMB: null,
  });
  const [t02Data, setT02Data] = useState([]);
  const [t02DataENT, setT02DataENT] = useState([]);
  const [t02Obj, setT02Obj] = useState({
    FIELD01: "",
    FIELD02: "",
    FIELD03: "",
    FIELD04: "",
    FIELD05: "",
    FIELD06: 0.0,
    FIELD07: 0.0,
    FIELD08: 0.0,
    FIELD09: 0.0,
    FIELD10: 0.0,
    FIELD11: "",
    FIELD12: "",
    FIELD56: "",
    FIELD13: "",
    FIELD14: "",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: "",
    FIELD19: "",
    FIELD20: "",
    FIELD21: 0.0,
    FIELD22: 0.0,
    FIELD23: 0.0,
    FIELD24: "",
    FIELD25: 0.0,
    FIELD26: "",
    FIELD27: "",
    FIELD28: false,
    FIELD29: 0.0,
    FIELD30: "",
    FIELD31: 0.0,
    FIELD32: 0.0,
    FIELD33: 0.0,
    FIELD41: "",
    FIELD50: 0.0,
    FIELD51: "",
    FIELD52: 0.0,
    FIELD71: "",
    FIELD81: 0.0,
    FIELD82: "",
    FIELD83: "",
    FIELD84: "",
    FIELD85: "",
    FIELD86: "",
    FIELD61: 0.0,
    FIELD62: 0.0,
    FIELD63: "",
    FIELD64: 0.0,
    FIELD65: "",
    FIELD66: 0.0,
    FIELD67: 0.0,
    FIELD72: "",
    FIELD68: "",
    FIELD69: "",
    FIELD53: "",
    FIELD54: 0.0,
    FIELD55: 0.0,
    FIELD091: 1.0,
    FIELD58: "",
    FIELD57: "",
    FIELD60: 0.0,
    T41PVT: "",
    FIELD87: 0.0,
    FIELD88: "",
    FLDUNQ: 0,
    FLDAED: "",
    FLDBRC: "",
    FIELD90: 0.0,
    IA00000001: "",
    IA00000002: "",
    IA00000003: "",
    IA00000004: "",
    IA00000005: "",
    IA00000006: "",
    IA00000007: "",
    IA00000008: "",
    IA00000009: "",
    IA00000010: "",
    IA00000011: "",
    IA00000012: "",
    IA00000013: "",
    IA00000014: "",
    IA00000015: "",
    IA00000016: "",
    IA00000017: "",
    IA00000018: "",
    IA00000019: "",
    IA00000020: "",
    IA00000021: "",
    IA00000022: "",
    IA00000023: "",
    IA00000024: "",
    IA00000025: "",
    ID00000001: 0.0,
    ID00000002: 0.0,
    ID00000003: 0.0,
    ID00000004: 0.0,
    ID00000005: 0.0,
    ID00000006: 0.0,
    ID00000007: 0.0,
    ID00000008: 0.0,
    ID00000009: 0.0,
    ID00000010: 0.0,
    ID00000011: 0.0,
    ID00000012: 0.0,
    ID00000013: 0.0,
    ID00000014: 0.0,
    ID00000015: 0.0,
    ID00000016: 0.0,
    ID00000017: 0.0,
    ID00000018: 0.0,
    ID00000019: 0.0,
    ID00000020: 0.0,
    ID00000021: 0.0,
    ID00000022: 0.0,
    ID00000023: 0.0,
    ID00000024: 0.0,
    ID00000025: 0.0,
    IP00000001: 0.0,
    IP00000002: 0.0,
    IP00000003: 0.0,
    IP00000004: 0.0,
    IP00000005: 0.0,
    IP00000006: 0.0,
    IP00000007: 0.0,
    IP00000008: 0.0,
    IP00000009: 0.0,
    IP00000010: 0.0,
    IP00000011: 0.0,
    IP00000012: 0.0,
    IP00000013: 0.0,
    IP00000014: 0.0,
    IP00000015: 0.0,
    IP00000016: 0.0,
    IP00000017: 0.0,
    IP00000018: 0.0,
    IP00000019: 0.0,
    IP00000020: 0.0,
    IP00000021: 0.0,
    IP00000022: 0.0,
    IP00000023: 0.0,
    IP00000024: 0.0,
    IP00000025: 0.0,
    IT00000001: "",
    IT00000002: "",
    IT00000003: "",
    IT00000004: "",
    IT00000005: "",
    IT00000006: "",
    IT00000007: "",
    IT00000008: "",
    IT00000009: "",
    IT00000010: "",
    IT00000011: "",
    IT00000012: "",
    IT00000013: "",
    IT00000014: "",
    IT00000015: "",
    IT00000016: "",
    IT00000017: "",
    IT00000018: "",
    IT00000019: "",
    IT00000020: "",
    IT00000021: "",
    IT00000022: "",
    IT00000023: "",
    IT00000024: "",
    IT00000025: "",
    UAP: 0.0,
    UBA: "",
    UDDATE: "",
    UDIS: 0.0,
    UDISC: 0.0,
    UGRO: 0.0,
    UISSUERA: 0.0,
    UMUR: 0.0,
    UPE: 0.0,
    URATE: 0.0,
    URE: "",
    UREMARK: "",
    IA00000026: "",
    ID00000026: 0.0,
    IP00000026: 0.0,
    IT00000026: "",
    UUWQ: "",
    USERFLD: "",
    F03NM: "",
    F20NM: "",
    CATNM: "",
    GRPNM: "",
    CATCD: "",
    GRPCD: "",
    USERFLDB: "",
    SRDETAIL: "",
    EXPDUMMY: " ",
    BARCODE: "",
    OLDPRD: "",
    OLDQTY: 0.0,
    OQTY2: 0.0,
    OLDRATE: 0.0,
    LADDNEW: "Y",
    PRICELIST: " ",
    _BATSTOCK: " ",
    _LOCWISE: " ",
    LDUALSTK: " ",
    SRNOSTK: " ",
    MRPSTK: " ",
    RORI: " ",
    ORDDUMMY: " ",
    JODUMMY: " ",
    SRIDUMMY: " ",
    SRRDUMMY: " ",
    SRIDUMMYF: " ",
    SRRDUMMYF: " ",
    SRISSCD: "",
    OLDSRNO: "",
    SCNOCL: "",
    SCNOCL1: "",
    MANUDATE: "",
    EXPDATE: "",
    T02F08EXP: 0.0,
    _EXPTOTALAMT: 0.0,
  });
  const [t02Flag, setT02Flag] = useState(false);
  const [editT41, setEditT41] = useState({});
  const [editT02, setEditT02] = useState({});
  // const [columns, setColumns] = useState([]);
  const [hasFocused, setHasFocused] = useState(true);
  const [hasFocused2, setHasFocused2] = useState(false);
  const [M01PRow, setM01PRow] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [billAmt, setBillAmt] = useState(0);
  const [NarrationData, setNarrationData] = useState([]);
  const [SelectedNarrationData, setSelectedNarrationData] = useState([]);
  const [creatingModeOn, setCreatingModeOn] = useState(false);
  const [pendingIssue, setPendingIssue] = useState([]);
  const [partyData, setParty] = useState([]);
  const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
    BillNoVal: "",
    TextInput: false,
    DataGridInput: true,
  });
  const [BillNoStatus, setBillNoStatus] = useState(false);
  const [dayVal, setDayVal] = useState("");
  const [selectedProductObj, setSelectedProductObj] = useState({});
  const [editingRow, setEditingRow] = useState(null);
  const [f02, setF02] = useState(false);
  const [vDate, setVDate] = useState("");
  const [partyAcc, setPartyAcc] = useState({});
  // console.log("selectedProductObj",selectedProductObj)
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    // onClickCloseButton: () => { },
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [flagCopy, setFlagCopy] = useState(false);
  // const footerButtonArray = ["Copy","Serial No."]
  const [flag, setFlag] = useState(true);
  const [jbProcess, setJBProcess] = useState({});
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const GetYearAndDate = useSelector(
    (state) => state?.UserList?.YearAndDateSend
  );
  const { isUserFieldLoading, UserField } = useSelector(
    (store) => store?.Transaction
  );
  const {
    AccountListData: accList,
    TMFormatData: tmList,
    isLoading: AccNameisLoading,
  } = useSelector((state) => state.AccountList);
  const oYear = useSelector(
    (state) => state?.Language?.General?.oYear?.OSC?._JYSUBJOB
  );
  const { isLoading, hasError, ErrorMsg } = useSelector(
    (state) => state?.Transaction
  );
  const formVouData = useSelector(
    (state) => state?.Transaction?.TransactionData
  );
  const copyRow = useSelector((state) => state?.GetPendingIssue?.copyRow);
  const footerDate = useSelector((state) => state?.Language?.footerDate);

  const inputRef = useRef();
  const input2Ref = useRef();
  const input3Id = useRef(null);
  const formRef = useRef(null);
  const TableRef = useRef();
  const TableContainer = useRef(null);
  const columnHelper = createColumnHelper();
  const map = new Map();
  // let dataEnt;

  // const { JWListData, JWOrderData } =
  // useSelector((state) => state?.GetPendingIssue);
  let dataEnt = formVouData;
  let editData = props?.obj;
  let editId = editData?.id;

  let p0 = props?.obj.p0;
  let page = props?.obj.page;
  

  const cType = "JD"; //Temporay defined.

  const T02 = formVouData?.T02;
  const M01PDT = formVouData?.M01PDT;
  const M01P = formVouData?.M01P;
  const P_M31JDT = formVouData?.P_M31JDT;
  const P_M31J = formVouData?.P_M31J;
  const M01PRT = formVouData?.M01PRT;
  const UFT41H = formVouData?.UFT41H;
  const T92 = formVouData?.T92;
  const T92DT = formVouData?.T92DT;
  const T41 = formVouData?.T41;
  const lEditVou = formVouData?.lEditVou;
  const OS11 = formVouData?.OS11;
  const OSV = formVouData?.OSV;
  console.log("OSV?._VOUNUMBER",OSV?._VOUNUMBER)
  // console.log(T92DT, "P_M31JDT--", UFT41H);
  let UFT41Hval = UFT41H?.filter((v) => v?.S13F87 == "HEADER");
  let UFT41Fval = UFT41H?.filter((v) => v?.S13F87 == "FOOTER");
  const dispatch = useDispatch();
  const [rowSelection, setRowSelection] = useState({});
  const [HeaderExpenseFlag, setHeaderExpenseFlag] = useState(false);
  const [pagecCode, setPagecCode] = useState("");
  const [isHandleCreateNeeded, setIsHandleCreateNeeded] = useState("");

  const [footerButtonArray, setFooterButtonArray] = useState([]);
  useEffect(() => {
    setFooterButtonArray(["Copy", "Serial No."]);
  }, []);

  //pre filled fields

  useEffect(() => {
    if (vDate?.length > 0 && p0 == "A") {
      setGroup({ ...group, FIELD02: vDate });
    }
  }, [vDate,group?.FIELD02]);

  useEffect(() => {
    if (Object.keys(jbProcess).length != 0 && p0 == "A" && group?.FIELD34=="") {
      setGroup({
        ...group,
        FIELD34: jbProcess?.FIELD34,
        FIELD34NM: jbProcess?.FIELD34NM,
      });
    }
  }, [jbProcess,group?.FIELD34]);

  useEffect(() => {
    if (Object.keys(partyAcc).length != 0 && p0 == "A" && group?.FIELD04=="") {
      console.log("partyAcc",partyAcc)
      setGroup({
        ...group,
        FIELD04: partyAcc?.FIELD04,
        FIELD04NM: partyAcc?.FIELD04NM,
      });
    }
  }, [partyAcc,group?.FIELD04]);

  useEffect(() => {
    // console.log(editId, "editId", obj);

    Promise.resolve(
      Store.dispatch(
        TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 })
      )
    )
      // .then(() =>
      //   dispatch(
      //     GetAccountList({
      //       id: "T_M32C",
      //       name: "",
      //       type: "A",
      //       p0: "T_M32C",
      //       p1: "T_M32C",
      //       p2: "",
      //       p3: "",
      //     })
      //   )
      // )
      // .then(() =>
      //   dispatch(
      //     GetTMFormat({
      //       id: "T_M32C",
      //       name: "",
      //       type: "A",
      //       p0: "T_M32C",
      //       p1: "T_M32C",
      //       p2: "",
      //       p3: "",
      //     })
      //   )
      // )
      .then(() => {
        dispatch(
          GetAccountList({
            id: "P_M21",
            name: "",
            type: "A",
            p0: "P_M21",
            p1: "P_M21",
            p2: "",
            p3: "",
          })
        );
      })
      .then(() => setHasFocused(true));

      if (obj?.cSTID === "TXNVOULISTJX") {
        setPagecCode("JXJX")
      }
      else if (obj?.cSTID === "TXNVOULISTJY") {
        setPagecCode("JYJY")
      }
      else if (obj?.cSTID === "TXNVOULISTJW") {
        setPagecCode("JWJW")
      }

  }, [obj]);

  useEffect(() => {
    if (UFT41H && p0 == "E") {
      UFT41H?.forEach((item) => {
        if (item?.S13F14 == "PLPOPUP") {
          const key = seperate(item.S13F09)[1]; // Key
          const value = T41?.[item.S13F02]; // Value
          console.log("key here", key);
          console.log("group", T41);
          console.log("[item.S13F02]", [item.S13F02]);
          console.log("value here", T41?.[item.S13F02]);
          // }
          if (map.has(key)) {
            // If it exists, add the value to the set/array of values for that key
            map.get(key).add(value); // Use a Set to ensure uniqueness of values
          } else {
            map.set(key, new Set([value]));
          }
        }
      });

      if (map?.size > 0) {
        console.log("map?.size", map?.size);
        map.forEach((value, key) => {
          console.log("Array.from(value)", value, "key", key);
          const dyValue = Array.from(value).length
            ? "'" + Array.from(value).join("', '") + "'"
            : "";
          console.log("dyValue", dyValue);
          setTimeout(() => {
            Promise.resolve(() => {
              dispatch(
                GetAccountList({
                  id: key,
                  name: "",
                  type: "A",
                  p0: key,
                  p1: key,
                  p2: "",
                  p3: "",
                  cWhere: `${seperate1(key)[1]}.FIELD01 IN (${dyValue})`,
                })
              );
            }).then(() => {
              dispatch(
                GetTMFormat({
                  id: key,
                  name: "",
                  type: "A",
                  p0: key,
                  p1: key,
                  p2: "TFORM0000001",
                  p3: "",
                })
              );
            });
          }, [500]);
        });
      }
    }
  }, [UFT41H]);
  // useEffect(() => {
  //   console.log(hasError, "hasError-");
  //   if (hasError) {
  //     Promise.resolve(Store.dispatch(ModalDelete(index))).then(() => {
  //       Store.dispatch(DataSaveLoading(false));

  //     });
  //   }
  // }, [hasError]);

  // console.log("T02OBJ",t02Obj);
  const rowSum = t02Data.reduce((a, b) => {
    if (b?.FIELD05 == "C") a = a + (b?.FIELD08 || 0);
    else if (b?.FIELD05 == "D") a = a - (b?.FIELD08 || 0);
    return a;
  }, 0);

  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);
  useEffect(() => {
    let date =
      f02 || p0 == "E"
        ? group?.FIELD02
        : vDate?.length !== 0
        ? vDate
        : dateFormater(footerDate);
    // console.log(date, "group?.FIELD02");
    if (date) {
      const year = parseInt(date.substr(0, 4));
      const month = parseInt(date.substr(4, 2)) - 1; // Month is zero-based in JavaScript Date
      const day = parseInt(date.substr(6, 2));
      const dateObject = new Date(year, month, day);
      // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const dayOfWeek = dateObject.toLocaleString("en-US", { weekday: "long" });
      setDayVal(dayOfWeek?.slice(0, 3));
    }
  }, [footerDate, vDate]);
  useEffect(() => {
    if (hasFocused) {
      inputRef?.current?.handlePopoverToggle();
      setHasFocused(false);
      // setHasFocused2(true);
    }
  }, [hasFocused]);

  useEffect(() => {
    if (T02) {
      setT02Flag(true);
      setT02Data(T02);
      // setT02Data(T02?.filter((t) => t?.FIELD03 != "" && t?.FIELD03 != null));
      if (obj.p0 == "E") {
        setT02Obj(T02?.[T02?.length - 1]);
      } else {
        setT02Obj(T02?.[0]);
      }
    }
  }, [T02]);

  useEffect(() => {
    if (creatingModeOn == true) {
      setT02Obj({
        ...t02Obj,
        FIELD05: "R,C".includes(OS11?.S11F22) ? "C" : "D",
      });
    }
  }, [creatingModeOn, OS11?.FIELD22]);
  useEffect(() => {
    console.log(OS11?.S11F01, "OS11?.S11F01=");
    if (OS11?.S11F01 && obj?.p0 == "U") {
      dispatch(GetUserFieldObj(OS11?.S11F01));
    }
  }, [OS11?.S11F01, obj]);
  useEffect(() => {
    // console.log(hasFocused2, "hasFocused2");
    if (hasFocused2) {
      input2Ref?.current?.handlePopoverToggle();
    }
  }, [hasFocused2]);

  useEffect(() => {
    // console.log(dataEnt?.T41, "dataEnt");
    setGroup({...formVouData?.T41,FIELD02:vDate?.length>0 && p0=="A" ? vDate : formVouData?.T41?.FIELD02});
    setT02DataENT(formVouData?.T02ENT);
  }, [formVouData]);
  useEffect(() => {
    if (obj.p0 == "E") {
      setM01PRow(M01PRT);
    }
  }, [M01PRT]);
  const seperate = (e) => {
    var paraArr = e.split("~C~");
    console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const seperate1 = (e) => {
    var paraArr = e.split("_");
    // console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };
  // console.log("paraArr=>", dataEnt);
  function NarrationBox() {
    setFlag(false);
    // if(flag )
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Naration</Text>,
      body: (
        <PLNarration
          NarrationArray={NarrationData}
          Flag={SelectedNarrationData?.length > 0 ? false : true}
          selectedNarrationArray={SelectedNarrationData}
          // NarrationVal={group}
          setNarrationArray={(e) => {
            // setGroup(e)
            console.log("setNarrationArray", e);
            setNarrationData(e.NarrationData);
            setSelectedNarrationData(e.selectedNarration);
          }}
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => setDrawerObj(e)}
        />
      ),
      open: true,
      size: "md",
      position: "right",
    });
  }
  function UFT41HFieldRenderer(e) {
    if (e == "Header") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((h) => {
          // console.log("User Field=>", h?.S13F14, h?.S13F07, h?.S13F02);
          if (h?.S13F87 == "HEADER") {
            //b?.S13F14
            //console.log("UFT41HRenderHeader==>",h);
            return (
              <>
                {h?.S13F14 == "TEXTBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "NUMBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLNumberBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "PLPOPUP" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDataGrid
                        width={"500px"}
                        name={h.S13F02}
                        value={{
                          value: group?.[h?.S13F02],
                          label: accList?.[seperate(h.S13F09)[1]]?.filter(
                            (a) => a?.FIELD01 == group?.[h?.S13F02]
                          )?.[0]?.[
                            h?.S13F88 == "H"
                              ? "FIELD01"
                              : "FIELD02" in
                                (accList?.[seperate(h.S13F09)[1]]?.filter(
                                  (a) => a?.FIELD01 === group?.[h?.S13F02]
                                )?.[0] || {})
                              ? "FIELD02"
                              : tmList[seperate(h?.S13F09)[1]]?.oS60?.cAssignFld
                          ],
                        }}
                        p0={seperate(h.S13F09)[0]}
                        p1={seperate(h.S13F09)[1]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e);
                          setGroup({ ...group, [h?.S13F02]: e?.FIELD01 });
                          // M01handlechange({ id: item.S13F02, value: e })
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "COMBOBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        value={group?.[h?.S13F02]}
                        copno={seperate(h?.S13F09)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e?.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "DATEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        defaultval={
                          group?.[h?.S13F02]?.trim() != ""
                            ? group?.[h?.S13F02]
                            : GetYearAndDate?.strtDate
                        }
                        dispformat="DD/MM/YYYY"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          let { startDate } = DateToString(e);
                          if (startDate?.length == 8) {
                            setGroup({ ...group, [h?.S13F02]: e });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "TIMEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        // value={new Date(group?.[h?.S13F02]).getTime()}
                        dispformat="DD/MM/YYYY"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "N" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "H" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "I" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "A" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}
              </>
            );
          }
        })
      );
    } else if (e == "Footer") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((f) => {
          if (f?.S13F87 == "FOOTER") {
            //b?.S13F14
            return (
              <>
                {f?.S13F14 == "TEXTBOX" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "NUMBOX" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLNumberBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "PLPOPUP" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDataGrid
                          width={"500px"}
                          value={{
                            value: group?.[f?.S13F02],
                            label: accList?.[seperate(f.S13F09)[1]]?.filter(
                              (a) => a?.FIELD01 == group?.[f?.S13F02]
                            )?.[0]?.[
                              f?.S13F88 == "H"
                                ? "FIELD01"
                                : "FIELD02" in
                                  (accList?.[seperate(f.S13F09)[1]]?.filter(
                                    (a) => a?.FIELD01 === group?.[f?.S13F02]
                                  )?.[0] || {})
                                ? "FIELD02"
                                : tmList[seperate(f?.S13F09)[1]]?.oS60
                                    ?.cAssignFld
                            ],
                          }}
                          p0={seperate(f.S13F09)[0]}
                          p1={seperate(f.S13F09)[1]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e?.FIELD01 });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "COMBOBOX" && (
                  <>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                     
                      <PLComboBox
                        value={group?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col> */}
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLComboBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "DATEPICKER" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDateBox
                          defaultval={
                            group?.[f?.S13F02]?.trim() != ""
                              ? group?.[f?.S13F02]
                              : GetYearAndDate?.strtDate
                          }
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            let { startDate } = DateToString(e);
                            if (startDate?.length == 8) {
                              setGroup({ ...group, [f?.S13F02]: e });
                            }
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    
                      <PLDateBox
                        // value={new Date(group?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f?.S13F14 == "TIMEPICKER" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDateBox
                          // value={new Date(group?.[f?.S13F02]).getTime()}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                     
                      <PLDateBox
                        // value={new Date(group?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "N" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                 
                      <PLTextBox
                        value={group?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "H" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "I" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "A" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}
              </>
            );
          }
        })
      );
    }
  }

  let stringOfNames;

  useEffect(() => {
    if (SelectedNarrationData.length > 0) {
      const stringifyArray = SelectedNarrationData?.map((a) => a.FIELD01);
      stringOfNames = stringifyArray.join(" ");
      // console.log("stringOfNames", stringOfNames);
    }
    setGroup({
      ...group,
      FIELD15:
        group?.FIELD15 != ""
          ? group?.FIELD15 + " " + stringOfNames
          : stringOfNames,
    });
    // setGroup({...group,FIELD15:})
  }, [stringOfNames, SelectedNarrationData]);

  function DateToString(e) {
    // console.log(e, "Datetostring");
    let year = e.toLocaleString("en-IN", { year: "numeric" });
    let month = e.toLocaleString("en-IN", {
      month: "2-digit",
    });
    let day = e.toLocaleString("en-IN", { day: "2-digit" });

    let formatedStartDate = day + "/" + month + "/" + year;
    let startDate = year + month + day;
    return { formatedStartDate, startDate };
  }

  function dateFormater(date) {
    let year = date.toLocaleString("en-IN", { year: "numeric" });
    let month = date.toLocaleString("en-IN", { month: "2-digit" });
    let day = date.toLocaleString("en-IN", { day: "2-digit" });
    let date1 = year + month + day;
    return date1;
  }

  let compareTwoArrayOfObjects = (editT02, t02Data) => {
    return (
      editT02?.length === t02Data?.length &&
      editT02?.every((v) =>
        t02Data?.some((c) => Object.keys(v).every((key) => v[key] === c[key]))
      )
    );
  };
  function dispatchFunction() {
    Promise.resolve(Store.dispatch(DataSaveLoading(false)))
      .then(() => {
        if (p0 == "E") {
          if(String(props?.index).startsWith("modalForm")){
            dispatch(TabRemove(props?.index))
          }else{
            dispatch(ModalDelete(props.index))
          }
        } else
          Promise.resolve(Store.dispatch(TransactionApi(obj))).then(() => {
            setF02(false);
          });
      })
      .then(() => {
        setBillSeriesHideShow({
          ...BillSeriesHideShow,
          BillNoVal: "",
        });
        // GlobalClass.Notify(
        //   "success",
        //   p0 == "A" ? "Added" : "Edited",
        //   p0 == "A" ? "Added successfully" : "Edited successfully"
        // );
      })
      .then(() => {
        Store.dispatch(RefreshIssuePopUp({}));
      })
      .then(() => {
        Store.dispatch(RefreshIOrderPopUp({}));
      });
    let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;

    dispatch(GetAccountList({...obj?.BtnPnlObj,pagination: PaginationObj[obj?.cSTID]}))

    // console.log(index, "ModalDelete");
    // Store.dispatch(ModalDelete(index));
    // if (obj?.cSTID == "TXNVOULISTJX") {
    //   Promise.resolve(
    //     Store.dispatch(
    //       GetAccountList({
    //         id: "04200417",
    //         name: "Receipt",
    //         p0: "MT",
    //         p1: "TXNVOULISTJX",
    //         p2: "",
    //         p3: "",
    //         type: "A",
    //         pagination: PaginationObj["04200417"],
    //       })
    //     )
    //   );
    // } else if (obj?.cSTID == "TXNVOULISTJY") {
    //   Promise.resolve(
    //     Store.dispatch(
    //       GetAccountList({
    //         id: "04260417",
    //         name: "Issue",
    //         p0: "MT",
    //         p1: "TXNVOULISTJY",
    //         p2: "",
    //         p3: "",
    //         type: "A",
    //         pagination: PaginationObj["04260417"],
    //       })
    //     )
    //   );
    // } else if (obj?.cSTID == "TXNVOULISTJW") {
    //   Promise.resolve(
    //     Store.dispatch(
    //       GetAccountList({
    //         id: "04230417",
    //         name: "Production",
    //         p0: "MT",
    //         p1: "TXNVOULISTJW",
    //         p2: "",
    //         p3: "",
    //         type: "A",
    //         pagination: PaginationObj["04230417"],
    //       })
    //     )
    //   );
    // }
  }
  function OnPrint() {
    Promise.resolve(dispatch(DataSaveLoading(false))).then(() => {
      setDrawerObj({
        ...dra,
        title: <Text fw={700}>Print Voucher</Text>,
        onClickCloseButton: () => {
          // setDrawerObj(dra);
          Promise.resolve(
            setDrawerObj({
              ...DrawerObj,
              open: false,
            })
          ).then(() => dispatchFunction());
        },
        onClose: () => {
          // setDrawerObj(dra);
          Promise.resolve(
            setDrawerObj({
              ...DrawerObj,
              open: false,
            })
          ).then(() => dispatchFunction());
        },
        body: (
          <>
            <PLPrint data={obj} />
          </>
        ),
        open: true,
        size: "sm",
        position: "right",
      });
    });
  }
  function OnWhtsUp() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>WhatsApp </Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        console.log(OSV._ONLINEVOU, "OSV._ONLINEVOU");
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      onClose: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      body: (
        <>
          <FormatListTable
            obj={{ ...obj, p0: "W", text: "WhatsApp" }}
            index={props.index}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  }
  function OnSMS() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>SMS </Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        console.log(OSV._ONLINEVOU, "OSV._ONLINEVOU");
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      onClose: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      body: (
        <>
          <FormatListTable
            obj={{ ...obj, p0: "S", text: "SMS" }}
            index={props.index}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  }
  function OnEmail() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>EMail </Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        console.log(OSV._ONLINEVOU, "OSV._ONLINEVOU");
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEWAP == "Y") {
              OnWhtsUp();
            } else if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      onClose: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEWAP == "Y") {
              OnWhtsUp();
            } else if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      body: (
        <>
          <FormatListTable
            obj={{ ...obj, p0: "E", text: "EMail" }}
            index={props.index}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  }
  function handleOkButton(e) {
    console.log("OK BTN", p0, "editId", editId, "page", page);

    if (group?.FIELD04 == "") {
      GlobalClass.Notify(
        "warning",
        "Validation error",
        "Please fill out all the required fields"
      );
    } else {
      let newT02 = T02?.filter((v, i) => v?.FIELD03 !== "");

      if (obj.p0 == "E" && compareTwoArrayOfObjects(newT02, t02Data) && JSON.stringify(T41) === JSON.stringify(group)) {
        Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>{
          if(String(props?.index).startsWith("modalForm")){
            dispatch(TabRemove(props?.index))
          }else{
            dispatch(ModalDelete(props.index))
          }})
        return;
      } else {
        Promise.resolve(Store.dispatch(DataSaveLoading(true))).then(() =>
          GlobalClass.Notify(
            "info",
            p0 == "A" ? "Adding" : "Editing",
            "Please wait while we process your data"
          )
        );

        let newT41 = {
          ...group,
          FIELD06: group?.FIELD07 == 0 ? rowSum : group?.FIELD07,
        };

        let FilterT02Data = t02Data?.filter((t) => t?.FIELD03 != "");

        let dataToSend = { T41: e ? e : { ...newT41 }, T02: FilterT02Data };

        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: props ? p0 : "A",
          cCode:
            p0 == "E"
              ? editId
              : obj?.cSTID == "TXNVOULISTJX"
                ? "JXJX"
                : obj?.cSTID == "TXNVOULISTJY"
                  ? "JYJY"
                  : obj?.cSTID == "TXNVOULISTJW"
                    ? "JWJW"
                    : "",
          cSData: JSON.stringify(dataToSend),
        };
        // console.log(dataToSend, "data Num");
        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: p0 == "E" ? p0 : "A",
          cCode:
            p0 == "E"
              ? editId
              : obj?.cSTID == "TXNVOULISTJX"
                ? "JXJX"
                : obj?.cSTID == "TXNVOULISTJY"
                  ? "JYJY"
                  : obj?.cSTID == "TXNVOULISTJW"
                    ? "JWJW"
                    : "",
        });

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        // console.log(dataToSend, "param Stock Journal");
        // try {
        fetch(
          GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
          requestOptions
        )
          .then((response) => {
            // console.log(!response.ok, "response.ok");
            if (!response?.ok) {
              throw new Error(`HTTP error! Status: ${response?.status}`);
            } //This block give more detail info about error other then 2xx status codes.
            return response?.json();
          })
          .then((data) => {
            // console.log("api data=>", data);
            // console.log("api data status=>", data?.status);

            if (data?.status == "SUCCESS") {
              GlobalClass.Notify(
                "success",
                p0 == "A" ? "Added" : "Edited",
                p0 == "A" ? "Added successfully" : "Edited successfully"
              );

              Promise.resolve(
                OSV._ONLINEMAL == "Y" || OSV._ONLINEMAL == "L"
                  ? Promise.resolve(dispatch(DataSaveLoading(false))).then(
                      () => {
                        OnEmail();
                      }
                    )
                  : OSV._ONLINEWAP == "Y" || OSV._ONLINEWAP == "L"
                  ? Promise.resolve(dispatch(DataSaveLoading(false))).then(
                      () => {
                        OnWhtsUp();
                      }
                    )
                  : OSV._ONLINESMS == "Y" || OSV._ONLINESMS == "L"
                  ? Promise.resolve(dispatch(DataSaveLoading(false))).then(
                      () => {
                        OnSMS();
                      }
                    )
                  : Promise.resolve(
                      setDrawerObj({ ...DrawerObj, open: false })
                    ).then(() => {
                      if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                        OnPrint();
                      } else {
                        dispatchFunction();
                      }
                    })
              );
            } else if (data?.status == "FAIL") {
              console.log(data?.status, "data?.status");
              Promise.resolve(Store.dispatch(DataSaveLoading(false))).then(
                () => {
                  setT02Data(t02Data);
                  GlobalClass.Notify("error", data?.status, data?.message);
                }
              );
            }
          })
          .catch((e) => {
            console.log("inside catch", e);
            GlobalClass.Notify("error", "Error", `${e.message}`);
            Promise.resolve(Store.dispatch(DataSaveLoading(false))).then(() => {
              setT02Data(t02Data);
            });
          });
      }
    }
  }
  useEffect(() => {
    if (M01P?.length > 0) {
      setParty(M01P);
    } else if (M01P?.jData?.length > 0) {
      setParty(M01P?.jData);
    } else {
      setParty([]);
    }
  }, [M01P]);

  const spoilerControlRef = useRef();
  const vouNoRef = useRef();
  // useEffect(() => {
  //   if (BillSeriesHideShow.BillNoVal.length <= 0) {
  //     setBillSeriesHideShow({
  //       ...BillSeriesHideShow,
  //       DataGridInput: true,
  //       TextInput: false,
  //     });
  //   }
  // }, [BillSeriesHideShow.BillNoVal]);
  return (
    <>
      {hasError ? (
        <>
          <Text>{ErrorMsg?.message}</Text>
          <Button
            onClick={() => {
              if (obj) {
                Promise.resolve(
                  dispatch(
                    TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 })
                  )
                )
                  .then(() => {
                    dispatch(
                      GetAccountList({
                        id: "P_M21",
                        name: "",
                        type: "A",
                        p0: "P_M21",
                        p1: "P_M21",
                        p2: "",
                        p3: "",
                      })
                    );
                  })
                  .then(() => {
                    if (obj?.p0 == "U") {
                      dispatch(GetUserFieldObj(OS11?.S11F01));
                    }
                  });
              }
            }}
          >
            Retry
          </Button>
        </>
      ) : SaveLoading == true || isLoading || isUserFieldLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : obj?.p0 == "U" ? (
        <>
          <PLUserFieldBtn
            UserFieldArray={UserField?.UFTbl}
            T41={group}
            onChange={(e) => {
              // Promise.resolve(setGroup(e)).then(()=>
              handleOkButton(e);
              // )
            }}
          />
        </>
      ) : (
        <div ref={formRef}>
          <Modal
            opened={DrawerObj?.open}
            // onClose={close}
            withCloseButton={() => {}}
            fullScreen={false} //
            // overlayProps={{ opacity: 0.5, blur: 4 }}
            closeButtonProps={{
              onClick: (e) => {
                if (typeof DrawerObj?.onClickCloseButton == "function") {
                  DrawerObj?.onClickCloseButton();
                } else {
                  setDrawerObj(dra);
                }
              },
            }}
            title={DrawerObj?.title}
            size={DrawerObj?.size}
            // centered={.Centered}
            closeOnClickOutside={true}
            // overlayProps={m.Overlay}
            position={DrawerObj?.position ?? "bottom"}
            onClose={
              typeof DrawerObj?.onclose == "function"
                ? DrawerObj?.onclose
                : () => {
                    setDrawerObj(dra);
                  }
            }
            // scrollAreaComponent={ScrollArea.Autosize}
          >
            {DrawerObj.body}
          </Modal>
          <Paper shadow="md" radius="md" p="xs" withBorder>
            <Grid gutter={4}>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0",
                  marginTop: "10",
                  marginBottom: "0",
                }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Party A/c.</Text>
                  </Grid.Col>
                  <Grid.Col span={9} style={{ overflow: "hidden" }}>
                    <PLDataGrid
                      width={"500px"}
                      ref={inputRef}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      value={
                        Object.keys(partyAcc).length == 0
                          ? { value: group?.FIELD04, label: group?.FIELD04NM }
                          : {
                              value: partyAcc?.FIELD04,
                              label: partyAcc?.FIELD04NM,
                            }
                      }
                      TmData={M01P}
                      // data={partyData}
                      dispexpr="FIELD02"
                      valexpr="FIELD01"
                      setEdit={(e) => {
                        // console.log("handleClose", e);
                        Promise.resolve(
                          setGroup({
                            ...group,
                            FIELD04: e?.FIELD01,
                            FIELD04NM: e?.FIELD02,
                          })
                        )
                          .then(() => {
                            setPartyAcc({
                              FIELD04: e?.FIELD01,
                              FIELD04NM: e?.FIELD02,
                            });
                          })
                          .then(() => {
                            // console.log(e.FIELD01, "group?.FIELD04?.length");
                            if (e?.FIELD01?.length > 1) setHasFocused2(true);
                          });
                        setM01PRow(e);
                      }}
                      handleClose={(e) => {
                        // console.log("handleClose", e);
                        // M48Dhandlechange(e);
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{props?.date}</Text>
                  </Grid.Col>
                  <Grid.Col span={7}>
                    <PLDateBox
                      ref={input3Id}
                      style={{ margin: "0", padding: "0" }}
                      onKeyDown={(e) => {
                        if(e?.key=="Tab"){
                          if (OSV?._VOUNUMBER == "Y" || OSV?._VOUNUMBER==undefined) {
                            if (vouNoRef?.current) {
                              vouNoRef?.current?.handlePopoverToggle();
                            } else {
                              document?.getElementById("vouRef")?.focus();
                            }
                          } else if (OSV?._DOCNODATE == "Y" || OSV?._DOCDATE=="Y") {
                            document?.getElementById("docNo")?.focus();
                          }
                        }
                      }}
                      dispformat="DD/MM/YYYY"
                      setEdit={(e) => {
                        // console.log("Stocke PLDateBox", e);
                        let { startDate } = DateToString(e);
                        // console.log(startDate, "startDateinpl");
                        setF02(true);
                        Promise.resolve(
                          startDate?.length == 8 &&
                            setGroup({ ...group, FIELD02: startDate })
                        )
                          .then(() => {
                            setVDate(startDate);
                          })
                          .then(() => {
                            if (OSV?._VOUNUMBER == "Y" || OSV?._VOUNUMBER==undefined) {
                              if (vouNoRef?.current) {
                                vouNoRef?.current?.handlePopoverToggle();
                              } else {
                                document?.getElementById("vouRef")?.focus();
                              }
                            } else if (OSV?._DOCNODATE == "Y" || OSV?._DOCDATE=="Y") {
                              document?.getElementById("docNo")?.focus();
                            }
                          });
                      }}
                      defaultval={
                        f02 || p0 == "E"
                          ? group?.FIELD02
                          : vDate?.length !== 0
                          ? vDate
                          : dateFormater(footerDate)
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={1} style={{ color: "red" }}>
                    <Text size={12}>{dayVal}</Text>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Doc No.</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLTextBox
                      id="docNo"
                      // disabled={true}
                      disabled={OSV?._DOCNODATE == "N" || OSV?._DOCDATE=="N"}
                      onKeyDown={(e) => {
                        console.log("e here", e);
                        if (e?.key == "Tab") {
                          document?.getElementById("docDate")?.focus();
                        }
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      value={group?.FIELD10}
                      setEdit={(e) => {
                        setGroup({ ...group, FIELD10: e?.target?.value });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0",
                  marginTop: "0",
                  marginBottom: "0",
                }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}></Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <Text size={12}>
                      {" "}
                      Balance : {M01PRow != null
                        ? M01PRow?.FIELD99
                        : null} DB{" "}
                    </Text>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{props?.No}</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                  {
                                    obj?.p0 == "E" ?
                                      <Grid.Col span={12}>
                                        <PLTextBox
                                          disabled={OSV?._VOUNUMBER=="N"}
                                          onKeyDown={(e) => {
                                            GlobalClass.formNextInput(e, formRef);
                                          }}
                                          setText={(e) => {
                                            if (e?.target?.value?.length > 0) {
                                              if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.FIELD12 == e?.target?.value)?.length > 0) {
                                                console.log("Duplicate Value log call");
                                                GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                setBillNoStatus(true)
                                              } else {
                                                setBillNoStatus(false)
                                              }
                                            } else {
                                              setBillNoStatus(false)
                                            }
                                          }}
                                          value={group?.FIELD12}
                                          setEdit={(e) => {
                                            console.log("setEdit=>", e);
                                            setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                          }}
                                        />
                                      </Grid.Col> :
                                      <Grid.Col span={12}>
                                        {
                                          T92 ? <>
                                            {
                                              BillSeriesHideShow?.DataGridInput == true && <>
                                                {
                                                  !group?.T92F00 ?
                                                    <PLDataGrid
                                                    disabled={OSV?._VOUNUMBER=="N"}
                                                      ref={vouNoRef}
                                                      onKeyDown={(e) => {
                                                        GlobalClass.formNextInput(e, formRef);
                                                      }}
                                                      width={"400px"}
                                                      position={"unset"}
                                                      valexpr={"FIELD00"}
                                                      // width={350} 
                                                      value={BillSeriesHideShow?.BillNoVal}
                                                      TmData={T92}
                                                      // data={T92DT}
                                                      setEdit={(e) => {
                                                        console.log("Bill No.=>", e)
                                                        // Promise.resolve(setGroup({ ...group, T92F00: e.FIELD01 })).then(()=>{
                                                        //   setBillSeriesHideShow({...BillSeriesHideShow,DataGridInput:false,TextInput:true,BillNoVal:e.FIELD01})
                                                        // })
                                                        //"FIELD00"
                                                        Promise.resolve(setGroup({ ...group, T92F00: e.FIELD00, FIELD12: "" })).then(() => {
                                                          if (e) {
                                                            var letr = e.FIELD02
                                                            console.log("num=>", num);
                                                            var num = parseInt(e.FIELD03) + 1
                                                            setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: false, TextInput: true, BillNoVal: letr + num })
                                                          }
                                                        })

                                                      }}
                                                    /> : <PLTextBox
                                                    disabled={OSV?._VOUNUMBER=="N"}
                                                    setText={(e) => {
                                                      // console.log("bill number setText 1", [{
                                                      //   "e": e,
                                                      //   "value": e?.target?.value
                                                      // }]);
                                                      if (e?.target?.value?.length > 0) {
                                                        if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                          console.log("Duplicate Value log call");
                                                          GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                          setBillNoStatus(true)
                                                        } else {
                                                          setBillNoStatus(false)
                                                        }
                                                      } else {
                                                        setBillNoStatus(false)
                                                      }
                                                    }}
                                                      onKeyDown={(e) => {
                                                        GlobalClass.formNextInput(e, formRef);
                                                      }}
                                                      value={group?.FIELD12}
                                                      // value={group?.T92F00}
                                                      setEdit={(e) => {
                                                        console.log("setEdit=>", e);
                                                        // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                                        setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                                      }}
                                                    />
                                                }
                                              </>
                                            }
                                            {BillSeriesHideShow?.TextInput == true && <PLTextBox
                                            disabled={OSV?._VOUNUMBER=="N"}
                                                setText={(e) => {
                                                  // console.log("bill number setText 2", [{
                                                  //   "e": e,
                                                  //   "value": e?.target?.value
                                                  // }]);
                                                  if (e?.target?.value?.length > 0) {
                                                    if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.T41VNO == e?.target?.value)?.length > 0) {
                                                      console.log("Duplicate Value log call");
                                                      GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                      setBillNoStatus(true)
                                                    } else {
                                                      setBillNoStatus(false)
                                                    }
                                                  } else {
                                                    setBillNoStatus(false)
                                                  }
                                                  if (BillSeriesHideShow.BillNoVal.length <= 0) {
                                                    setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
                                                  }
                                                }}
                                              onKeyDown={(e) => {
                                                GlobalClass.formNextInput(e, formRef);
                                              }}
                                              value={BillSeriesHideShow?.BillNoVal}
                                              // value={group?.T92F00}
                                              setEdit={(e) => {
                                                console.log("setEdit=>", e);
                                                setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                                setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                              }}
                                            />
                                            }
                                          </> :
                                            < PLTextBox
                                            disabled={OSV?._VOUNUMBER=="N"}
                                            setText={(e) => {
                                              // console.log("bill number setText 3", [{
                                              //   "e": e,
                                              //   "value": e?.target?.value
                                              // }]);
                                              if (e?.target?.value?.length > 0) {
                                                if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                  console.log("Duplicate Value log call");
                                                  GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                  setBillNoStatus(true)
                                                } else {
                                                  setBillNoStatus(false)
                                                }
                                              } else {
                                                setBillNoStatus(false)
                                              }
                                            }}
                                            value={BillSeriesHideShow?.BillNoVal}
                                            setEdit={(e) => {
                                              console.log("setEdit=>", e);
                                              setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                              // setGroup({ ...group, T92F00: "", FIELD12: e?.target?.value })
                                              // if(EntryPages?.PurchaseEntry?.includes(obj?.page) || EntryPages?.PurchaseEntry?.includes(obj?.id)){
                                              //   setGroup({ ...group, T92F00: "", FIELD12: e.target.value, FIELD10: e.target.value })
                                              // }else{
                                              setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                              // }
                                            }}
                                              onKeyDown={(e) => {
                                                GlobalClass.formNextInput(e, formRef);
                                              }}
                                            />
                                        }
                                      </Grid.Col>
                                  }
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Doc Date</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDateBox
                      // disabled={true}
                      id="docDate"
                      disabled={OSV?._DOCNODATE == "N" || OSV?._DOCDATE=="N"}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      style={{ margin: "0", padding: "0" }}
                      dispformat="DD/MM/YYYY"
                      setEdit={(e) => {
                        // console.log("e PLDateBox", e);
                        let { startDate } = DateToString(e);
                        // console.log(startDate, "startDateinpl");
                        if (startDate?.length == 8) {
                          setGroup({ ...group, FIELD11: startDate });
                        }
                      }}
                      defaultval={group?.FIELD11?.trim()}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
              {obj?.cSTID == "TXNVOULISTOJ" ? (
                ""
              ) : (
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Jobwork Process</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDataGrid
                      ref={input2Ref}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      width={"500px"}
                      isMulti={true}
                      value={
                        Object.keys(jbProcess).length == 0
                          ? { value: group?.FIELD34, label: group?.FIELD34NM }
                          : {
                              value: jbProcess?.FIELD34,
                              label: jbProcess?.FIELD34NM,
                            }
                      }
                      // value= {p0 == "E" ? {value:group?.FIELD34,label:group?.FIELD34NM} : group?.FIELD34 }
                      pid={"P_M31J"}
                      // value={group?.FIELD34}
                      TmData={P_M31J}
                      // data={P_M31JDT?.jData}
                      setEdit={(e) => {
                        // console.log("handleClose", e);
                        let arr = e?.map((v) => v?.original?.FIELD01).join();
                        let arr2 = e?.map((v) => v?.original?.FIELD02).join();
                        Promise.resolve(
                          setGroup({ ...group, FIELD34: arr, FIELD34NM: arr2 })
                        )
                          .then(() => {
                            setJBProcess({ FIELD34: arr, FIELD34NM: arr2 });
                          })
                          .then(() => setHasFocused2(false))
                          .then(() => {
                            input3Id?.current.focus();
                          });
                      }}
                      handleClose={(e) => {
                        // console.log("handleClose", e);
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}

              {/* </Grid.Col> */}
              {/* {page == "20400399" ? (
              ""
            ) :  */}
            </Grid>
            <>
              {UFT41H?.length > 0 && UFT41Hval?.length > 0 ? (
                <Divider
                  my="xs"
                  label={
                    <Button
                      variant="subtle"
                      color="dark"
                      size="xs"
                      compact
                      onFocus={() => {
                        if (
                          UFT41H?.filter((af) => af?.S13HMF == "H")?.length > 3
                        ) {
                          spoilerControlRef.current.click();
                        }
                      }}
                    >
                      User Fields
                    </Button>
                  }
                  labelPosition="center"
                  labelProps={{ component: "strong" }}
                />
              ) : null}
              <Spoiler
                maxHeight={80}
                // showLabel="Show more"
                // hideLabel="Hide"
                controlRef={spoilerControlRef}
              >
                <Grid
                  gutter={4}
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                >
                  {/* <Grid.Col span={12} style={{ display: "flex" }}> */}
                  {UFT41HFieldRenderer("Header")}
                  {/* </Grid.Col> */}
                </Grid>
              </Spoiler>
            </>

            <Divider
              // my="xs"
              mb={"xs"}
              label={
                <Button
                  variant="subtle"
                  color="dark"
                  size="xs"
                  compact
                  onFocus={() => {
                    if (UFT41H?.filter((af) => af?.S13HMF == "H")?.length > 3) {
                      spoilerControlRef.current.click();
                    }

                    // document?.activeElement?.blur()
                    console.log("TableRef =>", TableRef);
                    TableRef?.current?.focus();

                    setTimeout(() => {
                      //Pending Challan jema avtu hoy ava pages ni id che if condition ma , pending challlan ama ny avtu
                      // if (obj?.id === "02700249" || obj?.id === "02340213") {
                      //   if (PendingChallanData?.dtchln?.length > 0 && PendingChallanStatus) {
                      //     Promise.resolve(PendingChallanFun()).then(() => setPendingChallanStatus(false))
                      //   } else {
                      //     // Promise.resolve(table.setCreatingRow(true)).then(() => setT02Obj({ ...T02Obj, FIELD05: OS11?.S11F21 }))

                      //     TableContainer?.current?.focus()

                      //     // TableRef?.current?.focus()

                      //     setRowSelection({ 0: true })
                      //   }
                      // } else {
                      // Promise.resolve(table.setCreatingRow(true)).then(() => setT02Obj({ ...T02Obj, FIELD05: OS11?.S11F21 })).then(() => {
                      //   // CategoryRef?.current?.focus()
                      //   CategoryRef?.current?.handlePopoverToggle()
                      // })

                      TableContainer?.current?.focus();
                      setRowSelection({ 0: true });

                      // TableRef?.current?.focus()
                      // }
                    }, 500);
                  }}
                >
                  Products
                </Button>
              }
              labelPosition="center"
              labelProps={{ component: "strong" }}
            />

            <Grid
              gutter={4}
              // onKeyUp={(e) => {
              //   GlobalClass.formNextInput(e, formRef);
              // }}
            >
              <Grid.Col span={12} my={7}>
                {/* <MantineReactTable table={table} /> */}
                {
                  <T02Table
                    accList={accList}
                    tmList={tmList}
                    dataEnt={formVouData}
                    obj={obj}
                    setT02Data={(e) => setT02Data(e)}
                    t02Data={t02Data}
                    setIsVisible={setIsVisible}
                    setCreatingModeOn={setCreatingModeOn}
                    t41={group}
                    t02Obj={t02Obj}
                    setT02Obj={(e) => setT02Obj(e)}
                    group={group}
                    setGroup={(e) => setGroup(e)}
                    editingRow={editingRow}
                    setEditingRow={setEditingRow}
                    setSelectedProductObj={setSelectedProductObj}
                    selectedProductObj={selectedProductObj}
                    flagCopy={flagCopy}
                    setFlagCopy={(e) => {
                      setFlagCopy(e);
                    }}
                    footerButtonArray={footerButtonArray}
                    TableRef={TableRef}
                    TableContainer={TableContainer}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    setHeaderExpenseFlag={setHeaderExpenseFlag}
                    HeaderExpenseFlag={HeaderExpenseFlag}
                    isHandleCreateNeeded={isHandleCreateNeeded}
                    setIsHandleCreateNeeded={setIsHandleCreateNeeded}
                    pagecCode={pagecCode}
                    // JWListData={JWListData}
                    // JWOrderData={JWOrderData}
                  />
                }
              </Grid.Col>
            </Grid>
            {/* <Group spacing={"xs"} position="center">
              <Button
                variant="light"
                compact
                onClick={() => {
                  console.log(copyRow?.original, "tableData-", copyRow);
                  if (
                    !creatingModeOn
                  ) {
                    setFlagCopy(true);
                  }
                }}
              >
                Copy
              </Button>
              <Button variant="light" compact>
                Serial No.
              </Button>
            </Group> */}
            {UFT41Fval?.length > 0 ? (
              <>
                <Divider my={2} />
                <Grid>
                  {/* <Grid.Col span={6}> */}
                  {UFT41HFieldRenderer("Footer")}
                  {/* </Grid.Col> */}
                </Grid>
              </>
            ) : (
              ""
            )}
            <Grid
              span={12}
              my={3}
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "space-between",
              }}
            >
              <Grid.Col span={5}>
                <Text size={12}>Narration</Text>

                <PLTextarea
                  minRows={2}
                  value={group?.FIELD15}
                  onChange={(e) => {
                    // //console.log(e)
                    setGroup({ ...group, FIELD15: e.target.value });
                  }}
                  onFocus={(e) => {
                    // console.log(e, "OnFocusNarr")
                    if (OSV?._ANARRHELP == "Y" && flag) {
                      NarrationBox();
                    } else {
                      return;
                    }
                  }}
                  onKeyDown={(e) => {
                    // console.log("Narration=>", e.shiftKey);
                    if (e.ctrlKey && e.key === "F1") {
                      NarrationBox();
                      //console.log("Narration call");
                      // setFlag(false)
                      // setDrawerObj({
                      //   ...dra,
                      //   title: <Text fw={700}>Naration</Text>,
                      //   body: (
                      //     <PLNarration
                      //       NarrationArray={NarrationData}
                      //       selectedNarrationArray={SelectedNarrationData}
                      //       setNarrationArray={(e) => {
                      //         setNarrationData(e.NarrationData);
                      //         setSelectedNarrationData(e.selectedNarration);
                      //       }}
                      //       DrawerOBJ={DrawerObj}
                      //       setDrawerOBJ={(e) => {
                      //         setDrawerObj(e);
                      //       }}
                      //     />
                      //   ),
                      //   open: true,
                      //   size: "md",
                      //   position: "right",
                      // });
                    }
                  }}
                />
              </Grid.Col>

              <Grid.Col
                span={4}
                // offset={2}
              >
                {/* <Box style={{ overflow: "hidden" }}> */}
                <Group mb={3}>
                  <Grid.Col>
                    {
                      <Grid>
                        <Text style={{ fontSize: 12 }}> Total Amount: </Text>
                        <IconCurrencyRupee />
                        <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                          {/* {billAmt} */}
                          {rowSum}
                          {OS11 ? (OS11["S11F21"] == "D" ? "CR" : "DB") : ""}
                        </Text>
                      </Grid>
                    }
                  </Grid.Col>
                  <Button
                    style={{ height: "30px", width: "100px" }}
                    size="sm"
                    onClick={() =>
                      BillNoStatus
                        ? GlobalClass.Notify(
                            "warning",
                            "Warning",
                            "Voucher Number Already Present"
                          )
                        : handleOkButton()
                    }
                    disabled={
                      creatingModeOn
                      // || t02Data?.length < 1
                    }
                  >
                    Ok
                  </Button>
                  <Button
                    style={{ height: "30px", width: "100px" }}
                    size="sm"
                    onClick={OnPrint}
                  >
                    Print
                  </Button>{" "}
                </Group>
              </Grid.Col>
            </Grid>
          </Paper>
          {lEditVou == false && <> {GlobalClass.Alert("info", props.index)}</>}
        </div>
      )}
    </>
  );
}
