import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";

export const VGetSalesEntryData = createAsyncThunk(
  "VSalesEntryData/VGetSalesEntryData",
  async (obj) => {
    console.log("VSalesEntryData/VGetSalesEntryData =>",obj);
    const ent = obj?.ent
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction:
          ent?.FIELD06 == 0 &&
          ent?.FIELD07 == 0 &&
          ent?.FIELD08 == 0 &&
          ent?.FIELD09 == 0 &&
          ent?.FIELD11== "" &&
          ent?.FIELD12 == "" &&
          ent?.FIELD13 == 0 &&
          ent?.FIELD14== "" &&
          ent?.FIELD15 == "" &&
          ent?.FIELD16 == "" 
            ? "A"
            : "E",
        cCode: obj.ccode,
        cOPara:
          ["SS"].includes(obj?.obj?.cSTID) && ["G"].includes(obj?.obj?.p0)
            ? "G~C~S"
            : ["SS"].includes(obj?.obj?.cSTID) && ["V"].includes(obj?.obj?.p0)
            ? "V~C~S"
            : ["PP"].includes(obj?.obj?.cSTID) && ["V"].includes(obj?.obj?.p0)
            ? "V~C~P"
            : "G~C~P",
            "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetM52Ent + "?pa=" + param
      );
      // console.log("response of sales data",response)
      return response;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);

const VSalesEntryDataSlice = createSlice({
  name: "VSalesEntryData",
  initialState: {
    VSalesEnrtyData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(VGetSalesEntryData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(VGetSalesEntryData.fulfilled, (state, action) => {
        // console.log('VGETSALESentryDATA==>',action.payload?.error);
        if (!action.payload?.error) {
          state.VSalesEnrtyData = action.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.VSalesEnrtyData = action.payload;
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(VGetSalesEntryData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
        state.ErrorMsg = action.payload?.error;
      });
  },
});
export default VSalesEntryDataSlice.reducer;
