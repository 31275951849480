import { Button, Drawer, Grid, Group, Modal, Paper, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDelete, ModalFunction } from '../../utils/slices/ModalSlice';
import { PLFilter } from './PLFilter';
import { DeleteFilter, GetFilterData, GetM47AED, getFilterStatus, getFiltersDataNull, getFiltersName } from '../../utils/FilterSlice/FilterSlice';
import { GetAccountList, SetAccountListData } from '../../utils/slices/AccountListSlice';
import { GetAccountListForPrint } from '../../utils/PrintSlice/PrintSlice';
import GlobalClass from '../../utils/GlobalClass';
import useWindowDimensions from '../../utils/UseWindowDimensions';
import gensetting from '../../utils/gensetting';
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice';
import Store from '../../utils/store';


function PLModal(props) {

    console.log("props", props);
    const { OBJ } = props
    const { height, width } = useWindowDimensions();
    const dispatch = useDispatch();
    const FiltersName = useSelector(store => store?.FilterSlice);
    const filterData = useSelector((state) => state.FilterSlice?.Data)
    // console.log("filterData", filterData)
    const M47List = useSelector((state) => state.FilterSlice?.Data?.M47list)
    console.log("M47List", M47List)
    const isLoading = useSelector(store => store?.FilterSlice?.isLoading)
    const CopyData = useSelector((state) => state.FilterSlice?.FiltersData)
    console.log("CopyData", CopyData)
    const Data = Store.getState().AccountList.AccountListData;

    const columnHelper = createColumnHelper();
    const [search, setSearch] = useState();
    const [columns, setColumns] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState();
    const [copyBtnClick, setCopyBtnClick] = useState(false)
    const [runBtnClick, setRunBtnClick] = useState(false)
    const [okBtnLoading, setOkBtnLoading] = useState(false)
    const [isRowSelected, setIsRowSelected] = useState("");
    const [filterName, setFilterName] = useState("");
    const [isFilterSave, setIsFilterSave] = useState(false);
    const [btnLoading, setBtnLoading] = useState({
        new: false,
        edit: false,
        delete: false,
        copy: false,
        run: false
    })

    const [accData, setAccData] = useState();

    const [accountData, setAccountData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [copyData, setCopyData] = useState([])

    useEffect(() => {
        console.log("tableData", data)
        console.log("filterName", filterName)
        console.log("FiltersName", FiltersName)
    }, [FiltersName, filterName, data])

    useEffect(() => {
        if (CopyData) {
            setCopyData(CopyData)
        }
    }, [CopyData])
    var group = "";
    // const [group, setGroup] = useState("");

    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
    };
    const [DrawerObj, setDrawerObj] = useState(dra);

    useEffect(() => {
        // console.log("FiltersName", FiltersName);
        // const FiltersNameData = Object.entries(FiltersName).map(item => ({ "FIELD01": item[0] }))
        // console.log("FiltersNameData", FiltersNameData);
        if (M47List) {
            setData(M47List)
        }
    }, [M47List])

    useEffect(() => {
        if (M47List && M47List?.length > 0) {
            let cols = []
            // M47List?.map((v, i) => {
            cols.push(
                columnHelper.accessor("FIELD04", {
                    header: "Select Filter"
                })
            )
            // })
            setColumns(cols)
        }

    }, [M47List])

    useEffect(() => {
        let isRowSelected = Object.values(rowSelection).join('')
        setIsRowSelected(isRowSelected)
    }, [rowSelection])

    const SaveCopyData = (obj) => {
        console.log("SaveData_obj", obj)
        console.log("CopyData", CopyData)
        setOkBtnLoading(true)
        const filterData = obj?.copyData?.filter((v) => v.FIELD01 !== null || v.FIELD01 !== "")
        console.log("filterData", filterData)
        var JData = { M47DATA: [...filterData] }
        console.log("JData", JData)

        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "",
            cCode: "",
            cOPara: obj?.filterName,
            cInvCode: OBJ?.cSTID,
            cSData: JSON.stringify(JData),
        };
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "",
            cCode: "",
            cOPara: obj?.filterName,
            cInvCode: OBJ?.cSTID,
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.SaveM47 + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.status == "SUCCESS") {
                    Promise.resolve(setOkBtnLoading(false))
                        .then(() => {
                            setDrawerObj(dra);
                        })
                        .then(() =>
                            GlobalClass.Notify(
                                "success",
                                "Added",
                                "Added successfully"
                            )
                        )
                        .then(() => dispatch(getFiltersDataNull()))
                        .then(() => {
                            dispatch(GetFilterData(OBJ))
                        })
                        .then(setCopyBtnClick(false))
                } else {
                    setOkBtnLoading(false)
                    GlobalClass.Notify("error", data?.status, data?.message)
                }
            })
            .catch((e) => {
                setOkBtnLoading(false)
                GlobalClass.Notify("error", "Error", `${e.message}`)
            });
    }

    const editBtn = () => {
        if (table.getSelectedRowModel()?.flatRows[0]?.original) {
            dispatch(
                ModalFunction({
                    onclose: (e) => { },
                    MTitle: <Text fw={700}>{""}</Text>,
                    MAction: true,
                    MSize: "85%",
                    position: 'bottom',
                    MBody: (i) => <PLFilter
                        OBJ={props?.OBJ}
                        index={i}
                        form={true}
                        value={table.getSelectedRowModel()?.flatRows[0]?.original}
                    />,
                    MClose: true,
                    Overlay: {
                        backgroundOpacity: 0.8,
                        blur: 5,
                    },
                })
            );
        }
    }

    const newBtn = () => {
        dispatch(
            ModalFunction({
                onclose: (e) => { },
                MTitle: <Text fw={700}>{""}</Text>,
                MAction: true,
                MSize: "85%",
                position: 'bottom',
                MBody: (i) => <PLFilter OBJ={props?.OBJ} form={true} index={i} />,
                MClose: true,
                Overlay: {
                    backgroundOpacity: 0.8,
                    blur: 5,
                },
            })
        );
    }

    const deleteBtn = (row) => {
        console.log("delete_row", row)
        if (window.confirm('Confirm to Delete ?')) {
            console.log("selectedRow", selectedRow)
            if (table?.getSelectedRowModel()?.flatRows[0]?.original) {
                setBtnLoading({ ...btnLoading, delete: true })
                Promise.resolve(
                    dispatch(DeleteFilter({
                        p0: "D",
                        id: table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD02,
                        cSTID: table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD01
                    }))
                )
                    .then(() => dispatch(GetFilterData(OBJ)))
                    .then(() => setBtnLoading({ ...btnLoading, delete: false }))
            }
        }
    }

    const copyBtn = () => {
        if (table?.getSelectedRowModel()?.flatRows[0]?.original) {
            console.log("selectedRow", selectedRow)
            setBtnLoading({ ...btnLoading, copy: true })
            setCopyBtnClick(true)
            setFilterName(table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD04)
            dispatch(GetM47AED({
                cSTID: table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD01,
                cCode: table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD02,
                cAction: "E"
            }))
        }
    }

    useEffect(() => {
        if (CopyData?.M47DATA && CopyData?.M47DATA?.length > 0 && copyBtnClick) {
            setBtnLoading({ ...btnLoading, copy: false })
            let newObj = { "filterName": table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD04, "copyData": CopyData?.M47DATA }
            // newObj = { "filterName": table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD04, "copyData": CopyData }
            setDrawerObj({
                ...dra,
                title: <Text fw={700}>Save Filter</Text>,
                body: (
                    <>
                        {isLoading ? GlobalClass.RenderLoader("dots") :
                            <Grid>
                                <Grid.Col span={12} style={{ display: 'flex' }}>
                                    <Grid.Col span={4}>
                                        <Text fw={700}>Filter Name</Text>
                                    </Grid.Col>
                                    <Grid.Col span={8}>
                                        <PLTextBox
                                            value={table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD04}
                                            setEdit={(e) => {
                                                newObj = { ...newObj, "filterName": e.target.value }
                                            }}
                                        />
                                    </Grid.Col>
                                </Grid.Col>
                                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button loading={okBtnLoading} onClick={() => SaveCopyData(newObj)} >Ok</Button>
                                </Grid.Col>
                            </Grid>
                        }
                    </>
                ),
                open: true,
                size: "md",
                position: "right",
            })
        }
    }, [CopyData?.M47DATA, copyBtnClick])

    const runBtn = () => {
        // if (isRowSelected === "true") {
        //     let selectedFilter = FiltersName?.[selectedRow?.FIELD01];
        //     selectedFilter = Object.keys(selectedFilter).filter(objKey => objKey !== 'undefined')
        //         .reduce((newObj, key) => {
        //             newObj[key] = selectedFilter[key];
        //             return newObj;
        //         }, {}
        //         );

        //     console.log("selectedFilter", selectedFilter)
        //     let filteredObj = {};
        //     Object.keys(selectedFilter).forEach(key => {
        //         if (selectedFilter[key].hasOwnProperty('value')) {
        //             filteredObj[key] = selectedFilter[key];
        //         }
        //     });


        //     console.log("filteredObj", filteredObj);

        //     let isQuery = false;

        //     function constructQuery(rules) {
        //         console.log("rules", rules);
        //         console.log("group", group);

        //         let query = '';
        //         let isFirstRule = true;
        //         let condition = "AND"
        //         let qry = "";
        //         let globalConditin = "AND"


        //         for (const key in rules) {
        //             if (key.startsWith('group0')) {

        //                 if (!isFirstRule) {
        //                     if (isQuery) {
        //                         // alert("isFirstRule")
        //                         if (!query.trim().endsWith('AND') && !query.trim().endsWith('OR')) {
        //                             query += ' ' + condition; // Add condition between groups
        //                         }
        //                     }
        //                 }

        //                 query += ' ' + constructGroupQuery(rules[key]);

        //                 console.log("qry", qry);
        //                 console.log("query", query);

        //                 if (qry != query) {
        //                     qry = query
        //                 }

        //                 if (isFirstRule) {
        //                     // alert("OR")
        //                     let flag = true;
        //                     for (const key in rules) {
        //                         if (key.startsWith('group0_condition')) {
        //                             // query = query + ' ' + rules[key].toUpperCase()
        //                             isFirstRule = false
        //                             flag = false;
        //                             condition = rules[key].toUpperCase()
        //                             globalConditin = rules[key].toUpperCase()
        //                         }
        //                     }
        //                     if (flag) {
        //                         if (isQuery) {
        //                             // query = query + " AND "

        //                             // alert("AND")
        //                             condition = "AND"
        //                         }
        //                     }
        //                     isFirstRule = false;
        //                 }
        //             }

        //             else {

        //                 console.log("group", group);
        //                 console.log("key", key);
        //                 console.log("group.localeCompare(key.split", group.localeCompare(key.split("_")[0]));

        //                 if (group.localeCompare(key.split("_")[0]) !== 0) {

        //                     if (!query.trim().endsWith('AND') && !query.trim().endsWith('OR')) {
        //                         query += condition // Add condition between groups
        //                     }

        //                     group = key.split("_")[0]

        //                     query = query.trim() + ' (' + constructGroupQuery(rules[key]);

        //                     if (qry != query) {
        //                         qry = query
        //                     }

        //                     console.log("key", key);

        //                     let flag = true;
        //                     for (const key in rules) {
        //                         const part = key.split("_")[0];

        //                         function getPreviousGroup(key) {
        //                             const match = key.match(/^group(\d+)$/);
        //                             console.log("match", match);

        //                             if (match) {
        //                                 const currentNumber = parseInt(match[1]);
        //                                 if (currentNumber > 0) {
        //                                     const previousNumber = currentNumber - 1;
        //                                     const previousKey = `group${previousNumber}`;
        //                                     return previousKey;
        //                                 } else {
        //                                     return null; // Assuming group0 doesn't exist
        //                                 }
        //                             } else {
        //                                 return null; // Handle cases where key format doesn't match
        //                             }
        //                         }

        //                         const previousKey = getPreviousGroup(part);
        //                         console.log("previousKey", previousKey);

        //                         if (key.endsWith(previousKey + '_condition')) {

        //                             alert("hey")
        //                             query = query + ' ' + previousKey.toUpperCase()
        //                             flag = false;
        //                         }
        //                     }
        //                     if (flag) {
        //                         // alert("hello")
        //                         // console.log("qry",qry,query);
        //                         // if (qry != query) {
        //                         query = query + " AND"
        //                         // }
        //                     }
        //                 }
        //                 else {
        //                     query += ' ' + constructGroupQuery(rules[key])
        //                     query += ')';
        //                 }
        //             }
        //         }
        //         return query;

        //     }

        //     function constructGroupQuery(group) {

        //         console.log("groupgroup", group);
        //         let query = '';
        //         let accountName = []
        //         let accountGroup = []
        //         let city = []

        //         if (group.field === "Account Name") {
        //             // group = { ...group, field: "M02.FIELD02" }
        //             if (group.value) {
        //                 let values = group.value.split(',');
        //                 console.log("accountData", accountData);
        //                 accountData?.filter((item) => {
        //                     values.map((ele) => {
        //                         ele === item.FIELD01 && item.FIELD02 && accountName.push(`%${item.FIELD02}%`)
        //                     })
        //                 })
        //                 accountName && accountName.join(',')
        //                 console.log("accountName", accountName);


        //             }
        //         }
        //         else if (group.field === "Account Group") {
        //             // group = { ...group, field: "M11.GAPGRPNAME" }
        //             if (group.value) {
        //                 let values = group.value.split(',');
        //                 groupData?.filter((item) => {
        //                     values.map((ele) => {
        //                         ele === item.FIELD01 && item.FIELD02 && accountGroup.push(`%${item.FIELD02}%`)
        //                     })
        //                 })
        //                 // console.log("accountGroup", accountGroup);
        //                 accountGroup && accountGroup.join(',')
        //                 console.log("accountGroup", accountGroup);

        //             }
        //         }
        //         else if (group.field === "City") {
        //             // group = { ...group, field: "M01.FIELD17" }
        //             if (group.value) {
        //                 let values = group.value.split(',');
        //                 cityData?.filter((item) => {
        //                     values.map((ele) => {
        //                         if (ele === item.FIELD01 && item.FIELD02) {
        //                             city.push(`%${item.FIELD02}%`)
        //                         }
        //                     })
        //                 })
        //                 console.log("city", city);
        //                 city && city.join(',')
        //                 console.log("city", city);

        //             }
        //         }

        //         if (group.field && group.operator && group.value) {
        //             switch (group.field) {
        //                 case "Account Name":
        //                     if (group.operator === 'Contains' && accountName.length > 0) {
        //                         for (let i = 0; i <= accountName.length - 1; i++) {
        //                             query += `M01.FIELD02 LIKE '${accountName[i]}'`;
        //                             if (i === accountName.length - 1) {

        //                             }
        //                             else {
        //                                 query += " OR "
        //                             }
        //                         }
        //                     } else if (group.operator === 'Not Contains' && accountName.length > 0) {
        //                         for (let i = 0; i <= accountName.length - 1; i++) {
        //                             query += `M01.FIELD02 NOT LIKE '${accountName[i]}'`;
        //                             if (i === accountName.length - 1) {
        //                             }
        //                             else {
        //                                 query += " AND "
        //                             }
        //                         }

        //                     }
        //                     break;
        //                 case "Account Group":
        //                     if (group.operator === 'Contains' && accountGroup.length > 0) {
        //                         for (let i = 0; i <= accountGroup.length - 1; i++) {
        //                             query += `M11.GAPGRPNAME LIKE '${accountGroup[i]}'`;
        //                             if (i === accountGroup.length - 1) {

        //                             }
        //                             else {
        //                                 query += " OR "
        //                             }
        //                         }
        //                     } else if (group.operator === 'Not Contains' && accountGroup.length > 0) {
        //                         for (let i = 0; i <= accountGroup.length - 1; i++) {
        //                             query += `M11.GAPGRPNAME NOT LIKE '${accountGroup[i]}'`;
        //                             if (i === accountGroup.length - 1) {
        //                             }
        //                             else {
        //                                 query += " AND "
        //                             }
        //                         }

        //                     }
        //                     break;
        //                 case "City":
        //                     if (group.operator === 'Contains' && city.length >= 1) {
        //                         for (let i = 0; i <= city.length - 1; i++) {
        //                             query += `M01.FIELD17 LIKE '${city[i]}'`;
        //                             if (i === city.length - 1) {

        //                             }
        //                             else {
        //                                 query += " OR "
        //                             }
        //                         }
        //                     } else if (group.operator === 'Not Contains' && city.length >= 1) {
        //                         for (let i = 0; i <= city.length - 1; i++) {
        //                             query += `M01.FIELD17 NOT LIKE '${city[i]}'`;
        //                             if (i === city.length - 1) {
        //                             }
        //                             else {
        //                                 query += " AND "
        //                             }
        //                         }

        //                     }
        //                     break;
        //                 default:
        //                     break;
        //             }

        //         }
        //         if (query.trim() !== "") {
        //             // alert("isquery")
        //             isQuery = true
        //         }
        //         return query;
        //     }

        //     let query = constructQuery(selectedFilter);
        //     console.log("query", query);

        //     let cWhere = "";

        //     if (query.trim() !== "") {
        //         if (query.trim().endsWith('AND') || query.trim().endsWith('OR') || query.trim().endsWith('AND (') || query.trim().endsWith('OR (')) {
        //             let andOrLength = 0;

        //             const queryLength = query.length;

        //             if (query.trim().endsWith('AND')) {
        //                 andOrLength = 4
        //             }
        //             else if (query.trim().endsWith('OR')) {
        //                 andOrLength = 3
        //             }
        //             else if (query.trim().endsWith('AND (') || query.trim().endsWith('OR (')) {
        //                 andOrLength = 5;
        //             }

        //             // console.log("queryLength", queryLength, andOrLength);
        //             // console.log("qry", qry, andOrLength)
        //             let qry = query.substring(0, queryLength - andOrLength);
        //             query = qry;
        //             console.log("query", query)
        //         }
        //         if (query.includes('(')) {
        //             // Check if the query ends with an incomplete group bracket
        //             if (!query.trim().endsWith(')')) {
        //                 query = query + ')';
        //             } else {
        //                 query = query;
        //             }
        //         }
        //     }


        //     console.log("query", query)
        //     dispatch(ModalDelete(props.index))
        //     dispatch(GetAccountList({ ...props?.OBJ?.BtnPnlObj, pagination: { pageIndex: 0, pageSize: 25, }, cWhere: query }))
        //     dispatch(getFilterStatus(true))

        //     // function constructQuery(rules) {
        //     //     let query = '';
        //     //     let groupConditions = {}; // To store group-specific conditions

        //     //     // First pass to collect group-specific conditions
        //     //     for (const key in rules) {
        //     //         const rule = rules[key];
        //     //         const groupName = key.split('_')[0]; // Extract group name

        //     //         if (rule.condition) {
        //     //             groupConditions[groupName] = rule.condition.toUpperCase();
        //     //         }
        //     //     }

        //     //     // Second pass to construct the query
        //     //     let isFirstGroup = true;

        //     //     for (const key in rules) {
        //     //         const rule = rules[key];
        //     //         const { field, operator, value } = rule;
        //     //         let condition = '';

        //     //         if (value) {
        //     //             const values = value.split(',');
        //     //             const valueConditions = values.map(val => {
        //     //                 switch (operator) {
        //     //                     case 'Contains':
        //     //                         return `${field} LIKE '%${val}%'`;
        //     //                     case 'Not Contains':
        //     //                         return `${field} NOT LIKE '%${val}%'`;
        //     //                     // Add more cases for other operators if needed
        //     //                     default:
        //     //                         return ''; // Handle other operators as needed
        //     //                 }
        //     //             });
        //     //             condition = valueConditions.join(' OR ');
        //     //         }

        //     //         // Determine the condition to use for joining rules in this group
        //     //         const groupName = key.split('_')[0];
        //     //         const groupCondition = groupConditions[groupName] || 'AND';

        //     //         if (!isFirstGroup) {
        //     //             query += ` ${groupCondition} `;
        //     //         }

        //     //         if (groupConditions[groupName]) {
        //     //             query += '(';
        //     //         }

        //     //         query += condition;

        //     //         if (groupConditions[groupName]) {
        //     //             query += ')';
        //     //         }

        //     //         isFirstGroup = false;
        //     //     }

        //     //     return query;
        //     // }


        //     // // if (key.startsWith('group')) {
        //     // //     // Start a new group
        //     // //     if (!isFirstGroup) {
        //     // //         console.log("groupConditions",groupConditions[key]);
        //     // //         query += ` ${groupConditions[key]} (`; // Use groupConditions dynamically
        //     // //     } else {
        //     // //         
        //     // //         isFirstGroup = false;
        //     // //     }

        //     // //     query += constructGroupQuery(rules[key]);
        //     // //     query += ')';
        //     // //     if (rules[key].condition) {
        //     // //          console.log("rules[key].condition",rules[key].condition);
        //     // //         groupConditions[key] = rules[key].condition;
        //     // //     }
        //     // // } else {
        //     // //     // Individual rule
        //     // //     query += constructRuleQuery(rules[key]);
        //     // // }
        //     // function constructRuleQuery(rule) {
        //     //     let query = '';

        //     //     if (rule.operator === 'Contains') {
        //     //         query += `${rule.field} LIKE '%${rule.value}%'`;
        //     //     } else if (rule.operator === 'Not Contains') {
        //     //         query += `${rule.field} NOT LIKE '%${rule.value}%'`;
        //     //     }

        //     //     return query;
        //     // }

        //     // // function constructQuery(rules) {
        //     // //     console.log("rules", rules);
        //     // //     let query = '';
        //     // //     let isFirstGroup = true;
        //     // //     let groupConditions = {};

        //     // //     for (const key in rules) {
        //     // //         if (key.startsWith('group')) {
        //     // //             // Start a new group
        //     // //             if (!isFirstGroup) {
        //     // //                 query += ` ${groupConditions[key]} (`; // Use groupConditions dynamically
        //     // //             } else {
        //     // //                 query += '(';
        //     // //                 isFirstGroup = false;
        //     // //             }

        //     // //             query += constructGroupQuery(rules[key]);
        //     // //             query += ')';
        //     // //             if (rules[key].condition) {
        //     // //                 groupConditions[key] = rules[key].condition;
        //     // //             }
        //     // //         } else {
        //     // //             // Individual rule
        //     // //             query += constructRuleQuery(rules[key]);
        //     // //         }
        //     // //     }

        //     // //     return query;


        //     // // }

        //     // // function constructRuleQuery(rule) {
        //     // //     let query = '';

        //     // //     if (rule.operator === 'Contains') {
        //     // //         query += `${rule.field} LIKE '%${rule.value}%'`;
        //     // //     } else if (rule.operator === 'Not Contains') {
        //     // //         query += `${rule.field} NOT LIKE '%${rule.value}%'`;
        //     // //     }

        //     // //     return query;
        //     // // }

        //     // // function constructGroupQuery(group) {
        //     // //     let query = '';


        //     // //     console.log("group", group);

        //     // //     if (group.field && group.operator && group.value) {
        //     // //         if (group.operator === 'Contains') {
        //     // //             query += `${group.field} LIKE '%${group.value}%'`;
        //     // //         } else if (group.operator === 'Not Contains') {
        //     // //             query += `${group.field} NOT LIKE '%${group.value}%'`;
        //     // //         }
        //     // //     }
        //     // //     console.log("query", query);


        //     // //     return query;
        //     // // }

        //     // // const rulesObject = {
        //     // //     "ABC": {
        //     // //         "group0_rule0": {
        //     // //             "field": "Account Group",
        //     // //             "operator": "Not Contains",
        //     // //             "value": "APMDSR84,APVWNWYM"
        //     // //         },
        //     // //         "group0_rule1": {
        //     // //             "field": "City",
        //     // //             "operator": "Contains",
        //     // //             "value": "AZHFDCHJ"
        //     // //         },
        //     // //         "group0": {
        //     // //             "operator": "Contains",
        //     // //             "condition": "or"
        //     // //         },
        //     // //         "group1_rule2": {
        //     // //             "field": "Account Name",
        //     // //             "operator": "Contains",
        //     // //             "value": "APMDSR84"
        //     // //         },
        //     // //         "group1_rule3": {
        //     // //             "field": "City",
        //     // //             "operator": "Not Contains",
        //     // //             "value": "APHA7ARY"
        //     // //         }
        //     // //     }
        //     // // };

        //     // // const query1 = constructQuery(rulesObject.ABC);
        //     // // console.log(query1);

        //     // //  function constructQuery(rules) {
        //     // //     console.log("rules",rules);
        //     // //     let query = '';
        //     // //     let isFirstGroup = true;

        //     // //     for (const key in rules) {
        //     // //         if (key.startsWith('group')) {
        //     // //             // Start a new group
        //     // //             if (!isFirstGroup) {
        //     // //                 query += ' AND (';
        //     // //             } else {
        //     // //                 query += '(';
        //     // //                 isFirstGroup = false;
        //     // //             }

        //     // //             query += constructGroupQuery(rules[key]);
        //     // //             query += ')';
        //     // //             console.log("constructQuery",query);
        //     // //         } else {
        //     // //             // Individual rule
        //     // //             query += constructRuleQuery(rules[key]);
        //     // //         }
        //     // //     }

        //     // //     return query;
        //     // // }

        //     // // function constructRuleQuery(rule) {


        //     // //     let query = '';

        //     // //     console.log("ruleQuery",rule);
        //     // //     if (rule.operator === 'Contains') {
        //     // //         query += `${rule.field} LIKE '%${rule.value}%'`;
        //     // //     } else if (rule.operator === 'Not Contains') {
        //     // //         query += `${rule.field} NOT LIKE '%${rule.value}%'`;
        //     // //     }

        //     // //     return query;
        //     // // }

        //     // // function constructGroupQuery(group) {
        //     // //     let query = '';

        //     // //     // Check if the group object has any rules
        //     // //     console.log("group",group);

        //     // //     if (group.field && group.operator && group.value) {
        //     // //         if (group.operator === 'Contains') {
        //     // //             query += `${group.field} LIKE '%${group.value}%'`;
        //     // //         } else if (group.operator === 'Not Contains') {
        //     // //             query += `${group.field} NOT LIKE '%${group.value}%'`;
        //     // //         }
        //     // //     }
        //     // // console.log("query",query);
        //     // //     return query;
        //     // // }

        //     // // const rulesObject = {
        //     // //     "ABC": {

        //     // //         "group0_rule0": {
        //     // //             "field": "Account Group",
        //     // //             "operator": "Not Contains",
        //     // //             "value": "APMDSR84,APVWNWYM"
        //     // //         },
        //     // //         "group0_rule1": {
        //     // //             "field": "City",
        //     // //             "operator": "Contains",
        //     // //             "value": "AZHFDCHJ"
        //     // //         },
        //     // //         "group0": {
        //     // //             "operator": "Contains",
        //     // //             "condition": "or"
        //     // //         },
        //     // //         "group1_rule2": {
        //     // //             "field": "Account Name",
        //     // //             "operator": "Contains",
        //     // //             "value": "APMDSR84"
        //     // //         },
        //     // //         "group1_rule3": {
        //     // //             "field": "City",
        //     // //             "operator": "Not Contains",
        //     // //             "value": "APHA7ARY"
        //     // //         }
        //     // //     }
        //     // // };

        //     // // const query1 = constructQuery(rulesObject.ABC);
        //     // // console.log(query1);


        //     // // const rulesObject = {
        //     // //     "ABC": {
        //     // //         "group0_rule0": {
        //     // //             "field": "Account Group",
        //     // //             "operator": "Not Contains",
        //     // //             "value": "APMDSR84,APVWNWYM"
        //     // //         },
        //     // //         "group0_rule1": {
        //     // //             "field": "City",
        //     // //             "operator": "Contains",
        //     // //             "value": "AZHFDCHJ"
        //     // //         },
        //     // //         "group0_condition": "or",

        //     // //         "group1_rule2": {
        //     // //             "field": "Account Name",
        //     // //             "operator": "Contains",
        //     // //             "value": "APMDSR84"
        //     // //         },
        //     // //         "group1_rule3": {
        //     // //             "field": "City",
        //     // //             "operator": "Not Contains",
        //     // //             "value": "APHA7ARY"
        //     // //         }
        //     // //     }
        //     // // };

        //     // // const query1 = constructQuery(rulesObject.ABC);
        //     // // console.log(query1);

        //     // const query2 = `M01.FIELD17 LIKE '%AHMEDABAD.%' OR (M01.FIELD02 LIKE '%11a%' AND M01.FIELD17 NOT LIKE '%ab%')`
        //     // const query = `M01.FIELD17 LIKE '%AHMEDABAD%'`
        // }
        if (table.getSelectedRowModel().flatRows[0]?.original) {
            setRunBtnClick(true)
            setBtnLoading({ ...btnLoading, run: true })
            dispatch(getFilterStatus(true))
            dispatch(GetM47AED({
                cSTID: table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD01,
                cCode: table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD02,
                cAction: "E"
            }))
        } else {
            GlobalClass.Notify("warning", "Warning", "Filter is not selected")
        }
    }

    useEffect(() => {
        if (CopyData?.M47DATA && CopyData?.M47DATA?.length > 0 && runBtnClick) {
            let UpdatedData = CopyData?.M47DATA?.filter((f, i) => f.FIELD01 != null && f.FIELD01 != "")
            let filterData = filterData?.filter((f) => f.FIELD99 == "V")
            console.log("filterData",filterData)
            if(filterData){
                setDrawerObj({
                    ...dra,
                    title: <Text fw={700}>Query Values</Text>,
                    body: (
                        <>
                                <Grid>
                                    <Grid.Col span={12} style={{ display: 'flex' }}>
                                    
                                    </Grid.Col>
                                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button>Ok</Button>
                                    </Grid.Col>
                                </Grid>
                        </>
                    ),
                    open: true,
                    size: "md",
                    position: "right",
                })
            }
            var JData = { M47DATA: [...UpdatedData] }
            console.log("JData", JData)
            var data = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "",
                cCode: "",
                cOPara: OBJ?.cSTID,
                cSData: JSON.stringify(JData),
            };
            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "",
                cCode: "",
                cOPara: OBJ?.cSTID,
            });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            fetch(
                GlobalClass.ApiUrl + GlobalClass.ApplyFilter + "?pa=" + param,
                requestOptions
            )
                .then((response) => response.json())
                .then((data) => {
                    console.log("data", data)
                    if (data.status == "SUCCESS") {
                        Promise.resolve(setBtnLoading({ ...btnLoading, run: true }))
                            .then(() => {
                                dispatch(ModalDelete(props.index))
                            })
                            .then(() => {
                                dispatch(SetAccountListData({ ...Data, [OBJ?.cSTID]: data?.data?.jData }))
                            })
                    } else {
                        setBtnLoading({ ...btnLoading, run: false })
                        GlobalClass.Notify("error", data?.status, data?.message)
                    }
                })
                .catch((e) => {
                    setBtnLoading({ ...btnLoading, run: false })
                    GlobalClass.Notify("error", "Error", `${e.message}`)
                });
        }
    }, [CopyData?.M47DATA, runBtnClick])

    const table = useMantineReactTable({
        data: data,
        columns,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableTopToolbar: false,
        enablePagination: true,
        enableColumnActions: false,
        enableSorting: false,
        enableGlobalFilter: true,
        enableGlobalFilterModes: true,
        enableColumnFilters: false,
        enableColumnDragging: false,
        mantineTableProps: { withColumnBorders: true },
        mantineTableContainerProps: {
            sx: { height: height * 0.2 },
        },
        initialState: {
            globalFilter: search,
        },
        state: {
            density: "0px",
            rowSelection
        },
        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
            onClick: () => {
                console.log(row, "row");
                setRowSelection((prev) => ({
                    [row.id]: !prev[row.id],
                }));
                setSelectedRow(row?.original)
            },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
        }),
        renderBottomToolbar: ({ row, table }) => (
            <Group
                style={{
                    marginTop: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Button
                    compact
                    onClick={newBtn}
                    loading={btnLoading.new}
                >New</Button>
                <Button
                    compact
                    onClick={editBtn}
                    loading={btnLoading.edit}
                >Edit</Button>
                <Button
                    compact
                    onClick={() => deleteBtn(row)}
                    loading={btnLoading?.delete}
                >Delete</Button>
                <Button
                    compact
                    onClick={copyBtn}
                    loading={btnLoading.copy}
                >Copy</Button>
                <Button
                    compact
                    onClick={runBtn}
                    loading={btnLoading.run}
                >Run</Button>
            </Group>
        )
    });

    console.log("table", table.getSelectedRowModel().flatRows[0])

    return (
        <>
            <Modal
                opened={DrawerObj?.open}
                // onClose={close}
                withCloseButton={() => { }}
                fullScreen={false} //
                // overlayProps={{ opacity: 0.5, blur: 4 }}
                closeButtonProps={{
                    onClick: () => {
                        setDrawerObj(dra);
                        setCopyBtnClick(false)
                        dispatch(getFiltersDataNull())
                        dispatch(getFilterStatus(false))
                    },
                }}
                title={DrawerObj.title}
                size={DrawerObj.size}
                // centered={.Centered}
                closeOnClickOutside={true}
                // overlayProps={m.Overlay}
                position={DrawerObj?.position ?? "bottom"}
                onClose={
                    typeof DrawerObj?.onclose == "function"
                        ? DrawerObj?.onclose
                        : () => {
                            setDrawerObj(dra);
                        }
                }
            // scrollAreaComponent={ScrollArea.Autosize}
            >
                {DrawerObj.body}
            </Modal>
            {
                // isLoading ? GlobalClass.RenderLoader("dots") :
                <div>
                    <Paper shadow="md" radius="md" p="xs" withBorder>
                        <Grid gutter={4}>
                            <Grid.Col span={12}>
                                <PLTextBox value={search}
                                    placeholder="Search Here"
                                    setEdit={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <MantineReactTable table={table} />
                            </Grid.Col>
                        </Grid>
                    </Paper>
                </div>
            }

        </>

    )
}

export default PLModal
