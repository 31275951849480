import {
  Button,
  Group,
  Tooltip,
} from "@mantine/core";
import React, { forwardRef, useImperativeHandle } from "react";
import DynamicFunList from "../AllFunctions/DynamicFunList";


const ButtonPanel = forwardRef((props, ref) => {
  // console.log("executeFunction props",props);

  const executeFunction = async (funcName, ...params) => {
    // funcName = "M01AED";
    console.log("executeFunction para =>", [{ funcName, params }]);
  
    if (funcName.includes(".")) {
      // Case 1: Module + Function (e.g., "M01.M01AED")
      const [moduleName, methodName] = funcName.split(".");
      const module = DynamicFunList[moduleName];
  
      if (module && typeof module[methodName] === "function") {
        try {
          return await module[methodName](...params);
        } catch (error) {
          console.error(`Error executing function ${funcName}:`, error);
        }
      } else {
        console.warn(`Function ${funcName} is not defined.`, params);
      }
    } else {
      // Case 2: Direct Function Name (e.g., "M01AED")
      for (const module of Object.values(DynamicFunList)) {
        if (typeof module[funcName] === "function") {
          try {
            return await module[funcName](...params);
          } catch (error) {
            console.error(`Error executing function ${funcName}:`, error);
          }
        }
      }
      console.warn(`Function ${funcName} is not defined in any module.`, params);
    }
  };
  
  const BindFunWithPara = (itemObj) => {
    const { KEYEXPR, CAPTION } = itemObj;
    if (!KEYEXPR) return;
  
    // Extract function name and parameters
    const paraStart = KEYEXPR.indexOf("(");
    const paraEnd = KEYEXPR.lastIndexOf(")");
    let funcName = KEYEXPR.substring(0, paraStart);
    let paraStr = KEYEXPR.substring(paraStart + 1, paraEnd - 1).replace(/\'/g, "");
    const paraArr = paraStr.split(",");
  
    // Remove "window." prefix if present
    if (funcName.startsWith("window.")) {
      funcName = funcName.substring(7);
    }
  
    // Construct the parameter object
    const tabObject = {
      id: props.Skey?.original?.FIELD01,
      cSTID:props?.cSTID,
      text: CAPTION,
      p0: paraArr[0],
      p1: paraArr[1],
      p2: paraArr[2],
      M41UID: props.Skey?.original?.M41UID,
      M45UID: props.Skey?.original?.M45UID,
      WMF01: props.Skey?.original?.WMF01,
      page: props.from, 
      G02F01: props.Skey?.original?.G02F01,
      DataGridRow: {
        ...props.Skey?.original,
        FIELD02:
          typeof props.Skey?.original?.FIELD02 === "string"
            ? props.Skey?.original?.FIELD02.replaceAll("'", "")
                .replaceAll('"', "")
                .replaceAll(",", "")
            : props.Skey?.original?.FIELD02,
      },
      FormCode: props?.FormCode,
      VoucherFormType: props?.VoucherFormType,
      ModalIndex: props?.ModalIndex,
      BtnPnlObj: props?.btnPnlObj,
      S11F01: props?.S11F01,
      setFormatBtn:(e)=>{
        if(props.setFormatBtn && typeof props?.setFormatBtn == "function"){
          props?.setFormatBtn(e)
        }
      }
    };
  
    // Call the function from the registry
    executeFunction(funcName, tabObject,(props.Skey?.original?.FIELD01 ?? ""), ...paraArr);
  };
  useImperativeHandle(ref, () => ({
    handleButtonClick(index) {
      // console.log('Button clicked:', props.data);
      BindFunWithPara(index);
    },
  }))
  return (
    <>
      <Group
        spacing={"xs"}
        position="center"
        display={"flex"}

      >
        {props?.data?.map((btn, i) => {
          if (btn.CAPTION) {
            return (
              <div key={i}>
                <Tooltip label={btn.TOOLTIP}>
                  <Button
                    id={btn?.CAPID}
                    size="sm"
                    variant="subtle"
                    compact
                    onClick={() => {
                      // console.log('btn panel data',btn);
                      BindFunWithPara(btn);
                    }}
                  >
                    {btn.CAPTION}
                  </Button>
                </Tooltip>
              </div>
            );
          }
        })}
      </Group>
    </>
  );
})
export default React.memo(ButtonPanel);
