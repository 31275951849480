import * as UserLevel from "../SetupComponent/User/User Detail/UserLevel";

//Setup > User > User Detail > User Level
export const M81GAED = (itemOBJ) => {
    const OBJ = itemOBJ;
    console.log('executeFunction M81GAED ===>', OBJ);

    switch (OBJ?.cSTID) {
        case "P_M81G":
            UserLevel.M81GAED(JSON.stringify(OBJ))
            break;

        default:
            break;
    }

}