import * as GstOne from "../ReportComponent/GSTReturns/GSTR1";
import * as GstTwo from "../ReportComponent/GSTReturns/GSTR2";

//Report > GST > GST Return > GSTR1
export const G1RET = (itemOBJ) => {
    const OBJ = itemOBJ;
    console.log('executeFunction G1RET ===>', OBJ);

    switch (OBJ?.cSTID) {
        case "GSTR1RETURN":
            GstOne.G1RET(JSON.stringify(OBJ))
            break;

        default:
            break;
    }

}

//Report > GST > GST Return > GSTR2
export const GSTR2 = (itemOBJ) => {
    const OBJ = itemOBJ;
    console.log('executeFunction GSTR2 ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "GSTR2RETURN":
            GstTwo.GSTR2(JSON.stringify(OBJ))
            break;

        default:
            break;
    }

}