import React from 'react'
import { modals } from '@mantine/modals';
import { ProductGroupForm } from './ProductGroupForm';
import { useDispatch } from 'react-redux';
import { ProductDeleteApi, ProductGroupApi } from '../../utils/slices/ProductGroup';
import Store from '../../utils/store';
import { GetAccountList } from '../../utils/slices/AccountListSlice';
import { notifications } from '@mantine/notifications';
import { Text } from '@mantine/core';
import { ModalFunction } from '../../utils/slices/ModalSlice';
import GlobalClass from '../../utils/GlobalClass';


export function MSAED(data) {
   console.log(data);
   const OBJ = JSON.parse(data);
   console.log("OBJJ MSAED", OBJ);

   if (OBJ.p0 === 'A') {
      Store.dispatch(
         ModalFunction({
            onclose: () => { },
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "sm",
            position:"right",
            MBody: (i) => (
               <ProductGroupForm OBJ={OBJ} index={i} />
            ),
            MClose: true,
            Overlay: {
               backgroundOpacity: 0.8,
               blur: 5,
            }
         })
      )
      // modals.open({
      //     title:<Text style={{fontWeight:700}}>{OBJ?.text}</Text>,
      //     size: '50%',
      //     children: (
      //         <>
      //             <ProductGroupForm OBJ={OBJ} />
      //         </>
      //     ),
      // })
   }
   else if (OBJ.id && OBJ.p0 === 'E') {
      Store.dispatch(
         ModalFunction({
            onclose: () => { },
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "sm",
            position:"right",
            MBody: (i) => (
               <ProductGroupForm OBJ={OBJ} index={i} />
            ),
            MClose: true,
            Overlay: {
               backgroundOpacity: 0.8,
               blur: 5,
            }
         })
      )
      //====================================================
      // modals.open({
      //     title:<Text style={{fontWeight:700}}>{OBJ?.text}</Text>,
      //     size: '50%',
      //     children: (
      //         <>
      //             <ProductGroupForm OBJ={OBJ} />
      //         </>
      //     ),
      // })
   }
   else if (OBJ.id && OBJ.p0 === 'D') {
      console.log(OBJ, "????????");
      const confirm = window.confirm("Are you sure ?");
      if (confirm) {
         Store.dispatch(ProductDeleteApi(OBJ))
      }
   }
}

