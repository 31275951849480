import * as TaxClass from "../FunctionsCall/TaxClass/TaxClass"

export const M14AED = (itemOBJ) => {
    const OBJ = itemOBJ;
    console.log('M14AED ===>', OBJ);

    switch (OBJ?.cSTID) {
        case "T_M14": // Master > Tax Class
        case "P_M14":
            TaxClass.M14AED(JSON.stringify(OBJ))
            break;

        default:
            break;
    }
}