import * as MasterLeadEntry from "../FunctionsCall/LeadEntry/LeadEntry"

export const M02LAED = (itemOBJ) => {
    const OBJ = itemOBJ;
    console.log('executeFunction M02LAED ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "P_M02L": //Master > Lead Customer Entry
            MasterLeadEntry.M02LAED(JSON.stringify(OBJ))
            break;

        default:
            break;
    }
}