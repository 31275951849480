import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GlobalClass from "../GlobalClass";
import axios from "axios";
import gensetting from "../gensetting";
import Store from "../store";
import { GetAccountList } from "./AccountListSlice";

export const GetJobProcessList = createAsyncThunk(
    "JobProcessListSlice/GetJobProcessList",
    async (data) => {
        console.log("JobProcessListSlice/GetJobProcessList", data);
        if (data.p0 === 'D') {
            GlobalClass.Notify(
                "info",
                "Deleting",
                "Please wait while we process your data"
            );
        }
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction":data?.p0?? 'A',
                    "cCode":data.id ?? '',
                    "cOPara": "",
                    "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM31JB + "?pa=" + param);
            // console.log("GetM01List response=>>", response.data.data);
            if (data.p0 === 'D' && response.data.status === "SUCCESS") {
                let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj

                Store.dispatch(GetAccountList({
                    "id": "T_M31J",
                    "name": "Job Process List",
                    "p0": "MT",
                    "p1": "T_M31J",
                    "p2": "",
                    "p3": "",
                    "type": "A",
                    "pagination": PaginationObj["T_M31J"]

                }))
                GlobalClass.Notify("success", "Deleted", "Deleted successfully");

            } else if (data.p0 === 'D' && response.data.status !== "SUCCESS") {
                GlobalClass.Notify(
                    "error",
                    response?.data?.status,
                    response?.data?.message
                );
            }
            return response.data.data;
        } catch (e) {
            GlobalClass.Notify("error", "Error", `${e?.message}`);
            return { e };
        }
    }
);


const JobProcessListSlice = createSlice({
    name: "JobProcessListSlice",
    initialState: {
        JobProcessListData: [],
        isLoading: false,
        hasError: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetJobProcessList.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetJobProcessList.fulfilled, (state, action) => {
                // console.log("GetJobProcessList fulfilled==>", action.payload);
                state.JobProcessListData = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(GetJobProcessList.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })
    }
})

export default JobProcessListSlice.reducer;