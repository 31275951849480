import * as TransactionLeadEntry from "../Transaction/LeadEntry/TransactionLeadEntry";

export const LEADAED = (itemOBJ) => {
    const OBJ = itemOBJ;
    console.log('executeFunction LEADAED ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "TXNVOULISTLD":
            TransactionLeadEntry.LEADAED(JSON.stringify(OBJ))
            break;

        default:
            break;
    }
}