import * as UserDetail from "../SetupComponent/User/User Detail/UserDetail";

//Setup > User > User Detail
export const M81AED = (itemOBJ) => {
    const OBJ = itemOBJ;
    console.log('executeFunction M81AED ===>', OBJ);

    switch (OBJ?.cSTID) {
        case "P_M81":
            UserDetail.M81AED(JSON.stringify(OBJ))
            break;

        default:
            break;
    }

}