import { Button, Grid, Group, Text } from "@mantine/core";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLDateBox from "../../PlusComponent/PLDateBox";
import { useEffect, useRef } from "react";
import GlobalClass from "../../utils/GlobalClass";

export default function ProductUserField(props) {

    const { state, setState, OBJ, UFM32, DrawerOBJ, setDrawerOBJ, onOkClicked } = props;

    const UserFieldRef = useRef(null);

    const seprate = (e) => {
        var paraArr = e.split('~C~');
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr
    }

    const saveBtn = () => {
        setDrawerOBJ({ ...DrawerOBJ, open: false })
        onOkClicked()
    }

    useEffect(() => {
        const formElement = UserFieldRef.current;
        if (formElement) {
            formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, UserFieldRef));
        }

        return () => {
            if (formElement) {
                formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, UserFieldRef));
            }
        };
    }, [UserFieldRef])

    return (
        <div ref={UserFieldRef}>
            {
                UFM32 && UFM32.length > 0 ? UFM32?.map((item, index) => {
                    return <Grid gutter={4}>
                        {
                            item?.S13F14 === 'PLPOPUP' &&
                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLDataGrid position=""
                                        autofocus={index == 0 ? true : false}
                                        width="500px"
                                        name={item?.S13F02}
                                        value={{ value: state?.[item.S13F02], label: state?.[item.S13F02] }}
                                        p1={seprate(item?.S13F09)[1]}
                                        valexpr="FIELD01"
                                        dispexpr={"FIELD01"}//FIELD02 is not there in event object
                                        setEdit={(e) => {
                                            console.log("e", e);
                                            setState({ ...state, [item.S13F02]: e.FIELD01 })
                                            // setState((prevState) => ({
                                            //     ...prevState,
                                            //     state: {
                                            //         ...prevState.state,
                                            //         [item.S13F02]: e.FIELD01
                                            //     }
                                            // }))
                                        }}
                                        onKeyDown={(e) => {
                                            GlobalClass.formNextInput(e, UserFieldRef);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'TEXTBOX' &&
                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox position=""
                                        autofocus={index == 0 ? true : false}
                                        name={item?.S13F02}
                                        value={state?.[item.S13F02]}
                                        setEdit={(e) => {
                                            setState({ ...state, [item.S13F02]: e.target.value })
                                            // setState((prevState) => ({
                                            //     ...prevState,
                                            //     state: {
                                            //         ...prevState.state,
                                            //         [item.S13F02]: e.target.value
                                            //     }
                                            // }))
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                        {
                            item?.S13F14 === 'NUMBOX' &&
                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLNumberBox
                                        autofocus={index == 0 ? true : false}
                                        name={item?.S13F02}
                                        value={state?.[item.S13F02]}
                                        setEdit={(e) => {
                                            setState({ ...state, [item.S13F02]: e })
                                            // setState((prevState) => ({
                                            //     ...prevState,
                                            //     state: {
                                            //         ...prevState.state,
                                            //         [item.S13F02]: e
                                            //     }
                                            // }))
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                        {
                            item?.S13F14 === 'COMBOBOX' &&
                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLComboBox
                                        autofocus={index == 0 ? true : false}
                                        name={item?.S13F02}
                                        value={state?.[item.S13F02]}
                                        copno={'val1'}
                                        dispexpr="DisplayMember"
                                        valexpr="ValueMember"
                                        setEdit={(e) => {
                                            console.log(e);
                                            setState({ ...state, [item.S13F02]: e })
                                        }}
                                        onKeyDown={(e) => {
                                            GlobalClass.formNextInput(e, UserFieldRef);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                        {
                            item?.S13F14 === 'DATEPICKER' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLDateBox
                                        autofocus={index == 0 ? true : false}
                                        setEdit={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'TIMEPICKER' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLDateBox
                                        autofocus={index == 0 ? true : false}
                                        setEdit={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                        {
                            item?.S13F14 === 'MEMOBOX' && item?.S13F21 === 'N' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox
                                        autofocus={index == 0 ? true : false}
                                        name={item?.S13F02}
                                        value={state?.[item.S13F02]}
                                        setEdit={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'MEMOBOX' && item?.S13F21 === 'H' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox
                                        autofocus={index == 0 ? true : false}
                                        name={item?.S13F02}
                                        value={state?.[item.S13F02]}
                                        setEdit={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'FILEUPLOAD' && item?.S13F21 === 'I' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox
                                        autofocus={index == 0 ? true : false}
                                        name={item?.S13F02}
                                        value={state?.[item.S13F02]}
                                        setEdit={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'FILEUPLOAD' && item?.S13F21 === 'A' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox
                                        autofocus={index == 0 ? true : false}
                                        setEdit={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                    </Grid>
                }) : []
            }
            <Group spacing={'xs'} position="center" display={'flex'}>
                <Button
                    size='sm' style={{ width: 100 }}
                    onClick={saveBtn}
                    onKeyDown={(e) => {
                        if (e.key == "Tab" || e.key == "Enter") {
                            setDrawerOBJ({ ...DrawerOBJ, open: false })
                            onOkClicked()
                        }
                    }}
                >
                    Ok
                </Button>
            </Group>
        </div>

    )

}
