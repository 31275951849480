import { Alert, Button, Grid, Group, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import PLComboBox from '../../PlusComponent/PLComboBox';
import { useDispatch, useSelector } from 'react-redux';
import { ProductListAPi } from '../../utils/slices/ProductList';
import { GetAccountList, GetTMFormat } from '../../utils/slices/AccountListSlice';
import { GetAccountListForPrint } from '../../utils/PrintSlice/PrintSlice';
import Store from '../../utils/store';
import GlobalClass from '../../utils/GlobalClass';
import { ModalDelete } from '../../utils/slices/ModalSlice';
import { IconInfoCircle } from '@tabler/icons-react';

const MasterFilterForm = (props) => {

    console.log("props =>", props);

    const pid = props?.data?.BtnPnlObj?.id

    const dispatch = useDispatch();

    const ProductListData = useSelector((store) => store?.ProductList?.ProductListData);
    const GetGeneral = useSelector((state) => state.Language.General);
    const resultNames = useSelector((state) => state?.PrintList?.PrintData[pid]);
    const isLoading = useSelector((state) => state?.PrintList?.isLoading);



    const [masterFields, setMasterFields] = useState({
        ProductGroup:"A",
        ProductCategory:"A",
        Product:"A"
    })
    const [groupSelected, setGroupSelected] = useState(false);
    const [categorySelected, setCategorySelected] = useState(false);
    const [productSelected, setProductSelected] = useState(false);
    const [groupData, setGroupData] = useState([]);
    const [groupData1, setGroupData1] = useState([]);
    const [groupData2, setGroupData2] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [categoryData1, setCategoryData1] = useState([]);
    const [categoryData2, setCategoryData2] = useState([]);
    const [isGroupSelected, setIsGroupSelected] = useState(false);
    const [isCategorySelected, setIsCategorySelected] = useState(false);
    const [productData, setProductData] = useState([]);
    const [selectedProductNames, setSelectedProductNames] = useState([]);
    const [waitForFilter, setWaitForFilter] = useState(true);
    const [isproductSelected, setIsProductSelected] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);


    useEffect(() => {
        Promise.resolve(
            dispatch(GetTMFormat({ ...props?.data?.BtnPnlObj, StrtDt: GetGeneral?.dYSDate, EndDt: GetGeneral?.dYEDate, })))
            .then(() => {
                dispatch(GetAccountListForPrint(props?.data?.BtnPnlObj));
            })
            .then(() => {
                dispatch(ProductListAPi(props?.data?.BtnPnlObj));
            })

        // .then(() => {
        //     if (!isLoading) {
        //         dispatch(GetPrintEntData())
        //     }
        // });
    }, []);

    useEffect(() => {
        if (isLoading == true) {
            setIsLoading1(false);
        }
    }, [isLoading]);

    useEffect(() => {
        setGroupData(ProductListData?.P_M32GDT?.jData);
        setCategoryData(ProductListData?.P_M32CDT?.jData);
        setProductData(resultNames);
        setSelectedProductNames(resultNames);
    }, [ProductListData]);

    useEffect(() => {
        if (productSelected == true) {
            let resData = [];

            Promise.resolve((resData = Store.getState()?.PrintList?.PrintData[pid]))
                .then(() => {

                    console.log("resData", resData);

                    if (isGroupSelected) {
                        let filteredArray = [];

                        for (let i = 0; i < groupData?.length; i++) {
                            for (let j = 0; j < resData?.length; j++) {
                                if (groupData[i]?.FIELD01 == resData[j]?.FIELD11) {
                                    filteredArray?.push(resData[j]);
                                }
                            }
                        }
                        resData = filteredArray;

                    }
                    if (isCategorySelected) {
                        let filteredArray = [];

                        for (let i = 0; i < categoryData?.length; i++) {
                            for (let j = 0; j < resData?.length; j++) {
                                if (categoryData[i]?.FIELD01 == resData[j]?.FIELD12) {
                                    filteredArray?.push(resData[j]);
                                }
                            }
                        }
                        resData = filteredArray;
                    }
                    setWaitForFilter(false);
                    setSelectedProductNames(
                        resData?.length == 0 ? isCategorySelected || isGroupSelected ? resData : resultNames : resData
                    );
                });
        }
    }, [productSelected])

    const handleInput = (e) => {
        let inputField;
        switch (e) {
            case "product":
                inputField = (<>

                    <Grid.Col span={6} style={{ display: "flex", alignItems: "center" }}>
                        <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Group Name</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <PLComboBox
                                copno={"AS"}
                                value={masterFields?.ProductGroup}
                                dispexpr={"DisplayMember"}
                                valexpr={"ValueMember"}
                                setEdit={(e) => {
                                    console.log("masterFields?.ProductGroup", e);
                                    setMasterFields({ ...masterFields, ProductGroup: e?.value })
                                    if (e?.value == "S") {
                                        setGroupSelected(true);
                                    } else {
                                        setGroupSelected(false);
                                    }
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={6} style={{ display: "flex", alignItems: "center" }}>
                        <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Category Name</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <PLComboBox
                                copno={"AS"}
                                value={masterFields?.ProductCategory}
                                dispexpr={"DisplayMember"}
                                valexpr={"ValueMember"}
                                setEdit={(e) => {
                                    console.log("masterFields?.ProductCategory", e);
                                    setMasterFields({ ...masterFields, ProductCategory: e?.value })
                                    if (e?.value == "S") {
                                        setCategorySelected(true);
                                    } else {
                                        setCategorySelected(false);
                                    }
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>

                    <Grid.Col span={6} style={{ display: "flex", alignItems: "center" }}>

                        {groupSelected == true && (
                            <Grid.Col style={{ display: "flex", flexDirection: "row" }}>
                                <Grid.Col span={4}>
                                    <Text style={{ fontSize: 12 }}>
                                        Select Group Names
                                    </Text>
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <PLDataGrid
                                        width={"500"}
                                        isMulti={true}
                                        TmData={ProductListData?.P_M32G}
                                        // data={ProductListData?.P_M32GDT?.jData}
                                        value={{ value: groupData1, label: groupData2 }}
                                        setEdit={(e) => {
                                            let group = e?.map((v) => v?.original);
                                            setGroupData(group);
                                            let val2 = [];
                                            let val1 = [];
                                            if (e?.length > 0) {
                                                e?.map((m, i) => {
                                                    val2.push(m?.original?.FIELD02);
                                                    val1.push(m?.original?.FIELD01);
                                                });
                                            }
                                            setGroupData1(val1?.join(","));
                                            setGroupData2(val2?.join(","));
                                            setIsGroupSelected(true);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        )}
                    </Grid.Col>
                    <Grid.Col span={6} style={{ display: "flex", alignItems: "center" }}>

                        {categorySelected == true && (
                            <Grid.Col style={{ display: "flex", flexDirection: "row" }}>
                                <Grid.Col span={4}>
                                    <Text style={{ fontSize: 12 }}>
                                        Select Category Names
                                    </Text>
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <PLDataGrid
                                        width={"500"}
                                        isMulti={true}
                                        TmData={ProductListData?.P_M32C}
                                        // data={ProductListData?.P_M32CDT?.jData}
                                        value={{ value: categoryData1, label: categoryData2 }}
                                        setEdit={(e) => {
                                            let category = e?.map((v) => v?.original);
                                            setCategoryData(category);
                                            let val2 = [];
                                            let val1 = [];
                                            if (e?.length > 0) {
                                                e?.map((m, i) => {
                                                    val2.push(m?.original?.FIELD02);
                                                    val1.push(m?.original?.FIELD01);
                                                });
                                            }
                                            setCategoryData1(val1?.join(","));
                                            setCategoryData2(val2?.join(","));
                                            setIsCategorySelected(true);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        )}
                    </Grid.Col>

                    <Grid.Col span={6} style={{ display: "flex", alignItems: "center" }}>
                        <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Product</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <PLComboBox
                                copno={"AS"}
                                value={masterFields?.Product}
                                dispexpr={"DisplayMember"}
                                valexpr={"ValueMember"}
                                setEdit={(e) => {
                                    console.log("masterFields?.Product", e);
                                    setMasterFields({ ...masterFields, Product: e?.value })
                                    setIsProductSelected(false)
                                    if (e?.value == "S") {
                                        setProductSelected(true);
                                    } else {
                                        setProductSelected(false);
                                    }
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>

                    <Grid.Col span={6}></Grid.Col>

                    <Grid.Col span={6} style={{ display: "flex", alignItems: "center" }}>
                        {productSelected == true ? (
                            <Grid.Col style={{ display: "flex", flexDirection: "row" }} >
                                <Grid.Col span={4}>
                                    <Text style={{ fontSize: 12 }}>
                                        Select Product Names
                                    </Text>
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <PLDataGrid
                                        isLoading={waitForFilter}
                                        data={selectedProductNames}
                                        TmData="P_M21"
                                        width={"500"}
                                        setEdit={(e) => {
                                            let account = e?.map((v) => v?.original);
                                            setProductData(account);
                                            setIsProductSelected(true);
                                            setWaitForFilter(false);
                                        }}
                                        dropdownPosition={"bottom"}
                                        isMulti={true}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        ) : (
                            ""
                        )}
                    </Grid.Col>
                </>)
                break;

            default:
                inputField =(<>
                <Grid.Col span={12}>
                 <Alert  variant="light" color="blue" title="Not Available" icon={<IconInfoCircle />}>
                    Some Error!!!
                </Alert>
                </Grid.Col>
                </>)
                break;
        }

        return inputField

    }

    const handleOk = async () => {
        let resData = [];
        setIsLoading1(true);
        Promise.resolve(productSelected == false &&
            Promise.resolve((resData = Store.getState()?.PrintList?.PrintData[pid]))
                .then(() => {
                    if (isGroupSelected) {
                        let filteredArray = [];

                        for (let i = 0; i < groupData?.length; i++) {
                            for (let j = 0; j < resData?.length; j++) {
                                if (groupData[i]?.FIELD01 == resData[j]?.FIELD11) {
                                    filteredArray?.push(resData[j]);
                                }
                            }
                        }
                        resData = filteredArray;

                    }
                    if (isCategorySelected) {
                        let filteredArray = [];

                        for (let i = 0; i < categoryData?.length; i++) {
                            for (let j = 0; j < resData?.length; j++) {
                                if (categoryData[i]?.FIELD01 == resData[j]?.FIELD12) {
                                    filteredArray?.push(resData[j]);
                                }
                            }
                        }
                        resData = filteredArray;
                    }
                    setSelectedProductNames(
                        resData?.length == 0
                            ? isCategorySelected ||
                                isGroupSelected
                                ? resData
                                : resultNames
                            : resData
                    );
                })
        )


        dispatch(ModalDelete(props?.index))

        const productArray = isproductSelected ? productData : selectedProductNames;

        const field01Array = productArray.map(item => item.FIELD01);

        const queryParts = field01Array.map(field01 => {
            return `M21.FIELD01 LIKE '%${field01}%'`;
        });

        const cWhereQuery = queryParts.join(' OR ');

        console.log("queryParts",queryParts);

        dispatch(GetAccountList({ ...props?.data?.BtnPnlObj, cWhere : cWhereQuery }))
    };

    return (<>
        {
            // isLoading1 ? GlobalClass.RenderLoader("dots") :
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: "flex", flexWrap: "wrap" }}>
                    {
                        handleInput(props?.data?.cSTID == "T_M21" ?"product":"")
                    }
                </Grid.Col>
                <Grid.Col span={12}>
                    <Group spacing={"xs"} position="center">
                        <Button style={{ height: "30px", width: "100px" }} size="sm" onClick={handleOk}>Ok</Button>
                    </Group>
                </Grid.Col>
            </Grid>
        }

    </>)
}

export default MasterFilterForm