import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
import Store from "../store";
import { GetAccountList } from "./AccountListSlice";

export const VGetSalesData = createAsyncThunk(
  "VSalesData/VGetSalesData",
  async (obj) => {
    // console.log(obj, " =>Obj");
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode: "",
        cOPara:["SS"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)
            ? "G~C~S"
            :  ["SS"].includes(obj?.cSTID) && ["V"].includes(obj?.p0)
            ? "V~C~S"
            : ["PP"].includes(obj?.cSTID) && ["V"].includes(obj?.p0)
            ? "V~C~P"
            : ["JJ"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)
            ? "G~C~J"
            : ["JZ"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)
            ? "G~C~Z"
            : "G~C~P",
            "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetM52 + "?pa=" + param
      );
      // console.log("response of sales data", response);
      return response?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);

export const VDeleteSalesData = createAsyncThunk(
  "VSalesData/VDeleteSalesData",
  async ({obj,ccode}) => {
    // console.log("obj", obj);
    GlobalClass.Notify(
      "info",
      "Deleting",
      "Please wait while we process your data"
    );
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "D",
        cCode: ccode ? ccode : "",
        cOPara:["SS"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)
        ? "G~C~S"
        :  ["SS"].includes(obj?.cSTID) && ["V"].includes(obj?.p0)
        ? "V~C~S"
        : ["PP"].includes(obj?.cSTID) && ["V"].includes(obj?.p0)
        ? "V~C~P"
        : ["JJ"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)
        ? "G~C~J"
        : ["JZ"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)
        ? "G~C~Z"
        : "G~C~P",
            "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetM52Ent + "?pa=" + param
      );
      // console.log("response  ", response);
      if (response.data.status === "SUCCESS") {
        let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;

        Promise.resolve(Store.dispatch(VGetSalesData(obj))).then(()=>{
          GlobalClass.Notify("success", "Deleted", "Deleted successfully");})
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
      }
      return response.data;
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);
      return { e };
    }
  }
);

const VSalesDataSlice = createSlice({
  name: "VSalesData",
  initialState: {
    VSalesData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
    isLoading1: false,
    hasError1: false,
    ErrorMsg1: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(VGetSalesData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.VSalesData = [];
      })
      .addCase(VGetSalesData.fulfilled, (state, action) => {
        // console.log("VGETSALESDATA==>", action.payload?.error);
        if (!action.payload?.error) {
          state.VSalesData = action.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.VSalesData = action.payload;
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(VGetSalesData.rejected, (state, action) => {
        // console.log("action.payload?.error", action.payload?.error); 
        state.VSalesData=[];
        state.hasError1 = true;
        state.isLoading1 = false;
        state.ErrorMsg1 = action.payload?.error;
      })
      .addCase(VDeleteSalesData.pending, (state, action) => {
        state.isLoading1 = true;
        state.hasError1 = false;
      })
      .addCase(VDeleteSalesData.fulfilled, (state, action) => {
        state.isLoading1 = false;
        state.hasError = false;
      })
      .addCase(VDeleteSalesData.rejected, (state, action) => {
        state.hasError1 = true;
        state.isLoading1 = false;
      });
  },
});
export default VSalesDataSlice.reducer;
