import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import gensetting from "../gensetting";
import GlobalClass from "../GlobalClass";
// import { notifications } from "@mantine/notifications";
import { GetAccountList } from "./AccountListSlice";
import { modals } from "@mantine/modals";
import Store from "../store";


export const ProductGroupApi = createAsyncThunk(
    'ProductGroup/ProductGroupApi',
    async (OBJ) => {
        // console.log("OBJ", OBJ);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": OBJ.p0,
                    "cCode": OBJ.p0 === "E" ? OBJ.id : "",
                    "cOPara": "",
                    "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM32GR + "?pa=" + param)
            // console.log(response, "response");
            return response.data.data
        } catch (error) {
            GlobalClass.Notify("error", "Error", error.message)
        }
    }
)


export const ProductCategoryAPi = createAsyncThunk(
    'ProductGroup/ProductCategoryAPi',
    async (OBJ) => {
        try {
            let param = JSON.stringify({
                "CorpID": gensetting.getcorpid(),
                "cUser": gensetting.getcuser(),
                "cPass": gensetting.getcpass(),
                "CmpNo": gensetting.getcmpno(),
                "cAction": OBJ.p0,
                "cCode": OBJ.p0 === "A" ? "" : OBJ.id,
                "cOPara": "",
                "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            })

            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM32CT + "?pa=" + param);
            // console.log("response ProductCategoryAPi", response);
            return response.data.data;
        } catch (error) {
            GlobalClass.Notify("error", "Error", error.message)
        }
    }

)


export const ProductDeleteApi = createAsyncThunk(
    'ProductGroup/ProductDeleteApi',
    async (OBJ) => {
        GlobalClass.Notify(
            "info",
            "Deleting",
            "Please wait while we process your data"
          );
        try {
            let param = JSON.stringify({
                "CorpID": gensetting.getcorpid(),
                "cUser": gensetting.getcuser(),
                "cPass": gensetting.getcpass(),
                "CmpNo": gensetting.getcmpno(),
                "cAction": OBJ.p0,
                "cCode": OBJ.id,
                "cOPara": "",
                "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            })

            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM32CT + "?pa=" + param);
            // console.log("response ProductCategoryAPi", response);

            if (response.data.status === 'SUCCESS') {
                modals.closeAll();
                let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj

                Store.dispatch(GetAccountList({
                    id: OBJ?.cSTID === 'T_M32G' ? "T_M32G" : "T_M32C",
                    name: OBJ?.cSTID === 'T_M32G' ? "Product Group" : "Product Category",
                    p0: "MT",
                    p1: OBJ?.cSTID === 'T_M32G' ? "T_M32G" : "T_M32C",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj[OBJ?.cSTID]
                }))

                GlobalClass.Notify("success", "Deleted", "Deleted successfully");

            }
            else if (response.data.status === 'FAIL') {
                GlobalClass.Notify(
                    "error",
                    response?.data?.status,
                    response?.data?.message
                  );  }
        }
        catch (e) {
                GlobalClass.Notify("error", "Error", `${e?.message}`);
                return { e };
        }
    }

)


const ProductGroup = createSlice({
    name: 'ProductGroup',
    initialState: {
        ProductGroupData: [],
        ProductCategoryData: [],
        isLoading: false,
        hasError: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(ProductGroupApi.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(ProductGroupApi.fulfilled, (state, action) => {
                // console.log("state", state);
                // console.log("action", action);
                state.ProductGroupData = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(ProductGroupApi.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })

            .addCase(ProductCategoryAPi.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(ProductCategoryAPi.fulfilled, (state, action) => {
                // console.log("state", state);
                // console.log("action", action);
                state.ProductCategoryData = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(ProductCategoryAPi.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })
    }
})

export default ProductGroup.reducer;