import * as SalePurchseEntry from "../Transaction/SaleEntry/TransactionSales";
import * as StockEntry from "../Transaction/StockEntry/OpeningStock";
import * as CreditDebitNote from "../Transaction/StockEntry/CreditDebitNote/CreditDebitNote";
import * as StockJournal from "../Transaction/StockEntry/StockJournalFun";
import * as StockTransfer from "../Transaction/StockEntry/StockTransfer/StockTransferFun";
import * as Production from "../Transaction/Production/Production";
import * as JobWorkOpening from "../Transaction/JobWorkOpening/JobWorkOpening";
import * as JobWorkInOut1 from "../Transaction/JobWorkOut/RIOJobOutFunction/JobWorkOutFun";
import * as JobWorkInOut2 from "../Transaction/JobWorkOut/JobWorkOutBill/JobWorkBillFun";
import * as JobWorkInOut3 from "../Transaction/JobWorkIn/RIPFunctionCalls/JwiRIPFun";
import * as JobWorkInOut4 from "../Transaction/JobWorkIn/BothBillFunCall/JwiJobWorkBillFun";
import * as CashBankEntry from "../FunctionsCall/Transaction/Transaction";
import * as JournalEntry from "../Transaction/JournalEntry/JournalEntry";
import * as GSTEntryJournalEntry from "../Transaction/GST Entry/Journal Entry/JournalEntryFn";

export const VouEntAct = (itemOBJ) => {
    const OBJ = itemOBJ;
    console.log('executeFunction VouEntAct ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "T41VLISTSSSS": //Transaction > Sale entry > Sales Invoice 
        case "T41VLISTSRSR": //Transaction > Sale entry > Sales Return
        case "T41VLISTHSHS": //Transaction > Sale entry > Sales Challan
        case "T41VLISTOSOS": //Transaction > Sale entry > Sales Order
        case "T41VLISTQTQT": //Transaction > Sale entry > Sales Quotation
        case "T41VLISTPPPP": //Transaction > Purchase entry > Purchase Invoice
        case "T41VLISTPRPR": //Transaction > Purchase entry > Purchase Return
        case "T41VLISTHPHP": //Transaction > Purchase entry > Purchase Challan
        case "T41VLISTOPOP": //Transaction > Purchase entry > Purchase Order
            SalePurchseEntry.VouEntAct(JSON.stringify(OBJ))
            break;
        case "TXNVOULISTHO": //transaction > Stock Entry > Opening Stock
            StockEntry.VouEntAct(JSON.stringify(OBJ))
            break;
        case "TXNVOULISTHC": //transaction > Stock Entry > Credit Note
        case "TXNVOULISTHD": //transaction > Stock Entry > Debit Note
            CreditDebitNote.VouEntAct(JSON.stringify(OBJ))
            break;
        case "TXNVOULISTIJ": //transaction > Stock Entry > Stock Journal 
            StockJournal.VouEntAct(JSON.stringify(OBJ))
            break;
        case "T41VOULISTTS": //transaction > Stock Entry > Stock Transfer 
            StockTransfer.VouEntAct(JSON.stringify(OBJ))
            break;
        case "TXNVOULISTIN": //Transaction > Production
            Production.VouEntAct(JSON.stringify(OBJ))
            break;
        case "TXNVOULISTJA": //Transaction > Jobwork Opening
            JobWorkOpening.VouEntAct(JSON.stringify(OBJ))
            break;
        case "TXNVOULISTOJ": //Transaction > Jobwork out > Order
        case "TXNVOULISTJI": //Transaction > Jobwork out > issue
        case "TXNVOULISTJO": //Transaction > Jobwork out > Receipt
        case "TXNVOULISTJD": //Transaction > Jobwork in > sub jobwork > issue
        case "TXNVOULISTJC": //Transaction > Jobwork in > sub jobwork > receipt
            JobWorkInOut1.VouEntAct(JSON.stringify(OBJ))
            break;
        case "TXNVOULISTJJ": // Transaction > jobwork out > Job Work Bill 
        case "TXNVOULISTSJ": // Transaction > jobwork in > sub job work > sub jobwork bill
            JobWorkInOut2.VouEntAct(JSON.stringify(OBJ))
            break;
        case "TXNVOULISTJX": // Transaction > jobwork in > receipt 
        case "TXNVOULISTJY": // Transaction > jobwork in > issue 
        case "TXNVOULISTJW": // Transaction > jobwork in > Production
            JobWorkInOut3.VouEntAct(JSON.stringify(OBJ))
            break;
        case "TXNVOULISTJZ": // Transaction > Jobwork in > Job work Bill 
            JobWorkInOut4.VouEntAct(JSON.stringify(OBJ))
            break;
        case "T41VLISTBPBP": // Transaction > cash/Bank Entry > Bank Payment
        case "T41VLISTBRBR": // Transaction > cash/Bank Entry > Bank receipt
        case "T41VLISTCPCP": // Transaction > cash/Bank Entry > Cash Payment
        case "T41VLISTCRCR": // Transaction > cash/Bank Entry > Cash Receipt
        case "T41VLISTBCBC": // Transaction > cash/Bank Entry > Contra 
            CashBankEntry.VouEntAct(JSON.stringify(OBJ))
            break;
        case "T41VLISTJRBP": //Transaction > Journal Entry > Bank Payment
        case "T41VLISTJRBR": //Transaction > Journal Entry > Bank receipt 
        case "T41VLISTJRCP": //Transaction > Journal Entry > Cash Payment
        case "T41VLISTJRCR": //Transaction > Journal Entry > Cash Receipt
        case "T41VLISTJRBC": //Transaction > Journal Entry > Contra
        case "T41VLISTJRNC": //Transaction > Journal Entry > Credit Note
        case "T41VLISTJRND": //Transaction > Journal Entry > Debit Note
        case "T41VLISTJRJR": //Transaction > Journal Entry > Journal
            JournalEntry.VouEntAct(JSON.stringify(OBJ))
            break;
        case "TXNVOULISTEG": //Transaction > GST Entry >  Journal entry > GST Expense 
        case "TXN_GSTLSTNC": //Transaction > GST Entry >  Journal entry > Credit Note (Without stock) 
        case "TXN_GSTLSTND": //Transaction > GST Entry >  Journal entry > Debit Note (Without stock)  
        case "TXNVOULISTIG": //Transaction > GST Entry >  Journal entry > Gst income
            GSTEntryJournalEntry.VouEntAct(JSON.stringify(OBJ))
            break;
        default:
            break;
    }
}

