import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
// import { notifications } from "@mantine/notifications";
import Store from "../store";

export const GetColumnSelection = createAsyncThunk(
  "Transaction/GetColumnSelection",
  async (cCode) => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode: cCode,
        cOPara: "",
        Yrno: localStorage.getItem("YrNo")?.replace(/[\\"]/g, ""),
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetF4ColumnData + "?pa=" + param
      );
      return response?.data?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);

const ColumnSelectionSlice = createSlice({
  name: "columnSelection",
  initialState: {
    columnSelectionData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetColumnSelection.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.columnSelectionData = [];
      })
      .addCase(GetColumnSelection.fulfilled, (state, action) => {
        console.log("action.payload",action?.payload)
        state.columnSelectionData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetColumnSelection.rejected, (state, action) => {
        state.hasError = true;
        state.ErrorMsg = action.payload?.error;
        state.isLoading = false;
      });
  },
});


export default ColumnSelectionSlice.reducer;