import * as SmsEmailWhatsapp from "../Transaction/SMSEmailWpBtn/SMS/AddBtn";

//Sms Button
export const SMSAED=(itemOBJ)=>{
    const OBJ = itemOBJ;
    console.log('executeFunction SMSAED ===>', OBJ);

    SmsEmailWhatsapp.SMSAED(JSON.stringify(OBJ))
}

//Email Button
export const EMLAED=(itemOBJ)=>{
    
    const OBJ = itemOBJ;
    console.log('executeFunction EMLAED ===>', OBJ);

    SmsEmailWhatsapp.EMLAED(JSON.stringify(OBJ))
    
}

//Whatsapp Button
export const WAPAED=(itemOBJ)=>{
    
    const OBJ = itemOBJ;
    console.log('executeFunction WAPAED ===>', OBJ);

    SmsEmailWhatsapp.WAPAED(JSON.stringify(OBJ))
    
}