import {
  ActionIcon,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  Spoiler,
  Text,
  Textarea,
  Tooltip,
  createStyles,
} from "@mantine/core";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import PLDataGrid from "../../../PlusComponent/PLDataGrid";
import PLDateBox from "../../../PlusComponent/PLDateBox";
import PLNarration from "../../../PlusComponent/PLNarration";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Store from "../../../utils/store";
import {
  GetUserFieldObj,
  TransactionApi,
} from "../../../utils/TransactionSlices/Transaction";
import GlobalClass from "../../../utils/GlobalClass";
import PLTextBox from "../../../PlusComponent/PLTextBox";
import { modals } from "@mantine/modals";
import gensetting from "../../../utils/gensetting";
import { DataSaveLoading } from "../../../utils/slices/DataSaveLoadingSlice";
import { ModalDelete, ModalFunction } from "../../../utils/slices/ModalSlice";
import { createColumnHelper } from "@tanstack/react-table";
import { MRT_TablePagination, MantineReactTable, useMantineReactTable } from "mantine-react-table";
import PLNumberBox from "../../../PlusComponent/PLNumberBox";
import { IconEdit } from "@tabler/icons-react";
import {
  GetAccountList,
  GetTMFormat,
} from "../../../utils/slices/AccountListSlice";
import { getPageId } from "../../../utils/TransactionSlices/GSTEntryPageIDSlice";
import PLBillToBill from "../../../PlusComponent/PLBillToBill";
import OriginalDetailInv from "../OriginalDetailInv";
import OriginalInvoiceDetailTable from "../../StockEntry/CreditDebitNote/OriginalInvoiceDetailTable";
import SITableUserField from "../../SaleEntry/SITableUserField";
import PLTextarea from "../../../PlusComponent/PLTextarea";
import PLUserFieldBtn from "../../../PlusComponent/PLUserFieldBtn";
import FormatListTable from "../../SMSEmailWpBtn/FormatListTable";
import PLPrint from "../../../FunctionsCall/Print/PLPrint";
import { TabRemove } from "../../../utils/slices/TabList";

export default function JournalEntryForm(props) {

  // console.log("JournalEntryForm props=>", props);

  const { index, obj } = props;

  const [group, setGroup] = useState({
    FIELD01: "",
    FIELD02: "20240808",
    FIELD03: 0,
    FIELD04: "",
    FIELD05: "",
    FIELD06: 0,
    FIELD07: 0,
    FIELD08: 0,
    FIELD09: 0,
    FIELD10: "",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "",
    FIELD14: "N",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "U0000000",
    FIELD18: 0,
    FIELD21: "",
    FIELD22: "",
    FIELD23: "",
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD70: "",
    FIELD71: "",
    FIELD75: "",
    FIELD99: "",
    FIELD72: "",
    FIELD20: 0,
    FIELD80: "",
    FIELD51: 0,
    FIELD34: "",
    FIELD35: "",
    FIELD36: "",
    FIELD37: "HOME-BRC",
    FIELD38: "",
    FLDJIO: "",
    FIELD39: "",
    FIELD40: "",
    FIELD41: "",
    FIELD42: "",
    FIELD43: "",
    FIELD44: "",
    FIELD45: "",
    FIELD46: "",
    FIELD47: "",
    FIELD48: "",
    FIELD49: "",
    FIELD50: "",
    FIELD52: "",
    FIELD53: "",
    T41PVT: "NC",
    FIELD54: "",
    T41VNO: "",
    CMID: 0,
    ADDID: 0,
    PRESCPATH: "",
    OSTATUS: "",
    DTYPE: "",
    DELURL: "",
    DELMBNO: "",
    FIELD55: "",
    FIELD56: "",
    T41POS: "",
    FLDUNQ: 0,
    FLDAED: "A",
    FLDBRC: "",
    EA00000001: "",
    EA00000002: "",
    EA00000003: "",
    EA00000004: "",
    EA00000005: "",
    EA00000006: "",
    EA00000007: "",
    EA00000008: "",
    EA00000009: "",
    EA00000010: "",
    EA00000011: "",
    EA00000012: "",
    EA00000013: "",
    EA00000014: "",
    EA00000015: "",
    EA00000016: "",
    EA00000017: "",
    EA00000018: "",
    EA00000019: "",
    EA00000020: "",
    EA00000021: "",
    EA00000022: "",
    ED00000001: 0,
    ED00000002: 0,
    ED00000003: 0,
    ED00000004: 0,
    ED00000005: 0,
    ED00000006: 0,
    ED00000007: 0,
    ED00000008: 0,
    ED00000009: 0,
    ED00000010: 0,
    ED00000011: 0,
    ED00000012: 0,
    ED00000013: 0,
    ED00000014: 0,
    ED00000015: 0,
    ED00000016: 0,
    ED00000017: 0,
    ED00000018: 0,
    ED00000019: 0,
    ED00000020: 0,
    ED00000021: 0,
    ED00000022: 0,
    EP00000001: 0,
    EP00000002: 0,
    EP00000003: 0,
    EP00000004: 0,
    EP00000005: 0,
    EP00000006: 0,
    EP00000007: 0,
    EP00000008: 0,
    EP00000009: 0,
    EP00000010: 0,
    EP00000011: 0,
    EP00000012: 0,
    EP00000013: 0,
    EP00000014: 0,
    EP00000015: 0,
    EP00000016: 0,
    EP00000017: 0,
    EP00000018: 0,
    EP00000019: 0,
    EP00000020: 0,
    EP00000021: 0,
    EP00000022: 0,
    PA000001: "",
    PA000002: "",
    PD000001: 0,
    PD000002: 0,
    UBILTO: "",
    UDISP: "",
    UEMAIL: "",
    UGSTR: "",
    UH: "",
    ULEN: "",
    ULRD: "",
    UNAME: "",
    UOD: "",
    UODD: "",
    UODP: "",
    UOP: "",
    UPAYT: "",
    UPR: "",
    URA: "",
    UREMA: "",
    UREST6: "",
    USAL: 0,
    USALM: "",
    UTR: "",
    UTRA: "",
    UU05: "",
    UU1: "",
    UU7: "",
    UU73: "",
    UU80: "",
    UU87: "",
    UU89: "",
    UUAASS01: "",
    UUABCD: "",
    UUASWER: "",
    UUE: "",
    UUFR4: "",
    UUGFEJH: "",
    UUISSUID: "",
    UUJA: "",
    UUJBWID: "",
    UUJWTRID: "",
    UUPRDID: "",
    UUPRODC: "",
    UUPRTR: "",
    UUSALESB: "",
    UUSALM: "",
    UUT: "",
    UUT78: "",
    UUTEST56: "",
    UUTPR: "",
    UUUASD09: "",
    UUUASWER: "",
    UUUJBOS: "",
    UUUJOB: "",
    UUUOS: "",
    EA00000023: "",
    ED00000023: 0,
    EP00000023: 0,
    EA00000024: "",
    ED00000024: 0,
    EP00000024: 0,
    DSDATE: "2023-04-01T00:00:00+03:00",
    DEDATE: "2024-03-31T00:00:00+02:00",
    CACTION: "A",
    lInvType: false,
    lMTaxEnt: false,
    T92F00: "",
    FIELD04NM: "",
    FIELD05NM: "",
    FIELD39NM: "",
    FIELD34NM: "",
    CFRMTITLE: "Add Credit Note",
    BTOBEFF: " ",
    VATFDATE: null,
    VATTDATE: null,
    VATTYPE: null,
    CLBAL: "",
    VOUTYPE: "NCNC",
  });
  const TableContainer = useRef(null);
  const TableRef = useRef();

  const [dayVal, setDayVal] = useState("");
  const [NarrationData, setNarrationData] = useState([]);
  const [t01Data, setT01Data] = useState([]);
  const [t01Obj, setT01Obj] = useState({
    FIELD01: null,
    FIELD02: null,
    FIELD03: null,
    FIELD04: null,
    FIELD05: null,
    FIELD06: null,
    FIELD09: null,
    FIELD11: null,
    FIELD12: null,
    FIELD15: null,
    FIELD16: null,
    FIELD20: null,
    FIELD21: null,
    FIELD22: null,
    FIELD25: null,
    FIELD26: null,
    FIELD58: null,
    FIELD23: null,
    FIELD59: null,
    T41PVT: null,
    FIELD24: null,
    FIELD75: null,
    FIELD56: null,
    FIELD57: null,
    FLDUNQ: null,
    FLDAED: null,
    FLDBRC: null,
    EGKEYID: 1,
    SGSTAMT: 0.0,
    CGSTAMT: 0.0,
    IGSTAMT: 0.0,
    CESSAMT: 0.0,
    UFDUMMY: null,
    SGSTACC: null,
    CGSTACC: null,
    IGSTACC: null,
    CESSACC: null,
    TOTAMT: null,
    NTBAMT: null,
    NSEQ: null,
    F03NM: null,
    F56NM: null,
    NTPAMT: null,
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const [T01Ent, setT01Ent] = useState([]);
  const [billNoData, setBillNoData] = useState("");
  const [expenseAccDt, setExpenseAccDt] = useState({});
  const [M01PDT, setM01PDT] = useState("");
  const [effectIn, setEffectIn] = useState(false);
  const [SelectedNarrationData, setSelectedNarrationData] = useState([]);
  const [creatingModeOn, setCreatingModeOn] = useState(false);
  const [hasFocused, setHasFocused] = useState(true);
  const [PRPopUp, setPRPopUp] = useState(false);
  // const [autoFocus, setAutoFocus] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  // const [billAmt, setBillAmt] = useState(0);
  const [T05DataArray, setT05DataArray] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [f02,setF02]=useState(false);
  const [vDate,setVDate]=useState("");
  // const [P_T41DT, setPT41DT] = useState({});
  const [textBox, setTextBox] = useState(false);
  const [InvTableData, setInvTableData] = useState([]);
  const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
    BillNoVal: "",
    TextInput: false,
    DataGridInput: true,
  });
  const [BillNoStatus, setBillNoStatus] = useState(false)
  const [enableOk, setEnableOk] = useState(false);
  // const [filterT41, setFilterT41] = useState([]);
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseBtn: null
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [tableEditingRow, setTableEditingRow] = useState(null);
  const [columns, setColumns] = useState([]);
  const [lTpEnt, setlTpEnt] = useState(null);
  const [M51, setM51] = useState({});
  const [userFieldHideShow, setUserFieldHideShow] = useState("");
  const [partyData, setPartyData] = useState({});
  const [type, setType] = useState("");
  const [TotalAmtSaveFlag,setTotalAmtSaveFlag]=useState(false)

  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const spoilerControlRef = useRef(null);
  const formRef = useRef(null);
  const expRef = useRef(null);
  const docDateRef = useRef(null)

  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const oYear = useSelector((state) => state?.Language?.General?.oYear);

  const {
    isLoading: isLoadingEnt,
    hasError: hasErrorEnt,
    ErrorMsg,
  } = useSelector((state) => state?.Transaction);

  const UFT41H = useSelector(
    (state) => state.Transaction?.TransactionData?.UFT41H
  );
  const UFT01M = useSelector(
    (state) => state?.Transaction?.TransactionData?.UFT01M
  );
  const formVouData = useSelector(
    (state) => state?.Transaction?.TransactionData
  );
  const footerDate = useSelector((state)=>state?.Language?.footerDate);

  const {
    AccountListData: accList,
    TMFormatData: tmList,
    isLoading: AccNameisLoading,
  } = useSelector((state) => state.AccountList);

  const GetYearAndDate = useSelector(
    (state) => state?.UserList?.YearAndDateSend
  );
  const { isUserFieldLoading, UserField } = useSelector(
    (store) => store?.Transaction
  );

  let editData = props.obj;
  let editId = editData?.id;
  let p0 = props.obj.p0;
  let page = props.obj.page;
 
  // console.log(page, "IN Page");

  let stringOfNames;

  const taxRef = useRef();
  const hsnRef = useRef();
  const vouNoRef = useRef();
  // const typeRef =useRef();

  const columnHelper = createColumnHelper();
  

  //pre filled fields
  useEffect(()=>{
    if(vDate?.length>0 && p0=="A"){
      setGroup({...group,FIELD02:vDate})}
  },[vDate,group?.FIELD02])
  
  useEffect(()=>{
    if(type?.length>0 && p0=="A"){
       setGroup({...group,FIELD21:type})
    }
  },[type,group?.FIELD21])
  
  useEffect(()=>{
    if(Object.keys(partyData).length!=0 && p0=="A" && group?.FIELD04==""){
      setGroup({...group,FIELD04:partyData?.FIELD04,FIELD04NM:partyData?.FIELD04NM})
    }
  },[partyData,group?.FIELD04])

  useEffect(() => {
    console.log(obj, "columnHelper");
    // if (editData?.p0 == "E") {
    //   Promise.resolve(Store.dispatch(TransactionApi(obj))).then((data) => {
    //     // console.log(data?.payload , "data?.payload")
    //     setEditT41(data?.payload?.T41);
    //     setEditT01(
    //       data?.payload?.T01?.filter(
    //         (t) => t?.FIELD03 != "" && t?.FIELD03 != null
    //       )
    //     );
    //     if (data?.payload?.T01?.length > 0) {
    //       setIsVisible(true);
    //     } else {
    //       setIsVisible(false);
    //     }
    //   });
    // } else {
    if (obj) {
      Promise.resolve(
        Store.dispatch(
          TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 })
        )
      ).then(() => setHasFocused(true));
    }
    // }
  }, [obj]);

  useEffect(() => {
    if (UFT41H && p0 == "E") {


      UFT41H?.forEach((item) => {
        if (item?.S13F14 == "PLPOPUP") {
          const key = seperate(item.S13F09)[1]; // Key
          const value = T41?.[item.S13F02]; // Value
          // console.log("key here", key);
          // console.log("group", T41);
          // console.log("[item.S13F02]", [item.S13F02])
          // console.log("value here", T41?.[item.S13F02]);
          // }
          if (map.has(key)) {
            // If it exists, add the value to the set/array of values for that key
            map.get(key).add(value); // Use a Set to ensure uniqueness of values

          }
          else {
            map.set(key, new Set([value]));
          }
        }

      })

      if (map?.size > 0) {
        // console.log("map?.size", map?.size);
        map.forEach((value, key) => {
          // console.log("Array.from(value)", value, "key", key);
          const dyValue = Array.from(value).length ? ("'" + Array.from(value).join("', '") + "'") : '';
          // console.log("dyValue", dyValue);
          setTimeout(() => {
            Promise.resolve(() => {
              dispatch(
                GetAccountList({
                  id: key,
                  name: "",
                  type: "A",
                  p0: key,
                  p1: key,
                  p2: "",
                  p3: "",
                  cWhere: `${seperate4(key)[1]}.FIELD01 IN (${dyValue})`,
                })
              );
            }).then(() => {
              dispatch(GetTMFormat({
                "id": key,
                "name": "",
                "type": "A",
                "p0": key,
                "p1": key,
                "p2": "TFORM0000001",
                "p3": ""
              }))
            })
          }, [500])
        })
      }




    }
  }, [UFT41H])

  let dataEnt = formVouData;

  const T01 = formVouData?.T01;
  const T41 = formVouData?.T41;
  const COP91 = formVouData?.COP91;
  const P_TAX = formVouData?.P_TAX;
  const COPGE = formVouData?.COPGE;
  const T92DT = formVouData?.T92DT;
  const T92 = formVouData?.T92;
  const COP92 = formVouData?.COP92;
  const COP93 = formVouData?.COP93;
  // const M01PDT = formVouData?.M01PDT;
  const M01P = formVouData?.M01P;
  const lEditVou = formVouData?.lEditVou;
  const COP1 = formVouData?.COP1;
  // const P_T41DT = formVouData?.P_T41DT?.jData;
  const P_T41 = formVouData?.P_T41;
  const OS11 = formVouData?.OS11;
  const T05ENT = formVouData?.T05ENT;
  const T05 = formVouData?.T05;
  const OSV = formVouData?.OSV;

  const rowSum = t01Data?.reduce((a, b) => (a = a + b?.TOTAMT), 0);
  const map = - new Map();
  useEffect(() => {
    // console.log(lEditVou, "T01--");
    if (T01) {
      setT01Data(T01);
      if (obj.p0 == "E") {
        setT01Obj(T01?.[T01?.length - 1]);
      } else {
        setT01Obj(T01?.[0])
      }
    }
  }, [T01]);

  useEffect(() => {
    // console.log(OS11?.S11F01, "OS11?.S11F01=");
    if (OS11?.S11F01 && obj?.p0 == "U") {
      dispatch(GetUserFieldObj(OS11?.S11F01));
    }
  }, [OS11?.S11F01, obj]);

  // useEffect(() => {
  //   Store.dispatch(getPageId(page));
  // }, [page]);

  useEffect(() => {
    if (oYear) {
      setUserFieldHideShow(oYear?.OSC?._USERFIELD);
    }
  }, [oYear]);

  function UFT41HFieldRenderer(e) {
    if (e == "Header") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((h) => {
          // console.log("User Field=>", h?.S13F14, h?.S13F07, h?.S13F02);
          if (h?.S13F87 == "HEADER") {
            //b?.S13F14
            //console.log("UFT41HRenderHeader==>",h);
            return (
              <>
                {h?.S13F14 == "TEXTBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "NUMBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLNumberBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "PLPOPUP" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDataGrid
                        width={"500px"}
                        name={h.S13F02}
                        value={{
                          value: group?.[h?.S13F02],
                          label: accList?.[seperate(h.S13F09)[1]]?.filter((a) => a?.FIELD01 == group?.[h?.S13F02])?.[0]?.[h?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(h.S13F09)[1]]?.filter((a) => a?.FIELD01 === group?.[h?.S13F02])?.[0] || {}) ? "FIELD02" : tmList[seperate(h?.S13F09)[1]]?.oS60?.cAssignFld)],
                        }}
                        p0={seperate(h.S13F09)[0]}
                        p1={seperate(h.S13F09)[1]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e);
                          setGroup({ ...group, [h?.S13F02]: e?.FIELD01 });
                          // M01handlechange({ id: item.S13F02, value: e })
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "COMBOBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        value={group?.[h?.S13F02]}
                        copno={seperate(h?.S13F09)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e?.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "DATEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        defaultval={
                          group?.[h?.S13F02]?.trim() != ""
                            ? group?.[h?.S13F02]
                            : GetYearAndDate?.strtDate
                        }
                        dispformat="DD/MM/YYYY"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          let { startDate } = DateToString(e);
                          if (startDate?.length == 8) {
                            setGroup({ ...group, [h?.S13F02]: e });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "TIMEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        // value={new Date(group?.[h?.S13F02]).getTime()}
                        dispformat="DD/MM/YYYY"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "N" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "H" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "I" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "A" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}
              </>
            );
          }
        })
      );
    } else if (e == "Footer") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((f) => {
          if (f?.S13F87 == "FOOTER") {
            //b?.S13F14
            return (
              <>
                {f?.S13F14 == "TEXTBOX" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "NUMBOX" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLNumberBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "PLPOPUP" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDataGrid
                          width={"500px"}
                          value={{
                            value: group?.[f?.S13F02],
                            label: accList?.[seperate(f.S13F09)[1]]?.filter((a) => a?.FIELD01 == group?.[f?.S13F02])?.[0]?.[f?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(f.S13F09)[1]]?.filter((a) => a?.FIELD01 === group?.[f?.S13F02])?.[0] || {}) ? "FIELD02" : tmList[seperate(f?.S13F09)[1]]?.oS60?.cAssignFld)],
                          }}
                          p0={seperate(f.S13F09)[0]}
                          p1={seperate(f.S13F09)[1]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e?.FIELD01 });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "COMBOBOX" && (
                  <>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                     
                      <PLComboBox
                        value={group?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col> */}
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLComboBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "DATEPICKER" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDateBox
                          defaultval={
                            group?.[f?.S13F02]?.trim() != ""
                              ? group?.[f?.S13F02]
                              : GetYearAndDate?.strtDate
                          }
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            let { startDate } = DateToString(e);
                            if (startDate?.length == 8) {
                              setGroup({ ...group, [f?.S13F02]: e });
                            }
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    
                      <PLDateBox
                        // value={new Date(group?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f?.S13F14 == "TIMEPICKER" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDateBox
                          // value={new Date(group?.[f?.S13F02]).getTime()}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                     
                      <PLDateBox
                        // value={new Date(group?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "N" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                 
                      <PLTextBox
                        value={group?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "H" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "I" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "A" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}
              </>
            );
          }
        })
      );
    }
  }

  const NextIndexFun = (index) => {
    let nextInd;
    // console.log("index21", index);
    nextInd = index + 1;
    // console.log("nextind", nextInd, `T01Ent_${nextInd}`);
    const nextInput = document.getElementById(`T01Ent_${nextInd}`);
    // console.log("nextInput=>", nextInput);

    if (nextInput) {
      nextInput?.focus();
    }
  };
  useEffect(() => {
    // console.log(expenseAccDt, "expenseAccDt-");
    if (expenseAccDt !== null) {
      Promise.resolve().then(() => {
        setT01Obj({
          ...t01Obj,
          FIELD03: expenseAccDt?.FIELD01 ?? t01Obj?.FIELD03,
          F03NM: expenseAccDt?.FIELD02 ?? t01Obj?.F03NM,
        });
      });
    }
  }, [expenseAccDt, t01Obj?.FIELD03, t01Obj?.F03NM]);

  const HiddenButtonField = (uID, v, c, cellid, index) => {
    switch (c.S53F02) {
      case "RID0064": //User Field
        // console.log(UFT01M, "UFT01M--");
        if (UFT01M?.length > 0) {
          // console.log(UFT01M?.length, "UFT01M11");
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>User Field</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            body: (
              <>
                <SITableUserField
                  T02UF={UFT01M}
                  T02ObjSend={t01Obj}
                  setT02Objsend={(e) => {
                    setT01Obj(e);
                    Promise.resolve(setT01Obj(e)).then(() => {
                      NextIndexFun(index);
                    });
                  }}
                  DrawerOBJ={DrawerObj}
                  setDrawerOBJ={(e) => {
                    setDrawerObj(e);
                  }}
                />
              </>
            ),
            open: true,
            size: "xs",
            position: "right",
          });
        } else {
          // console.log(UFT01M, "UFT01M--");
          taxRef?.current?.handlePopoverToggle();
          // NextIndexFun(index);
        }
        break;
      default:
        break;
    }
  };

  function renderValue(uID, v, c, cellid, index, column) {
    let setInput;
    // console.log("c?.S53F03", c?.S53F03, "==>", index);
    if (c?.S53F07 == 0) {
      // console.log("c==>", c?.S53F02, "==>", c?.S53F07);
      return (
        <Button
          id={`T01Ent_${index}`}
          variant="default"
          onFocus={() => {
            document.activeElement.blur();
            HiddenButtonField(uID, v, c, cellid, index);
          }}
          onClick={() => HiddenButtonField(uID, v, c, cellid, index)}
          style={{margin: "0px", padding: "0px" }}
        />
      );
    } else {
      switch (c?.S53F14) {
        case "PLPOPUP":
          if (c?.S53F02 == "RID0065") {
            // Tax Class
            const qry3 = `M51.FIELD08 LIKE '%G%'`;
            setInput = (
              <PLDataGrid
                ref={taxRef}
                cWhere={qry3}
                id={`T01Ent_${index}`}
                width={"500px"}
                value={{
                  value: t01Obj?.[c?.S53F04],
                  label: t01Obj?.F56NM,
                }}
                p1={seperate(c.S53F15)[1]}
                setEdit={(e) => {
                  // console.log(e?.FIELD02, "e?.FIELD02");
                  setM51(e);
                  setT01Obj({
                    ...t01Obj,
                    [c?.S53F04]: e?.FIELD01,
                    F56NM: e?.FIELD02,
                  });
                  hsnRef.current.handlePopoverToggle();
                  // NextIndexFun(index);
                }}
              />
            );
          } else if (c?.S53F02 == "RID0063") {
            //Expense A/c. Name
            const qry2 = `(M01.FIELD07 NOT LIKE '%TX%' AND M01.FIELD07 NOT LIKE '%PR%' AND M01.FIELD07 NOT LIKE '%CS%' AND M01.FIELD07 NOT LIKE '%BK%' AND M01.FIELD07 NOT LIKE '%PV%') OR (M01.FIELD07 LIKE '%TX%' AND (M01.FIELD22 LIKE '%S%' AND M01.FIELD22 LIKE '%T%'))`;

            setInput = (
              <PLDataGrid
                ref={expRef}
                id={`T01Ent_${index}`}
                cWhere={qry2}
                width={"500px"}
                // value={t01Obj?.[c?.S53F04]}
                value={{
                  value: t01Obj?.[c?.S53F04],
                  label: t01Obj?.F03NM,
                }}
                p1={seperate(c.S53F15)[1]}
                setEdit={(e) => {
                  Promise.resolve(
                    setT01Obj({
                      ...t01Obj,
                      [c?.S53F04]: e?.FIELD01,
                      F03NM: e?.FIELD02,
                    })
                  )
                    .then(() => {
                      setExpenseAccDt(e);
                    })
                    .then(() => {
                      NextIndexFun(index);
                    });
                }}
              />
            );
          } else if (c?.S53F02 == "RID0066") {
            //HSN Code
            setInput = (
              <PLDataGrid
                ref={hsnRef}
                id={`T01Ent_${index}`}
                width={"500px"}
                // value={t01Obj?.[c?.S53F04]}
                value={{
                  value: t01Obj?.[c?.S53F04],
                  label: t01Obj?.FIELD59,
                }}
                p1={seperate(c.S53F15)[1]}
                dispexpr={"FIELD01"}
                setEdit={(e) => {
                  // console.log(e, "HSN--", c?.S53F04);
                  Promise.resolve(
                    setT01Obj({
                      ...t01Obj,
                      [c?.S53F04]: e?.FIELD01,
                      FIELD59: e?.FIELD01,
                    })
                  ).then(() => {
                    NextIndexFun(index);
                  });
                }}
              />
            );
          } else {
            setInput = (
              <PLDataGrid
                id={`T01Ent_${index}`}
                width={"500px"}
                value={t01Obj?.[c?.S53F04]}
                p1={seperate(c.S53F15)[1]}
                setEdit={(e) => {
                  // console.log(e, "Taxclass--", c?.S53F04);
                  setT01Obj({ ...t01Obj, [c?.S53F04]: e?.FIELD01 });
                  NextIndexFun(index);
                }}
              />
            );
          }

          break;
        case "COMBOBOX":
          setInput = (
            <PLComboBox
              cmbid={`T01Ent_${index}`}
              value={t01Obj?.[c?.S53F04]}
              copno={seperate(c.S53F15)[1]}
              dispexpr={"DisplayMember"}
              valexpr={"ValueMember"}
              setEdit={(e) => {
                Promise.resolve(
                  setT01Obj({ ...t01Obj, [c?.S53F04]: e.value })
                ).then(() => NextIndexFun(index));
              }}
            />
          );
          break;
        case "TEXTBOX":
          setInput = (
            <PLTextBox
              id={`T01Ent_${index}`}
              value={t01Obj?.[c?.S53F04]}
              setEdit={(e) => {
                setT01Obj({ ...t01Obj, [c?.S53F04]: e.target.value });
                NextIndexFun(index);
              }}
            />
          );
          break;
        case "NUMBOX":
          if (c?.S53F02 == "RID0074") {
            // console.log("RID0074000");
            setInput = (
              <PLNumberBox
                decimalSeparator={true}
                precision={2}
                onKeyDown={(e) => {
                  // console.log("RID0074", e);
                  if (e.key == "Enter" || e.key == "Tab") {
                    // console.log("Enter key Press", tableEditingRow);
                    if (tableEditingRow == null) {
                      // console.log("Add Product Call", t01Obj?.FIELD03);
                      if (t01Obj?.FIELD03 == null || t01Obj?.FIELD03 == "") {
                        GlobalClass.Notify(
                          "warning",
                          "Warning",
                          "Some Field Require to Enter"
                        );
                      } else {
                        // console.log("Add Product Call", t01Obj?.FIELD03);
                        handleCreateUser()
                        // .then(() => {

                        // });
                      }
                    } else {
                      // console.log("Edit Product Call");
                      if (t01Obj?.FIELD03 == null || t01Obj?.FIELD03 == "") {
                        GlobalClass.Notify(
                          "warning",
                          "Warning",
                          "Some Field Require to Enter"
                        );
                      } else {
                        handleSaveUser()
                      }
                    }
                  } else if (e.key == "Escape") {
                    // console.log("Esc key Press", e);
                    rowCancelHandler();
                    table.setCreatingRow(false);
                  }
                }}
                id={`T01Ent_${index}`}
                value={t01Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  setT01Obj({ ...t01Obj, [c?.S53F04]: e });
                  // NextIndexFun(index);
                }}
              />
            );
          } else {
            setInput = (
              <PLNumberBox
                decimalSeparator={true}
                precision={2}
                id={`T01Ent_${index}`}
                value={t01Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  setT01Obj({ ...t01Obj, [c?.S53F04]: e });
                  // NextIndexFun(index);
                }}
                disabled={
                  c?.S53F02 == "RID0068"
                    ? t01Obj?.FIELD56 == null
                      ? true
                      : false
                    : false
                }
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    let ind1 = index - 1;
                    // let ind2 = index + 1;
                    // let ind = index;

                    NextIndexFun(ind1);
                  }
                }}
              />
            );
          }
          break;
        case "DATEPICKER":
          setInput = (
            <PLDateBox
              // defaultval={t01Obj?.[c?.S53F04]}
              id={`T01Ent_${index}`}
              dispformat="DD/MM/YYYY"
              setEdit={(e) => {
                // console.log("===>",e);

                let year = e.toLocaleString("en-IN", { year: "numeric" });
                let month = e.toLocaleString("en-IN", { month: "2-digit" });
                let day = e.toLocaleString("en-IN", { day: "2-digit" });

                let BillDate = year + month + day;

                setT01Obj({ ...t01Obj, [c?.S53F04]: BillDate });
                NextIndexFun(index);
              }}
            />
          );

          break;
        default:
          setInput = (
            <PLTextBox
              id={`T01Ent_${index}`}
              value={t01Obj?.[c?.S53F04]}
              setEdit={(e) => {
                setT01Obj({ ...t01Obj, [c?.S53F04]: e.target.value });
                NextIndexFun(index);
              }}
            />
          );
          break;
      }
    }
    return setInput;
  }
  // function modeOn(arg) {
  //   setCreatingModeOn(arg);
  // }
  const handleT01Delete = (table) => {
    // set
    let row = table?.getSelectedRowModel()?.flatRows[0]?.original
    // console.log("handleT01Delete_row", row)
    if (row?.FIELD03 == "" || row?.FIELD03 == null) {
      return
    } else {
      if (window.confirm('Confirm to Delete ?')) {
        let keys = Object.keys(rowSelection);
        if (t01Data.hasOwnProperty(keys)) {
          let filteredArr = t01Data?.filter((v, i) => i != keys);
          setT01Data(filteredArr);
          // console.log(filteredArr , "filteredArr")
        }
      }
    }
  }
  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);
  useEffect(() => {
    if (T05) {
      setT05DataArray(T05);
    }
  }, [T05]);
  useEffect(() => {
    // if (editData?.p0 == "E") {
    //   if (editT41) setGroup(editT41);
    // } else {
    // console.log(formVouData?.T41, "formVouData--");
    if (formVouData) {
      setT01Ent(formVouData?.T01ENT);
      setGroup(formVouData?.T41);
    }
    // }
  }, [formVouData]);
  const BillToBillTableFun = () => {
    // document.getElementById("okbutton").focus();

    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Bill to Bill</Text>,
      body: (
        <>
          <PLBillToBill
            T41ObjSend={group}
            T05Format={T05ENT}
            T05UserField={dataEnt?.UFT05H}
            T05Data={T05DataArray}
            setT05Data={(e) => setT05DataArray(e)}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  };
  const seperate = (e) => {
    var paraArr = e.split("~C~");
    // console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const seperate3 = (e) => {
    var paraArr = e.split("_");
    // console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const seperate4 = (e) => {
    var paraArr = e.split("_");
    // console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };
  const myComboBox = document.getElementById("#typeId");
  const revVou = document.getElementById("#revId");

  function rowEditCancelHandler(props) {
    if (p0 == "E") {
      setT01Obj(T01?.[T01?.length - 1]);
    } else {
      setT01Obj(T01?.[0]);
    }
    setExpenseAccDt(null);
    setTableEditingRow(null);
    // modeOn(false);
  }
  function handleCreateUser(props) {
    // console.log("EditSave", t01Obj);
    if (t01Obj?.FIELD03 == "" || t01Obj?.FIELD03 == null) {
      GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter");
    } else {
      let nullFilter = t01Data?.filter((d) => d?.FIELD03 != "")
      setT01Data([...nullFilter, (p0 == "E" ? T01?.[T01?.length - 1] : T01?.[0])]);

      if (p0 == "E") {
        setT01Obj(T01?.[T01?.length - 1]);
      } else {
        setT01Obj(T01?.[0]);
      }
      setCreatingModeOn(false);
      setTableEditingRow(null);
      table.setCreatingRow(null);
      setIsVisible(true);
      setExpenseAccDt(null);
      // modeOn(false);
    }
  }

  // console.log(billNoData, "billNoData-")
  function handleSaveUser(props) {
    // console.log(tableEditingRow, "handleSaveUser", t01Data, t01Obj);
    // modeOn(true);
    let row = props?.row;
    let EditT01Data = [...t01Data];
    EditT01Data[tableEditingRow?.index] = t01Obj;
    let nullFilter = EditT01Data?.filter((d) => d?.FIELD03 != "")
    setT01Data([...nullFilter, (obj.p0 == "E" ? T01?.[T01?.length - 1] : T01?.[0])]);
    setIsVisible(true);
    setCreatingModeOn(false);
    if (p0 == "E") {
      setT01Obj(T01?.[T01?.length - 1]);
    } else {
      setT01Obj(T01?.[0]);
    }
    setRowSelection({ [t01Data?.length]: true })
    setExpenseAccDt(null);
    table.setEditingRow(null); //exit editing mode
    setTableEditingRow(null);
    // props.exitEditingMode();
  }
  function rowCancelHandler() {
    // modeOn(false);
    setCreatingModeOn(false);
    if (p0 == "E") {
      setT01Obj(T01?.[T01?.length - 1]);
    } else {
      setT01Obj(T01?.[0]);
    }
    setRowSelection({});
    setExpenseAccDt(null);
    table.setEditingRow(null);
  }
  const Summary = (obj, i, tag) => {
    // let stype = "sum";
    var value = "";
    switch (obj?.S53F02) {

      case "RID0068": //Amount
        value = t01Data?.reduce((a, b) => (a = a + b?.NTBAMT), 0);
        break;
      case "RID0069": //Acc Amt
        value = t01Data?.reduce((a, b) => (a = a + b?.NTPAMT), 0);
        break;
      case "RID0072": //IGST
        value = t01Data?.reduce((a, b) => (a = a + b?.IGSTAMT), 0);
        break;
      case "RID0074": //Total Amount
        value = t01Data?.reduce((a, b) => (a = a + b?.TOTAMT), 0);
        break;
      default:
        value = null;
        break;
    }
    return value;
  };

  useEffect(() => {
    console.log(oYear, "GetGeneral--");
    console.log("OS11", OS11);
    setlTpEnt((oYear?.OSC?._TPRTNCNT && OS11?.S11F01 == "NC") || (oYear?.OSC?._TPRTNDNT && OS11?.S11F01 == "ND") ||
      (oYear?.OSC?._TPPPRATE && (OS11?.S11F01 == "PP" || OS11?.S11F01 == "PR")) || (oYear?.OSC?._TPRATE && (OS11?.S11F01 == "SS" || OS11?.S11F01 == "SR")));
  }, [oYear?.OSC, OS11]);


  useEffect(() => {
    if ((t01Obj?.NTBAMT != "" || typeof t01Obj?.NTBAMT == "number") && M51 && !(Object.keys(M51).length === 0) && M51.constructor === Object) {
      let ncPer = M51?.FIELD05;
      let nSPer = M51?.FIELD07;
      let nIPer = M51?.FIELD06;
      let nCsPer = M51?.FIELD09;
      let nVal = 0;

      console.log("lTpEnt", lTpEnt);

      if (lTpEnt) {
        if (group?.FIELD53 == "Y" && oYear.OSC._GR1RTTAX && (OS11.S11F01 == "NC" || OS11.S11F01 == "ND" || OS11.S11F01 == "JR")) {
          if (group.FIELD21 == "G" || group.FIELD21 == "C") {
            nVal = (t01Obj?.NTBAMT * 100) / (2 * (nSPer * 0.65) + nCsPer + 100);
          } else {
            nVal = (t01Obj?.NTBAMT * 100) / (nIPer * 0.65 + nCsPer + 100);
          }
        } else {
          if (group.FIELD21 == "G" || group.FIELD21 == "C") {
            nVal = (t01Obj?.NTBAMT * 100) / (2 * nSPer + nCsPer + 100);
          } else {
            nVal = (t01Obj?.NTBAMT * 100) / (nIPer + nCsPer + 100);
          }
        }
      } else {
        nVal = t01Obj?.NTBAMT;
      }

      console.log("nVal--", nVal, t01Obj?.NTBAMT, ncPer, nSPer, nCsPer);
      console.log("group.FIELD21", group.FIELD21, t01Obj);

      let decimalPoint = oYear?.OSC?._RATEDEC;

      if (nVal != null && ((ncPer != 0 && nSPer != 0) || nIPer != 0)) {
        // if (nVal != null && ncPer != 0 && nSPer != 0) {

        console.log("decimalPoint", decimalPoint, group.FIELD21);
        let FinalT01Object = {...t01Obj}

        let CessAmt = Math?.round((nVal * nCsPer) / 100);
        let roundedCess = parseFloat(CessAmt?.toFixed(decimalPoint));
        // setT01Obj({ ...t01Obj, CESSAMT: roundedCess });

        if (group.FIELD21 == "G" || group.FIELD21 == "C" || group.FIELD45 == "3") {

          let CGSTAMT = Math?.round((nVal * ncPer) / 100);
          let SGSTAMT = Math?.round((nVal * nSPer) / 100);

          let roundedCGST = parseFloat(CGSTAMT?.toFixed(decimalPoint));
          let roundedSGST = parseFloat(SGSTAMT?.toFixed(decimalPoint));

          console.log("roundedCGST", roundedCGST);
          console.log("roundedSGST", roundedSGST);
          // roundedCGST && roundedSGST && 
          FinalT01Object={ 
            ...FinalT01Object,
             SGSTAMT: roundedSGST,
              CGSTAMT: roundedCGST,
              CESSAMT: roundedCess
            }
          // setT01Obj({ 
          //   ...t01Obj,
          //    SGSTAMT: roundedSGST,
          //     CGSTAMT: roundedCGST,
          //     CESSAMT: roundedCess
          //   });

        } else if (["I","M","X","S"].includes(group.FIELD21) ||["4","X","I","M","S","F"].includes(group.FIELD45)) {

          let IGSTAMT = Math?.round((nVal * nIPer) / 100);
          let roundedIGST = parseFloat(IGSTAMT?.toFixed(decimalPoint));

          FinalT01Object = { 
            ...FinalT01Object,
             IGSTAMT: roundedIGST,
             CESSAMT: roundedCess
             }
          // setT01Obj({ 
          //   ...t01Obj,
          //    IGSTAMT: roundedIGST,
          //    CESSAMT: roundedCess
          //    });
        }else if (["8"].includes(group.FIELD21) ||["8"].includes(group.FIELD45)) {

          let CGSTAMT = Math?.round((nVal * 0.1) / 100);
          let SGSTAMT = Math?.round((nVal * 0.1) / 100);
          let roundedCGST = parseFloat(CGSTAMT?.toFixed(decimalPoint));
          let roundedSGST = parseFloat(SGSTAMT?.toFixed(decimalPoint));
          FinalT01Object = { 
            ...FinalT01Object,
             SGSTAMT: roundedSGST,
              CGSTAMT: roundedCGST,
              CESSAMT: roundedCess
            }
          // setT01Obj({ 
          //   ...t01Obj,
          //    SGSTAMT: roundedSGST,
          //     CGSTAMT: roundedCGST,
          //     CESSAMT: roundedCess
          //   });

        }else if (["9"].includes(group.FIELD21) || ["9"].includes(group.FIELD45)) {

          let IGSTAMT = Math?.round((nVal * 0.1) / 100);
          let roundedIGST = parseFloat(IGSTAMT?.toFixed(decimalPoint));
          FinalT01Object = {
            ...FinalT01Object, 
            IGSTAMT: roundedIGST,
            CESSAMT: roundedCess
           }
          // setT01Obj({
          //    ...t01Obj, 
          //    IGSTAMT: roundedIGST,
          //    CESSAMT: roundedCess
          //   });

        }

        console.log("FinalT01Object =>",FinalT01Object);
        setT01Obj({...FinalT01Object});
        setTotalAmtSaveFlag(true)
        // let CessAmt = Math?.round((nVal * nCsPer) / 100);
        // let roundedCess = CessAmt?.toFixed(decimalPoint);
        // setT01Obj({ ...t01Obj, CESSAMT: roundedCess });
      }
    
    }
  }, [t01Obj?.NTBAMT, M51]);

  useEffect(() => {
    if(TotalAmtSaveFlag){
    if (lTpEnt) {
      if (t01Obj?.NTBAMT != NaN) {
        let nTpAMT =
          t01Obj?.NTBAMT -
          t01Obj?.CGSTAMT -
          t01Obj?.SGSTAMT -
          t01Obj?.IGSTAMT -
          t01Obj?.CESSAMT;
        setT01Obj({ ...t01Obj, NTPAMT: nTpAMT, TOTAMT: t01Obj?.NTBAMT });
      }
    }
    else {
      if (t01Obj?.NTBAMT != NaN) {
        let nTpAMT =
          t01Obj?.NTBAMT +
          t01Obj?.CGSTAMT +
          t01Obj?.SGSTAMT +
          t01Obj?.IGSTAMT +
          t01Obj?.CESSAMT;
        setT01Obj({ ...t01Obj, TOTAMT: nTpAMT });
      }
    }
    setTotalAmtSaveFlag(false)
  }
  }, [TotalAmtSaveFlag,lTpEnt, t01Obj?.NTBAMT, t01Obj?.CGSTAMT, t01Obj?.SGSTAMT, t01Obj?.IGSTAMT, t01Obj?.CESSAMT]);

  useEffect(() => {
    if (T01Ent) {
      let col = [];
      T01Ent?.filter((c, i) => {
        if (c.S53F00 == "C") {
          col.push(
            columnHelper.accessor(c?.S53F04, {
              header: c.S53F07 == 0 ? "" : c.S53F03,
              id: c?.S53UID,
              enableColumnFilter: false,
            enableGlobalFilter:c.S53F07 == 0 ? false: true,
            enableSorting:c.S53F07 == 0 ? false: c?.lColOrder,
              size:  c?.S53F07,
              // minSize: 0,
            // maxSize: c.ColWidth,
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue();
                // console.log("Cell Value=>>", cell);
                return renderValue(c?.S53UID, val, c, cell?.id, i, column);
              },
              Cell: ({ cell, row }) => {
                let val = cell?.getValue();

                if (c?.S53F02 == "RID0065") {
                  return t01Data[row.id]?.F56NM ?? t01Data[row.id]?.FIELD56;
                } else if (c?.S53F02 == "RID0063") {
                  return t01Data[row?.id]?.F03NM ?? t01Data[row.id]?.FIELD03;
                }
                return c.S53F07 == 0 ? "" : val;
                // return val;
              },
              Footer: () => <div>{Summary(c, i, "v")}</div>,
            })
          );
        }
        // console.log("#value==>", c?.S53F04, "#header=>", c?.S53F03, "#Input", c?.S53F14);
      });
      setColumns(col);
    }
  }, [T01Ent, t01Obj, t01Data, T01]);
  const table = useMantineReactTable({
    data: t01Data ? t01Data : [],
    columns: columns,
    enableFilters: false,
    enableTopToolbar: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    positionToolbarAlertBanner: false,
    enableColumnActions: false,
    enableSorting: true,
    enableStickyFooter:true,
    enableStickyHeader:true,
    enableFilters: false,
    enableSelectAll:false,
    enableEditing: true,
    mantineTableProps: { withColumnBorders: true },
    mantineTableContainerProps: {
      sx: { maxHeight: "250px", minHeight: "300px" },
    },
    onColumnVisibilityChange: (column) => { },
    enableHiding: false,
    createDisplayMode: "row",
    editDisplayMode: "row",
    initialState: {
      columnVisibility: {
        RID0071:
          ("G", "C", "8").includes(type=="" ? group?.FIELD21 : type) || group?.FIELD43 == "3",
        RID0070:
          ("G", "C", "8").includes(type=="" ? group?.FIELD21 : type) || group?.FIELD43 == "3",
        RID0072:
          ("I", "M", "X", "9", "S").includes(type=="" ? group?.FIELD21 : type) ||
          group?.FIELD43 == ("4", "X", "I", "M", "S", "F"),
      },
    },
    onCreatingRowSave: () => {
      handleCreateUser();
    },
    onEditingRowSave: () => {
      handleSaveUser();
    },
    onCreatingRowCancel: rowCancelHandler,
    onEditingRowCancel: rowEditCancelHandler,
    onPaginationChange: setPagination,
    mantineTableBodyProps: {
      tabIndex: 0,
      ref: TableRef,
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      ref: TableContainer,
      onDoubleClick: () => {
        // handleRowDblClk(row, cell);
      },
      tabIndex: 0,
      onKeyUp: (e) => handleRowKeyDown(e, row, table),
      onClick: () => {
        // console.log(row?.id, "row?.id");
        setRowSelection((prev) => {
          if (!prev[row.id]) {
            return { [row.id]: true };
          }
          return prev;
        });
        // setRowSelection((prev) => ({
        //   [row?.id]: !prev[row?.id],
        // }));
      },

      selected: rowSelection[row?.id],
      sx: { cursor: "pointer" },
      height: 30,
    }),
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              Promise.resolve(table.setCreatingRow(false)).then(() => {
                table?.setEditingRow(row);
                setTableEditingRow(row);
                setT01Obj(row?.original);
                // setCreatingModeOn(true);
              });
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: (props) => {
      // console.log(props, "renderTopToolbarCustomActions");
      return (
        <Button
          onFocus={(e) => {
            if (spoilerControlRef?.current?.innerText == "Hide") {
              spoilerControlRef.current.click();
            }
            if (e.type === "focus" && e.relatedTarget)
              Promise.resolve(table.setCreatingRow(true)).then(() => {
                setCreatingModeOn(true);
                setTimeout(() => {
                  expRef?.current?.handlePopoverToggle();
                }, 500);
              });
          }}
          onClick={() => {
            setRowSelection({});
            // console.log(tableEditingRow, "tableEditingRow");
            if (tableEditingRow == null) {
              Promise.resolve(table.setCreatingRow(true)).then(() => {
                setCreatingModeOn(true);
                setTimeout(() => {
                  expRef?.current?.handlePopoverToggle();
                }, 500);
              });
            } else {
              // console.log(tableEditingRow, "tableEditingRow");

              Promise.resolve(table.setCreatingRow(false)).then(() => {
                setCreatingModeOn(false);
              });
            }
          }}
          size="xs"
        >
          Add New
        </Button>
      );
    },
    renderBottomToolbar: ({ table }) => (

      <Box>
        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Grid.Col span={4}>

          </Grid.Col>

          <Grid.Col span={4}>
            <Center h={50}>
              <Button size="xs" variant="subtle" compact onClick={() => {
                handleT01Delete(table)
              }}>
                Delete
              </Button>
            </Center>
          </Grid.Col>

          <Grid.Col span={4}>
            <Group
              spacing={"xs"}
              position="right"
              display={"flex"}
            >
              <MRT_TablePagination position="bottom" table={table} />
            </Group>
          </Grid.Col>

        </Grid>
      </Box>
    ),
    state: {
      rowSelection,
      density: "1.5px",
    },
  });

  const handleRowKeyDown = (e, row, tab) => {
    // console.log("TABKEY", e.key, tab);
    // if (e?.key == "Enter" && tableEditingRow == null) {
    //   if (row?.id == 'mrt-row-create') {
    //     return
    //   } else {
    //     tab?.setEditingRow(row);
    //     setTableEditingRow(row);
    //     setCreatingModeOn(false);
    //     setT01Obj(row?.original);
    //   }
    // }
    const tabled = TableRef.current;
    const rows = tabled?.querySelectorAll('tr');
    const currentRowIndex = Array.from(rows).indexOf(e.target);
    // console.log("TABKEY", e.key);
    switch (e?.key) {
      case 'ArrowDown':
        // e.preventDefault();
        if (currentRowIndex < rows.length - 1) {
          e.preventDefault();
          rows[currentRowIndex + 1].focus();
          // rows[currentRowIndex + 1].scrollIntoView({ behavior: "smooth",block: "start"})

        } else if (
          table?.getPageCount() && table?.getPageCount() > 1 &&
          pagination?.pageIndex < table?.getPageCount() - 1
        ) {
          // Navigate to the next page
          table.nextPage()
          // setPagination((prev) => ({
          //   ...prev,
          //   pageIndex: prev.pageIndex + 1,
          // }));
          // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
          // rows[0].focus();
        }

        break;
      case 'ArrowUp':

        if (currentRowIndex > 0) {
          e.preventDefault();
          rows[currentRowIndex - 1].focus();
          // rows[currentRowIndex - 1].scrollIntoView({ behavior: "smooth",block: "end"})
        } else if (pagination.pageIndex > 0) {
          // Navigate to the previous page
          table.previousPage()
          // setPagination((prev) => ({
          //   ...prev,
          //   pageIndex: prev.pageIndex - 1,
          // }));
          // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
          // rows[0].focus();
        }

        break;
      case "Enter":
        if (row?.id == 'mrt-row-create') {
          return
        } else if (tableEditingRow) {
          return
        } else {
          table?.setEditingRow(row)
          setTableEditingRow(row)
          setT01Obj(row?.original)
        }
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (tableEditingRow == null && creatingModeOn) {
      setEnableOk(true);
    } else if (creatingModeOn == false && tableEditingRow != null) {
      // console.log(creatingModeOn, "creatingModeOn-", tableEditingRow);
      setEnableOk(true);
    } else {
      setEnableOk(false);
    }
  }, [creatingModeOn, tableEditingRow]);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e?.key == "Escape") {
        setTableEditingRow(null)
        if (tableEditingRow == null) {
          Promise.resolve()
            .then(() => rowCancelHandler())
            .then(() => table.setCreatingRow(null));
        } else {
          Promise.resolve()
            .then(() => rowEditCancelHandler())
            .then(() => table.setEditingRow(null));
        }
      }
    });
  }, [tableEditingRow]);

  function parseStringToObjectArray() {
    // Step 1: Split by ~R~ to separate segments
    const segments = group.FIELD55.split("~R~");

    // Step 2 & 3: Process each segment to remove leading/trailing ' and split by ~C~
    const objectsArray = segments.map((segment) => {
      // Remove leading and trailing '
      const cleanedSegment = segment.replace(/^'(.*)'$/, "$1");

      const keyValuePairs = cleanedSegment.split("~C~");
      // console.log("keyValuePairs", keyValuePairs);

      // Trim spaces from key-value pairs
      // const trimmedPairs = keyValuePairs.map(pair => pair.trim(''));
      // console.log('trimmedPairs', trimmedPairs)

      const obj = {
        VouNo: keyValuePairs[1].replace(/^''|''$/g, ""),
        Date: keyValuePairs[2].replace(/^''|'$/g, ""),
      };
      // console.log("obj of create obj", obj);
      return obj;
    });
    return setInvTableData(objectsArray);
  }
  useEffect(() => {
    if (p0 == "E" && group?.FIELD48 == "" && group?.FIELD55 !== "") {
      parseStringToObjectArray();
      setTextBox(true);
    } else {
      setTextBox(false);
    }
  }, [p0, group?.FIELD48, group?.FIELD55]);
  useEffect(() => {
    if (hasFocused) {
      inputRef?.current?.handlePopoverToggle();
      setHasFocused(false);
    }
  }, [hasFocused]);

  function DateToString(e) {
    // console.log(e, "Datetostring");
    let year = e.toLocaleString("en-IN", { year: "numeric" });
    let month = e.toLocaleString("en-IN", {
      month: "2-digit",
    });
    let day = e.toLocaleString("en-IN", { day: "2-digit" });

    let formatedStartDate = day + "/" + month + "/" + year;
    let startDate = year + month + day;
    return { formatedStartDate, startDate };
  }

  function dateFormater(date){
    let year = date.toLocaleString("en-IN", { year: "numeric" });
        let month = date.toLocaleString("en-IN", { month: "2-digit" });
        let day = date.toLocaleString("en-IN", { day: "2-digit" });
        let date1 = year + month + day;
        return date1;
   }
  useEffect(() => {
    let date = f02 || p0 =="E" ? group?.FIELD02 : vDate?.length!==0 ? vDate : dateFormater(footerDate);
    // console.log(date, "group?.FIELD02");
    if (date) {
      const year = parseInt(date.substr(0, 4));
      const month = parseInt(date.substr(4, 2)) - 1; // Month is zero-based in JavaScript Date
      const day = parseInt(date.substr(6, 2));
      const dateObject = new Date(year, month, day);
      // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const dayOfWeek = dateObject.toLocaleString("en-US", { weekday: "long" });
      setDayVal(dayOfWeek?.slice(0, 3));
    }
  }, [group?.FIELD02,footerDate,vDate]);

  const [vouTypeVal, setVouTypeVal] = useState("I");
  useEffect(() => {
    if (obj?.cSTID == "TXN_GSTLSTNC") {
      if (COP91?.length > 0) {
        // console.log(COP91?.length, "COP91---");
        setVouTypeVal("C");
        // setGroup({
        //   ...group,
        //   FIELD46: "C",
        // });
      }
    } else if (obj?.cSTID == "TXN_GSTLSTND") {
      // console.log(COP91 , "COP91---")

      if (COP91?.length > 0) {
        setVouTypeVal("D");
        // setGroup({
        //   ...group,
        //   FIELD46: "D",
        // });
      }
    } else if (obj?.cSTID == "TXNVOULISTEG") {
      // console.log(COP91 , "COP91---")

      if (COP91?.length > 0) {
        setVouTypeVal("E");

        // setGroup({
        //   ...group,
        //   FIELD46: "E",
        // });
      }
    } else {
      if (COP91?.length > 0) {
        setVouTypeVal("I");

        // setGroup({
        //   ...group,
        //   FIELD46: "I",
        // });
      }
    }
  }, [obj, COP91]);
  useEffect(() => {
    // console.log(P_T41DT, "P_T41DT-", T41DT);
    if (
      // (P_T41DT != null || P_T41DT?.length > 0) && P_T41DT instanceof Array &&
      group?.FIELD43 != "" &&
      group?.FIELD04 != "" &&
      (P_T41 != null || P_T41?.length > 0)
    ) {
      // if ((P_T41 != null || P_T41?.length > 0) && group?.FIELD43 != "") {
      if (group?.FIELD43 == "I" || group?.FIELD43 == "P") {
        let qryWhere = `T41.T41PVT IN ('PP','JJ') OR (T41.T41PVT LIKE '%JR%' AND T41.FIELD99 LIKE '%EG%' AND (T41.FIELD04 LIKE '%${group?.FIELD04}%' OR T41.FIELD31 LIKE '%${group?.FIELD04}%'))`;
        setBillNoData(
          P_T41?.split("~C~")[0] +
          "~C~" +
          P_T41?.split("~C~")[1] +
          "~C~" +
          qryWhere
        );
        // console.log(newArr, "newArr--", group?.T41PVT, group?.FIELD04);
      } else {
        let qryWhere = `T41.T41PVT IN ('SS','JZ') OR (T41.T41PVT LIKE '%JR%' AND T41.FIELD99 LIKE '%EG%' AND (T41.FIELD04 LIKE '%${group?.FIELD04}%' OR T41.FIELD31 LIKE '%${group?.FIELD04}%'))`;

        // console.log(qryWhere, "qryWhere--")
        setBillNoData(
          P_T41?.split("~C~")[0] +
          "~C~" +
          P_T41?.split("~C~")[1] +
          "~C~" +
          qryWhere
        );
      }
    }
  }, [group?.FIELD43, group?.FIELD04, P_T41]);
  useEffect(() => {
    if (
      group?.FIELD49 != "" &&
      group?.FIELD49 == "Y" &&
      (P_T41 != null || P_T41?.length > 0)
    ) {
      // let billData = P_T41DT?.filter(
      //   (v) => v?.T41PVT == OS11?.S11F01 && v?.FIELD75 != 4
      // );
      let qryWhere = `T41.T41PVT LIKE '%${OS11?.S11F01} AND T41.FIELD75 NOT LIKE '%4%'`;
      setBillNoData(
        P_T41?.split("~C~")[0] +
        "~C~" +
        P_T41?.split("~C~")[1] +
        "~C~" +
        qryWhere
      );
    }
  }, [group?.FIELD49, P_T41]);

  const seperate1 = (e) => {
    // console.log("e=====>", e);
    let val = [],
      lab = [];
    if (group?.FIELD55 !== "") {
      const rows = e?.split("~R~");
      // console.log("row", rows);
      const parsedData = rows?.map((row) => row.split("~C~"));
      // console.log("parsedData", parsedData);
      if (parsedData && parsedData.length > 0) {
        parsedData.map((v, i) => {
          const cleanValue1 = v[0].replace(/^''|''$/g, "");
          const cleanValue2 = v[1].replace(/^''|''$/g, "");
          // console.log(cleanValue1, "cleanValue1", cleanValue2);
          if (group.FIELD48 !== "") {
            val.push(cleanValue1);
            lab.push(cleanValue2);
          } else {
            val.push(cleanValue2);
          }
        });
      }
      // console.log(lab, "val=>", val.join(","));
      return p0 == "E"
        ? { value: val.join(","), label: lab.join(",") }
        : val.join(",");
    }
  };
  const seperate2 = (e) => {
    // console.log("e=====>", e);
    let val = [],
      lab = [];
    if (group?.FIELD55 !== "") {
      const rows = e?.split("~R~");
      // console.log("row", rows);
      const parsedData = rows.map((row) => row.split("~C~"));
      // console.log("parsedData", parsedData);
      if (parsedData && parsedData.length > 0) {
        parsedData.map((v, i) => {
          const cleanValue1 = v[0].replace(/^''|''$/g, "");
          const cleanValue2 = v[1].replace(/^''|''$/g, "");
          // console.log(cleanValue1, "cleanValue1", cleanValue2);
          if (group.FIELD48 !== "") {
            val.push(cleanValue1);
            lab.push(cleanValue2);
          } else {
            val.push(cleanValue2);
          }
        });
      }
      // console.log(lab, "val=>", val.join(","));
      return val.join(",");
    }
  };
  useEffect(() => {
    if (SelectedNarrationData.length > 0) {
      const stringifyArray = SelectedNarrationData?.map((a) => a.FIELD01);
      stringOfNames = stringifyArray.join(" ");
      // console.log("stringOfNames", stringOfNames);
    }
    setGroup({ ...group, FIELD15: stringOfNames });
  }, [stringOfNames, SelectedNarrationData]);
  let compareTwoArrayOfObjects = (editT01, t01Data) => {
    return (
      editT01?.length === t01Data?.length &&
      editT01?.every((v) =>
        t01Data?.some((c) => Object.keys(v).every((key) => v[key] === c[key]))
      )
    );
  };
  // useEffect(() => {
  //   if (group?.FIELD04 !== "") {
  //     // console.log(M01PDT, "M01PDT-")
  //     // let newData = M01PDT?.FIELD01 == group?.FIELD04
  //     console.log(M01PDT?.FIELD07, "newData-");
  //     if (M01PDT?.FIELD07 === "CS") {
  //       setPRPopUp(true);
  //     } else {
  //       setPRPopUp(false);
  //     }
  //   }
  // }, [group?.FIELD04, M01PDT]);
  useEffect(() => {
    if (
      group?.FIELD46 !== "" &&
      (group?.FIELD46 == "C" || group?.FIELD46 == "D")
    ) {
      setEffectIn(true);
    } else {
      setEffectIn(false);
    }
  }, [group?.FIELD46]);
  // console.log(T92, "T92--");

  function originalInvoiceFn() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Original Invoice Detail</Text>,
      body: (
        <OriginalInvoiceDetailTable
          InvTableData={InvTableData}
          OBJ={obj}
          textBox={textBox}
          setTextBoxSend={(e) => {
            setTextBox(e);
          }}
          setInvTableData={(e) => {
            // console.log("setInvTableData", e);
            setInvTableData(e);
          }}
          T41ObjSend={group}
          setT41ObjSend={(e) => {
            // console.log("setT41ObjSend=>", e);
            setGroup(e);
          }}
          DrawerObjSend={DrawerObj}
          setDrawerObjSend={(e) => {
            setDrawerObj(e);
          }}
        />
      ),
      open: true,
      size: "md",
      position: "right",
    });
  }
  function dispatchFunction() {
    Promise.resolve(dispatch(DataSaveLoading(false)))
      .then(() => {
        if (p0 !== "A") {
          if(String(props?.index).startsWith("modalForm")){
            dispatch(TabRemove(props?.index))
          }else{
            dispatch(ModalDelete(props.index))
          }
        }
        setTimeout(() => {
          if (p0 == "A") {
            Promise.resolve(Store.dispatch(TransactionApi(obj))).then(()=>{
               setF02(false)
            })
          }
        }, 1000);
      })

      .then(() => {
        setBillSeriesHideShow({
          ...BillSeriesHideShow,
          BillNoVal: "",
        });

      });

    let PaginationObj =
      Store.getState().DataSaveLoadingSlice.PaginationObj;

      dispatch(GetAccountList({...obj?.BtnPnlObj,pagination: PaginationObj[obj?.cSTID]}))

    // console.log(index, "ModalDelete");
    // Store.dispatch(ModalDelete(index));
    // if (obj?.cSTID == "TXN_GSTLSTNC") {
    //   Promise.resolve(
    //     Store.dispatch(
    //       GetAccountList({
    //         id: "05520546",
    //         name: "Credit Note (Without Stock)",
    //         p0: "MT",
    //         p1: "TXN_GSTLSTNC",
    //         p2: "",
    //         p3: "",
    //         type: "A",
    //         pagination: PaginationObj["05520546"],
    //       })
    //     )
    //   );
    // } else if (obj?.cSTID == "TXNVOULISTEG") {
    //   Promise.resolve(
    //     Store.dispatch(
    //       GetAccountList({
    //         id: "05490546",
    //         name: "GST Expense",
    //         p0: "MT",
    //         p1: "TXNVOULISTEG",
    //         p2: "",
    //         p3: "",
    //         type: "A",
    //         pagination: PaginationObj["05490546"],
    //       })
    //     )
    //   );
    // } else if (obj?.cSTID == "TXN_GSTLSTND") {
    //   Promise.resolve(
    //     Store.dispatch(
    //       GetAccountList({
    //         id: "05550546",
    //         name: "Debit Note (Without Stock)",
    //         p0: "MT",
    //         p1: "TXN_GSTLSTND",
    //         p2: "",
    //         p3: "",
    //         type: "A",
    //         pagination: PaginationObj["05550546"],
    //       })
    //     )
    //   );
    // } else if (obj?.cSTID == "TXNVOULISTIG") {
    //   Promise.resolve(
    //     Store.dispatch(
    //       GetAccountList({
    //         id: "20460546",
    //         name: "GST Income",
    //         p0: "MT",
    //         p1: "TXNVOULISTIG",
    //         p2: "",
    //         p3: "",
    //         type: "A",
    //         pagination: PaginationObj["20460546"],
    //       })
    //     )
    //   );
    // }
  }
  function OnPrint() {
    Promise.resolve(dispatch(DataSaveLoading(false))).then(() => {
      setDrawerObj({
        ...dra,
        title: <Text fw={700}>Print Voucher</Text>,
        onClickCloseButton: () => {
          // setDrawerObj(dra);
          Promise.resolve(
            setDrawerObj({
              ...DrawerObj,
              open: false,
            })
          ).then(() => dispatchFunction());
        },
        onClose: () => {
          // setDrawerObj(dra);
          Promise.resolve(
            setDrawerObj({
              ...DrawerObj,
              open: false,
            })
          ).then(() => dispatchFunction());
        },
        body: (
          <>
            <PLPrint data={obj} />
          </>
        ),
        open: true,
        size: "sm",
        position: "right",
      });
    });
  }
  function OnWhtsUp() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>WhatsApp </Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        // console.log(OSV._ONLINEVOU, "OSV._ONLINEVOU");
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      onClose: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      body: (
        <>
          <FormatListTable
            obj={{ ...obj, p0: "W", text: "WhatsApp" }}
            index={props.index}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  }
  function OnSMS() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>SMS </Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        // console.log(OSV._ONLINEVOU, "OSV._ONLINEVOU");
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      onClose: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      body: (
        <>
          <FormatListTable
            obj={{ ...obj, p0: "S", text: "SMS" }}
            index={props.index}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  }
  function OnEmail() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>EMail </Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        // console.log(OSV._ONLINEVOU, "OSV._ONLINEVOU");
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEWAP == "Y") {
              OnWhtsUp();
            } else if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      onClose: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEWAP == "Y") {
              OnWhtsUp();
            } else if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      body: (
        <>
          <FormatListTable
            obj={{ ...obj, p0: "E", text: "EMail" }}
            index={props.index}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  }
  function handleOkButton(e) {
    // console.log("OKBTN", e);
    const sortedEditT41 = JSON.stringify(T41);
    const sortedGroup = JSON.stringify(group);
    let editT01 = T01?.filter((v, i) => v?.FIELD03 !== null && v?.FIELD03 !== "");
    // console.log(editT01, "compareTwoArrayOfObjects", t01Data)
    if (obj.p0 == "E" &&
      sortedEditT41 === sortedGroup &&
      compareTwoArrayOfObjects(editT01, t01Data)
    ) {
      Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>{
        if(String(props?.index).startsWith("modalForm")){
        dispatch(TabRemove(props?.index))
      }else{
        dispatch(ModalDelete(props.index))
      }}
      );
    } else {
      Promise.resolve(Store.dispatch(DataSaveLoading(true))).then(() =>
        GlobalClass.Notify(
          "info",
          p0 == "A" ? "Adding" : "Editing",
          "Please wait while we process your data"
        )
      );
      // console.log(t01Data, "billData-", group);
      let currentDate = DateToString(new Date())?.startDate;
      // console.log(currentDate, "currentDate")
      let newT41 = {
        ...group,
        FIELD06: rowSum,
        FIELD15: group?.FIELD15 == undefined ? "" : group?.FIELD15,
        FIELD11: group?.FIELD11 == "" ? currentDate : group?.FIELD11,
      };
      let PostObj = { T01: [...t01Data], T41: e ? e : { ...newT41, UCRE2: 0 } };

      // console.log("ObjSend==>", PostObj);

      var data = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: props ? p0 : "A",
        cCode:
          p0 == "E"
            ? editId
            : obj?.cSTID == "TXN_GSTLSTNC"
              ? "NCNC"
              : obj?.cSTID == "TXNVOULISTEG" || obj?.cSTID === "TXNVOULISTIG"
                ? "JRJR"
                : obj?.cSTID == "TXN_GSTLSTND"
                  ? "NDND"
                  : "",
        cOPara: obj?.cSTID === "TXNVOULISTIG" ? "CTYPE~C~IG" : "CTYPE~C~EG",
        cSData: JSON.stringify(PostObj),
      };

      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: props ? p0 : "A",
        cCode:
          p0 == "E"
            ? editId
            : obj?.cSTID == "TXN_GSTLSTNC"
              ? "NCNC"
              : obj?.cSTID == "TXNVOULISTEG" || obj?.cSTID === "TXNVOULISTIG"
                ? "JRJR"
                : obj?.cSTID == "TXN_GSTLSTND"
                  ? "NDND"
                  : "",
        cOPara: obj?.cSTID === "TXNVOULISTIG" ? "CTYPE~C~IG" : "CTYPE~C~EG",
      });
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      fetch(
        GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
        requestOptions
      )
        .then((response) => {
          if (!response?.ok) {
            throw new Error(`HTTP error! Status: ${response?.status}`);
          } //This block give more detail info about error other then 2xx status codes.
          return response.json();
        })
        .then((data) => {
          if (data.status == "SUCCESS") {
            GlobalClass.Notify(
              "success",
              obj.p0 == "A" ? "Added" : "Edited",
              obj.p0 == "A" ? "Added successfully" : "Edited successfully"
            );
            Promise.resolve(
              OSV._ONLINEMAL == "Y" || OSV._ONLINEMAL == "L"
                ? Promise.resolve(dispatch(DataSaveLoading(false))).then(
                  () => {
                    OnEmail();
                  }
                )
                : OSV._ONLINEWAP == "Y" || OSV._ONLINEWAP == "L"
                  ? Promise.resolve(dispatch(DataSaveLoading(false))).then(
                    () => {
                      OnWhtsUp();
                    }
                  )
                  : OSV._ONLINESMS == "Y" || OSV._ONLINESMS == "L"
                    ? Promise.resolve(dispatch(DataSaveLoading(false))).then(
                      () => {
                        OnSMS();
                      }
                    )
                    : Promise.resolve(
                      setDrawerObj({ ...DrawerObj, open: false })
                    ).then(() => {
                      if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                        OnPrint();
                      } else {
                        dispatchFunction();
                      }
                    })
            );

          } else if (data.status == "FAIL") {
            Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
              GlobalClass.Notify("error", data?.status, data?.message)
            );
          }
        })
        .catch((e) =>
          Promise.resolve(
            GlobalClass.Notify("error", "Error", `${e.message}`)
          ).then(() => dispatch(DataSaveLoading(false)))
        );
    }
  }
  // console.log(isLoadingEnt, SaveLoading, "isLoadingEnt");
  return (
    <>
      {hasErrorEnt ? <>
        <Text>{ErrorMsg?.message}</Text>
        <Button onClick={() => {
          if (obj) {
            Promise.resolve(dispatch(TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 }))).then(() => {
              dispatch(GetAccountList({
                "id": "P_M21",
                "name": "",
                "type": "A",
                "p0": "P_M21",
                "p1": "P_M21",
                "p2": "",
                "p3": ""
              }))
            }).then(() => {
              if (obj?.p0 == "U") {
                dispatch(GetUserFieldObj(OS11?.S11F01))
              }
            })
          }
        }}>Retry</Button>
      </> : SaveLoading == true || isLoadingEnt || isUserFieldLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : obj?.p0 == "U" ? (
        <>
          <PLUserFieldBtn
            UserFieldArray={UserField?.UFTbl}
            T41={group}
            onChange={(e) => {
              // Promise.resolve(setGroup(e)).then(()=>
              handleOkButton(e);
              // )
            }}
          />
        </>
      ) : (
        <>
          <div ref={formRef}>
            <Modal
              opened={DrawerObj?.open}
              // onClose={close}
              withCloseButton={true}
              fullScreen={false} //
              // overlayProps={{ opacity: 0.5, blur: 4 }}
              closeButtonProps={{
                onClick: (e) => {
                  if (typeof DrawerObj?.onClickCloseButton == "function") {
                    DrawerObj?.onClickCloseButton();
                  } else {
                    setDrawerObj(dra);
                  }
                },
              }}
              title={DrawerObj?.title}
              size={DrawerObj?.size}
              // centered={.Centered}
              closeOnClickOutside={true}
              // overlayProps={m.Overlay}
              position={DrawerObj?.position ?? "bottom"}
              onClose={
                typeof DrawerObj?.onclose == "function"
                  ? DrawerObj?.onclose
                  : () => {
                    setDrawerObj(dra);
                  }
              }
            // scrollAreaComponent={ScrollArea.Autosize}
            >
              {DrawerObj.body}
            </Modal>
            <Paper shadow="md" radius="md" p="xs" withBorder>
              <Grid gutter={4}>
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                  }}
                >
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Voucher Type</Text>
                    </Grid.Col>
                    <Grid.Col span={9} style={{ overflow: "hidden" }}>
                      <PLComboBox
                        value={vouTypeVal}
                        data={COP91}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        disabled={true}
                      // setEdit={(e) => {
                      //   //console.log(e)
                      //   setGroup({ ...group, T41PVT: e?.value });
                      // }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Date </Text>
                    </Grid.Col>
                    <Grid.Col span={5}>
                      <PLDateBox
                        ref={docDateRef}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        style={{
                          margin: "0",
                          padding: "0",
                        }}
                        dispformat="DD/MM/YYYY"
                        setEdit={(e) => {
                          let { startDate } = DateToString(e);
                          setGroup({ ...group, FIELD02: startDate });
                          setF02(true);
                          setVDate(startDate);
                        }}
                        defaultval={f02 || p0=="E"? group?.FIELD02 : vDate?.length!==0 ? vDate : dateFormater(footerDate)}
                      />
                    </Grid.Col>
                    <Grid.Col span={1}>{dayVal}</Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Doc. No.</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLTextBox
                        value={group?.FIELD10}
                        setEdit={(e) => {
                          setGroup({ ...group, FIELD10: e?.target?.value });
                        }}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                  }}
                >
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Party</Text>
                    </Grid.Col>
                    <Grid.Col span={9} style={{ overflow: "hidden" }}>
                      <PLDataGrid
                        width={"500px"}
                        value={Object.keys(partyData).length > 0 ? {
                          value: partyData?.FIELD04,
                          label: partyData?.FIELD04NM,
                        } :{
                          value: group?.FIELD04,
                          label: group?.FIELD04NM,
                        }}
                        TmData={M01P}
                        ref={inputRef}
                        dispexpr="FIELD02"
                        valexpr="FIELD01"
                        // data={M01PDT}
                        // data={M01PDT?.jData}
                        setEdit={(e) => {
                          // console.log(e, "setEdit");
                          setM01PDT(e);
                          setGroup({
                            ...group,
                            FIELD04: e?.FIELD01,
                            FIELD04NM: e?.FIELD02,
                          });
                          // setAutoFocus(true);
                          myComboBox?.focus();
                          setPartyData({
                            FIELD04: e?.FIELD01,
                            FIELD04NM: e?.FIELD02,
                          })
                        }}
                      // onKeyDown={(e) => {
                      //   GlobalClass.formNextInput(e, formRef);
                      // }}
                      // handleClose={(e)=>//console.log(e)}
                      />
                      {PRPopUp ? (
                        <PLDataGrid
                          width={"500px"}
                          value={group?.FIELD31}
                          TmData={M01P}
                          dispexpr="FIELD02"
                          valexpr="FIELD01"
                          // data={M01PDT?.filter((v) => v?.FIELD07 == "PR")}
                          // cWhere={`P_M01.FIELD07 LIKE 'PR'`}
                          setEdit={(e) => {
                            // console.log(e, "setEdit");

                            setGroup({ ...group, FIELD31: e?.FIELD01 });
                          }}
                        // handleClose={(e)=>//console.log(e)}
                        />
                      ) : (
                        ""
                      )}
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Vou No. </Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                    {
                                    obj?.p0 == "E" ?
                                      <Grid.Col span={12}>
                                        <PLTextBox
                                          onKeyDown={(e) => {
                                            GlobalClass.formNextInput(e, formRef);
                                          }}
                                          setText={(e) => {
                                            if (e?.target?.value?.length > 0) {
                                              if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.FIELD12 == e?.target?.value)?.length > 0) {
                                                console.log("Duplicate Value log call");
                                                GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                setBillNoStatus(true)
                                              } else {
                                                setBillNoStatus(false)
                                              }
                                            } else {
                                              setBillNoStatus(false)
                                            }
                                          }}
                                          value={group?.FIELD12}
                                          setEdit={(e) => {
                                            console.log("setEdit=>", e);
                                            setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                          }}
                                        />
                                      </Grid.Col> :
                                      <Grid.Col span={12}>
                                        {
                                          T92 ? <>
                                            {
                                              BillSeriesHideShow?.DataGridInput == true && <>
                                                {
                                                  !group?.T92F00 ?
                                                    <PLDataGrid
                                                      ref={vouNoRef}
                                                      onKeyDown={(e) => {
                                                        GlobalClass.formNextInput(e, formRef);
                                                      }}
                                                      width={"400px"}
                                                      position={"unset"}
                                                      valexpr={"FIELD00"}
                                                      // width={350} 
                                                      value={BillSeriesHideShow?.BillNoVal}
                                                      TmData={T92}
                                                      // data={T92DT}
                                                      setEdit={(e) => {
                                                        console.log("Bill No.=>", e)
                                                        // Promise.resolve(setGroup({ ...group, T92F00: e.FIELD01 })).then(()=>{
                                                        //   setBillSeriesHideShow({...BillSeriesHideShow,DataGridInput:false,TextInput:true,BillNoVal:e.FIELD01})
                                                        // })
                                                        //"FIELD00"
                                                        Promise.resolve(setGroup({ ...group, T92F00: e.FIELD00, FIELD12: "" })).then(() => {
                                                          if (e) {
                                                            var letr = e.FIELD02
                                                            console.log("num=>", num);
                                                            var num = parseInt(e.FIELD03) + 1
                                                            setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: false, TextInput: true, BillNoVal: letr + num })
                                                          }
                                                        })

                                                      }}
                                                    /> : <PLTextBox
                                                    setText={(e) => {
                                                      // console.log("bill number setText 1", [{
                                                      //   "e": e,
                                                      //   "value": e?.target?.value
                                                      // }]);
                                                      if (e?.target?.value?.length > 0) {
                                                        if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                          console.log("Duplicate Value log call");
                                                          GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                          setBillNoStatus(true)
                                                        } else {
                                                          setBillNoStatus(false)
                                                        }
                                                      } else {
                                                        setBillNoStatus(false)
                                                      }
                                                    }}
                                                      onKeyDown={(e) => {
                                                        GlobalClass.formNextInput(e, formRef);
                                                      }}
                                                      value={group?.FIELD12}
                                                      // value={group?.T92F00}
                                                      setEdit={(e) => {
                                                        console.log("setEdit=>", e);
                                                        // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                                        setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                                      }}
                                                    />
                                                }
                                              </>
                                            }
                                            {BillSeriesHideShow?.TextInput == true && <PLTextBox
                                                setText={(e) => {
                                                  // console.log("bill number setText 2", [{
                                                  //   "e": e,
                                                  //   "value": e?.target?.value
                                                  // }]);
                                                  if (e?.target?.value?.length > 0) {
                                                    if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                      console.log("Duplicate Value log call");
                                                      GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                      setBillNoStatus(true)
                                                    } else {
                                                      setBillNoStatus(false)
                                                    }
                                                  } else {
                                                    setBillNoStatus(false)
                                                  }
                                                  if (BillSeriesHideShow.BillNoVal.length <= 0) {
                                                    setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
                                                  }
                                                }}
                                              onKeyDown={(e) => {
                                                GlobalClass.formNextInput(e, formRef);
                                              }}
                                              value={BillSeriesHideShow?.BillNoVal}
                                              // value={group?.T92F00}
                                              setEdit={(e) => {
                                                console.log("setEdit=>", e);
                                                setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                                setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                              }}
                                            />
                                            }
                                          </> :
                                            < PLTextBox
                                            setText={(e) => {
                                              // console.log("bill number setText 3", [{
                                              //   "e": e,
                                              //   "value": e?.target?.value
                                              // }]);
                                              if (e?.target?.value?.length > 0) {
                                                if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.T41VNO == e?.target?.value)?.length > 0) {
                                                  console.log("Duplicate Value log call");
                                                  GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                  setBillNoStatus(true)
                                                } else {
                                                  setBillNoStatus(false)
                                                }
                                              } else {
                                                setBillNoStatus(false)
                                              }
                                            }}
                                            value={BillSeriesHideShow?.BillNoVal}
                                            setEdit={(e) => {
                                              console.log("setEdit=>", e);
                                              setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                              // setGroup({ ...group, T92F00: "", FIELD12: e?.target?.value })
                                              // if(EntryPages?.PurchaseEntry?.includes(obj?.page) || EntryPages?.PurchaseEntry?.includes(obj?.id)){
                                              //   setGroup({ ...group, T92F00: "", FIELD12: e.target.value, FIELD10: e.target.value })
                                              // }else{
                                              setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                              // }
                                            }}
                                              onKeyDown={(e) => {
                                                GlobalClass.formNextInput(e, formRef);
                                              }}
                                            />
                                        }
                                      </Grid.Col>
                                  }
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Doc. Date</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLDateBox

                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        style={{ margin: "0", padding: "0" }}
                        dispformat="DD/MM/YYYY"
                        setEdit={(e) => {
                          // console.log("e PLDateBox", e);
                          let { startDate } = DateToString(e);
                          // console.log(startDate, "startDateinpl");

                          if (startDate?.length == 8) {
                            setGroup({ ...group, FIELD11: startDate });
                          }
                        }}
                        defaultval={group?.FIELD11?.trim()}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                  }}
                >
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {/* <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>Party</Text>
                </Grid.Col>
                <Grid.Col span={9} style={{ overflow: "hidden" }}>
                  <PLDataGrid />
                </Grid.Col> */}
                  </Grid.Col>
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {!effectIn ? (
                      ""
                    ) : (
                      <>
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Effect In</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLComboBox
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            value={group?.FIELD43}
                            data={COP92}
                            dispexpr={"DisplayMember"}
                            valexpr={"ValueMember"}
                            setEdit={(e) => {
                              // console.log(e, "DisplayMember");
                              setGroup({ ...group, FIELD43: e });
                            }}
                          />
                        </Grid.Col>
                      </>
                    )}
                  </Grid.Col>
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {group?.FIELD46 == "C" || group?.FIELD46 == "D" ? (
                      <>
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Reason</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLComboBox
                            data={COP93}
                            value={group?.FIELD52}
                            dispexpr={"DisplayMember"}
                            valexpr={"ValueMember"}
                            setEdit={(e) => {
                              //console.log(e)
                              setGroup({ ...group, FIELD52: e });
                            }}
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                          />
                        </Grid.Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                  }}
                >
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Type</Text>
                    </Grid.Col>
                    <Grid.Col span={9} style={{ overflow: "hidden" }}>
                      <PLComboBox
                        cmbid="#typeId"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        data={COPGE}
                        value={type=="" ? group?.FIELD21 : type}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        setEdit={(e) => {
                          Promise.resolve(
                            setGroup({ ...group, FIELD21: e })
                          ).then(()=>{
                            setType(e);
                          }).then(() => {
                            docDateRef?.current?.focus();
                          });
                        }}
                      />
                      {/* <PLDataGrid
                      // dropdownOpened={flag &&true}
                      // autoFocus={autoFocus}
                      // onKeyDown={(e) => {
                      //   GlobalClass.formNextInput(e, formRef);
                      // }}
                      width={"500px"}
                      value={group?.FIELD21}
                      TmData={P_TAX}
                      data={P_TAXDT}
                      dispexpr={"TAXF02"}
                      valexpr={"TAXF03"}
                      setEdit={(e) => {
                        // console.log(e, "P_TAXDT");
                        setGroup({ ...group, FIELD21: e?.TAXF03 });
                      }}
                    /> */}
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {["TXNVOULISTEG","TXNVOULISTIG"].includes(obj?.cSTID) ? (
                      ""
                    ) : group?.FIELD49 == "Y" ? (
                      <>
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Vou No. </Text>
                        </Grid.Col>
                        <Grid.Col span={5}>
                          <PLDataGrid
                            // onKeyDown={(e) => {
                            //   GlobalClass.formNextInput(e, formRef);
                            // }}
                            width={"500px"}
                            position={"unset"}
                            isMulti={true}
                            pid={"P_T41"}
                            autoFocus={
                              (p0 == "E" || p0 == "A") && InvTableData.length > 0
                                ? true
                                : false
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Tab" && group.FIELD48 === "") {
                                setTextBox(true);
                                return originalInvoiceFn();
                              }
                              // else if (e.key === "Enter") {
                              //   GlobalClass.formNextInput(e, formRef);
                              // }
                            }}
                            // dispexpr={"FIELD12"}
                            dispexpr={group?.T41BNO != "" ? "T41BNO" : "T41PVT"}
                            // valexpr={"FIELD48"}
                            value={seperate1(group?.FIELD55)}
                            // {{value: seperate1(group?.FIELD55), }}
                            // value={obj.p0 == "E" ? seperate1(group?.FIELD55) : group?.FIELD55}

                            TmData={
                              group?.FIELD04 != "" && group?.FIELD43 != ""
                                ? billNoData
                                : P_T41
                            }
                            setEdit={(e) => {
                              // console.log("Bill No e=>>", e);
                              let val = [];
                              let tempVal;
                              Promise.resolve()
                                .then(() => {
                                  if (e?.length > 0) {
                                    e.map((v, i) => {
                                      val.push(v.original);
                                    });
                                    // console.log("Bill val", val);
                                    return val;
                                  }
                                })
                                .then(() => {
                                  if (val && val.length > 0) {
                                    tempVal = val
                                      .map((s, i) => {
                                        return `${s.FIELD01}~C~${s.FIELD12}~C~${s.FIELD02}`;
                                      })
                                      .join("~R~");
                                    // console.log('tempVal', tempVal)
                                    setGroup({
                                      ...group,
                                      FIELD55: tempVal,
                                      FIELD48: val[0]?.FIELD01,
                                      FIELD33: val[0]?.FIELD02,
                                      FIELD40: val[0]?.FIELD12,
                                    });
                                  } else {
                                    setGroup({
                                      ...group,
                                      FIELD48: "",
                                      FIELD55: "",
                                      FIELD33: "",
                                      FIELD40: "",
                                    });
                                  }
                                })
                                .then(() => setInvTableData([]));
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col span={4}>
                          <PLDateBox
                            // onKeyDown={(e) => {
                            //   GlobalClass.formNextInput(e, formRef);
                            // }}
                            //  style={{ margin: "0", padding: "0" }}
                            dispformat="DD/MM/YYYY"
                            setEdit={(e) => {
                              // console.log("e PLDateBox", e);
                              let { startDate } = DateToString(e);
                              // console.log(startDate, "startDateinpl");

                              if (startDate?.length == 8) {
                                setGroup({ ...group, FIELD33: startDate });
                              }
                            }}
                            defaultval={group?.FIELD33?.trim()}
                          />
                        </Grid.Col>
                      </>
                    ) : (
                      <>
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Bill No. </Text>
                        </Grid.Col>
                        
                        <Grid.Col span={9}>
                          {textBox ? (
                            <PLTextBox
                              id="#billId"
                              onKeyDown={(e) => {
                                GlobalClass.formNextInput(e, formRef);
                              }}
                              value={seperate2(group?.FIELD55)}
                              onClick={(e) => {
                                setTextBox(false);
                                // console.log('onclick', e)
                              }}
                            />
                          ) : (
                            <PLDataGrid
                              width={"500px"}
                              position={"unset"}
                              isMulti={true}
                              pid={"P_T41"}
                              autoFocus={
                                p0 == "E" && InvTableData.length > 0
                                  ? true
                                  : p0 == "A" && InvTableData.length > 0
                                    ? true
                                    : false
                              }
                              cWhere={`T41.FIELD04 LIKE '%${
                                group?.FIELD04}%'`}
                              onKeyDown={(e) => {
                                if (e.key === "Tab" && group.FIELD48 === "") {
                                  setTextBox(true);
                                  return originalInvoiceFn();
                                }
                              }}
                              dispexpr={
                                group?.T41BNO != "" ? "T41BNO" : "T41PVT"
                              }
                              // valexpr={"FIELD48"}
                              value={seperate1(group?.FIELD55)}
                              // {{value: seperate1(group?.FIELD55), }}
                              // value={obj.p0 == "E" ? seperate1(group?.FIELD55) : group?.FIELD55}

                              TmData={
                                group?.FIELD04 != "" && group?.FIELD43 != ""
                                  ? billNoData
                                  : P_T41
                              }
                              setEdit={(e) => {
                                // console.log("Bill No e=>>", e);
                                let val = [];
                                let tempVal;
                                Promise.resolve()
                                  .then(() => {
                                    if (e?.length > 0) {
                                      e.map((v, i) => {
                                        val.push(v.original);
                                      });
                                      // console.log("Bill val", val);
                                      return val;
                                    }
                                  })
                                  .then(() => {
                                    if (val && val.length > 0) {
                                      tempVal = val
                                        .map((s, i) => {
                                          return `${s.FIELD01}~C~${s.FIELD12}~C~${s.FIELD02}`;
                                        })
                                        .join("~R~");
                                      // console.log('tempVal', tempVal)
                                      setGroup({
                                        ...group,
                                        FIELD55: tempVal,
                                        FIELD48: val[0]?.FIELD01,
                                        FIELD33: val[0]?.FIELD02,
                                        FIELD40: val[0]?.FIELD12,
                                      });
                                    } else {
                                      setGroup({
                                        ...group,
                                        FIELD48: "",
                                        FIELD55: "",
                                        FIELD33: "",
                                        FIELD40: "",
                                      });
                                    }
                                  })
                                  .then(() => setInvTableData([]));
                              }}
                            />
                          )}
                        </Grid.Col>
                      </>
                    )}
                  </Grid.Col>
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {/* <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>Doc. No.</Text>
                </Grid.Col>
                <Grid.Col span={8}>
                  <PLDateBox />
                </Grid.Col> */}
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                  }}
                >
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {["TXNVOULISTEG","TXNVOULISTIG"].includes(obj?.cSTID) ? (
                      ""
                    ) : (
                      <>
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Revised Vou.</Text>
                        </Grid.Col>
                        <Grid.Col span={9} style={{ overflow: "hidden" }}>
                          <PLComboBox
                            cmbid="#revId"
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            data={COP1}
                            value={group?.FIELD49}
                            dispexpr={"DisplayMember"}
                            valexpr={"ValueMember"}
                            setEdit={(e) => {
                              setGroup({ ...group, FIELD49: e });
                            }}
                          />
                        </Grid.Col>
                      </>
                    )}
                  </Grid.Col>
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {/* {page == "05490546" ? (
                  ""
                ) : (
                  <> */}
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Applicable %? </Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        cmbid="1"
                        name="1"
                        copno={1}
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        value={group?.FIELD53}
                        setEdit={(e) => {
                          setGroup({ ...group, FIELD53: e.value });
                        }}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                      />
                    </Grid.Col>
                    {/* </>
                )}  */}
                  </Grid.Col>
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {/* <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>Doc. No.</Text>
                </Grid.Col>
                <Grid.Col span={8}>
                  <PLDateBox />
                </Grid.Col> */}
                  </Grid.Col>
                </Grid.Col>
                {/* Header User Field  */}
              </Grid>

              <Grid gutter={4}>
                <Grid.Col span={12}>
                  {userFieldHideShow == "Y" &&
                    <>
                      {
                        UFT41H && UFT41H?.length > 0 ?
                          <Divider
                            my="xs"
                            label={<Button
                              variant="subtle"
                              color="dark"
                              size="xs"
                              compact
                              onFocus={() => {
                                if (userFieldHideShow == "Y" && UFT41H && UFT41H?.length > 3) {
                                  spoilerControlRef.current.click()
                                }
                              }}
                            >User Fields</Button>}
                            labelPosition="center"
                            labelProps={{ component: "strong" }}
                          /> : null
                      }
                      {/* Header User Field  */}
                      <Spoiler
                        // initialState={userFieldSpoiler}
                        // initialState={true}

                        maxHeight={40}
                        showLabel
                        hideLabel
                        controlRef={spoilerControlRef}
                      >
                        <Grid gutter={2} onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}>{UFT41HFieldRenderer("Header")}</Grid>
                      </Spoiler>
                    </>
                  }
                  <Divider
                    my="xs"
                    label={<Button
                      variant="subtle"
                      color="dark"
                      size="xs"
                      compact
                      onFocus={() => {
                        if (userFieldHideShow == "Y" && UFT41H && UFT41H?.length > 3) {
                          spoilerControlRef.current.click()
                        }
                        TableContainer?.current?.focus()

                        // TableRef?.current?.focus()

                        setRowSelection({ 0: true })
                      }}
                    >Products</Button>}
                    labelPosition="center"
                    labelProps={{ component: "strong" }}
                  />
                </Grid.Col>
                <Grid.Col span={12} my={7}
                // onKeyUp={(e) => {
                //   GlobalClass.formNextInput(e, formRef);
                // }}
                >
                  {/* <MantineReactTable table={table} /> */}
                  {/* <Grid.Col span={12}> */}
                  <MantineReactTable table={table} />
                </Grid.Col>
                {/* </Grid.Col> */}
              </Grid>


              {/* <div>{Summary(c, i, "v")}</div> */}
              {/* <Divider my={2} /> */}
              <Grid>
                <Grid.Col span={12}>
                  {userFieldHideShow == "Y" && UFT41HFieldRenderer("Footer")}
                </Grid.Col>
              </Grid>

              {/* Footer User Field  */}
              {/* <Grid gutter={4}>
     <Grid.Col span={6}>{UFT41HFieldRenderer("Footer")}</Grid.Col>
      </Grid> */}
              <Grid
                span={12}
                my={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid.Col span={5} style={{ alignItems: "center" }}>
                  <Grid.Col span={10} p={0}>

                    <Text style={{ fontSize: 12 }}>Narration:</Text>
                  </Grid.Col>
                  <Grid.Col span={12} p={0} display={"flex"}>
                    <Grid.Col span={9} p={0}>
                      <PLTextarea
                        minRows={2}
                        value={group?.FIELD15}
                        onChange={(e) => {
                          // //console.log(e)
                          setGroup({ ...group, FIELD15: e.target.value });
                        }}
                        onKeyDown={(e) => {
                          // console.log("Narration=>", e.shiftKey);
                          if (e.ctrlKey && e.key === "F1") {
                            //console.log("Narration call");
                            setDrawerObj({
                              ...dra,
                              title: <Text fw={700}>Naration</Text>,
                              body: (
                                <PLNarration
                                  NarrationArray={NarrationData}
                                  Flag={
                                    SelectedNarrationData?.length > 0
                                      ? false
                                      : true
                                  }
                                  selectedNarrationArray={SelectedNarrationData}
                                  // NarrationVal={group}
                                  setNarrationArray={(e) => {
                                    // setGroup(e)
                                    // console.log("setNarrationArray", e);
                                    setNarrationData(e.NarrationData);
                                    setSelectedNarrationData(
                                      e.selectedNarration
                                    );
                                  }}
                                  DrawerOBJ={DrawerObj}
                                  setDrawerOBJ={(e) => setDrawerObj(e)}
                                />
                              ),
                              open: true,
                              size: "md",
                              position: "right",
                            });
                          }
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={3} style={{ display: "flex" }}>
                      {/* <Grid.Col span={6} p={0}>
                                            <Button size='xs' variant='light' compact>Scheme Off</Button>
                                        </Grid.Col> */}
                      <Grid.Col span={6}>
                        <Button
                          hidden
                          variant="light"
                          compact
                          onFocusCapture={() => {
                            // console.log("Bill to Bill call");
                            // if (T05ENT) {
                            //   BillToBillTableFun();
                            // }
                          }}
                          onClick={() => (T05ENT ? BillToBillTableFun() : null)}
                        >
                          Bill to Bill
                        </Button>
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Button hidden size='sm' variant='light' compact onFocusCapture={() => { document.getElementById('okbutton').focus(); BillToBillTableFun() }} onClick={() => BillToBillTableFun()}>Bill to Bill</Button> */}
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  // offset={2}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Group style={{ display: "flex" }}>
                    <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                      Party Amount:
                    </Text>
                    {isVisible ? (
                      <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                        {/* {billAmt ?? group?.FIELD06} */}
                        {rowSum}
                      </Text>
                    ) : (
                      ""
                    )}
                  </Group>

                  <Group wrap="nowrap" mt={5}>
                    <Button
                      style={{ height: "30px", width: "100px" }}
                      size="sm"
                      onClick={() => BillNoStatus ? GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present"):  handleOkButton()}
                      disabled={
                        enableOk
                        // || t01Data?.length <= 1
                      }
                    >
                      Ok
                    </Button>
                    <Button
                      style={{ height: "30px", width: "100px" }}
                      size="sm"
                      onClick={OnPrint}>
                      Print
                    </Button>
                  </Group>
                  {/* </Box> */}
                </Grid.Col>
              </Grid>
            </Paper>
            {lEditVou == false && (
              <> {GlobalClass.Alert("info", props.index)}</>
            )}
          </div>
        </>
      )}
    </>
  );
}
