import React from 'react'
import { ModalFunction } from '../../utils/slices/ModalSlice'
import Store from '../../utils/store'
import LeadEntryForm from './LeadEntryForm'
import { Text } from '@mantine/core'
import { DeleteLeadEntryData, GetLeadEntryData } from '../../utils/slices/LeadEntrySlice'
import GlobalClass from '../../utils/GlobalClass'
import gensetting from '../../utils/gensetting'
import { GetAccountList } from '../../utils/slices/AccountListSlice'

export function M02LAED(data) {
  const OBJ = JSON.parse(data)
  console.log("OBJ M02LAED", OBJ)
  const getUserId = JSON.parse(localStorage.getItem("authUser"));

  if (OBJ.p0 === "A") {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {

        },
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "50%",
        position: "right",
        MBody: (i) => (
          <LeadEntryForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 == "E" && OBJ.id) {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {

        },
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "50%",
        position: "right",
        MBody: (i) => (
          <LeadEntryForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 == "F" && OBJ.id) {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {

        },
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "50%",
        position: "right",
        MBody: (i) => (
          <LeadEntryForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 == "S" && OBJ.id) {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {

        },
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "50%",
        position: "right",
        MBody: (i) => (
          <LeadEntryForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 == "C" && OBJ.id) {
    Promise.resolve(Store.dispatch(GetLeadEntryData({ action: "E", code: OBJ.id }))).then((res) => {
      console.log("res=>", res)
      if (res) {
        let JData = { M02: { ...res?.payload?.M02, FIELD70: getUserId?.UserId } }
        console.log("jData", JData)
        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "",
          cCode: OBJ.id,
          cSData: JSON.stringify(JData),
        };
        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "",
          cCode: OBJ.id,
        });
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(
          GlobalClass.ApiUrl + GlobalClass.PostM02ToCust + "?pa=" + param,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status == "SUCCESS") {
              let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
              // Store.dispatch(GetAccountList({
              //     id: "20570001",
              //     name: "Lead Entry",
              //     p0: "MT",
              //     p1: "P_M02L",
              //     p2: "",
              //     p3: "",
              //     type: "A",
              //     pagination: PaginationObj["20570001"],
              //   }))
              Store.dispatch(GetAccountList({...OBJ?.BtnPnlObj,pagination: PaginationObj[OBJ?.cSTID]}))
            } else {
              GlobalClass.Notify("error", data?.status, data?.message)
            }
          })
          .catch((e) => {
            GlobalClass.Notify(
              "error",
              "Error",
              `${e?.message}`
            )
          });
      }

    })
  } else if (OBJ.p0 == "D" && OBJ.id) {
    if (window.confirm('Confirm to Delete ?')) {
      Store.dispatch(DeleteLeadEntryData(OBJ))
   }
  }
}

const LeadEntry = () => {
  return (
    <div>LeadEntry</div>
  )
}

export default LeadEntry