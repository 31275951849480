import * as NarrationPopup from "../FunctionsCall/Narration/Narration";
import * as ProductHsnCode from "../FunctionsCall/ProductList/HSNCode";
import * as ProductSaleUnit from "../FunctionsCall/ProductList/SaleUnit";
import * as ProductPurchaseUnit from "../FunctionsCall/ProductList/PurchaseUnit";

export const M35AED = (itemOBJ) => { //Transaction > Narration
    const OBJ = itemOBJ;
    console.log('executeFunction M35AED ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "P_HSN": // Master > Product list > HSN code
            ProductHsnCode.M35AED(JSON.stringify(OBJ))
            break;
        case "P_UNTS": //Master > Product List > Sale Unit
            ProductSaleUnit.M35AED(JSON.stringify(OBJ))
            break;
        case "P_UNTP": //Master > Product List > Purchase Unit
            ProductPurchaseUnit.M35AED(JSON.stringify(OBJ))
            break;

        default:
            if (OBJ?.page == "Narration") {
                NarrationPopup.M35AED(JSON.stringify(OBJ))
            }
            break;
    }

}