import React from 'react'
import * as AccountList from "../FunctionsCall/M01Form/m01"

const M01 = () => {
  return (
    <div>M01</div>
  )
}
export default M01


//Master > Account List
export function M01AED(itemOBJ) {
    const OBJ = itemOBJ;
    console.log('executeFunction M01AED ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "T_M01": // Master > Account List
        case "P_M01":
        case "T_M01OB": // Report > Balance Sheet > Trial Balance > Opening Balance
        case "LEDMAIN": //Report Account Ledger
            AccountList.M01AED(JSON.stringify(OBJ))
            break;
        default:
            break;
    }
}

