import { Paper, ScrollArea, Tabs } from "@mantine/core";
import {
  IconPhoto,
  IconMessageCircle,
  IconSettings,
  IconX,
  IconDashboard,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabAdd, TabRemove, UpdateSelected } from "../utils/slices/TabList";
import DataGrid from "../components/DataGrid";
import MyComponent from "../MyComponent/MyComponent";
import { TDSMaster } from "../FunctionsCall/TDSMaster/TDSMaster";
import SalesSetup from "../FunctionsCall/SalesAndPurchaseSetup/SalesSetup";
import StockRate from "../FunctionsCall/StockRate/StockRatePage";
import { CashBank } from "../Transaction/QuickEntry/CashBank";
// import ItemWiseExpense from "../FunctionsCall/OtherInfo/ItemWiseExpense";
import ItemwiseExpenseList2 from "../FunctionsCall/ItemWiseExpense/ItemwiseExpenseList2";
// import OpeningStock from "../Transaction/StockEntry/OpeningStock";
import VoucherNumberGrid from "../SetupComponent/VoucherNumber/VoucherNumberGrid";
import NewDataGrid from "../components/NewDataGrid";
import ItemWiseExpenseTableOne from "../FunctionsCall/ItemWiseExpense/ItemWiseExpenseTableOne";
import SalesPurchaseSetup from "../FunctionsCall/SalesAndPurchaseSetup/SalesPurchaseSetup";
import SalesSetupNew from "../FunctionsCall/SalesAndPurchaseSetup/SalesSetupNew";
import { GetButtonPannel, GetButtonPannelList, GetCmpSetUpObj } from "../utils/slices/DataSaveLoadingSlice";
import { Dashboard } from "./Dashboard";
import DataGridSyncfusion from "../components/DataGridSyncfusion";
import { SaveAccountListData, getCurrentDate, getSortingField } from "../utils/slices/AccountListSlice";
import { ModalDelete } from "../utils/slices/ModalSlice";
import useWindowDimensions from "../utils/UseWindowDimensions";
import { useNavigate, useParams } from "react-router-dom";
import { getFilterStatus } from "../utils/FilterSlice/FilterSlice";

export default function TabView(props) {
  const { height, width } = useWindowDimensions();
  const TabList = useSelector((state) => state.TabList.value);
  const selected = useSelector((state) => state.TabList.selected);
  const SaveAccountListArr = useSelector((store) => store?.AccountList.SaveAccountListArr); //Rathod kishan
  const BtnStatus = useSelector((store) => store?.DataSaveLoadingSlice.ButtonPannelSave); //rathod kishan
  const BtnStatusList = useSelector((store) => store?.DataSaveLoadingSlice.ButtonPannelList); //rathod kishan

  const { AccountListData: accList, TMFormatData: tmList } = useSelector((state) => state.AccountList); // rathod kishan
  const ReportIdArray = useSelector((state) => state.DataSaveLoadingSlice.ReportIdArray); // rathod kishan

  const dispatch = useDispatch();
  const [tabRenderKeys, setTabRenderKeys] = useState({});
  const navigate = useNavigate();
  const { tabValue } = useParams();
  // console.log(Object.keys(TabList) , "TabList")
  useEffect(() => {
    // console.log("tablog",TabList[selected]);

  }, []);
  const triggerTabReRender = (tabKey) => {
    setTabRenderKeys((prev) => ({
      ...prev,
      [tabKey]: (prev[tabKey] || 0) + 1,
    }));
  };
  

  const removeObjectKey = (data, key) => {
    const { [key]: _, ...newData } = data
    return newData
  }

  const TabSwitch = (tab, item,cSTID) => {
    const renderKey = tabRenderKeys[tab] || 0;
    // console.log("TabSwitch =>",[{
    //   tab:tab,
    //    item:item,
    //    cSTID:cSTID
    // }])
    item = {
      ...item,
      cSTID:cSTID
    }
    if(tab.includes("modalForm")){
      // return <ScrollArea h={height*0.8} type="never">{item.p0.MBody(tab)}</ScrollArea>
      return <>{item.p0.MBody(tab)}</>
    }
    switch (cSTID) {
      // case "01980001":
      //   return <DataGrid tag="PM" obj={item} />
      //   break;
      // case "03600342":
      //   return <StockJournal tag="M" obj={item}/>
      case "T92":
        return <Paper id={"tab"+tab} key={renderKey}><VoucherNumberGrid tag="M" obj={item} /></Paper>;
        break;
      case "M21B":
        return <Paper id={"tab"+tab} key={renderKey}><StockRate tag="M" obj={item} /></Paper>;
        break;
      case "SS": //VAT/TDS > Sales Setup
      case "PP": //VAT/TDS > Purchase Setup
      // case "00780072": //GST > Sales Setup
      // case "00810072": //GST > Purchase Setup
      case "JJ": //GST > J/W In Setup
      case "JZ": //GST > J/W Out Setup
        return <Paper id={"tab"+tab} key={renderKey}><SalesSetupNew tag="M" obj={item} /></Paper>;
        // return <SalesPurchaseSetup tag="M" obj={item} />
        break;
      case "M_VTDS":
        return <Paper id={"tab"+tab} key={renderKey}><TDSMaster tag="M" obj={item} /></Paper>;
        break;
      case "M_OE":
        // return <ItemwiseExpenseList2 tag="M" obj={item} />;
        return <Paper id={"tab"+tab} key={renderKey}><ItemWiseExpenseTableOne obj={item} /></Paper>
        break;
      case "T_QC":
      case "T_QJ":
        return <Paper id={"tab"+tab} key={renderKey}><CashBank tag="M" obj={item} /></Paper>
        break;
      // case "03450342":
      //   return <OpeningStock tag="M" obj={item} />;
      case "_MNU0114":
      case "_MNU0115":
        return <Paper id={"tab"+tab} key={renderKey}><MyComponent obj={item?.p0} /></Paper>
      case "Dash":
        return ""//<Dashboard/>;
      
      default:
        // return <Paper id={"tab"+tab} key={renderKey}><DataGridSyncfusion tag="M" obj={item} IsMultiSelect={false}/></Paper>
        return <Paper id={"tab"+tab} key={renderKey}><DataGrid tag="M" IsMultiSelect={false} obj={item} /></Paper>//<NewDataGrid tag="M" obj={item} />//<DataGrid tag="M" obj={item} />;
        break;
    }
  };
  if(Object.keys(TabList).findIndex((i) => i == tabValue) == -1){
    
  }
// useEffect(() => {
//   if(tabValue != selected){
//     // dispatch(UpdateSelected(tabValue))
//     // navigate(`/${tabValue}`);
//     let inTablist = Object.keys(TabList).find((key) => key == tabValue);
//     TabChange(!inTablist?tabValue:selected);
//     // dispatch(UpdateSelected(tabValue));
//   }
// },[tabValue,selected])
// function TabChange(value){
//   if(selected == value){
//     navigate(`/${value}`);
//   }
//   else{
//     Promise.resolve(dispatch(UpdateSelected(value))).then(() => {
//       navigate(`/${value}`);
//     })
//   }
// }
  return (
    <div id="mainTabID">
    <Tabs
      value={selected}
      // onTabChange={(value) => {
      //   Object.keys(TabList).map((i) => {
      //     if(!i.includes("modalForm")){
      //       if(i == value){
      //         triggerTabReRender(i);
      //       }
      //     }
      //   })
      //   dispatch(UpdateSelected(value))
      // }}
      onTabChange={(value)=>dispatch(UpdateSelected(value))}
      keepMounted={false}
      variant="outline"
      defaultValue="Dashboard"
      
    >
      <Tabs.List>


        {TabList &&
          Object.keys(TabList).map((i) => {
            var item = TabList[i];
            // console.log("item==>",[{
            //   item:item,
            //   i:i
            // }])
            if(i.includes("modalForm")){
              return (<Tabs.Tab
                value={i}
                
                key={i}
                rightSection={
                  <IconX
                    onClick={() => {
                      if(item?.p0?.onClickCloseButton && typeof item?.p0?.onClickCloseButton == "function"){
                        item?.p0?.onClickCloseButton()
                      }
                      // console.log("OnClickCloseTab =>", item);
                      dispatch(getSortingField({ "arrange": "", "order": "", "id": i }));
                      // dispatch(getCurrentDate({ "date": {}, "id": i, "flag": false }));

                      if ((ReportIdArray.includes(item?.id)) && (SaveAccountListArr) && (item.id in SaveAccountListArr)) {

                        // console.log("RemoveTabConsole 1=>", [{
                        //   "item": item,
                        //   "SaveAccountListArr": SaveAccountListArr,
                        //   "BtnStatus": BtnStatus,
                        //   "BtnStatusList":BtnStatusList
                        // }]);

                        const arr1 = removeObjectKey(SaveAccountListArr, item.id)
                        const arr2 = removeObjectKey(BtnStatus, item.id)
                        const arr3 = removeObjectKey(BtnStatusList,item.id)

                        // console.log("RemoveTabConsole 2=>", [{
                        //   "item": item,
                        //   "SaveAccountListArr": arr1,
                        //   "BtnStatus": arr2,
                        //   "BtnStatusList":arr3
                        // }]);

                        dispatch(SaveAccountListData({ ...arr1 }))
                        dispatch(GetButtonPannel({ ...arr2 }))
                        dispatch(GetButtonPannelList({...arr3}))
                      }

                      dispatch(TabRemove(i));
                      // dispatch(ModalDelete(item.modalid));
                      if (item.id == "_MNU0114" || item.id == "_MNU0115") {
                        dispatch(GetCmpSetUpObj(null))
                      }
                    }}
                  />
                }
              >
                {item.p0.MTitle}
              </Tabs.Tab>) 
            }
            else if (i == "Dash") {
              return (<Tabs.Tab
                value={i}
                key={i}
                icon={<IconDashboard size="0.8rem" />}
              >
                {item.name}
              </Tabs.Tab>)
            }
            else{
            return (
              <Tabs.Tab
                key={i}
                
                rightSection={
                  <IconX
                    onClick={() => {
                      // console.log("OnClickCloseTab =>", item);
                      dispatch(getFilterStatus(false))
                      dispatch(getSortingField({ "arrange": "", "order": "", "id": i }));
                      // dispatch(getCurrentDate({ "date": {}, "id": i, "flag": false }));

                      if ((ReportIdArray.includes(item?.id)) && (SaveAccountListArr) && (item.id in SaveAccountListArr)) {

                        // console.log("RemoveTabConsole 1=>", [{
                        //   "item": item,
                        //   "SaveAccountListArr": SaveAccountListArr,
                        //   "BtnStatus": BtnStatus,
                        //   "BtnStatusList":BtnStatusList
                        // }]);

                        const arr1 = removeObjectKey(SaveAccountListArr, item.id)
                        const arr2 = removeObjectKey(BtnStatus, item.id)
                        const arr3 = removeObjectKey(BtnStatusList,item.id)

                        // console.log("RemoveTabConsole 2=>", [{
                        //   "item": item,
                        //   "SaveAccountListArr": arr1,
                        //   "BtnStatus": arr2,
                        //   "BtnStatusList":arr3
                        // }]);

                        dispatch(SaveAccountListData({ ...arr1 }))
                        dispatch(GetButtonPannel({ ...arr2 }))
                        dispatch(GetButtonPannelList({...arr3}))
                      }

                      dispatch(TabRemove(i));
                      if (item.id == "_MNU0114" || item.id == "_MNU0115") {
                        dispatch(GetCmpSetUpObj(null))
                      }
                    }}
                  />
                }
                value={i}

              >
                {item.name}
              </Tabs.Tab>
            );}
          })}
      </Tabs.List>

      {TabList &&
        Object.keys(TabList).map((i) => {
          var item = TabList[i];
          
          return (
            <Tabs.Panel key={i + "Panel"} value={i} pt="xs">
              {TabSwitch(i, item,(item.id == "_MNU0114" || item.id == "_MNU0115" || item.id == "UM" ? item?.id : 
              item.name == "Dashboard"? "Dash":
              item?.p1))}
            </Tabs.Panel>
          );
        })}
    </Tabs>
    </div>
  );
}
