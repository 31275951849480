import * as AccountGroup from "../FunctionsCall/AccountGroup/AccountGroup";
import * as ProductGroupCategory from "../FunctionsCall/OtherInfo/ProductGroup";
import * as LocationList from "../FunctionsCall/LocationList/LocationList";
import * as JobProcessList from "../FunctionsCall/JobProcessList/JobProcessList";
import * as CityList from "../FunctionsCall/OtherInfo/CityList";
import * as AreaList from "../FunctionsCall/OtherInfo/AreaList";
import * as AccountCategory from "../FunctionsCall/AccountCategory/AccountCategory";
import * as EcommersOperator from "../FunctionsCall/OtherInfo/ECommerceOperator";
import * as TestCerti from "../FunctionsCall/UserMaster/UserMasterEntry/TestCertificate/TestCertificate";
import * as UserMasterFields from "../FunctionsCall/UserMaster/UserMasterEntry/UserMasterEntry"


export const MSAED = (itemOBJ) => {
    const OBJ = itemOBJ;
    console.log('executeFunction MSAED ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "T_M11": // Master > Account Group
        case "P_M11E":
            AccountGroup.MSAED(JSON.stringify(OBJ))
            break;
        case "T_M32G": // Master > other info > Product Group
        case "P_M32G":
        case "T_M32C": //Master > other info > Product Category 
        case "P_M32C":
            ProductGroupCategory.MSAED(JSON.stringify(OBJ))
            break;
        case "T_M32L": // Master > Other info > Location List
        case "P_M32L":
            LocationList.MSAED(JSON.stringify(OBJ))
            break;
        case "T_M31J": // Master > Other info > Job Process List
        case "P_M31J":
            JobProcessList.MSAED(JSON.stringify(OBJ))
            break;
        case "T_M31T": // Master > Other info > City List
        case "P_M31T":
            CityList.MSAED(JSON.stringify(OBJ))
            break;
        case "T_M31A": // Master > Other info > Area List
        case "P_M31A":
            AreaList.MSAED(JSON.stringify(OBJ))
            break;
        case "T_M31R": // Master > Other info > Acount Category List
        case "P_M31R":
            AccountCategory.MSAED(JSON.stringify(OBJ))
            break;
        case "T_G02": // Master > Other info > E-Commers Operator
            EcommersOperator.MSAED(JSON.stringify(OBJ))
            break;
        case "T_TESTCERT": // Master > Other info > User Master Entry > Test Certi
            TestCerti.MSAED(JSON.stringify(OBJ))
            break;
        default: // User Master Dynamic Fields and User Field Help "YES" then call this function
            // if ((props?.from?.charAt(0) == "U" || props?.from?.startsWith("P_U")) &&  !props?.from?.startsWith("P_UNIT")){
                
            if ((OBJ?.page?.startsWith("UM")|| OBJ?.page?.startsWith("P_U") || ["UM"].includes(OBJ?.cSTID)) && !OBJ?.page?.startsWith("P_UNIT")) {
                if(OBJ?.BtnPnlObj?.p1=="T_TESTCERT"){
                    TestCerti.MSAED(JSON.stringify(OBJ))
                }else{
                UserMasterFields.MSAED(JSON.stringify(OBJ))
                }
            }
            break;
    }
}