import * as PriceList from "../FunctionsCall/PriceList/PriceList"

export const M24AED = (itemOBJ) => {
    const OBJ = itemOBJ;
    console.log("executeFunction M24AED =>", OBJ)

    switch (OBJ?.cSTID) {
        case "T_M24": // Master > Price List
            PriceList.M24AED(JSON.stringify(OBJ))
            break;

        default:
            break;
    }
}