import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
// import { notifications } from "@mantine/notifications";
import Store from "../store";
import { GetAccountList } from "./AccountListSlice";

export const ProcessMasterData = createAsyncThunk(
  "ProcessMaster/ProcessMasterData",
  async (obj) => {
    // console.log("ProcessMaster ==>", obj);
   
    try {
      let param = JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cAction": obj.action ? obj.action : "A",
        'cCode': obj.code ? obj.code : "",
        "cOPara": "",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM71Ent + "?pa=" + param);
      // const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM71Ent + "?=" + param);

      // console.log("ProcessMaster/ProcessMasterData", response?.data?.data)
      if(obj.action === 'D'){
        if (response.data.status === "SUCCESS") {
          let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
  
          Promise.resolve(Store.dispatch(GetAccountList({
            'id': 'T_M71',
            'name': 'Process Master',
            'p0': 'MT',
            'p1': 'T_M71',
            'p2': "",
            'p3': "",
            'type': "A",
            'pagination': PaginationObj['T_M71']
          })
          )).then(() => GlobalClass.Notify("success", "Deleted", "Deleted successfully"))
        } else {
            GlobalClass.Notify( "error", response?.data?.status, response?.data?.message) 
        }
      }
      if (response.data.status === "SUCCESS") {
        console.log("ProcessMaster/ProcessMasterData s=>",response?.data);
        return response?.data?.data;
      }else{
        console.log("ProcessMaster/ProcessMasterData f=>",response?.data);
        return {error:response?.data}
      }
      
    } catch (e) {
      // GlobalClass.Notify("error", "Error", `${e?.message}`);
      console.log("ProcessMaster/ProcessMasterData ff=>",e);
      return {error: e };
    }
  }
);

const ProcessMasterSlice = createSlice({
  name: "ProcessMaster",
  initialState: {
    ProcessMData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
  },
  reducers: {
    handleProcessMasterNullState:(state, action)=>{
      state.ErrorMsg = {};
      state.hasError = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ProcessMasterData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(ProcessMasterData.fulfilled, (state, action) => {
        console.log('ProcessMaster/ProcessMasterData action.payload 1==>',action.payload.error);
        if(!action.payload.error){
          state.ProcessMData = action.payload;
          state.isLoading = false;
          state.hasError = false;
        }else{
        console.log('ProcessMaster/ProcessMasterData action.payload 2==>',action.payload.error);

          state.ProcessMData = [];
          state.ErrorMsg = action.payload.error;
          state.isLoading = false;
          state.hasError = true;
        }
      })
      .addCase(ProcessMasterData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});
export const { handleProcessMasterNullState } = ProcessMasterSlice.actions;
export default ProcessMasterSlice.reducer;
