import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import GlobalClass from '../GlobalClass';
import gensetting from '../gensetting';
import axios from 'axios';
import { GetAccountList } from './AccountListSlice';
import Store from '../store';

export const GetLeadEntryData = createAsyncThunk(
    "LeadEntry/GetLeadEntryData",
    async (data) => {
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": data ? data.action : "A", 
                    "cCode": data ? data.code : "",
                    "cOPara": "",
                    "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM02Lead + "?pa=" + param)
            return response.data?.data;
        } catch (error) {
            GlobalClass.Notify("error", "Error", "Fail", error.message)
            console.error(error)
            return { error }
        }
    }
)

export const DeleteLeadEntryData = createAsyncThunk(
    'LeadEntry/DeleteLeadEntryData',
    async (obj) => {
        GlobalClass.Notify(
            "info",
            "Deleting",
            "Please wait while we process your data"
        );
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": obj.p0,
                    "cCode": obj.id,
                    "cOPara": '',
                    "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM02Lead + "?pa=" + param);
            if (response.data.status === "SUCCESS") {
                let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
                Promise.resolve(GlobalClass.Notify('success', 'Success:' + 'Delete Data', 'Delete Succesfully')).then(() => Store.dispatch(GetAccountList({
                    id: "P_M02L",
                    name: "Lead Customer Entry",
                    p0: "MT",
                    p1: "P_M02L",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["P_M02L"]
                })))
            } else {
                GlobalClass.Notify("success", "Deleted", "Deleted successfully");
            }
            return response.data
        } catch (e) {
            GlobalClass.Notify("error", "Error", `${e?.message}`);
            return { e };
        }
    }

)

const LeadEntrySlice = createSlice({
    name: "LeadEntry",
    initialState: {
        LeadEntryData: [],
        isLoading: true,
        hasError: false,
        ErrorMsg: ""
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetLeadEntryData.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetLeadEntryData.fulfilled, (state, action) => {
                console.log("GetLeadEntryData action.payload", action.payload);
                if (!action.payload?.error) {
                    state.LeadEntryData = action.payload;
                    state.isLoading = false;
                    state.hasError = false;
                } else {
                    state.isLoading = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error;
                }
            })
            .addCase(GetLeadEntryData.rejected, (state, action) => {
                state.isLoading = false;
                state.hasError = true;
            })
    }
});

export const { } = LeadEntrySlice.actions

export default LeadEntrySlice.reducer